<template>
  <wizard-base
    class="living-commercial-space"
    :title="$t('WIZARD.LIVING_AND_COMMERCIAL_SPACE.TITLE')"
    :subtitle="$t('GENERAL.FIELD_REQUIRED')"
    :icon="slideIcon"
  >
    <div class="living-commercial-space__fields">
      <hd-input-formatter
        v-model="livingSpace"
        name="living-space"
        type="number"
        :label="$t('GENERAL.LIVING_SPACE')"
        :formatter="$formatSpace"
        :custom-rules="customRules"
        class="living-commercial-space__input"
      />
      <hd-input-formatter
        v-model="commercialSpace"
        name="commercial-space"
        type="number"
        :label="$t('GENERAL.COMMERCIAL_SPACE')"
        :formatter="$formatSpace"
        :custom-rules="customRules"
        class="living-commercial-space__input"
      />
    </div>
  </wizard-base>
</template>

<script>
// Components
import WizardBase from '@/layout/WizardBase.vue';
// Services
import { HdInputFormatter } from 'homeday-blocks';
import { mapFeatures } from '@/services/feature';
// Icons
import { apartmentCommercial as slideIcon } from 'homeday-assets/L';
import { toFinite as _toFinite, isNumber as _isNumber, inRange as _inRange } from 'lodash-es';
import { MAXIMUM_SPACE, MINIMUM_SPACE } from '@/config/CONSTANTS';

export default {
  name: 'LivingAndCommercialSpaceSlide',
  components: {
    HdInputFormatter,
    WizardBase,
  },
  data() {
    return {
      slideIcon,
    };
  },
  computed: {
    ...mapFeatures(['livingSpace', 'commercialSpace']),
    /** @returns {import('@/@types/global').ValidationRules} */
    customRules() {
      return [
        {
          validate: (value) => {
            if (!_isNumber(Number(value))) return false;
            return _inRange(_toFinite(value), MINIMUM_SPACE, MAXIMUM_SPACE);
          },
          errorMessage: this.$t('FIELD_VALIDATIONS.INCORRECT_VALUE'),
        },
      ];
    },
    deal() {
      return this.$store.state.wizard.currentDeal;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.living-commercial-space {
  ::v-deep .wizard-base {
    &__container {
      justify-content: center;
    }

    &__content {
      flex-grow: 0;
    }
  }
}
</style>
