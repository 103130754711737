import { GETTER } from './CONSTANTS';

export default {
  [GETTER.UID](state) {
    return state.data.uid;
  },
  [GETTER.AUTHENTICATED](state) {
    return Boolean(state.token);
  },
  [GETTER.FULL_NAME](state) {
    const { first_name: firstName = '', last_name: lastName = '', salutation } = state.data;

    if (!firstName) {
      return `${salutation} ${lastName}`;
    }

    return `${firstName} ${lastName}`;
  },
  [GETTER.FORMAL_NAME](state) {
    const { first_name: firstName = '', last_name: lastName = '', title, salutation } = state.data;
    let formalName = lastName;

    if (title) {
      formalName = `${title} ${formalName}`;
    }

    if (salutation) {
      formalName = `${salutation === 'male' ? 'Herr' : 'Frau'} ${formalName}`;
    }

    if (firstName && formalName === lastName) {
      formalName = `${firstName} ${lastName}`;
    }

    return formalName;
  },
};
