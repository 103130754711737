<template>
  <wizard-base class="procedure" :title="$t('WIZARD.PROCEDURE.TITLE')">
    <property-address />
    <ProcedureItems :items="items" />
  </wizard-base>
</template>

<script>
// Components
import WizardBase from '@/layout/WizardBase.vue';
import ProcedureItems from '@/components/ProcedureItems.vue';
import PropertyAddress from '@/components/PropertyAddress.vue';
// Icons
import {
  locationQuality as locationIcon,
  propertyMagnifier as propertyIcon,
  germanyMapMagnifyingGlass as marketingIcon,
  myHomedayApp as soldIcon,
} from 'homeday-assets/L';

export default {
  name: 'ProcedureSlide',
  components: {
    WizardBase,
    ProcedureItems,
    PropertyAddress,
  },
  data() {
    return {
      items: [
        {
          icon: locationIcon,
          label: this.$t('GENERAL.LOCATION'),
        },
        {
          icon: propertyIcon,
          label: this.$t('GENERAL.PROPERTY'),
        },
        {
          icon: marketingIcon,
          label: this.$t('GENERAL.TARGET_GROUP'),
        },
        {
          icon: soldIcon,
          label: this.$t('GENERAL.ASSESSMENT_REPORT'),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.procedure {
  &.slides__slide {
    padding-top: 0;
  }
}
</style>
