import {
  mapValues as _mapValues,
  isObject as _isObject,
  isString as _isString,
  isEmpty as _isEmpty,
  isNil as _isNil,
  omitBy as _omitBy,
  trim as _trim,
} from 'lodash-es';

function isEmptyObject(object) {
  return _isObject(object) && _isEmpty(object);
}

function isEmptyString(value) {
  return _isString(value) && _isEmpty(_trim(value));
}

function cleanupObject(object) {
  const newObject = _mapValues(object, (value) => {
    if (_isObject(value)) {
      return cleanupObject(value);
    }

    return value;
  });

  return _omitBy(
    newObject,
    (value) => _isNil(value) || isEmptyObject(value) || isEmptyString(value),
  );
}

const sentry = {
  dsn: process.env.VUE_APP_SENTRY,
};

const imgix = {
  domain: process.env.VUE_APP_IMGIX_DOMAIN,
  secureURLToken: process.env.VUE_APP_IMGIX_TOKEN,
};

const cookiebot = {
  enabled: Boolean(process.env.COOKIEBOT_ID),
  id: process.env.COOKIEBOT_ID,
};

const mapbox = {
  staticImagesApi: process.env.VUE_APP_MAPBOX_STATIC_API,
  accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
};

const gtm = {
  id: process.env.VUE_APP_GTM_ID,
  application: process.env.VUE_APP_GTM_APPLICATION,
};

export const isDevelopment = process.env.VUE_APP_MODE === 'development';

export default {
  environment: process.env.VUE_APP_MODE,
  api: {
    register_user: `${process.env.VUE_APP_API_BASE}/v2/users/register`,
    request_new_password: `${process.env.VUE_APP_API_BASE}/v2/users/forgot_password`,
    confirm_new_password: `${process.env.VUE_APP_API_BASE}/v2/users/new_password`,
    update_user: `${process.env.VUE_APP_API_BASE}/v2/user/profile`,
    sessions: `${process.env.VUE_APP_API_BASE}/v2/sessions`,
    deals: `${process.env.VUE_APP_API_BASE}/v2/deals/:dealUid`,
    evaluation_report: `${process.env.VUE_APP_API_BASE}/v2/evaluations/send_report`,
    potential_buyers: `${process.env.VUE_APP_API_BASE}/v2/potential_buyers`,
    evaluations: `${process.env.VUE_APP_API_BASE}/v2/evaluations`,
    similar_properties: `${process.env.VUE_APP_API_BASE}/v2/similar_properties`,
  },
  integrations: cleanupObject({
    sentry,
    imgix,
    mapbox,
    gtm,
    cookiebot,
  }),
};
