import Vue from 'vue';
import { pick as _pick, merge as _merge } from 'lodash-es';
import { getComputedProperties } from '@/services/deal';
import { STATUS } from '@/config/WIZARD';
import { MUTATION } from './CONSTANTS';

export default {
  [MUTATION.CLEAN_DEALS_CACHE](state) {
    Vue.set(state, 'deals', []);
  },
  [MUTATION.TOGGLE_NAVIGATION](state, value) {
    Vue.set(state, 'menuIsOpen', value);
  },
  [MUTATION.UPDATE_UNSAVED_DEALS](state) {
    Vue.set(state.unsavedDeals, state.currentDeal.uid, {
      ...state.currentDeal,
    });
  },
  [MUTATION.SET_UNSAVED_DEAL_SUGGESTED_SQM_PRICE](state, { uid, suggestedSqmPrice }) {
    const deal = state.deals.find((_) => _.uid === uid);
    if (deal) {
      Vue.set(state.unsavedDeals, uid, {
        ...deal,
        suggestedSqmPrice,
      });
    }
  },
  [MUTATION.SET_DEALS](state, deals) {
    Vue.set(state, 'deals', deals);
  },
  [MUTATION.ADD_DEALS](state, deals) {
    const currentDeals = state.deals;

    Vue.set(state, 'deals', currentDeals.concat(deals));
  },
  [MUTATION.SET_STATUS](state, status) {
    if (!Object.values(STATUS).includes(status)) {
      console.warn('Status not defined', status);
    }

    Vue.set(state, 'status', status);
  },
  [MUTATION.UPDATE_DEAL_PROPERTIES](state, { uid, properties, deep = false }) {
    // The same deal can appear twice (future and past)
    const deals = state.deals.filter((_) => _.uid === uid);

    deals.forEach((deal) => {
      let updatedProperties = properties;

      if (deep) {
        // For performance, we pick only the properties that will be affected by the change
        const changedDealProperties = _pick(deal, Object.keys(properties));
        updatedProperties = _merge(changedDealProperties, properties);
      }

      const entries = Object.entries(updatedProperties);

      entries.forEach(([property, value]) => {
        Vue.set(deal, property, value);
      });

      // As those properties are computed, we have to compute them once again
      const computedPropertiesEntries = Object.entries(getComputedProperties(deal));

      computedPropertiesEntries.forEach(([property, value]) => {
        Vue.set(deal, property, value);
      });
    });
  },
  [MUTATION.SET_IS_SLIDE_VALIDATING](state, isSlideValidating) {
    Vue.set(state, 'isSlideValidating', isSlideValidating);
  },
};
