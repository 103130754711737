import Vue from 'vue';
import { MUTATION } from './CONSTANTS';

export default {
  [MUTATION.SET_OFFLINE](state, offline) {
    Vue.set(state, 'offline', offline);
  },
  [MUTATION.SET_LOADING](state, loading) {
    Vue.set(state, 'loading', loading);
  },
  [MUTATION.SET_SERVICE_WORKER_STATUS](state, serviceWorkerStatus) {
    Vue.set(state, 'serviceWorkerStatus', serviceWorkerStatus);
  },
};
