<template>
  <PageBase class="imprint">
    <Breadcrumb class="imprint__breadcrumb" :page-name="$t('FOOTER.LINK.IMPRESSUM')" />
    <section class="imprint__wrapper columns is-centered is-multiline">
      <div class="column is-full">
        <h1 class="imprint__title">{{ $t('IMPRINT.TITLE') }}</h1>
        <p class="imprint__description">{{ $t('IMPRINT.DESCRIPTION') }}</p>
      </div>
      <div class="column is-5">
        <template v-for="({ title, description }, index) in contactContent">
          <h4 :key="`contact-subtitle-${index}`" class="imprint__subtitle">{{ title }}</h4>
          <p :key="`contact-content-${index}`" class="imprint__content" v-html="description"></p>
        </template>
      </div>
      <div class="column is-7">
        <template v-for="({ title, description }, index) in disclaimerContent">
          <h4 :key="`disclaimer-subtitle-${index}`" class="imprint__subtitle">{{ title }}</h4>
          <p
            :key="`disclaimer-description-${index}`"
            class="imprint__content"
            v-html="description"
          ></p>
        </template>
      </div>
      <div class="column is-full">
        <p v-html="copyrights"></p>
      </div>
    </section>
  </PageBase>
</template>

<script>
// Components
import PageBase from '@/layout/PageBase.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
// Constants
import { CURRENT_YEAR as year } from '@/config/CONSTANTS';

export default {
  name: 'Imprint',
  components: {
    PageBase,
    Breadcrumb,
  },
  computed: {
    contactContent() {
      return [
        {
          title: this.$t('IMPRINT.CONTACT.GROUP_1_TITLE'),
          description: this.$t('IMPRINT.CONTACT.GROUP_1_DESCRIPTION'),
        },
        {
          title: this.$t('IMPRINT.CONTACT.GROUP_2_TITLE'),
          description: this.$t('IMPRINT.CONTACT.GROUP_2_DESCRIPTION'),
        },
        {
          title: this.$t('IMPRINT.CONTACT.GROUP_3_TITLE'),
          description: this.$t('IMPRINT.CONTACT.GROUP_3_DESCRIPTION'),
        },
      ];
    },
    disclaimerContent() {
      return [
        {
          title: this.$t('IMPRINT.DISCLAIMER.GROUP_1_TITLE'),
          description: this.$t('IMPRINT.DISCLAIMER.GROUP_1_DESCRIPTION'),
        },
        {
          title: this.$t('IMPRINT.DISCLAIMER.GROUP_2_TITLE'),
          description: this.$t('IMPRINT.DISCLAIMER.GROUP_2_DESCRIPTION'),
        },
        {
          title: this.$t('IMPRINT.DISCLAIMER.GROUP_3_TITLE'),
          description: this.$t('IMPRINT.DISCLAIMER.GROUP_3_DESCRIPTION'),
        },
      ];
    },
    copyrights() {
      return this.$t('IMPRINT.COPYRIGHTS', { year });
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins.scss';
.imprint {
  height: 100vh;

  &__wrapper {
    padding: $sp-l 0 0 0;
  }

  &__title {
    margin: $sp-m 0;
  }

  &__description {
    margin: $sp-m 0 $sp-l;
  }

  &__subtitle {
    margin: 0 0 $sp-m 0;
  }

  &__content {
    margin: 0 0 $sp-m 0;
  }

  a:visited,
  a:link {
    color: $quaternary-color;
  }
}

.page__main--imprint {
  padding: $sp-l 0;
}
</style>
