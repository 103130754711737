import { namespace } from '@/store/helpers';
import {
  MUTATION as DEAL_MUTATION,
  ACTION as DEAL_ACTION,
  GETTER as DEAL_GETTER,
} from './deal/CONSTANTS';

export const DEAL_MODULE_NAME = 'currentDeal';

export const MUTATION = {
  CLEAN_DEALS_CACHE: 'cleanDealsCache',
  SET_DEALS: 'setDeals',
  ADD_DEALS: 'addDeals',
  SET_STATUS: 'setStatus',
  UPDATE_UNSAVED_DEALS: 'updateUnsavedDeals',
  SET_UNSAVED_DEAL_SUGGESTED_SQM_PRICE: 'setUnsavedDealSuggestedSqmPrice',
  UPDATE_DEAL_PROPERTIES: 'updateDealProperties',
  TOGGLE_NAVIGATION: 'toggleNavigation',
  SET_IS_SLIDE_VALIDATING: 'setIsSlideValidating',
  ...namespace(DEAL_MUTATION, DEAL_MODULE_NAME),
};

export const ACTION = {
  ADD_DEAL: 'addDeal',
  REMOVE_DEAL: 'removeDeal',
  SIGN_BROKER_ORDER: 'signBrokerOrder',
  CLEAN_DEALS_STATE: 'cleanDealsState',
  FETCH_DEALS: 'fetchDeals',
  FETCH_DEAL: 'fetchDeal',
  FETCH_SINGLE_DEAL: 'fetchSingleDeal',
  UPDATE_EVALUATION_REPORT: 'updateEvaluationReport',
  CREATE_EVALUATION: 'createEvaluation',
  ADD_FAILED_REQUEST: 'addFailedRequest',
  REMOVE_FAILED_REQUEST: 'removeFailedRequest',
  ...namespace(DEAL_ACTION, DEAL_MODULE_NAME),
};

export const GETTER = {
  ...namespace(DEAL_GETTER, DEAL_MODULE_NAME),
};
