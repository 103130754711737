var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"property-item"},[_c('div',{staticClass:"property-item__thumbnail",style:({
      gridRowEnd: !_vm.hasSecondThumbnail ? 'span 2' : 'span 1',
      backgroundImage: ("url(" + (_vm.firstThumbnail.url) + ")"),
    }),on:{"click":function($event){return _vm.$emit('thumbnailClick', _vm.firstThumbnail.index)}}}),(_vm.hasSecondThumbnail)?_c('div',{staticClass:"property-item__thumbnail property-item__thumbnail--second",style:({
      backgroundImage: ("url(" + (_vm.secondThumbnail.url) + ")"),
    }),on:{"click":function($event){return _vm.$emit('thumbnailClick', _vm.secondThumbnail.index)}}}):_vm._e(),_c('div',{staticClass:"property-item__details"},[_c('p',{staticClass:"property-item__address"},[_vm._v(" "+_vm._s(_vm.address)+" ")]),_c('dl',{staticClass:"property-item__list"},[_vm._l((_vm.listItems),function(ref){
    var label = ref.label;
    var value = ref.value;
return _c('div',{key:label,staticClass:"property-item__list-item",class:{
          'property-item__list-item--bold':
            label === _vm.$t('WIZARD.SIMILAR_PROPERTIES.DAYS_SINCE_PUBLISHED') &&
            _vm.getDifferenceInDays > 60,
        }},[_c('dt',[_vm._v(" "+_vm._s(label)+" ")]),_c('div',{staticClass:"property-item__separator"}),_c('dd',[_vm._v(_vm._s(value !== undefined ? value : '-'))])])}),_c('div',{staticClass:"property-item__list-item property-item__list-item--highlighted"},[_c('dt',[_vm._v(_vm._s(_vm.$t('WIZARD.SIMILAR_PROPERTIES.OFFER_PRICE'))),_c('span',[_vm._v("*")])]),_c('div',{staticClass:"property-item__separator"}),_c('dd',[_vm._v(_vm._s(_vm.totalPrice))])])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }