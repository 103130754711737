<template>
  <div
    ref="wrapper"
    :class="{
      isEditMode: editMode,
    }"
    class="owner-share-input"
    @click="toggleEnableMode"
  >
    <p
      :class="{
        isEditMode: editMode,
      }"
      class="owner-share-input__value value-tag"
    >
      <span class="value-tag__dynamic">{{ inputValue }}</span>
      <span class="value-tag__static">/{{ ownerShareDenominator }}</span>
    </p>
    <HdIcon class="owner-share-input__icon" :src="edit" width="12" height="12" />
    <form class="owner-share-input__form" @submit.prevent="disableEditMode">
      <input
        ref="input"
        :value="inputValue"
        type="text"
        inputmode="numeric"
        tabindex="-1"
        class="price-tag__input"
        @input="onInputInput($event.target.value)"
        @keyup="setCursorToEnd"
        @blur="onInputBlur"
      />
    </form>
  </div>
</template>

<script>
// Components
import { HdIcon } from 'homeday-blocks';
// Icons
import { edit } from 'homeday-assets';

const ownerShareDenominator = 1_000;

export default {
  name: 'OwnerShareInput',
  components: {
    HdIcon,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
      ownerShareDenominator,
      edit,
    };
  },
  computed: {
    inputValue() {
      return Math.round(this.value * ownerShareDenominator);
    },
  },
  methods: {
    onInputInput(value) {
      let number = Math.min(
        Number(value) / ownerShareDenominator,
        1, // Cap to 1
      );

      if (Number.isNaN(number)) {
        number = 0;
      }

      this.$emit('input', number);
    },
    onInputBlur() {
      // We wait for the other events to be handled
      // to avoid unexpected behaviour when clicking the edit toggle
      setTimeout(() => {
        if (this.editMode) {
          this.disableEditMode();
        }
      }, 300);
    },
    setCursorToEnd() {
      const numberOfCharacters = this.inputValue.toString().length;
      this.$refs.input.setSelectionRange(numberOfCharacters, numberOfCharacters);
    },
    toggleEnableMode() {
      if (this.editMode) {
        this.disableEditMode();
      } else {
        this.enableEditMode();
      }
    },
    enableEditMode() {
      this.editMode = true;
      this.$refs.input.focus();
    },
    disableEditMode() {
      this.editMode = false;
      if (this.$refs.input) this.$refs.input.blur();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.owner-share-input {
  border: 1px solid getShade($neutral-gray, 60);
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: $sp-s #{$sp-s + $sp-xs};
  cursor: pointer;
  display: flex;

  &.isEditMode {
    background-color: getShade($primary-color, 35);
  }

  &__value {
    margin-right: $sp-s;
  }

  &__form {
    position: absolute;
    width: 0;
    height: 0;
    border: 0;
    opacity: 0;
    z-index: -10;
    overflow: hidden;
  }
}

.value-tag {
  $value-tag: &;

  &__dynamic {
    position: relative;
    margin-right: $sp-xs;

    #{$value-tag}.isEditMode & {
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        right: -$sp-xs;
        bottom: 2px;
        width: 2px;
        background-color: $tertiary-color;
        animation: blink 1s infinite;
      }
    }
  }
}
</style>
