<template>
  <div class="page" :data-route-name="$route.name">
    <header class="page__header">
      <page-container>
        <div class="page__header__inner">
          <div class="header-item header-item--left">
            <slot name="headerLeft">
              <router-link :to="{ name: DEALS_ROUTES.INDEX }">
                <img
                  src="@/assets/logo/immowelt-logo-default.svg"
                  class="page__logo"
                  alt="Aviv logo"
                />
              </router-link>
            </slot>
          </div>

          <div class="header-item header-item--center">
            <slot name="headerCenter" />
          </div>

          <div class="header-item header-item--right">
            <slot name="headerRight" />
          </div>
        </div>
      </page-container>
    </header>
    <!-- Use the dynamic class to customize its CSS -->
    <main class="page__main" :class="[`page__main--${$route.name}`]">
      <page-container>
        <slot />
      </page-container>
    </main>
    <footer class="page__footer">
      <page-container v-if="hasFooter">
        <slot name="footer" />
      </page-container>
      <div class="page__footer-static">
        <template v-for="link in footerLinks">
          <a v-if="link.target" :key="link.text" :href="link.to" :target="link.target">{{
            link.text
          }}</a>
          <router-link v-else :key="link.text" :to="{ name: link.to }">
            {{ link.text }}
          </router-link>
        </template>
      </div>
    </footer>
  </div>
</template>

<script>
import PageContainer from '@/layout/components/PageContainer.vue';
import { DEALS as DEALS_ROUTES } from '@/router/wizard/ROUTE_NAMES';

export default {
  name: 'PageBase',
  components: {
    PageContainer,
  },
  data() {
    return {
      DEALS_ROUTES,
      footerLinks: [
        {
          text: this.$t('FOOTER.LINK.TERMS_AND_CONDITIONS'),
          to: 'terms-and-conditions',
        },
        {
          text: this.$t('FOOTER.LINK.IMPRESSUM'),
          to: 'imprint',
        },
        {
          text: this.$t('FOOTER.LINK.DATENSCHUTZ'),
          to: 'data-protection',
        },
        {
          text: this.$t('FOOTER.LINK.COOKIES'),
          target: '_self',
          // eslint-disable-next-line no-script-url
          to: 'javascript: Cookiebot.renew()',
        },
      ],
    };
  },
  computed: {
    hasFooter() {
      return Boolean(this.$slots.footer);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;

  &__header {
    background-color: white;
    padding-top: $sp-s;
    padding-bottom: $sp-s;
    position: sticky;
    top: 0;
    z-index: 30;
    box-shadow: 0 5px 5px 2px white;
    margin-bottom: $sp-s;

    @include for('tablet') {
      padding-top: calc(#{$sp-l} + 20px);
      padding-bottom: $sp-l;
    }

    &__inner {
      display: flex;
      justify-content: space-between;
    }
  }

  .header-item {
    &--left {
      flex: 1;
      justify-content: flex-end;
      max-width: 50%;
    }

    &--right {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      max-width: 50%;
    }
  }

  &__logo {
    max-width: 110px;
    margin-bottom: -25px; // For the "Mein Immobilienmakler"
  }

  &__main {
    flex-grow: 1;
  }

  &__footer {
    margin-top: auto;

    &-static {
      background-color: $secondary-color;
      height: $sp-l;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      padding: $sp-xs $sp-m;

      a {
        color: $quaternary-color;
        text-decoration: none;

        &:hover,
        &:active {
          color: getShade($secondary-color, 60);
        }

        & + a {
          margin-left: $sp-s;
          padding-left: $sp-s;
          border-left: 1px solid $quaternary-color;
        }
      }
    }
  }
}
</style>
