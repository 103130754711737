<template>
  <div class="market-development">
    <h2>{{ $t('WIZARD.MARKET_DEVELOPMENT') }}</h2>
    <HdTabsMenu alignment="center" :model="value" :items="tabs" :value="value" @input="changeTab" />

    <section v-if="value === 'priceDevelopment'" class="market-development__section">
      <HdIcon :src="graphics.priceDevelopment" />
      <p class="market-development__section-paragraph-text">
        {{ $t('WIZARD.MARKET_DEVELOPMENT.PRICE_DEVELOPMENT') }}
      </p>
    </section>

    <section v-else-if="value === 'buyerCosts'" class="market-development__section">
      <p class="market-development__text-strong">
        {{ $t('WIZARD.MARKET_DEVELOPMENT.BUYER_COSTS_TITLE') }}
      </p>
      <img :src="graphics.debitPurchaserMonthly" alt="" />
      <p class="market-development__text-strong market-development__section-paragraph-text">
        {{ $t('WIZARD.MARKET_DEVELOPMENT.BUYER_COSTS_10_YEARS') }}
      </p>
      <img :src="graphics.debitPurchaserYearly" alt="" />
      <p class="market-development__section-paragraph-text-bottom">
        {{ $t('WIZARD.MARKET_DEVELOPMENT.BUYER_COSTS_FOOTER') }}
      </p>
    </section>

    <section v-else-if="value === 'propertyDemand'" class="market-development__section">
      <div class="market-development__graph">
        <HdIcon :src="graphics.propertyDemand" />
      </div>
    </section>

    <section v-else class="market-development__section">
      <div class="market-development__marketing-success">
        <div class="market-development__text-wrapper">
          <p v-html="$t('WIZARD.MARKET_DEVELOPMENT.MARKETING_SUCCESS')"></p>
        </div>
        <div class="market-development__icons">
          <div
            v-for="(icon, i) in icons"
            :key="`marketDevelopment-${i}-icon`"
            :class="`market-development__icon-card market-development__icon-card-${i}`"
          >
            <HdIcon :src="icon.image" width="100" height="100" />
            <p>{{ icon.label }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { HdTabsMenu, HdIcon } from 'homeday-blocks';
import { camera, womanHouseProperty, laptopChatMessageHouse } from 'homeday-assets/L';
import priceDevelopment from '@/assets/graphs/priceDevelopment.svg';
import realEstateDemand from '@/assets/graphs/realEstateDemand.svg';
import debitPurchaserMonthly from '@/assets/graphs/debitPurchaserMonthly.svg';
import debitPurchaserYearly from '@/assets/graphs/debitPurchaserYearly.svg';
import propertyDemand from '@/assets/graphs/propertyDemand.svg';

export default {
  name: 'MarketDevelopment',
  components: {
    HdIcon,
    HdTabsMenu,
  },
  data() {
    return {
      // Default tab
      value: 'priceDevelopment',
      tabs: [
        {
          value: 'priceDevelopment',
          label: this.$t('WIZARD.MARKET_DEVELOPMENT.TAB_PRICE_DEVELOPMENT'),
        },
        {
          value: 'buyerCosts',
          label: this.$t('WIZARD.MARKET_DEVELOPMENT.TAB_BUYER_COSTS'),
        },
        {
          value: 'propertyDemand',
          label: this.$t('WIZARD.MARKET_DEVELOPMENT.TAB_DEMAND'),
        },
        {
          value: 'marketingSuccess',
          label: this.$t('WIZARD.MARKET_DEVELOPMENT.TAB.MARKETING_SUCCESS'),
        },
      ],
      icons: [
        {
          image: camera,
          label: this.$t('WIZARD.MARKET_DEVELOPMENT.HIGH_QUALITY_PRESENTATION'),
        },
        {
          image: womanHouseProperty,
          label: this.$t('WIZARD.MARKET_DEVELOPMENT.ACTIVE_SEARCH_CUSTOMERS'),
        },
        {
          image: laptopChatMessageHouse,
          label: this.$t('WIZARD.MARKET_DEVELOPMENT.TARGETED_MARKETING'),
        },
      ],
    };
  },
  computed: {
    graphics() {
      return {
        debitPurchaserMonthly,
        debitPurchaserYearly,
        realEstateDemand,
        priceDevelopment,
        propertyDemand,
      };
    },
  },
  methods: {
    changeTab(tab) {
      this.value = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.market-development {
  text-align: center;
  min-width: 0;
  max-width: 720px;

  &::v-deep .tabs-menu__item {
    padding: 24px $sp-l $sp-s 0;
    margin-right: 0;
  }
  &::v-deep .tabs-menu__item:after {
    width: calc(100% - #{$sp-l});
  }

  &::v-deep .flickity-slider button:first-of-type {
    padding-left: 0;
  }

  &::v-deep .flickity-slider button:last-of-type {
    padding-right: 0;
  }

  &::v-deep .flickity-slider button:last-of-type:after {
    // when built, the third party styling form HD-blocks will overwrite this style if not important.
    width: 100% !important;
    margin-left: $sp-l;
  }

  &::v-deep .btn--flat:after {
    left: -$sp-l;
  }

  > div {
    margin: 0 auto;
    max-width: 720px;
  }

  &::v-deep .btn--flat {
    @include font('DS-100');
    font-weight: 600;
  }

  &__section {
    margin: 0 auto;
    max-width: 720px;
    padding-top: 40px;
    text-align: left;

    img {
      max-width: 710px;
      width: auto;
    }

    svg {
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  &__section-paragraph-text {
    padding-top: $sp-l;
  }

  &__section-paragraph-text-bottom {
    padding-top: 70px;
  }

  &__text-strong {
    font-weight: 700;
    padding-bottom: $sp-m;
  }

  &__graph {
    padding: $sp-l $sp-xl;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__marketing-success {
    padding: $sp-l $sp-xl;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__text-wrapper {
    padding-bottom: $sp-m;
    p {
      @include font('DS-300');
      font-weight: 700;
    }
    span {
      font-weight: bold;
      color: getShade($secondary-color, 110);
    }
  }

  &__icons {
    display: flex;
    flex-wrap: wrap;
    gap: $sp-m;
    @include for('tablet') {
      flex-wrap: nowrap;
    }
  }
  &__icon-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    &:first-of-type {
      @include for('tablet') {
        justify-content: left;
      }
    }

    p {
      padding-top: $sp-s;
      @include font('DS-200');
      font-weight: 600;
    }
    &:nth-of-type(2) {
      @include for('tablet') {
        width: 40%;
      }
    }
    @include for('tablet') {
      width: 30%;
    }
  }
}
</style>
