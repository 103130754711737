var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"location-qualities"},_vm._l((_vm.allQualities),function(ref){
var quality = ref.quality;
var label = ref.label;
var color = ref.color;
return _c('div',{key:("location-quality-" + quality),staticClass:"location-qualities-item",class:{
      isCurrent: quality === _vm.currentDeal.quality,
    },style:({
      flexBasis: ("calc(100% / " + (_vm.allQualities.length) + ")"),
    })},[_c('div',{staticClass:"location-qualities-item__color",style:({
        backgroundColor: color,
      })}),_c('p',{staticClass:"location-qualities-item__label"},[_vm._v(" "+_vm._s(label)+" ")])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }