import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import store from '@/store';

const USER_AGENTS_TO_IGNORE = ['Cypress'];

export function init({ dsn, environment }) {
  if (dsn === undefined) {
    return;
  }

  Sentry.init({
    ...(process.env.GITHUB_SHA && { release: `aviv-valuation-wizard@${process.env.GITHUB_SHA}` }),
    Vue,
    dsn,
    integrations: [
      new CaptureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    environment,
    normalizeDepth: 10,
    beforeSend(event) {
      const isIgnoredUserAgent = USER_AGENTS_TO_IGNORE.some((userAgent) =>
        window.navigator.userAgent.includes(userAgent),
      );

      if (isIgnoredUserAgent) {
        return null;
      }

      return event;
    },
  });
}

export function setUser({ uid, email }) {
  Sentry.setUser({
    uid,
    email,
  });
}

export function setExtra(extra) {
  Object.entries(extra).forEach(([key, value]) => {
    Sentry.configureScope((scope) => {
      scope.setExtra(key, value);
    });
  });
}

store.watch(
  (state) => state.user.data,
  (data) => setUser(data),
  {
    immediate: true,
  },
);

store.watch(
  (state) => state.wizard.currentDeal.uid,
  (uid) =>
    setExtra({
      dealUid: uid,
    }),
  {
    immediate: true,
  },
);
