import PRICE_CONTRIBUTIONS from '@/config/PRICE_CONTRIBUTIONS';
import { ACTION, MUTATION } from './CONSTANTS';

export default {
  [ACTION.UPDATE_FEATURE]({ commit, state }, { name, value }) {
    // Empty strings don't make sense for us, so we set the value to `null`
    // to be able to not included in the calculations and also filter it out in the evaluation
    const adjustedValue = value === '' ? null : value;
    commit(MUTATION.SET_FEATURE_VALUE, { name, value: adjustedValue });

    // We find and unset the user-provided contribution prices that use this feature
    const priceContributions = PRICE_CONTRIBUTIONS.filter(({ usedFeatures, getUsedFeatures }) => {
      const featuresList = usedFeatures || getUsedFeatures(state);
      return featuresList.includes(name);
    });

    priceContributions.forEach((priceContribution) => {
      commit(MUTATION.SET_PRICE_CONTRIBUTION, { name: priceContribution.name, value: null });
    });
  },
};
