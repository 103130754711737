<template>
  <PageBase class="registration">
    <div class="registration__wrapper columns is-mobile is-centered is-vcentered">
      <section class="column is-half-tablet is-4-desktop">
        <h3>{{ $t('GENERAL.REGISTER') }}</h3>
        <div class="registration__salutation-name-wrapper columns">
          <HdSelect
            v-model="form.salutation"
            name="user.gender"
            class="hd-register__form-item hd-register__form-item--salutation column is-one-third"
            :options="salutationOptions"
            :label="$t('FORM.SALUTATION.LABEL')"
            required
          />
          <HdInput
            v-model="form.lastName"
            name="user.last_name"
            class="hd-register__form-item hd-register __form-item--last-name column is-two-thirds"
            :label="$t('FORM.LAST_NAME.LABEL')"
            required
          />
        </div>
        <HdInput
          v-model="form.firstName"
          name="user.first_name"
          class="hd-register__form-item"
          :label="$t('FORM.FIRST_NAME.LABEL')"
          required
        />
        <HdInput
          ref="email"
          v-model="form.email"
          type="email"
          name="user.email_address"
          class="hd-register__form-item"
          autocomplete="email"
          inputmode="email"
          :label="$t('FORM.EMAIL.LABEL_INFORMAL')"
          required
        />
        <HdInput
          v-model="form.phone"
          name="user.phone_number"
          class="hd-register__form-item"
          autocomplete="tel"
          inputmode="tel"
          type="tel"
          :custom-rules="phoneValidation"
          :label="$t('FORM.TELEPHONE_HANDY.LABEL')"
          required
        />
        <HdInputPassword
          ref="password"
          v-model="form.password"
          name="user.password"
          class="hd-register__form-item"
          autocomplete="new-password"
          :label="$t('FORM.PASSWORD.LABEL_INFORMAL')"
          required
        />
        <HdButton
          class="registration__submit-button"
          :aria-label="$t('REGISTRATION.TITLE')"
          type="button"
          modifier="primary"
          :disabled="!isFormValid"
          @click="registerUser"
        >
          {{ $t('REGISTRATION.TITLE') }}
        </HdButton>
        <div class="registration__to-login-page">
          <router-link class="link registration__to-login-page-inner" :to="{ name: 'login' }">
            {{ $t('REGISTRATION.TO_LOGIN_PAGE') }}
          </router-link>
        </div>
      </section>
    </div>
  </PageBase>
</template>

<script>
// Components
import { HdSelect, HdInput, HdButton, HdInputPassword } from 'homeday-blocks';
import PageBase from '@/layout/PageBase.vue';
// Services
import { email as validateEmail } from 'homeday-blocks/src/services/formValidation';
import { validatePhone } from '@/services/validators';
import { getItemsFromTranslationObject } from '@/services/helpers';
import { sendEvent, sendPageView, EVENTS, getCustomStatistics } from '@/services/gtm';
// Constants
import { ACTION } from '@/store/CONSTANTS';
import { SALUTATION_TYPES } from '@/config/TRANSLATION_CONSTANTS';

const SALUTATION_OPTIONS = getItemsFromTranslationObject(SALUTATION_TYPES);

export default {
  name: 'RegistrationView',
  components: {
    HdSelect,
    HdInput,
    HdInputPassword,
    HdButton,
    PageBase,
  },
  data() {
    return {
      form: { salutation: SALUTATION_OPTIONS[0].value },
      phoneValidation: [
        {
          validate: validatePhone,
          errorMessage: this.$t('FORM.TELEPHONE.ERROR.INVALID_FORMAT'),
        },
      ],
      emailValidation: [
        {
          validate: validateEmail,
          errorMessage: this.$t('WIZARD.INSTRUCTION.VALIDATION.EMAIL'),
        },
      ],
    };
  },
  computed: {
    salutationOptions() {
      return SALUTATION_OPTIONS;
    },
    isFormValid() {
      return (
        this.isSalutationValid &&
        this.isLastNameValid &&
        this.isFirstNameValid &&
        this.isEmailValid &&
        this.isPhoneValid &&
        this.isPasswordValid
      );
    },

    isSalutationValid() {
      return !!this.form.salutation;
    },

    isFirstNameValid() {
      return !!this.form.firstName;
    },
    isLastNameValid() {
      return !!this.form.lastName;
    },
    isEmailValid() {
      if (this.form.email) {
        return validateEmail(this.form.email);
      }
      return false;
    },

    isPhoneValid() {
      if (this.form.phone) {
        return validatePhone(this.form.phone);
      }
      return false;
    },

    isPasswordValid() {
      return !!this.form.password;
    },
  },
  created() {
    // Tracking
    sendPageView({
      ...EVENTS.PAGE_VIEW.REGISTRATION,
      ...getCustomStatistics(this.$store, this.$route.path),
    });
  },
  methods: {
    registerUser() {
      if (!this.isFormValid) {
        return;
      }
      this.$store
        .dispatch(ACTION.USER.REGISTER, this.form)
        .then(() => {
          // Tracking
          sendEvent({
            ...EVENTS.INTERACTION.SIGN_UP,
            email: this.form.email,
          });

          this.$router.push('/login');
        })
        .catch((error) => {
          this.error = error;
          this.$nextTick(() => console.error(error));
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.registration {
  height: 100vh;

  &__salutation-name-wrapper {
    margin-top: calc(#{$sp-xxs} + #{$sp-m});
  }
  &__wrapper {
    height: 100%;
    padding-top: $sp-l;
    padding-bottom: $sp-l;
  }
  &__to-login-page {
    text-align: center;
    margin-top: calc(#{$sp-xxs} + #{$sp-m});
  }
  &__to-login-page-inner {
    @include font('DS-100');
    display: inline-block;
  }

  &__submit-button {
    width: 100%;
  }
}
</style>
