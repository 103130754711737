<template>
  <wizard-base
    class="construction-renovation"
    :title="$t('WIZARD.CONSTRUCTION_AND_RENOVATION.TITLE')"
    :subtitle="$t('GENERAL.FIELD_REQUIRED')"
    :icon="slideIcon"
    :content-column-classes="['is-9', 'is-8-desktop']"
  >
    <div class="construction-renovation__fields">
      <div class="columns">
        <div class="column is-half">
          <hd-input
            v-model="constructionYear"
            name="construction-year"
            type="number"
            :label="$t('GENERAL.CONSTRUCTION_YEAR')"
            :custom-rules="customRules"
          />
        </div>
        <div class="column is-half">
          <PriceContributionInput v-model="constructionPrice" name="construction" />
        </div>
      </div>
      <div class="columns">
        <div class="column is-half">
          <hd-input
            v-model="lastRenovationYear"
            name="last-renovation"
            type="number"
            :label="$t('GENERAL.LAST_RENOVATION')"
            :custom-rules="customRules"
          />
        </div>
        <PriceContributionInput
          v-model="generalRenovationPrice"
          name="generalRenovation"
          class="column is-half"
        />
      </div>
      <div class="columns">
        <div class="column is-half is-offset-half">
          <HdLink
            class="construction-renovation__link"
            data-cy="construction-renovation"
            data-testid="construction-renovation"
            @click.native="goToDetailedRenovations"
          >
            {{ $t('WIZARD.CONSTRUCTION_AND_RENOVATION.DETAILED') }}
          </HdLink>
        </div>
      </div>
    </div>
  </wizard-base>
</template>

<script>
// Components
import { HdInput, HdLink } from 'homeday-blocks';
import PriceContributionInput from '@/components/PriceContributionInput.vue';
import WizardBase from '@/layout/WizardBase.vue';
// Services
import { mapFeatures } from '@/services/feature';
import { mapPriceContributions } from '@/services/priceContribution';
// Icons
import { houseConstruction as slideIcon } from 'homeday-assets/L';
// Constants
import { MUTATION } from '@/store/CONSTANTS';
import { toFinite as _toFinite, isNumber as _isNumber, inRange as _inRange } from 'lodash-es';
import { MAXIMUM_YEAR, MINIMUM_YEAR } from '@/config/CONSTANTS';

export default {
  name: 'ConstructionAndRenovationSlide',
  components: {
    HdInput,
    HdLink,
    PriceContributionInput,
    WizardBase,
  },
  inject: ['goToNextSlide'],
  data() {
    return {
      slideIcon,
    };
  },
  computed: {
    deal() {
      return this.$store.state.wizard.currentDeal;
    },
    ...mapFeatures(['constructionYear', 'lastRenovationYear']),
    ...mapPriceContributions(['construction', 'generalRenovation']),
    /** @returns {import('@/@types/global').ValidationRules} */
    customRules() {
      return [
        {
          validate: (value) => {
            if (!_isNumber(Number(value))) return false;
            return _inRange(_toFinite(value), MINIMUM_YEAR, MAXIMUM_YEAR);
          },
          errorMessage: this.$t('FIELD_VALIDATIONS.INCORRECT_CONSTRUCTION_YEAR'),
        },
      ];
    },
  },
  mounted() {
    this.$store.commit(MUTATION.WIZARD.CURRENT_DEAL.SET_SHOULD_SHOW_DETAILED_RENOVATIONS, false);
  },
  methods: {
    goToDetailedRenovations() {
      this.$store.commit(MUTATION.WIZARD.CURRENT_DEAL.SET_SHOULD_SHOW_DETAILED_RENOVATIONS, true);
      this.$store.commit(MUTATION.WIZARD.CURRENT_DEAL.SET_FEATURE_VALUE, {
        name: 'lastRenovationYear',
        value: null,
      });

      this.goToNextSlide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.construction-renovation {
  &__link {
    @include font('DS-200');
    cursor: pointer;
  }
}
</style>
