<template>
  <span
    class="slide-submit-button-wrapper"
    :class="{
      'slide-submit-button-wrapper--loading': loading,
    }"
  >
    <slot :loading="loading" :disabled="disabled" />
  </span>
</template>

<script>
export default {
  name: 'SlideSubmitButtonWrapper',
  computed: {
    loading() {
      return this.$store.state.wizard.isSlideValidating;
    },
    disabled() {
      return this.loading;
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-submit-button-wrapper {
  &--loading {
    cursor: progress;
    & > * {
      pointer-events: none;
    }
  }
}
</style>
