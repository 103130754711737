/* eslint-disable no-console */

import { register } from 'register-service-worker';
import { SERVICE_WORKER_STATUS } from '@/config/CONSTANTS';
import store from '@/store';
import { MUTATION } from '@/store/CONSTANTS';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      store.commit(MUTATION.SET_SERVICE_WORKER_STATUS, SERVICE_WORKER_STATUS.READY);

      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered() {
      store.commit(MUTATION.SET_SERVICE_WORKER_STATUS, SERVICE_WORKER_STATUS.REGISTERED);

      console.log('Service worker has been registered.');
    },
    cached() {
      store.commit(MUTATION.SET_SERVICE_WORKER_STATUS, SERVICE_WORKER_STATUS.CACHED);

      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      store.commit(MUTATION.SET_SERVICE_WORKER_STATUS, SERVICE_WORKER_STATUS.UPDATE_FOUND);

      console.log('New content is downloading.');
    },
    updated() {
      store.commit(MUTATION.SET_SERVICE_WORKER_STATUS, SERVICE_WORKER_STATUS.UPDATED);

      console.log('New content is available; please refresh.');
    },
    offline() {
      store.commit(MUTATION.SET_SERVICE_WORKER_STATUS, SERVICE_WORKER_STATUS.OFFLINE);

      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      store.commit(MUTATION.SET_SERVICE_WORKER_STATUS, SERVICE_WORKER_STATUS.ERROR);

      console.error('Error during service worker registration:', error);
    },
  });
}
