import propertyThumbnail from '@/assets/placeholder/property-placeholder.svg';
import propertyThumbnailSmall from '@/assets/placeholder/property-placeholder--small.svg';
import avatarMale from '@/assets/placeholder/photo-placeholder--male.png';
import avatarFemale from '@/assets/placeholder/photo-placeholder--female.png';

export const PHOTO = {
  male: avatarMale,
  female: avatarFemale,
};

export const PROPERTY = {
  thumbnail: propertyThumbnail,
  thumbnailSmall: propertyThumbnailSmall,
};

export default {
  PHOTO,
};
