import browserStorage from '@/services/browserStorage';

export const FAILED_REQUEST_TYPE = {
  EVALUATION: 'evaluation',
  UPDATE_REPORT: 'update_report',
  BROKER_ORDER: 'broker_order',
  DELETE: 'delete',
};

// The notifications will be shown based on this order (LCFS)
const FAILED_REQUESTS_TYPES = [
  FAILED_REQUEST_TYPE.DELETE,
  FAILED_REQUEST_TYPE.BROKER_ORDER,
  FAILED_REQUEST_TYPE.EVALUATION,
  FAILED_REQUEST_TYPE.UPDATE_REPORT,
];

function getFailedRequestItemKey({ dealUid, type }) {
  return `failedRequest-${dealUid}-${type}`;
}

export function removeFailedRequest({ dealUid, type }) {
  const itemKey = getFailedRequestItemKey({ dealUid, type });

  browserStorage.local.removeItem(itemKey);
}

export function saveFailedRequest({ dealUid, type, actionType, actionParams, reason }) {
  const itemKey = getFailedRequestItemKey({ dealUid, type });

  browserStorage.local.setItem(
    itemKey,
    JSON.stringify({
      actionType,
      actionParams,
      reason,
    }),
  );
}

export function compareFailedRequests(a, b) {
  return FAILED_REQUESTS_TYPES.indexOf(a.type) - FAILED_REQUESTS_TYPES.indexOf(b.type);
}

export function getFailedRequests({ dealUid }) {
  return FAILED_REQUESTS_TYPES.map((type) => {
    const itemKey = getFailedRequestItemKey({ dealUid, type });
    const requestString = browserStorage.local.getItem(itemKey);

    if (requestString === null) {
      return undefined;
    }

    const { actionType, actionParams, reason } = JSON.parse(requestString);

    return {
      type,
      actionType,
      actionParams,
      reason,
    };
  }).filter((request) => request);
}
