/* eslint-disable arrow-body-style */
import calculations from '@/services/calculations';

const PRICE_CONTRIBUTIONS = [
  {
    name: 'accessibility',
    isForPriceOnly: true,
    usedFeatures: ['apartmentType', 'floor', 'numberOfFloors', 'hasElevator'],
    getCalculatedPrice: (state, getters) => {
      return calculations[state.specificPropertyType].getApartmentTypeAndFloorAndElevatorPrice({
        basePrice: getters.basePrice,
        apartmentType: state.apartmentType,
        floor: state.floor,
        numberOfFloors: state.numberOfFloors,
        hasElevator: state.hasElevator,
      });
    },
  },
  {
    name: 'outdoor',
    isForPriceOnly: true,
    getUsedFeatures: (state) => {
      const { hasOutdoor } = state;
      if (hasOutdoor)
        return ['hasBalcony', 'hasTerrace', 'hasWinterGarden', 'hasLoggia', 'hasOutdoor'];
      return ['hasOutdoor'];
    },
    getCalculatedPrice: (state, getters) => {
      const { hasOutdoor } = state;
      return calculations[state.specificPropertyType].getBalconyAndTerracePrice({
        basePrice: getters.basePrice,
        hasOutdoor,
      });
    },
  },
  {
    name: 'buildingType',
    usedFeatures: ['buildingType'],
    getCalculatedPrice: (state, getters) => {
      return calculations[state.specificPropertyType].getBuildingTypePrice({
        basePrice: getters.basePrice,
        buildingType: state.buildingType,
      });
    },
  },
  {
    name: 'construction',
    usedFeatures: ['constructionYear'],
    getCalculatedPrice: (state, getters) => {
      return calculations[state.specificPropertyType].getConstructionYearPrice({
        basePrice: getters.basePrice,
        constructionYear: state.constructionYear,
        constructionYearAverage: state.constructionYearAverage,
      });
    },
  },
  {
    name: 'earningsValue',
    isForPriceOnly: true,
    usedFeatures: [
      'rentStatus',
      'livingSpace',
      'commercialSpace',
      'baseRentPerSqm',
      'commercialBaseRentPerSqm',
      'rentMultiplier',
    ],
    getCalculatedPrice: (state) => {
      return calculations[state.specificPropertyType].getEarningsValuePrice({
        rentStatus: state.rentStatus,
        livingSpace: state.livingSpace,
        commercialSpace: state.commercialSpace,
        baseRentPerSqm: state.baseRentPerSqm,
        commercialBaseRentPerSqm: state.commercialBaseRentPerSqm,
        rentMultiplier: state.rentMultiplier,
      });
    },
  },
  {
    name: 'garden',
    usedFeatures: ['hasGarden'],
    getCalculatedPrice: (state, getters) => {
      return calculations[state.specificPropertyType].getGardenPrice({
        basePrice: getters.basePrice,
        hasGarden: state.hasGarden,
      });
    },
  },
  {
    name: 'parking',
    usedFeatures: ['hasParkingSpace'],
    getCalculatedPrice: (state, getters) => {
      return calculations[state.specificPropertyType].getParkingPrice({
        basePrice: getters.basePrice,
        hasParkingSpace: state.hasParkingSpace,
        sqmPrice: state.suggestedSqmPrice,
      });
    },
  },
  {
    name: 'heating',
    usedFeatures: [],
    getCalculatedPrice: (state) => {
      // Todo: The array usedFeatures is currently empty since the heating price contribution consists
      //  of a set of basic input fields that are initially set to 0 for the minimum viable product (MVP) of this feature.
      //  The calculation logic for this value can be incorporated at a later stage.
      return calculations[state.specificPropertyType].getHeatingRenovationPrice({
        heating: state.heating,
        lastHeatingRenovationYear: state.lastHeatingRenovationYear,
        ownerShare: state.ownerShare,
      });
    },
  },
  {
    name: 'facadeRenovation',
    usedFeatures: ['lastFacadeRenovationYear', 'numberOfFloors', 'ownerShare'],
    getCalculatedPrice: (state) => {
      return calculations[state.specificPropertyType].getFacadeRenovationPrice({
        lastFacadeRenovationYear: state.lastFacadeRenovationYear,
        numberOfFloors: state.numberOfFloors,
        ownerShare: state.ownerShare,
      });
    },
  },
  {
    name: 'generalRenovation',
    usedFeatures: ['lastRenovationYear', 'numberOfFloors'],
    getCalculatedPrice: (state, getters) => {
      // Todo: heating price contribution is not calculated for the MVP of the feature.
      const { lastRenovationYear } = state;

      const adjustedState = {
        ...state,
        lastRoofRenovationYear: lastRenovationYear,
        lastWindowRenovationYear: lastRenovationYear,
        lastFacadeRenovationYear: lastRenovationYear,
      };

      const INCLUDED_PRICE_CONTRIBUTIONS = [
        'roofRenovation',
        'windowRenovation',
        'facadeRenovation',
        'heating',
      ];

      return (
        INCLUDED_PRICE_CONTRIBUTIONS
          // We find the price contribution objects
          .map((priceContributionName) =>
            PRICE_CONTRIBUTIONS.find(({ name }) => name === priceContributionName),
          )
          // We get the calculated prices
          .map((priceContribution) => priceContribution.getCalculatedPrice(adjustedState, getters))
          // We sum the prices
          .reduce((sum, value) => sum + value, 0)
      );
    },
  },
  {
    name: 'roofRenovation',
    usedFeatures: ['lastRoofRenovationYear', 'livingSpace', 'numberOfFloors', 'ownerShare'],
    getCalculatedPrice: (state) => {
      return calculations[state.specificPropertyType].getRoofRenovationPrice({
        livingSpace: state.livingSpace,
        numberOfFloors: state.numberOfFloors,
        lastRoofRenovationYear: state.lastRoofRenovationYear,
        ownerShare: state.ownerShare,
      });
    },
  },
  {
    name: 'windowRenovation',
    usedFeatures: ['livingSpace'],
    getCalculatedPrice: (state, getters) => {
      return calculations[state.specificPropertyType].getWindowRenovationPrice({
        basePrice: getters.basePrice,
        lastWindowRenovationYear: state.lastWindowRenovationYear,
        livingSpace: state.livingSpace,
      });
    },
  },
  {
    name: 'basement',
    usedFeatures: [
      'numberOfBasementRooms',
      'hasCellar',
      'hasBasementCompartment',
      'hasPartialBasement',
    ],
    getCalculatedPrice: (state, getters) => {
      return calculations[state.specificPropertyType].getBasementPrice({
        basePrice: getters.basePrice,
        numberOfBasementRooms: state.numberOfBasementRooms,
        hasCellar: state.hasCellar,
        hasBasementCompartment: state.hasBasementCompartment,
        hasPartialBasement: state.hasPartialBasement,
      });
    },
  },
  {
    name: 'bathroom',
    usedFeatures: ['bathroomWithWindowPrice', 'interiorBathroomPrice', 'separateGuestWcPrice'],
    getCalculatedPrice: (state) => {
      return state.priceContributions.bathroom;
    },
  },
  {
    name: 'rooms',
    usedFeatures: ['numberOfRooms', 'livingSpace'],
    getCalculatedPrice: (state, getters) => {
      return calculations[state.specificPropertyType].getNumberOfRoomsPrice({
        basePrice: getters.basePrice,
        livingSpace: state.livingSpace,
        numberOfRooms: state.numberOfRooms,
      });
    },
  },
  {
    name: 'plot',
    usedFeatures: ['plotArea'],
    getCalculatedPrice: (state) => {
      return calculations[state.specificPropertyType].getPlotAreaPrice({
        plotArea: state.plotArea,
        plotAreaAverage: state.plotAreaAverage,
        sqmPrice: state.suggestedSqmPrice,
      });
    },
  },
  {
    name: 'availability',
    usedFeatures: ['rentStatus'],
    getCalculatedPrice: (state, getters) => {
      return calculations[state.specificPropertyType].getRentStatusPrice({
        basePrice: getters.basePrice,
        rentStatus: state.rentStatus,
      });
    },
  },
];

export default PRICE_CONTRIBUTIONS;
