import { buildURL as buildImgixURL, getPreset as imgixPresets } from '@/services/imgix';
import { PHOTO as PHOTO_PLACEHOLDERS } from '@/config/PLACEHOLDERS';

export function getRealtorPhoto(realtor, { preset = 'REALTOR' } = {}) {
  if (!realtor) {
    return PHOTO_PLACEHOLDERS.female;
  }
  const imageUrl = realtor.contact_photo;

  if (imageUrl) {
    return buildImgixURL(imageUrl, imgixPresets(preset));
  }
  return PHOTO_PLACEHOLDERS[realtor.salutation] || PHOTO_PLACEHOLDERS.female;
}

export default {
  getRealtorPhoto,
};
