<template>
  <div class="wizard-base">
    <h2 v-if="title" ref="title" class="wizard-base__title" data-cy="wizard-title">
      {{ title }}
    </h2>
    <p v-if="subtitle" class="wizard-base__subtitle">
      {{ subtitle }}
    </p>
    <section class="wizard-base__container columns is-centered is-vcentered">
      <div v-if="hasIcon" class="wizard-base__icon column is-3 is-2-desktop">
        <HdIcon ref="icon" :src="icon" :class-from-fill="defaultIconColors" />
      </div>
      <div :class="contentClasses">
        <slot />
      </div>
    </section>
  </div>
</template>

<script>
import { HdIcon } from 'homeday-blocks';
import { SVG_DEFAULT_COLORS } from '@/config/CONSTANTS';

export default {
  name: 'WizardBase',
  components: {
    HdIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    contentColumnClasses: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    defaultIconColors() {
      return SVG_DEFAULT_COLORS;
    },
    contentClasses() {
      const classes = ['wizard-base__content', 'column'];

      if (this.contentColumnClasses.length) {
        return [...classes, ...this.contentColumnClasses];
      }

      // If no specific column classes are provided, we use our defaults
      const columnSizeClass = this.hasIcon ? 'is-6 is-4-desktop' : 'is-12';

      return [...classes, columnSizeClass];
    },
    hasIcon() {
      return Boolean(this.icon);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.wizard-base {
  $base: &;

  &__title,
  &__subtitle {
    text-align: center;
  }

  &__subtitle {
    font-size: 18px;
    margin-top: $sp-s;

    @include for('mobile-only') {
      font-size: 16px;
    }
  }

  &__container {
    margin-top: $sp-l;

    @include for('tablet') {
      margin-top: $sp-xl;
    }
  }

  &__icon {
    display: none;

    @include for('tablet') {
      display: flex;
      justify-content: flex-end;
    }

    img {
      width: 100%;
    }
  }
}
</style>
