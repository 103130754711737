// Route names
import { DEALS as ROUTE_NAMES } from '@/router/wizard/ROUTE_NAMES';

// Services
import { i18n } from '@/services/i18n';

// Views
import DealsFutureView from '@/views/wizard/deals/DealsFutureView.vue';
import DealsPastView from '@/views/wizard/deals/DealsPastView.vue';

export default [
  {
    path: '',
    name: ROUTE_NAMES.INDEX,
    redirect: { name: ROUTE_NAMES.FUTURE_DEALS },
  },
  {
    path: 'future',
    name: ROUTE_NAMES.FUTURE_DEALS,
    component: DealsFutureView,
    meta: {
      title: i18n.t('WIZARD.LIST.MENU.FUTURE_DEALS'),
    },
  },
  {
    path: 'past',
    name: ROUTE_NAMES.PAST_DEALS,
    component: DealsPastView,
    meta: {
      title: i18n.t('WIZARD.LIST.MENU.PAST_DEALS'),
    },
  },
];
