export const BUILDING_TYPES = {
  single_family_house: 1.02,
  semidetached_house: 1.01,
  terrace_house: 1,
  bungalow: 1.01,
  farmhouse: 0.95,
  villa: 1.05,
  castle_manor_house: 1.04,
  stepped_building: 1,
};

export const RENT_STATUSES = {
  vacant: 1.03,
  owner_occupation: 1.03,
  rented: 0.97,
};
