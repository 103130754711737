<template>
  <wizard-base
    class="rooms"
    :title="$t('WIZARD.ROOMS.TITLE')"
    :subtitle="$t('GENERAL.FIELD_REQUIRED')"
  >
    <div class="rooms__fields">
      <HdTileSelect
        v-model="numberOfRooms"
        :items="roomItems"
        :formatter="$formatNumber"
        accept-new-value
        type="number"
        name="rooms-value"
        class="rooms__select"
      />
      <PriceContributionInput v-model="roomsPrice" name="rooms" class="rooms__input" />
    </div>
  </wizard-base>
</template>

<script>
// Components
import { HdTileSelect } from 'homeday-blocks';
import WizardBase from '@/layout/WizardBase.vue';
import PriceContributionInput from '@/components/PriceContributionInput.vue';
// Services
import { mapFeatures } from '@/services/feature';
import { mapPriceContributions } from '@/services/priceContribution';

export default {
  name: 'RoomsSlide',
  components: {
    WizardBase,
    HdTileSelect,
    PriceContributionInput,
  },
  data() {
    return {
      roomItems: [1, 1.5, 2, 2.5, 3, 3.5, 4],
    };
  },
  computed: {
    deal() {
      return this.$store.state.wizard.currentDeal;
    },
    ...mapFeatures(['numberOfRooms']),
    ...mapPriceContributions(['rooms']),
  },
  created() {
    // If we don't have the initially selected value as an option, we add it if it's valid
    if (this.numberOfRooms) {
      const initalItem = this.roomItems.find((value) => value === this.numberOfRooms);

      if (!initalItem && this.numberOfRooms) {
        this.roomItems.push(this.numberOfRooms);
      }
    }

    // For a better UX we sort the items
    this.roomItems.sort((a, b) => a - b);
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.rooms {
  &__fields {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__select {
    width: 100%;
    @include font('DS-100');
  }

  &__input {
    max-width: 200px;
    margin-top: $sp-l;
  }
}
</style>
