<template>
  <PageBase class="data-protection">
    <Breadcrumb class="data-protection__breadcrumb" :page-name="$t('FOOTER.LINK.DATENSCHUTZ')" />
    <section class="data-protection__wrapper columns is-centered is-multiline">
      <div class="column is-full">
        <h1 class="data-protection__title">{{ $t('DATA_PROTECTION.TITLE') }}</h1>
      </div>
      <div class="column is-full" v-html="content"></div>
    </section>
  </PageBase>
</template>

<script>
// Components
import PageBase from '@/layout/PageBase.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';

export default {
  name: 'DataProtection',
  components: {
    PageBase,
    Breadcrumb,
  },
  data() {
    return {
      content: '',
    };
  },
  created() {
    this.loadContent();
  },
  methods: {
    async loadContent() {
      const { CONTENT } = await import('@/config/DATA_PROTECTION');
      this.content = CONTENT;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins.scss';

.data-protection {
  height: 100vh;

  a:visited,
  a:link {
    color: $quaternary-color;
  }

  h1,
  h2,
  h3,
  h4 {
    margin: $sp-m 0 0;
  }
  li {
    margin: $sp-s 0 0;
  }

  &__app-item {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: $sp-m 0 0;

    input[disabled] {
      background-color: #f0f0f0;
      color: #999;
    }
    input[type='checkbox'] {
      accent-color: #009daf;
      height: 24px;
      width: 24px;
      margin: 0 $sp-s 0 0;
      cursor: pointer;
    }

    h3 {
      margin: 0;
    }
  }

  &__wrapper {
    margin: $sp-m 0;
  }
}

.page__main--data-protection {
  margin-bottom: $sp-l;
}
</style>
