import { render, staticRenderFns } from "./RoofRenovation.vue?vue&type=template&id=c5c08c24&scoped=true&"
import script from "./RoofRenovation.vue?vue&type=script&lang=js&"
export * from "./RoofRenovation.vue?vue&type=script&lang=js&"
import style0 from "./RoofRenovation.vue?vue&type=style&index=0&id=c5c08c24&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5c08c24",
  null
  
)

export default component.exports