export const CHARACTERISTICS_EVALUATION_CONFIG = {
  FEATURES: [
    {
      key: 'buildingType',
      titleKey: 'GENERAL.HOUSE_TYPE',
      valueType: 'STRING',
    },
    {
      key: 'apartmentType',
      titleKey: 'GENERAL.APARTMENT_TYPE',
      valueType: 'STRING',
    },
    {
      key: 'constructionYear',
      titleKey: 'GENERAL.CONSTRUCTION_YEAR',
      valueType: 'NUMBER',
    },
    {
      key: 'numberOfRooms',
      titleKey: 'GENERAL.NUMBER_ROOMS',
      valueType: 'NUMBER',
    },
    {
      key: 'floor',
      titleKey: 'GENERAL.APARTMENT_FLOOR',
      valueType: 'NUMBER',
    },
    {
      key: 'numberOfFloors',
      titleKey: 'GENERAL.NUMBER_FLOORS',
      valueType: 'NUMBER',
    },
    {
      key: 'hasElevator',
      titleKey: 'WIZARD.ACCESSIBILITY.ELEVATOR',
      valueType: 'BOOLEAN',
    },
    {
      key: 'numberOfBasementRooms',
      titleKey: 'GENERAL.BASEMENT',
      valueType: 'BOOLEAN',
      details: [
        {
          key: 'hasPartialBasement',
          titleKey: 'WIZARD.BASEMENT.TYPE.PARTIAL',
          valueType: 'BOOLEAN',
        },
        {
          key: 'hasBasementCompartment',
          titleKey: 'WIZARD.BASEMENT.TYPE.CELLAR',
          valueType: 'BOOLEAN',
        },
        {
          key: 'hasCellar',
          titleKey: 'WIZARD.BASEMENT.TYPE.BASEMENT',
          valueType: 'BOOLEAN',
        },
      ],
    },
    {
      key: 'plotArea',
      titleKey: 'GENERAL.PLOT_AREA',
      valueType: 'NUMBER',
    },
  ],
  RENOVATIONS: [
    {
      key: 'lastRenovationYear',
      titleKey: 'GENERAL.LAST_RENOVATION',
      valueType: 'STRING',
    },
    {
      key: 'lastRoofRenovationYear',
      titleKey: 'GENERAL.ROOF',
      valueType: 'NUMBER',
    },
    {
      key: 'lastFacadeRenovationYear',
      titleKey: 'GENERAL.FACADE',
      valueType: 'NUMBER',
    },
    {
      key: 'lastWindowRenovationYear',
      titleKey: 'GENERAL.WINDOW',
      valueType: 'NUMBER',
    },
    {
      key: 'lastHeatingRenovationYear',
      titleKey: 'GENERAL.HEATER',
      valueType: 'NUMBER',
    },
    // {
    //   key: 'heating',
    //   titleKey: 'GENERAL.HEATING_TYPE',
    //   valueType: 'STRING',
    //   potentialValue: null,
    // },
    {
      key: 'gasCentralHeatingPrice',
      titleKey: 'WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.GAS_CENTRAL_HEATING',
      valueType: 'BOOLEAN',
    },
    {
      key: 'gasFloorHeatingPrice',
      titleKey: 'WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.GAS_FLOOR_HEATING',
      valueType: 'BOOLEAN',
    },
    {
      key: 'oilHeatingPrice',
      titleKey: 'WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.OIL_HEATING',
      valueType: 'BOOLEAN',
    },
    {
      key: 'districtHeatingPrice',
      titleKey: 'WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.DISTRICT_HEATING',
      valueType: 'BOOLEAN',
    },
    {
      key: 'pelletHeatingPrice',
      titleKey: 'WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.PELLET_HEATING',
      valueType: 'BOOLEAN',
    },
    {
      key: 'electricHeatingPrice',
      titleKey: 'WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.ELECTRIC_HEATING',
      valueType: 'BOOLEAN',
    },
    {
      key: 'cogenerationUnitPrice',
      titleKey: 'WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.COGENERATION_UNIT',
      valueType: 'BOOLEAN',
    },
    {
      key: 'solarThermalHeatingPrice',
      titleKey: 'WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.SOLAR_THERMAL_HEATING',
      valueType: 'BOOLEAN',
    },
    {
      key: 'heatPumpPrice',
      titleKey: 'WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.HEAT_PUMP',
      valueType: 'BOOLEAN',
    },
  ],
  ADDITIONAL_INFORMATION: [
    {
      key: 'rentStatus',
      titleKey: 'GENERAL.USAGE',
      valueType: 'STRING',
    },
    {
      key: 'hasParkingSpace',
      titleKey: 'GENERAL.PLOTS',
      valueType: 'BOOLEAN',
    },
    {
      key: 'hasGarden',
      titleKey: 'WIZARD.BALCONY_TERRACE.TYPE.GARDEN',
      valueType: 'BOOLEAN',
    },
    {
      key: 'hasBalcony',
      titleKey: 'WIZARD.BALCONY_TERRACE.TYPE.BALCONY',
      valueType: 'BOOLEAN',
    },
    {
      key: 'hasTerrace',
      titleKey: 'WIZARD.BALCONY_TERRACE.TYPE.TERRACE',
      valueType: 'BOOLEAN',
    },
    {
      key: 'interiorBathroomPrice',
      titleKey: 'WIZARD.BATHROOM.BATHROOM_OPTIONS.INTERIOR_BATHROOM',
      valueType: 'BOOLEAN',
    },
    {
      key: 'separateGuestWcPrice',
      titleKey: 'WIZARD.BATHROOM.BATHROOM_OPTIONS.SEPARATE_GUEST_WC',
      valueType: 'BOOLEAN',
    },
    {
      key: 'bathroomWithWindowPrice',
      titleKey: 'WIZARD.BATHROOM.BATHROOM_OPTIONS.BATHROOM_WITH_WINDOW',
      valueType: 'BOOLEAN',
    },
    {
      key: 'hasLoggia',
      titleKey: 'WIZARD.BALCONY_TERRACE.TYPE.LOGGIA',
      valueType: 'BOOLEAN',
    },
    {
      key: 'hasWinterGarden',
      titleKey: 'WIZARD.BALCONY_TERRACE.TYPE.WINTER_GARDEN',
      valueType: 'BOOLEAN',
    },
  ],
};
