<template>
  <div class="property-item">
    <div
      :style="{
        gridRowEnd: !hasSecondThumbnail ? 'span 2' : 'span 1',
        backgroundImage: `url(${firstThumbnail.url})`,
      }"
      class="property-item__thumbnail"
      @click="$emit('thumbnailClick', firstThumbnail.index)"
    />
    <div
      v-if="hasSecondThumbnail"
      :style="{
        backgroundImage: `url(${secondThumbnail.url})`,
      }"
      class="property-item__thumbnail property-item__thumbnail--second"
      @click="$emit('thumbnailClick', secondThumbnail.index)"
    />

    <div class="property-item__details">
      <p class="property-item__address">
        {{ address }}
      </p>
      <dl class="property-item__list">
        <div
          v-for="{ label, value } in listItems"
          :key="label"
          class="property-item__list-item"
          :class="{
            'property-item__list-item--bold':
              label === $t('WIZARD.SIMILAR_PROPERTIES.DAYS_SINCE_PUBLISHED') &&
              getDifferenceInDays > 60,
          }"
        >
          <dt>
            {{ label }}
          </dt>
          <div class="property-item__separator" />
          <dd>{{ value !== undefined ? value : '-' }}</dd>
        </div>

        <div class="property-item__list-item property-item__list-item--highlighted">
          <dt>{{ $t('WIZARD.SIMILAR_PROPERTIES.OFFER_PRICE') }}<span>*</span></dt>
          <div class="property-item__separator" />
          <dd>{{ totalPrice }}</dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import { differenceInDays } from 'date-fns';

export default {
  name: 'SimilarPropertyItem',
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  computed: {
    listItems() {
      return [
        {
          label: this.$t('WIZARD.SIMILAR_PROPERTIES.PUBLISHED'),
          value: this.$formatMonth(new Date(this.property.start_at)),
        },
        {
          label: this.$t('WIZARD.SIMILAR_PROPERTIES.DAYS_SINCE_PUBLISHED'),
          value: `${this.getDifferenceInDays} ${this.$t('GENERAL.DAYS')}`,
        },
        {
          label: this.$t('GENERAL.LIVING_SPACE'),
          value: this.$formatSpace(this.property.living_space),
        },
        {
          label: this.$t('GENERAL.FLOOR'),
          value: this.property.floor,
        },
        {
          label: this.$t('GENERAL.CONSTRUCTION_YEAR'),
          value: this.property.construction_year,
        },
        {
          label: this.$t('GENERAL.NUMBER_OF_ROOMS'),
          value: this.property.number_of_rooms,
        },
        {
          label: this.$t('WIZARD.SIMILAR_PROPERTIES.SQM_OFFER_PRICE'),
          value: this.sqmPrice,
        },
      ];
    },
    publishedAt() {
      const startAt = new Date(this.property.start_at);

      if (!Number.isNaN(startAt.getTime())) {
        return this.$formatMonth(new Date(this.property.start_at));
      }

      return undefined;
    },
    totalPrice() {
      if (this.property.total_price) {
        return this.$formatPrice(this.property.total_price);
      }

      return undefined;
    },
    sqmPrice() {
      if (this.property.price_sqm) {
        return this.$formatSqmPrice(this.property.price_sqm);
      }

      return undefined;
    },
    address() {
      const { zip_code: zipCode, city } = this.property;

      return `${zipCode} ${city}`;
    },
    firstThumbnail() {
      const index = 0;

      return {
        url: this.property.photos[index],
        index,
      };
    },
    secondThumbnail() {
      const index = 1;

      return {
        url: this.property.photos[index],
        index,
      };
    },
    hasSecondThumbnail() {
      return Boolean(this.secondThumbnail.url);
    },
    getDifferenceInDays() {
      return differenceInDays(new Date(), new Date(this.property.start_at));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.property-item {
  $root: &;
  display: grid;
  grid-template-columns: minmax(64px, 50%) min-content;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: $sp-l;
  grid-row-gap: $sp-m;
  padding: $sp-l;
  background-color: getShade($quaternary-color, 40);
  border-radius: 4px;

  @include for('desktop') {
    width: 720px;
  }

  &__thumbnail {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    background-color: getShade($quaternary-color, 50);
    background-size: cover;
    background-position: center center;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    &--second {
      grid-column: 1 / span 1;
      grid-row: 2 / span 1;
    }
  }

  &__details {
    background-color: white;
    grid-column: 2 / span 1;
    grid-row: 1 / span 2;
    padding: $sp-l;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  &__list {
    @include font('DS-100');
    margin-top: $sp-m;
  }

  &__list-item {
    display: flex;

    dd {
      color: $quaternary-color;
    }

    span {
      color: $monza-red;
    }

    &--highlighted {
      font-weight: bold;
      margin-top: $sp-m;

      dd {
        color: $primary-color;
      }
    }
  }

  &__list-item--bold {
    dd,
    dt {
      color: $primary-color;
      font-weight: 800;
    }
  }

  &__separator {
    position: relative;
    bottom: $sp-xs;
    flex: 1;
    border-bottom: 2px dashed getShade($quaternary-color, 50);
    margin-right: $sp-xs;
    margin-left: $sp-xs;
  }

  &__address {
    @include font('DS-200');
    font-weight: bold;
  }
}
</style>
