import * as sharedCalculations from '../shared';

export default {
  getEarningsValuePrice: () => 0,
  getPlotAreaPrice: () => 0,
  getBuildingTypePrice: () => 0,
  getApartmentTypeAndFloorAndElevatorPrice: () => 0,
  getHeatingRenovationPrice: () => 0,
  ...sharedCalculations,
};
