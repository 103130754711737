<template>
  <div>
    <HdNotification
      v-if="failedBrokerOrderRequest"
      type="error"
      :message="failedBrokerOrderRequest"
    />
    <PageBase class="slides">
      <template #headerLeft>
        <component :is="headerLeft.component" v-bind="headerLeft.props" />
        <SlideNavigation :flow-slides="flowNavigationSlides" />
      </template>
      <template #headerCenter>
        <component :is="headerCenter.component" v-bind="headerCenter.props" />
      </template>
      <template #headerRight>
        <component :is="headerRight.component" v-bind="headerRight.props" />
      </template>
      <HdForm :id="slideFormId" class="slides__form" @submit="submitSlideForm">
        <div class="slides__slide-wrapper">
          <transition :name="transitionName">
            <router-view
              ref="slide"
              :style="{
                transitionDuration: `${slideTransitionDuration}ms`,
              }"
              class="slides__slide"
            />
          </transition>
        </div>
      </HdForm>
      <template #footer>
        <component :is="footer.component" v-bind="footer.props" />
      </template>
    </PageBase>
  </div>
</template>

<script>
// Lodash
import {
  isEqual as _isEqual,
  isNil as _isNil,
  get as _get,
  uniq as _uniq,
  result as _result,
} from 'lodash-es';

// Components
import PageBase from '@/layout/PageBase.vue';
import WizardNavigation from '@/components/WizardNavigation.vue';
import SlideNavigation from '@/components/SlideNavigation.vue';
import { HdNotification, HdForm } from 'homeday-blocks';

// Services
import { priceContributionGetters } from '@/services/priceContribution';
import { createEvaluationFromDeal } from '@/services/evaluation';

// Constants
import { BROKER_ORDER_ERROR_REASON, SPECIFIC_PROPERTY_TYPE } from '@/config/CONSTANTS';
import { FAILED_REQUEST_TYPE } from '@/services/failedRequest';
import FLOWS from '@/config/WIZARD_FLOWS';
import { SLIDES } from '@/router/wizard/slides';
import { DEALS as DEALS_ROUTE_NAMES } from '@/router/wizard/ROUTE_NAMES';
import { MUTATION, GETTER } from '@/store/CONSTANTS';
import { FILTERS } from '@/config/DEAL';
import NAVIGATION_FLOWS from '@/config/NAVIGATION_FLOWS';

const SLIDE_TRANSITION_DURATION = 300;
const SLIDE_FORM_ID = 'wizard-slide-form';

// A holder for the store subscription so we can be able to unsubscribe
let unsubscribeFromEvaluationAction = () => {};

export default {
  name: 'SlideItem',
  components: {
    PageBase,
    HdNotification,
    HdForm,
    SlideNavigation,
  },
  provide() {
    return {
      goToNextSlide: this.goToNextSlide,
      goToPreviousSlide: this.goToPreviousSlide,
      getPreviousSlides: this.getPreviousSlides,
      applyAllFlowPriceContributions: this.applyAllFlowPriceContributions,
      applyPriceContributionsUntilThisSlide: this.applyPriceContributionsUntilThisSlide,
      isLastSlide: this.isLastSlide,
      slideFormId: this.slideFormId,
      slideTransitionDuration: this.slideTransitionDuration,
    };
  },
  beforeRouteLeave(_to, _from, next) {
    this.detectFormChanges();

    unsubscribeFromEvaluationAction();

    next();
  },
  data() {
    return {
      goingForward: true,
      overlay: undefined,
      slideTransitionDuration: SLIDE_TRANSITION_DURATION,
      slideFormId: SLIDE_FORM_ID,
      currentSpecificPropertyType: _get(
        this.$store.state.wizard,
        'currentDeal.specificPropertyType',
        SPECIFIC_PROPERTY_TYPE.APARTMENT,
      ),
      savedEvaluation: null,
      showError: false,
    };
  },
  computed: {
    transitionName() {
      return this.goingForward ? 'slide-left' : 'slide-right';
    },
    currentDeal() {
      return this.$store.state.wizard.currentDeal;
    },
    dealState() {
      return this.$store.state.wizard.deals.find((deal) => deal.uid === this.currentDeal.uid);
    },
    failedBrokerOrderRequest() {
      if (this.currentRouteName !== 'instructionSlide') return null;
      const failedRequest = this.dealState.failedRequests.find(
        (fail) =>
          fail.type === FAILED_REQUEST_TYPE.BROKER_ORDER &&
          fail.reason !== BROKER_ORDER_ERROR_REASON.OFFLINE,
      );
      return failedRequest
        ? this.$t(`WIZARD.INSTRUCTION.ERROR.${failedRequest.reason?.toUpperCase() || 'NOT_SENT'}`)
        : null;
    },
    totalPrice() {
      return this.$store.getters[GETTER.WIZARD.CURRENT_DEAL.TOTAL_PRICE];
    },
    flowSlides() {
      // We filter the slides that return `true` when their `shouldBeSkipped()` is called
      // the slides without this method are always shown
      return FLOWS[this.currentSpecificPropertyType].filter(
        ({ shouldBeSkipped = () => false }) => !shouldBeSkipped(this.$store),
      );
    },
    flowNavigationSlides() {
      const filterTheOnesThatShouldBeSkipped = (filteredSlides, slide) => {
        if (slide.shouldBeSkipped && slide.shouldBeSkipped(this.$store)) {
          // skip this slide
          return [...filteredSlides];
        }
        // check the children
        const slideWithFilteredItems = { ...slide };
        if (slide.items) {
          const filteredChildren = slide.items.reduce(filterTheOnesThatShouldBeSkipped, []);
          // skip it if all the children were filtered
          if (filteredChildren?.length === 0) {
            return [...filteredSlides];
          }
          slideWithFilteredItems.items = filteredChildren;
        }
        return [...filteredSlides, slideWithFilteredItems];
      };

      return NAVIGATION_FLOWS[this.currentSpecificPropertyType].reduce(
        filterTheOnesThatShouldBeSkipped,
        [],
      );
    },
    currentRouteName() {
      return this.$route.name;
    },
    currentSlideIndex() {
      const index = this.flowSlides.findIndex(({ name }) => this.currentRouteName === name);

      // We go to first slide when the evaluation is initially started
      // and if the user tried to access a slide that doesn't exist in the flow
      return index < 0 ? 0 : index;
    },
    currentSlide() {
      return this.flowSlides[this.currentSlideIndex];
    },
    firstSlide() {
      return this.flowSlides[0];
    },
    isFirstSlide() {
      return this.currentRouteName === this.firstSlide.name;
    },
    headerLeft() {
      return _get(this.currentSlide, 'headerLeft', {});
    },
    headerCenter() {
      return _get(this.currentSlide, 'headerCenter', {});
    },
    headerRight() {
      return _get(this.currentSlide, 'headerRight', {
        // We fall back to the navigation component if no headerRight component is defined
        component: WizardNavigation,
        props: {
          showNextButton: this.currentRouteName !== 'instructionSlide',
        },
      });
    },
    footer() {
      return _get(this.currentSlide, 'footer', {});
    },
  },
  watch: {
    $route(to, from) {
      const toIndex = this.flowSlides.findIndex(({ name }) => name === to.name);
      const fromIndex = this.flowSlides.findIndex(({ name }) => name === from.name);

      this.goingForward = toIndex > fromIndex;

      if (this.goingForward) {
        const toSlide = this.flowSlides.find(({ name }) => name === to.name);
        this.overlay = toSlide.overlay;
      } else {
        this.removeOverlay();
      }

      this.applyPriceContributionsUntilThisSlide();
    },
    totalPrice() {
      // We unset the marketing price in the state to make it fall back
      // to the new totalPrice. We do this just for the non-public evaluations
      if (_get(this.currentDeal, 'lastEvaluation.public')) {
        return;
      }

      this.$store.commit(MUTATION.WIZARD.CURRENT_DEAL.SET_MARKETING_PRICE, undefined);
      // We unset the optimalPrice as it's not valid anymore
      this.$store.commit(MUTATION.WIZARD.CURRENT_DEAL.SET_OPTIMAL_PRICE, undefined);
    },
  },
  created() {
    this.goToInitialSlide();

    this.detectUnsavedEvaluation();
  },
  methods: {
    detectFormChanges() {
      const evaluation = this.getCurrentDealFullEvaluation();

      this.$store.commit(MUTATION.WIZARD.UPDATE_UNSAVED_DEALS);

      if (!_isEqual(evaluation, this.savedEvaluation)) {
        this.$store.commit(MUTATION.WIZARD.UPDATE_DEAL_PROPERTIES, {
          uid: this.currentDeal.uid,
          properties: {
            unsavedEvaluation: evaluation,
          },
        });
      }
    },
    goToInitialSlide() {
      if (this.currentRouteName !== this.currentSlide.name) {
        this.$router.push(this.currentSlide);
      }
    },
    async submitSlideForm(data) {
      if (!data.isValid) return;

      this.$store.commit(MUTATION.WIZARD.SET_IS_SLIDE_VALIDATING, true);
      /**
       * We check per slide if it's valid. All slides are valid by default if the last argument
       * is set to true.
       * @type {boolean}
       */
      const isValidSlide = await _result(this.$refs, 'slide.validate', true);
      this.$store.commit(MUTATION.WIZARD.SET_IS_SLIDE_VALIDATING, false);

      // The `beforeMoveToNextSlide` can be defined by need and acts as an async method in the slide life cycle.
      // Here we call it before moving to the next slide if there is any.
      const isCompletedSlide = await _result(this.$refs, 'slide.beforeMoveToNextSlide', true);

      if (isValidSlide && isCompletedSlide) {
        this.goToNextSlide();
      }
    },
    getNextSlide() {
      return this.flowSlides[this.currentSlideIndex + 1];
    },
    getPreviousSlide() {
      return this.flowSlides[this.currentSlideIndex - 1];
    },
    getPreviousSlides() {
      return this.flowSlides.slice(0, this.currentSlideIndex);
    },
    isLastSlide() {
      return this.currentSlide.name === this.flowSlides[this.flowSlides.length - 1].name;
    },
    goToNextSlide() {
      this.detectFormChanges();

      if (this.isLastSlide()) {
        return this.goToDealsView();
      }

      return this.$router.push(this.getNextSlide());
    },
    goToPreviousSlide() {
      this.detectFormChanges();

      if (this.isFirstSlide) {
        return this.goToDealsView();
      }

      if (!this.getPreviousSlide()) {
        return false;
      }

      return this.$router.push(this.getPreviousSlide());
    },
    goToDealsView() {
      const { filter } = this.currentDeal;
      let dealsRouteName = DEALS_ROUTE_NAMES.INDEX;

      // For a better UX, if we have the filter we go directly to the right tab
      if (filter) {
        dealsRouteName =
          filter === FILTERS.PAST ? DEALS_ROUTE_NAMES.PAST_DEALS : DEALS_ROUTE_NAMES.FUTURE_DEALS;
      }

      this.$router.push({ name: dealsRouteName });
    },
    removeOverlay() {
      this.overlay = undefined;
    },
    setAppliedPriceContributionsFromSlides(slides) {
      const priceContributionGetterNames = Object.keys(priceContributionGetters);

      const appliedPriceContributions = slides
        .flatMap((slide) => Object.keys(slide.component.computed || {}))
        .filter((computedProperty) => priceContributionGetterNames.includes(computedProperty));

      // We check the `additionalFeaturesPrice` individually
      // because it doesn't comply to our deal features structure
      if (slides.find(({ name }) => name === SLIDES.additionalFeatures.name)) {
        appliedPriceContributions.push('additionalFeaturesPrice');
      }

      // The array should be unique to avoid adding the same price more than once
      this.$store.commit(
        MUTATION.WIZARD.CURRENT_DEAL.SET_APPLIED_PRICE_CONTRIBUTIONS,
        _uniq(appliedPriceContributions),
      );
    },
    applyAllFlowPriceContributions() {
      this.setAppliedPriceContributionsFromSlides(this.flowSlides);
    },
    applyPriceContributionsUntilThisSlide() {
      const thisAndPreviousSlides = [...this.getPreviousSlides(), this.currentSlide];

      this.setAppliedPriceContributionsFromSlides(thisAndPreviousSlides);
    },
    detectUnsavedEvaluation() {
      // We detect if it's an evaluation with detailed renovations
      // to make sure we include the right features
      this.$store.commit(
        MUTATION.WIZARD.CURRENT_DEAL.SET_SHOULD_SHOW_DETAILED_RENOVATIONS,
        _isNil(this.currentDeal.lastRenovationYear),
      );

      // We generate an evaluation at first, to have it as a reference
      this.savedEvaluation = this.getCurrentDealFullEvaluation();

      // We update the saved evaluation if the user submits a new one
      unsubscribeFromEvaluationAction = this.$store.subscribeAction(
        ({ type, payload: { evaluation } }) => {
          if (type === 'wizard/createEvaluation') {
            this.savedEvaluation = evaluation;
          }
        },
      );
    },
    getCurrentDealFullEvaluation() {
      // We apply all the flow features so we can have a proper Total price
      this.applyAllFlowPriceContributions();

      const evaluation = createEvaluationFromDeal({
        deal: this.currentDeal,
        getters: this.$store.getters,
      });

      // We reset the applied feature prices to not cause side effects
      this.applyPriceContributionsUntilThisSlide();

      return evaluation;
    },
  },
};
</script>

<style lang="scss">
// Bulma adds a margin-bottom to .columns to match the gap vertically
// and this doesn't match out expected UI, as we don't want a gap vertically
// because out input components already have a margin bottom for the error area
.columns:not(:last-child) {
  margin-bottom: 0;
}
</style>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.slides {
  height: 100vh;

  &__form {
    height: 100%;
  }

  &__slide {
    min-width: 100%;
    padding: #{$sp-xl + $sp-s} $sp-m $sp-l;
    margin-right: auto;
    margin-left: auto;

    @include for('tablet') {
      padding: #{$sp-xl + $sp-m} 0 $sp-l;
    }
  }

  &__slide-wrapper {
    display: flex;
    position: relative;
    min-height: 100%;
    margin-left: auto;
    overflow: hidden;
    width: calc(100% - 67px); // Collapsed SlideNavigation width

    @include for('mobile-only') {
      margin-right: auto;
      width: 100%;
    }
  }
}

.slide-left,
.slide-right {
  &-leave-active,
  &-enter-active {
    transition-property: transform;
    transition-timing-function: linear;

    ::v-deep .notification__missing_data {
      display: none;
    }
  }
  &-enter-active {
    position: absolute;
  }
  &-enter-to {
    transform: translateX(0);
  }
}

.slide-left {
  &-leave-to {
    transform: translateX(-100%);
  }

  &-enter {
    transform: translateX(100%);
  }
}

.slide-right {
  &-leave-to {
    transform: translateX(100%);
  }

  &-enter {
    transform: translateX(-100%);
  }
}
</style>
