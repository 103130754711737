<template>
  <PageBase class="login">
    <div class="login__wrapper columns is-mobile is-centered is-vcentered">
      <section class="login__inner column is-half-tablet is-4-desktop">
        <HdAlert v-if="error" type="error" :no-icon="false" class="login__error">
          {{ error }}
        </HdAlert>

        <h3>{{ $t('LOGIN.TITLE') }}</h3>

        <HdDynamicForm
          :items="formItems"
          :submit-label="$t('LOGIN.LOGIN_BUTTON')"
          class="login__form"
          @submit="login"
        />

        <div class="login__link-to-registration">
          <router-link
            class="link login__link-to-forgot-password"
            :to="{ name: 'request-new-password' }"
          >
            {{ $t('LOGIN.FORGOT_PASSWORD') }}
          </router-link>
          |
          <router-link
            class="link login__link-to-registration-inner"
            :to="{ name: 'registration' }"
          >
            {{ $t('REGISTRATION.TITLE') }}
          </router-link>
        </div>
      </section>
    </div>
  </PageBase>
</template>

<script>
// Components
import { HdAlert, HdDynamicForm } from 'homeday-blocks';
import PageBase from '@/layout/PageBase.vue';
// Route names
import { DEALS as WIZARD_ROUTE_NAMES } from '@/router/wizard/ROUTE_NAMES';
// Constants
import { ACTION } from '@/store/CONSTANTS';
import { REDIRECT_TO } from '@/config/QUERY_PARAMS';
// Services
import { sendEvent, sendPageView, EVENTS, getCustomStatistics } from '@/services/gtm';

export default {
  name: 'LoginView',
  components: {
    HdDynamicForm,
    PageBase,
    HdAlert,
  },
  data() {
    return {
      rememberMe: false,
      email: '',
      password: '',
      error: '',
    };
  },
  computed: {
    formItems() {
      return [
        {
          type: 'input',
          name: 'email',
          initialValue: this.email,
          props: {
            type: 'email',
            required: true,
            label: this.$t('FORM.EMAIL.LABEL_INFORMAL'),
            placeholder: this.$t('FORM.EMAIL.PLACEHOLDER'),
          },
        },
        {
          type: 'inputPassword',
          name: 'password',
          initialValue: this.password,
          props: {
            required: true,
            label: this.$t('FORM.PASSWORD.LABEL_INFORMAL'),
          },
        },
        {
          type: 'checkbox',
          name: 'rememberMe',
          initialValue: this.rememberMe,
          props: {
            required: false,
            innerLabel: this.$t('LOGIN.REMEMBER_ME'),
          },
        },
      ];
    },
  },
  mounted() {
    // Tracking
    sendPageView({
      ...EVENTS.PAGE_VIEW.LOGIN,
      ...getCustomStatistics(this.$store, this.$route.path),
    });
  },
  methods: {
    login({ data, isValid }) {
      if (!isValid) {
        return;
      }

      this.$store
        .dispatch(ACTION.USER.LOGIN, data)
        .then(() => {
          // If the redirection param exists we go to that path
          // otherwise, we go to the deals overview
          const nextRoute = this.$route.query[REDIRECT_TO] || { name: WIZARD_ROUTE_NAMES.INDEX };

          // Tracking
          sendEvent({
            ...EVENTS.INTERACTION.LOGIN,
            email: data.email,
          });

          this.$router.push(nextRoute);
        })
        .catch(({ response: { status } = {} }) => {
          this.error = '';
          this.$nextTick(() => this.setErrorFromStatus(status));
        });
    },
    setErrorFromStatus(status) {
      switch (status) {
        case 401:
          this.error = this.$t('LOGIN.CREDS_ERROR');
          break;

        default:
          this.error = this.$t('LOGIN.GENERIC_ERROR');
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.login {
  height: 100vh;

  &__wrapper {
    height: 100%;
    padding-top: $sp-l;
    padding-bottom: $sp-l;
  }

  &__error {
    margin-bottom: $sp-m;
    animation: fadeIn 0.5s forwards;
  }

  &__form {
    margin-top: $sp-m;
    text-align: left;
  }

  &__link-to-registration {
    text-align: center;
    margin-top: $sp-m;
  }

  &__link-to-registration-inner,
  &__link-to-forgot-password {
    @include font('DS-100');
    display: inline-block;
    -webkit-box-shadow: inset 0 -1px 0 $secondary-color;
    box-shadow: inset 0 -1px 0 $secondary-color;
    &:hover {
      color: $secondary-color;
    }
  }
}
</style>
