<template>
  <div class="location">
    <property-address />
    <img :src="currentDeal.mapImage" class="location__screenshot" />
    <p class="location__text">{{ $t('GENERAL.LOCATION') }}</p>
    <location-qualities class="location__qualities" />

    <section class="location__actions">
      <div v-for="{ icon, label, clickHandler } in actions" :key="label" class="location__action">
        <HdButton
          :name="icon"
          modifier="tertiary"
          :icon-src="icon"
          type="button"
          @click.native="clickHandler"
        />
        <p class="location__label">
          {{ label }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
// Components
import LocationQualities from '@/components/LocationQualities.vue';
import { HdButton } from 'homeday-blocks';
import PropertyAddress from '@/components/PropertyAddress.vue';
// Icons
import { myLocation, map } from 'homeday-assets';

export default {
  name: 'LocationSlide',
  components: {
    LocationQualities,
    HdButton,
    PropertyAddress,
  },
  data() {
    return {
      actions: [
        {
          icon: myLocation,
          label: this.$t('GENERAL.IMMOWELT'),
          clickHandler: () => {
            this.openUrl(this.currentDeal.immoweltUrl);
          },
        },
        {
          icon: map,
          label: this.$t('GENERAL.GOOGLE_MAPS'),
          clickHandler: () => {
            this.openUrl(this.currentDeal.googleMapsUrl);
          },
        },
      ],
    };
  },
  computed: {
    currentDeal() {
      return this.$store.state.wizard.currentDeal;
    },
  },
  methods: {
    openUrl(url) {
      window.open(url, '_blank', 'noopener');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.location {
  padding-top: $sp-m;

  ::v-deep .property-address.columns {
    margin-bottom: $sp-l;
  }

  &__screenshot {
    width: 100%;
    height: 250px;
    object-fit: cover;

    @include for('tablet') {
      height: 400px;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: $sp-m;
  }

  &__action {
    text-align: center;
    margin-right: $sp-m;
    margin-left: $sp-m;
  }

  &__label {
    margin-top: 4px;
  }

  &__text {
    font-style: italic;
    font-weight: 600;
    padding-right: $sp-xs;
    text-align: right;
  }
}
</style>
