<template>
  <wizard-base
    class="building-type"
    :title="$t('WIZARD.BUILDING_TYPE.TITLE')"
    :subtitle="$t('GENERAL.FIELD_REQUIRED')"
  >
    <HdRadioCardGroup v-model="buildingType" name="building-type" :mode="radioCardMode">
      <HdRadioCard v-for="type in buildingTypes" :key="type.value" :native-value="type.value">
        <template #icon>
          <HdIcon
            :src="radioCardMode === 'card' ? type.icon : type.mobileIcon"
            :class-from-fill="defaultIconColors"
          />
        </template>
        {{ type.text }}
      </HdRadioCard>
    </HdRadioCardGroup>
    <PriceContributionInput
      v-model="buildingTypePrice"
      name="buildingType"
      class="building-type__input"
    />
  </wizard-base>
</template>

<script>
// Components
import WizardBase from '@/layout/WizardBase.vue';
import PriceContributionInput from '@/components/PriceContributionInput.vue';
import { HdRadioCard, HdRadioCardGroup, OnResizeService, HdIcon } from 'homeday-blocks';
// Services
import { mapFeatures } from '@/services/feature';
import { mapPriceContributions } from '@/services/priceContribution';
import { isMobile } from '@/services/breakpoints';
import { SVG_DEFAULT_COLORS } from '@/config/CONSTANTS';
// Icons
import {
  houseSingleFamily as houseSingleFamilyL,
  houseSemiDetached as houseSemiDetachedL,
  houseTown as houseTownL,
  houseBungalow as houseBungalowL,
  houseFarm as houseFarmL,
  houseVilla as houseVillaL,
  houseCastle as houseCastleL,
  houseStepped as houseSteppedL,
} from 'homeday-assets/L';

import {
  houseSingleFamily as houseSingleFamilyM,
  houseSemiDetached as houseSemiDetachedM,
  houseTown as houseTownM,
  houseBungalow as houseBungalowM,
  houseFarm as houseFarmM,
  houseVilla as houseVillaM,
  houseCastle as houseCastleM,
  houseStepped as houseSteppedM,
} from 'homeday-assets/M';

export default {
  name: 'BuildingTypeSlide',
  components: {
    WizardBase,
    HdRadioCard,
    HdRadioCardGroup,
    PriceContributionInput,
    HdIcon,
  },
  inject: ['slideTransitionDuration'],
  data() {
    return {
      radioCardMode: isMobile() ? 'tile' : 'card',
      buildingTypes: [
        {
          value: 'single_family_house',
          text: this.$t('WIZARD.BUILDING_TYPE.OPTIONS.SINGLE_FAMILY_HOUSE'),
          icon: houseSingleFamilyL,
          mobileIcon: houseSingleFamilyM,
        },
        {
          value: 'semidetached_house',
          text: this.$t('WIZARD.BUILDING_TYPE.OPTIONS.SEMIDETACHED_HOUSE'),
          icon: houseSemiDetachedL,
          mobileIcon: houseSemiDetachedM,
        },
        {
          value: 'terrace_house',
          text: this.$t('WIZARD.BUILDING_TYPE.OPTIONS.TERRACE_HOUSE'),
          icon: houseTownL,
          mobileIcon: houseTownM,
        },
        {
          value: 'bungalow',
          text: this.$t('WIZARD.BUILDING_TYPE.OPTIONS.BUNGALOW'),
          icon: houseBungalowL,
          mobileIcon: houseBungalowM,
        },
        {
          value: 'farmhouse',
          text: this.$t('WIZARD.BUILDING_TYPE.OPTIONS.FARMHOUSE'),
          icon: houseFarmL,
          mobileIcon: houseFarmM,
        },
        {
          value: 'villa',
          text: this.$t('WIZARD.BUILDING_TYPE.OPTIONS.VILLA'),
          icon: houseVillaL,
          mobileIcon: houseVillaM,
        },
        {
          value: 'castle_manor_house',
          text: this.$t('WIZARD.BUILDING_TYPE.OPTIONS.CASTLE_MANOR_HOUSE'),
          icon: houseCastleL,
          mobileIcon: houseCastleM,
        },
        {
          value: 'stepped_building',
          text: this.$t('WIZARD.BUILDING_TYPE.OPTIONS.STEPPED_BUILDING'),
          icon: houseSteppedL,
          mobileIcon: houseSteppedM,
        },
      ],
    };
  },
  computed: {
    ...mapFeatures(['buildingType']),
    ...mapPriceContributions(['buildingType']),
    defaultIconColors() {
      return SVG_DEFAULT_COLORS;
    },
  },
  created() {
    OnResizeService.onThrottled(this.updateRadioCardMode);
  },
  destroyed() {
    OnResizeService.offThrottled(this.updateRadioCardMode);
  },
  methods: {
    updateRadioCardMode() {
      if (isMobile()) {
        this.radioCardMode = 'tile';
      } else {
        this.radioCardMode = 'card';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.building-type {
  overflow-x: hidden;

  ::v-deep .wizard-base__container {
    // Overwrite layout
    margin-right: 0;
    margin-left: 0;
  }

  &__input {
    width: 200px;
    margin: $sp-xl auto $sp-l;
  }

  ::v-deep .card img {
    width: 85px;
    height: 85px;
  }

  ::v-deep .card--tile img {
    width: 44px;
    height: 44px;
  }

  .radio-group {
    grid-template-columns: repeat(auto-fill, minmax(241px, 1fr));
  }
}
</style>
