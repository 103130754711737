const VALUE_TYPES = { BOOLEAN: 'BOOLEAN', STRING: 'STRING', NUMBER: 'NUMBER' };

const STRING_VALUE_PER_KEY = {
  // building_type
  semidetached_house: 'WIZARD.BUILDING_TYPE.OPTIONS.SEMIDETACHED_HOUSE',
  terrace_house: 'WIZARD.BUILDING_TYPE.OPTIONS.TERRACE_HOUSE',
  single_family_house: 'WIZARD.BUILDING_TYPE.OPTIONS.SINGLE_FAMILY_HOUSE',
  multi_family_house: 'WIZARD.BUILDING_TYPE.OPTIONS.MULTI_FAMILY_HOUSE',
  mid_terrace_house: 'WIZARD.BUILDING_TYPE.OPTIONS.MID_TERRACE_HOUSE',
  end_terrace_house: 'WIZARD.BUILDING_TYPE.OPTIONS.END_TERRACE_HOUSE',
  villa: 'WIZARD.BUILDING_TYPE.OPTIONS.VILLA',
  bungalow: 'WIZARD.BUILDING_TYPE.OPTIONS.BUNGALOW',
  farmhouse: 'WIZARD.BUILDING_TYPE.OPTIONS.FARMHOUSE',
  stepped_building: 'WIZARD.BUILDING_TYPE.OPTIONS.STEPPED_BUILDING',
  castle_manor_house: 'WIZARD.BUILDING_TYPE.OPTIONS.CASTLE_MANOR_HOUSE',
  // apartment_type
  maisonette: 'WIZARD.ACCESSIBILITY.APARTMENT_TYPES.MAISONETTE',
  ground_floor: 'WIZARD.ACCESSIBILITY.APARTMENT_TYPES.GROUND_FLOOR',
  apartment: 'WIZARD.ACCESSIBILITY.APARTMENT_TYPES.APARTMENT',
  roof_storey: 'WIZARD.ACCESSIBILITY.APARTMENT_TYPES.ROOF_STOREY',
  loft: 'WIZARD.ACCESSIBILITY.APARTMENT_TYPES.LOFT',
  penthouse: 'WIZARD.ACCESSIBILITY.APARTMENT_TYPES.PENTHOUSE',
  raised_ground_floor: 'WIZARD.ACCESSIBILITY.APARTMENT_TYPES.RAISED_GROUND_FLOOR',
  half_basement: 'WIZARD.ACCESSIBILITY.APARTMENT_TYPES.HALF_BASEMENT',
  terraced_flat: 'WIZARD.ACCESSIBILITY.APARTMENT_TYPES.TERRACED_FLAT',
  attica: 'WIZARD.ACCESSIBILITY.APARTMENT_TYPES.ATTICA',
  // interior_quality
  very_simple: 'EVALUATION_REPORT.INTERIOR_QUALITIES.VERY_SIMPLE',
  simple: 'EVALUATION_REPORT.INTERIOR_QUALITIES.SIMPLE',
  normal: 'EVALUATION_REPORT.INTERIOR_QUALITIES.NORMAL',
  sophisticated: 'EVALUATION_REPORT.INTERIOR_QUALITIES.SOPHISTICATED',
  luxury: 'EVALUATION_REPORT.INTERIOR_QUALITIES.LUXURY',
  // last_modernization
  this_year: 'EVALUATION_REPORT.LAST_MODERNIZATION.THIS_YEAR',
  last_year: 'EVALUATION_REPORT.LAST_MODERNIZATION.LAST_YEAR',
  two_years_ago: 'EVALUATION_REPORT.LAST_MODERNIZATION.TWO_YEARS_AGO',
  three_years_ago: 'EVALUATION_REPORT.LAST_MODERNIZATION.THREE_YEARS_AGO',
  four_years_ago: 'EVALUATION_REPORT.LAST_MODERNIZATION.FOUR_YEARS_AGO',
  five_years_ago: 'EVALUATION_REPORT.LAST_MODERNIZATION.FIVE_YEARS_AGO',
  six_years_ago: 'EVALUATION_REPORT.LAST_MODERNIZATION.SIX_YEARS_AGO',
  seven_years_ago: 'EVALUATION_REPORT.LAST_MODERNIZATION.SEVEN_YEARS_AGO',
  eight_years_ago: 'EVALUATION_REPORT.LAST_MODERNIZATION.EIGHT_YEARS_AGO',
  nine_years_ago: 'EVALUATION_REPORT.LAST_MODERNIZATION.NINE_YEARS_AGO',
  ten_years_ago: 'EVALUATION_REPORT.LAST_MODERNIZATION.TEN_YEARS_AGO',
  fifteen_years_ago: 'EVALUATION_REPORT.LAST_MODERNIZATION.FIFTEEN_YEARS_AGO',
  more_than_fifteen_years_ago: 'EVALUATION_REPORT.LAST_MODERNIZATION.MORE_THAN_FIFTEEN_YEARS_AGO',
  never: 'EVALUATION_REPORT.LAST_MODERNIZATION.NEVER',
  // rent_status
  rented: 'WIZARD.RENT_STATUS.RENTED',
  vacant: 'WIZARD.RENT_STATUS.VACANT',
  owner_occupation: 'WIZARD.RENT_STATUS.OWNER_OCCUPATION',
};

const formatBooleanValue = (booleanValue) => {
  return booleanValue ? 'GENERAL.YES' : 'GENERAL.NO';
};

const formatStringValue = (stringValue) => {
  return STRING_VALUE_PER_KEY[stringValue] || stringValue;
};
const formatNumberValue = (numberValue) => numberValue;

export const CHARACTERISTIC_VALUE_FORMATS = {
  [VALUE_TYPES.BOOLEAN]: formatBooleanValue,
  [VALUE_TYPES.STRING]: formatStringValue,
  [VALUE_TYPES.NUMBER]: formatNumberValue,
};
