import CONFIG from '@/config/env';
import { get } from 'lodash-es';

const isCookiebotEnabled = get(CONFIG, 'integrations.cookiebot.enabled');
export const DEFAULT_CONSENT = {
  CookieConsent: {
    consent: {
      necessary: true,
      preferences: false,
      statistics: false,
      marketing: false,
    },
  },
};

export function getCurrentConsent() {
  return window.CookieConsent
    ? { CookieConsent: { consent: window.CookieConsent.consent } }
    : DEFAULT_CONSENT;
}

export function hasStatisticsConsent() {
  // if we have disabled the cookiebot feature, then we assume that the consent was given
  if (!isCookiebotEnabled) {
    return true;
  }

  return getCurrentConsent()?.CookieConsent?.consent?.statistics;
}

// Forward cookie consent to GTM - set default to false for safety
export function shareConsentGTM() {
  window.dataLayer.push(['set', getCurrentConsent()]);
}

// We listen to any update on CookiebotConsent and forward the consent to the required places
// https://www.cookiebot.com/en/developer/
export function initListener() {
  shareConsentGTM();
  window.addEventListener(
    'CookiebotOnConsentReady',
    () => {
      shareConsentGTM();
    },
    false,
  );
}
