<template>
  <PageBase class="forgot">
    <div class="forgot__wrapper columns is-mobile is-centered is-vcentered">
      <section v-if="isConfirmed === true" class="forgot__inner column is-half-tablet is-4-desktop">
        <h3>{{ $t('RESET_PASSWORD.REQUEST.CONFIRM_TITLE') }}</h3>

        <p class="forgot__description">
          {{ $t('RESET_PASSWORD.REQUEST.CONFIRM_DESCRIPTION') }}
        </p>

        <HdButton modifier="primary" class="forgot__login-btn" @click="redirectToLogin">
          {{ $t('GENERAL.TO_LOGIN_PAGE') }}
        </HdButton>
      </section>
      <section v-else class="forgot__inner column is-half-tablet is-4-desktop">
        <HdAlert v-if="error" type="error" :no-icon="false" class="forgot__error">
          {{ error }}
        </HdAlert>

        <h3>{{ $t('RESET_PASSWORD.REQUEST.TITLE') }}</h3>

        <p class="forgot__description">
          {{ $t('RESET_PASSWORD.REQUEST.DESCRIPTION') }}
        </p>

        <HdDynamicForm
          :items="formItems"
          :submit-label="$t('GENERAL.SEND')"
          class="forgot__form"
          @submit="submitHandler"
        />

        <div class="forgot__link-to">
          <router-link class="link forgot__link-to-page" :to="{ name: 'login' }">
            {{ $t('REGISTRATION.TO_LOGIN_PAGE') }}
          </router-link>
        </div>
      </section>
    </div>
  </PageBase>
</template>

<script>
// Components
import { HdAlert, HdDynamicForm, HdButton } from 'homeday-blocks';
import PageBase from '@/layout/PageBase.vue';
// Constants
import { ACTION } from '@/store/CONSTANTS';

export default {
  name: 'RequestNewPasswordView',
  components: {
    HdDynamicForm,
    PageBase,
    HdAlert,
    HdButton,
  },
  data() {
    return {
      email: '',
      error: '',
      isConfirmed: false,
    };
  },
  computed: {
    formItems() {
      return [
        {
          type: 'input',
          name: 'email',
          initialValue: this.email,
          props: {
            type: 'email',
            required: true,
            label: this.$t('FORM.EMAIL.LABEL_INFORMAL'),
            placeholder: this.$t('FORM.EMAIL.PLACEHOLDER'),
          },
        },
      ];
    },
  },
  methods: {
    redirectToLogin() {
      this.$router.push('/login');
      this.$router.go(0);
    },
    submitHandler({ data, isValid }) {
      if (!isValid) {
        return;
      }

      this.$store
        .dispatch(ACTION.USER.REQUEST_NEW_PASS, data)
        .then(() => {
          this.isConfirmed = true;
        })
        .catch(({ response: { status } = {} }) => {
          this.error = '';
          this.$nextTick(() => this.setErrorFromStatus(status));
        });
    },
    setErrorFromStatus(status) {
      switch (status) {
        default:
          this.error = this.$t('LOGIN.GENERIC_ERROR');
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.forgot {
  height: 100vh;

  &__description {
    font-size: 18px;
    line-height: 28px;
    margin: $sp-s auto;
  }

  &__wrapper {
    height: 100%;
    padding-top: $sp-l;
    padding-bottom: $sp-l;
  }

  &__error {
    margin-bottom: $sp-m;
    animation: fadeIn 0.5s forwards;
  }

  &__form {
    margin-top: $sp-m;
    text-align: left;
  }

  &__link-to {
    text-align: center;
    margin-top: $sp-m;
  }

  &__link-to-page {
    @include font('DS-100');
    display: inline-block;
    -webkit-box-shadow: inset 0 -1px 0 $secondary-color;
    box-shadow: inset 0 -1px 0 $secondary-color;
    &:hover {
      color: $secondary-color;
    }
  }

  &__login-btn {
    margin-top: $sp-s;
    width: 100%;
  }
}
</style>
