import { get as _get } from 'lodash-es';

/**
 * Transform a translation object to an array of objects. Useful for form components
 * @param {Object} translationObject - An object in that format:
 *  { VALUE_1: 'label 1', VALUE_2: 'label 2' }
 * @returns {Array} An array that looks like this:
 * [ { label: 'label 1', value: 'value_1' }, { label: 'label 2', value: 'value_2' } ]
 */
export function getItemsFromTranslationObject(translationObject) {
  return Object.entries(translationObject).map(([key, label]) => ({
    label,
    text: label,
    value: key.toLowerCase(),
  }));
}

/**
 * Gets feature from last Evaluation.
 * @param apiDeal
 * @returns {function({path: *, defaultValue: *}): *}
 */
export function getFeatureFactory(apiDeal) {
  return function getFeature({ path, defaultValue, normalizer }) {
    // Call the normalizer directly if there is any.
    if (normalizer) {
      return normalizer.normalize(apiDeal);
    }

    // We try to get the feature value from the last evaluation first
    const propertyName = path.split('.').pop();
    const lastEvaluationFeature = _get(apiDeal, 'last_evaluation.features', []).find(
      ({ name }) => name === propertyName,
    );
    return _get(lastEvaluationFeature, 'value', _get(apiDeal, path, defaultValue));
  };
}
