<template>
  <PageBase class="profile">
    <template #headerRight>
      <AccountControls />
    </template>
    <HdForm
      ref="form"
      class="profile__form container"
      @submit="submit"
      @keydown.enter.native.prevent
      @key.enter.native.prevent
      @keyup.enter.native="submit"
    >
      <div class="profile__group columns is-multiline">
        <h4 class="profile__title column is-full" v-text="$t('PROFILE.TITLE')" />
        <ProfileUploader
          ref="profileUploader"
          v-model="profile.profileImage"
          :custom-rules="fileValidation"
          class="column is-full"
          name="profile-uploader-component"
        />
        <HdRadio
          v-model="profile.salutation"
          class="profile__salutation column is-full"
          :items="genderField"
          name="gender"
          required
        />
      </div>
      <div class="profile__group columns is-multiline">
        <h5 class="column is-full" v-text="$t('PROFILE.FORM.YOUR_DATA')" />
        <HdInput
          v-model="profile.firstName"
          class="column is-full"
          :required="true"
          :custom-rules="nameValidations"
          name="first-name"
          :label="$t('FORM.FIRST_NAME.LABEL')"
        />
        <HdInput
          v-model="profile.lastName"
          class="column is-full"
          :custom-rules="nameValidations"
          :required="true"
          name="last-name"
          :label="$t('FORM.LAST_NAME.LABEL')"
        />
        <HdInput
          v-model="profile.profileEmail"
          class="column is-full"
          :required="true"
          name="email"
          :label="$t('FORM.EMAIL.LABEL_INFORMAL')"
          :custom-rules="emailValidation"
        />
        <HdInput
          v-model="profile.phone"
          class="column is-full"
          :required="true"
          name="phone"
          :label="$t('PROFILE.FORM.PHONE')"
          :custom-rules="phoneValidation"
        />
      </div>
      <div class="profile__group columns is-multiline">
        <h5 class="column is-full" v-text="$t('PROFILE.FORM.YOUR_PASSWORD')" />
        <HdInputPassword
          v-model="profile.password"
          class="column is-full"
          :required="Boolean(profile.newPassword)"
          name="current-password"
          :label="$t('PROFILE.FORM.CURRENT_PASSWORD')"
        />
        <HdInputPassword
          v-model="profile.newPassword"
          class="column is-full"
          :required="Boolean(profile.currentPassword)"
          name="new-password"
          :label="$t('PROFILE.FORM.NEW_PASSWORD')"
          :custom-rules="passwordValidation"
        />
      </div>
      <div class="profile__submit columns">
        <div class="column is-4">
          <HdButton
            modifier="secondary"
            type="reset"
            @click="navigateToDeals"
            @keydown.enter.native.prevent
            @keyup.enter.native.prevent
            @key.enter.native.prevent
            >{{ $t('FORM.BUTTON.CLOSE') }}</HdButton
          >
        </div>
        <div class="column is-8">
          <HdButton modifier="primary">{{ $t('PROFILE.FORM.SUBMIT') }}</HdButton>
        </div>
      </div>
    </HdForm>
  </PageBase>
</template>

<script>
// Components
import { HdForm, HdRadio, HdInput, HdButton, HdInputPassword } from 'homeday-blocks';
import PageBase from '@/layout/PageBase.vue';
import AccountControls from '@/components/AccountControls.vue';
import ProfileUploader from '@/components/ProfileUploader.vue';
// Constants
import { SALUTATION_TYPES } from '@/config/TRANSLATION_CONSTANTS';
import { ACTION } from '@/store/CONSTANTS';
// Services
import { getItemsFromTranslationObject } from '@/services/helpers';
import { email as validateEmail } from 'homeday-blocks/src/services/formValidation';
import { validatePhone } from '@/services/validators';
import { UtilsService } from 'homeday-blocks';

const { keysToCamelCase, getPasswordStrength } = UtilsService;

export default {
  name: 'ProfileView',
  components: {
    HdForm,
    HdRadio,
    HdInput,
    HdButton,
    HdInputPassword,
    ProfileUploader,
    AccountControls,
    PageBase,
  },
  data() {
    return {
      error: '',
      profile: {},
    };
  },
  computed: {
    genderField() {
      return getItemsFromTranslationObject(SALUTATION_TYPES);
    },
    phoneValidation() {
      return [
        {
          validate: validatePhone,
          errorMessage: this.$t('FORM.TELEPHONE.ERROR.INVALID_FORMAT'),
        },
        {
          validate: (value) => value.toString().length <= 15,
          errorMessage: this.$t('PROFILE.FORM.MAX_CHARS', { size: 15 }),
        },
      ];
    },
    emailValidation() {
      return [
        {
          validate: validateEmail,
          errorMessage: this.$t('WIZARD.INSTRUCTION.VALIDATION.EMAIL'),
        },
        {
          validate: (value) => value.toString().length <= 40,
          errorMessage: this.$t('PROFILE.FORM.MAX_CHARS', { size: 40 }),
        },
      ];
    },
    passwordValidation() {
      return [
        {
          validate: (password) => getPasswordStrength(password, 2),
          errorMessage: this.$t('PROFILE.FORM.INVALID_PASSWORD'),
        },
      ];
    },
    fileValidation() {
      return [
        {
          validate: (file) => {
            return file.size < 5_000_000;
          },
          errorMessage: this.$t('PROFILE.FORM.INVALID_FILE'),
        },
      ];
    },
    isFormValid() {
      return this.$refs.form?.$data.fields.every((field) => field.isValid !== false); // some fields don't have this attribute
    },
    nameValidations() {
      return [
        {
          validate: (value) => value.toString().length <= 20,
          errorMessage: this.$t('PROFILE.FORM.MAX_CHARS', { size: 20 }),
        },
      ];
    },
  },
  beforeMount() {
    this.initProfileObject(); // Needs to be here to initialize the profile with an image if it already has
  },
  mounted() {
    this.initProfileForm(); // Needs to be here to have the form instance ready
  },
  methods: {
    initProfileObject() {
      // Transform the profile data to fit the component
      const profile = keysToCamelCase(this.$store.state.user.data);

      // ContactPhoto = link, profileImage = bites
      profile.profileImage = profile.contactPhoto;
      delete profile.contactPhoto;

      // profileEmail = notification email
      // email = login credential - cannot be changed
      if (!profile.profileEmail) profile.profileEmail = profile.email;

      this.profile = profile;
    },
    initProfileForm() {
      // Add the ProfileUploader input to the HdForm fields
      this.$refs.profileUploader.name = 'profile-uploader-component'; // Force a name here because HdForm requires it 🫣
      this.$refs.form.addField(this.$refs.profileUploader);
    },
    navigateToDeals() {
      this.$router.push({ name: 'futureDeals' });
    },
    submit() {
      if (!this.isFormValid) return;
      if (!this.profile.profileImage) delete this.profile.profileImage; // Force removing to avoid update or 500

      this.$store
        .dispatch(ACTION.USER.UPDATE, this.profile)
        .then(() => {
          this.navigateToDeals();
        })
        .catch((error) => {
          this.error = error;
          this.$nextTick(() => console.error(error));
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.profile {
  &__form {
    width: 40vw;
    min-width: 400px;
    margin-bottom: $sp-l;
  }

  &__title {
    text-align: center;
  }

  &__salutation {
    display: flex;
  }

  &__submit {
    button {
      width: 100%;
    }
  }

  ::v-deep {
    & .page__main .page-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .radio-wrapper {
      align-items: center;
      justify-content: space-around;
    }
    & .radio {
      flex: 0;
    }
  }
}
</style>
