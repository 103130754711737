import Vue from 'vue';
import { getDefaultState } from './state';
import { MUTATION } from './CONSTANTS';

export default {
  [MUTATION.SET](state, deal) {
    Object.keys(deal).forEach((key) => Vue.set(state, key, deal[key]));
  },
  [MUTATION.RESET](state) {
    // We set our added value to the defaults and delete the rest
    // to avoid dragging data from a deal to another
    // especially that the BE doesn't always return the same data for all the deals
    const defaultState = getDefaultState();
    Object.keys(state).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(defaultState, key)) {
        Vue.set(state, key, defaultState[key]);

        return;
      }

      Vue.delete(state, key);
    });
  },
  [MUTATION.SET_FEATURE_VALUE](state, { name, value }) {
    Vue.set(state, name, value);
  },
  [MUTATION.SET_PRICE_CONTRIBUTION](state, { name, value }) {
    Vue.set(state.priceContributions, name, value);
  },
  [MUTATION.SET_ACTUAL_COLD_RENT](state, value) {
    Vue.set(state, 'actualColdRent', value);
  },
  [MUTATION.SET_SUGGESTED_SQM_PRICE](state, value) {
    Vue.set(state, 'suggestedSqmPrice', value);
  },
  [MUTATION.SET_MARKETING_PRICE](state, value) {
    Vue.set(state, 'marketingPrice', value);
  },
  [MUTATION.SET_ADDITIONAL_FEATURES](state, features) {
    Vue.set(state, 'additionalFeatures', features);
  },
  [MUTATION.SET_BATHROOMS](state, bathrooms) {
    Vue.set(state, 'bathrooms', bathrooms);
  },
  [MUTATION.SET_APPLIED_PRICE_CONTRIBUTIONS](state, appliedPriceContributions) {
    Vue.set(state, 'appliedPriceContributions', appliedPriceContributions);
  },
  [MUTATION.SET_OPTIMAL_PRICE](state, optimalPrice) {
    Vue.set(state, 'optimalPrice', optimalPrice);
  },
  [MUTATION.SET_WINDOWS](state, windows) {
    Vue.set(state, 'windows', windows);
  },
  [MUTATION.SET_SHOULD_SHOW_DETAILED_RENOVATIONS](state, shouldShowDetailedRenovations) {
    Vue.set(state, 'shouldShowDetailedRenovations', shouldShowDetailedRenovations);
  },
};
