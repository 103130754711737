import { get as _get, isNil as _isNil, head as _head, camelCase as _camelCase } from 'lodash-es';

import { UtilsService as utils } from 'homeday-blocks';
import CONFIG from '@/config/env';
import { BUILDING_TYPES, SALUTATION } from '@/config/CONSTANTS';
import { buildURL as buildImgixURL, getPreset as getImgixPreset } from '@/services/imgix';
import { validateSpace } from '@/services/validators';
import { heatingNormalizer, windowNormalizer, basementNormalizer } from '@/services/normalizers';
import { bathroomsDecoder } from '@/services/decoders/bathrooms';
import { getFeatureFactory } from '@/services/helpers';
import { i18n } from '@/services/i18n';
import { createEnhancedEvaluation } from '@/services/evaluation';

function getFormattedAddressWithoutCity({ address }) {
  return `${address.street} ${address.street_number}, ${address.zip_code}`;
}
function getImmoweltUrl(deal) {
  const PROPERTY_TYPE_DICTIONARY = {
    plot: 'grundstueckspreise',
    house: 'hauspreise',
    apartment: 'wohnungspreise',
  };

  const IMMOWELT_BASE_URL = 'https://www.immowelt.de/immobilienpreise';
  const propertyType = `/${PROPERTY_TYPE_DICTIONARY[deal.propertyType]}`;
  const encodedCity = `/${encodeURIComponent(deal.address.city.toLowerCase())}`;
  const zipCode = `/${deal.address.zip_code}`;
  const address = `_${encodeURIComponent(deal.address.street.toLowerCase())}_${
    deal.address.street_number
  }`;

  return `${IMMOWELT_BASE_URL}${encodedCity}/adresse${zipCode}${address}${propertyType}`;
}

export function getMapImage(deal, { isApproximative = false } = {}) {
  if (!CONFIG.integrations.mapbox || !deal.location) {
    return '';
  }
  const WIDTH = 1280;
  const HEIGHT = 960;
  const ZOOM = 16;

  const { staticImagesApi: mapboxApi, accessToken } = CONFIG.integrations.mapbox;
  const staticImageUrl = utils.populateTemplate(mapboxApi, {
    width: WIDTH,
    height: HEIGHT,
    zoom: ZOOM,
    lng: deal.location.lng,
    lat: deal.location.lat,
    accessToken,
  });

  return buildImgixURL(
    staticImageUrl,
    getImgixPreset(isApproximative ? 'MAP_APPROXIMATIVE_IMAGE' : 'MAP_IMAGE'),
  );
}

export const defaultGetters = {
  basePrice(deal) {
    if (
      !validateSpace(deal.livingSpace) ||
      _isNil(deal.suggestedSqmPrice) ||
      deal.specificPropertyType === BUILDING_TYPES.MULTI_FAMILY_HOUSE
    ) {
      return 0;
    }

    return Math.round(deal.suggestedSqmPrice * deal.livingSpace);
  },
  formattedAddressWithoutCity: getFormattedAddressWithoutCity,
  nextAppointment(deal) {
    const now = new Date();
    const newAppointments = deal.appointments
      .filter((appointment) => appointment.from >= now)
      .sort((a, b) => a - b); // ASC

    return _head(newAppointments);
  },
  lastAppointment(deal) {
    const now = new Date();
    const lastAppointments = deal.appointments
      .filter((appointment) => appointment.from < now)
      .sort((a, b) => a - b); // ASC

    return _head(lastAppointments);
  },
  googleMapsUrl(deal) {
    const GOOGLE_MAPS_URL = 'https://www.google.com/maps/place/';
    const encodedAddress = encodeURIComponent(deal.address.formatted_address);

    return `${GOOGLE_MAPS_URL}${encodedAddress}`;
  },
  immoweltUrl: getImmoweltUrl,
  mapImage: getMapImage,
};

export function getComputedProperties(deal) {
  return Object.keys(defaultGetters).reduce(
    (properties, getterName) => ({
      ...properties,
      [getterName]: defaultGetters[getterName](deal),
    }),
    {},
  );
}

function getSalutation(contact = {}) {
  const { first_name: firstName, last_name: lastName, salutation } = contact;

  if (!lastName) {
    return '';
  }

  const title =
    salutation === SALUTATION.MALE
      ? i18n.t('GENERAL.SALUTATION_MALE')
      : i18n.t('GENERAL.SALUTATION_FEMALE');

  return `${title} ${firstName} ${lastName}`;
}

function getSuggestedSqmPrice(apiDeal, quality = 3) {
  // Try to find the price per sqm from `qualities` array based on the quality of the property.
  // `qualities` range from poor (1) to excellent (5) and depending on what is chosen in the evaluation the quality of the deal may change.

  // If the price service doesn't return any qualities, we let the realtor suggest a price by setting `suggestedSqmPrice`.
  if (!apiDeal.qualities?.length) {
    return 0;
  }

  return apiDeal.qualities.find((_) => _.quality === quality).price;
}

// Deserialize
export function apiToStateDeal(apiDeal, { withComputed = false } = {}) {
  const getFeature = getFeatureFactory(apiDeal);

  const quality = getFeature({ path: 'quality' });

  // The feature name should be the camel case of what's used by the backend
  // this is important for the evaluation generation, check evaluation.js
  const deal = {
    // Deal general information
    uid: _get(apiDeal, 'uid', ''),
    // Property details
    specificPropertyType:
      _get(apiDeal, 'property.property_type_flow') || _get(apiDeal, 'property.property_type'),
    propertyType: _get(apiDeal, 'property.property_type'),
    livingSpace: getFeature({ path: 'property.details.living_space', defaultValue: 0 }),
    address: _get(apiDeal, 'property.address', {}),
    location: _get(apiDeal, 'property.location', {}),
    // Primary contact
    ownerSalutation: getSalutation(apiDeal.primary_contact),
    primaryContact: _get(apiDeal, 'primary_contact', {}),
    // CRM appointments
    appointments: _get(apiDeal, 'crm_appointments', []).map((appointment) => ({
      ...appointment,
      from: new Date(appointment.from),
      to: new Date(new Date(appointment.from).getTime() + 24 * 60 * 60 * 1000),
      type: 'first_appointment', // Todo: Remove this as soon as we get the right one from BE
      // to: new Date(appointment.to), // Todo: Un-comment this as soon as we get correct value from BE
    })),
    // Non-modified properties
    updatedAt: _get(apiDeal, 'updated_at'),
    buildingType: _get(apiDeal, 'last_evaluation.features', []).find(
      (feature) => feature.name === 'building_type',
    )?.value,
    apartmentType: _get(apiDeal, 'property.apartment_type'),
    plotArea: getFeature({ path: 'property.details.plot_area' }),
    plotAreaAverage: _get(apiDeal, 'avg_plot_area'),
    buildingSpace: getFeature({ path: 'property.details.building_space' }),
    commercialSpace: getFeature({ path: 'property.details.commercial_space', defaultValue: 0 }),
    ownerShare: getFeature({ path: 'property.details.owner_share' }),
    rentStatus: getFeature({ path: 'property.details.rent_status' }),
    numberOfRooms: getFeature({ path: 'property.details.number_of_rooms' }),
    suggestedSqmPrice: _get(apiDeal, 'suggested_price') || getSuggestedSqmPrice(apiDeal, quality),
    // Here we are falling back directly, but we can have it as a computed property as well
    sqmPrice: Math.round(_get(apiDeal, 'property.details.price_per_sqm', 0)),
    baseRentPerSqm: getFeature({ path: 'property.details.base_rent_per_sqm', defaultValue: 0 }),
    commercialBaseRentPerSqm: getFeature({
      path: 'property.details.commercial_base_rent_per_sqm',
      defaultValue: 0,
    }),
    potentialBaseRentPerSqm: getFeature({ path: 'potential_base_rent_per_sqm', defaultValue: 0 }),
    potentialCommercialBaseRentPerSqm: getFeature({
      path: 'potential_commercial_base_rent_per_sqm',
      defaultValue: 0,
    }),
    rentMultiplier: Math.round(getFeature({ path: 'rent_multiplier', defaultValue: 0 })),
    qualities: _get(apiDeal, 'qualities'),
    quality,
    marketingStart: _get(apiDeal, 'marketing_start')
      ? new Date(_get(apiDeal, 'marketing_start'))
      : undefined,
    constructionYear: getFeature({ path: 'property.details.construction_year' }),
    constructionYearAverage: _get(apiDeal, 'avg_construction_year'),
    lastRenovationYear: getFeature({ path: 'property.details.last_renovation_year' }),
    lastRoofRenovationYear: getFeature({ path: 'property.details.last_roof_renovation_year' }),
    windows: getFeature({ normalizer: windowNormalizer }),
    lastFacadeRenovationYear: getFeature({ path: 'property.details.last_facade_renovation_year' }),
    floor: getFeature({ path: 'property.details.floor' }),
    numberOfFloors: getFeature({ path: 'property.details.number_of_floors', defaultValue: 1 }),
    heating: getFeature({ normalizer: heatingNormalizer }),
    numberOfBasementRooms: getFeature({ path: 'property.details.number_of_basement_rooms' }),
    basements: getFeature({ normalizer: basementNormalizer }),
    hasElevator: getFeature({ path: 'property.details.has_elevator' }),
    hasParkingSpace: getFeature({ path: 'property.details.has_parking_space' }),
    hasOutdoor: getFeature({ path: 'property.details.has_outdoor' }),
    hasBalcony: getFeature({ path: 'property.details.has_balcony' }),
    balconySize: getFeature({ path: 'property.details.balcony_size' }),
    balconyValue: getFeature({ path: 'property.details.balcony_value' }),
    hasBalconyWithRoofing: getFeature({ path: 'property.details.has_balcony_with_roofing' }),
    hasBalconyWithWindProtection: getFeature({
      path: 'property.details.has_balcony_with_wind_protection',
    }),
    hasTerrace: getFeature({ path: 'property.details.has_terrace' }),
    terraceSize: getFeature({ path: 'property.details.terrace_size' }),
    terraceValue: getFeature({ path: 'property.details.terrace_value' }),
    hasTerraceWithRoofing: getFeature({ path: 'property.details.has_terrace_with_roofing' }),
    hasTerraceWithWindProtection: getFeature({
      path: 'property.details.has_terrace_with_wind_protection',
    }),
    hasLoggia: getFeature({ path: 'property.details.has_loggia' }),
    loggiaSize: getFeature({ path: 'property.details.loggia_size' }),
    loggiaValue: getFeature({ path: 'property.details.loggia_value' }),
    hasWinterGarden: getFeature({ path: 'property.details.has_winter_garden' }),
    winterGardenSize: getFeature({ path: 'property.details.winter_garden_size' }),
    winterGardenValue: getFeature({ path: 'property.details.winter_garden_value' }),
    hasGarden: getFeature({ path: 'property.details.has_garden' }),
    bathrooms: bathroomsDecoder(apiDeal, getFeature),
    targetGroups: getFeature({
      path: 'property.details.target_groups',
      defaultValue: '',
    })
      .split(',')
      .filter((value) => value),
    lastEvaluation: createEnhancedEvaluation({ evaluation: apiDeal.last_evaluation }),
    lastEvaluationReport: _get(apiDeal, 'last_evaluation_report'),
    marketingPrice: _get(apiDeal, 'last_evaluation.marketing_price'),
    additionalFeatures: _get(apiDeal, 'last_evaluation.custom_features', []),
    priceContributions: _get(apiDeal, 'last_evaluation.price_contributions', []).reduce(
      (object, { name, value }) => ({ ...object, [_camelCase(name)]: value }),
      {},
    ),
    lendingValue: _get(apiDeal, 'lending_value'),
    additionalPurchaseCosts: _get(apiDeal, 'additional_purchase_costs'),
  };

  if (withComputed) {
    const computedProperties = getComputedProperties(deal);

    Object.assign(deal, computedProperties);
  }

  return deal;
}
