import { getMapImage } from '@/services/deal';

function reducePrecision(coordinate, precision = 4) {
  const expo = 10 ** precision;
  return Math.round(coordinate * expo) / expo;
}

function similarPropertyToStateDeal(property, isApproximative = false) {
  const location = {
    lat: isApproximative ? reducePrecision(property.lat) : property.lat,
    lng: isApproximative ? reducePrecision(property.lng) : property.lng,
  };

  return {
    propertyType: property.property_type,
    address: {
      street: property.street,
      street_number: property.house_number,
      city: property.city,
      zip_code: property.zip_code,
    },
    location,
  };
}

export function getSimilarPropertiesWithScreenshots(
  similarProperties,
  { isApproximative = false } = {},
) {
  return similarProperties.map((property) => {
    const propertyInDealPresentation = similarPropertyToStateDeal(property, isApproximative);

    return {
      ...property,
      photos: [
        ...(property.photos || []),
        getMapImage(propertyInDealPresentation, { isApproximative }),
      ],
    };
  });
}
