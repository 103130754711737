import Vue from 'vue';
import VueI18n from 'vue-i18n';
import de from '@/lang/de.json';

export function init() {
  Vue.use(VueI18n);

  const messages = {
    de,
  };

  return new VueI18n({
    locale: 'de',
    messages,
  });
}

export const i18n = init();

export default {
  i18n,
};
