import { get as _get } from 'lodash-es';
import browserStorage from '@/services/browserStorage';
import {
  confirmNewPassword,
  createSession,
  registerNewUser,
  requestNewPassword,
  updateUser,
} from '@/services/apiMapper';
import { setAuthorizationToken } from '@/services/axios/authorization';
import { REQUEST_STATUS } from '@/config/CONSTANTS';
import { USER_ACTION_MESSAGES } from '@/config/TRANSLATION_CONSTANTS';
import { ACTION, TOKEN_KEY, DATA_KEY, VUEX_PERSIST_KEY, MUTATION } from './CONSTANTS';

export default {
  [ACTION.LOGIN]({ dispatch }, { rememberMe, ...data }) {
    return new Promise((resolve, reject) => {
      createSession(data)
        .then(({ user, realtor_contact: realtorContact, session }) => {
          // TODO: Use the `realtorContact` object directly when the BE releases REAL-1730
          const userData = _get(user, 'included[0].attributes', realtorContact);

          dispatch(ACTION.SAVE, {
            token: session.token,
            data: userData,
            rememberMe,
          });

          resolve(userData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ACTION.REQUEST_NEW_PASS](_, data) {
    return new Promise((resolve, reject) => {
      requestNewPassword(data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ACTION.CONFIRM_NEW_PASS]({ commit }, data) {
    return new Promise((resolve, reject) => {
      confirmNewPassword(data)
        .then(() => {
          commit(MUTATION.SET_ACTION_STATUS, {
            message: USER_ACTION_MESSAGES.UPDATE_PASSWORD_SUCCESS,
            type: REQUEST_STATUS.SUCCESS,
          });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ACTION.REGISTER]({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      registerNewUser(data)
        .then((data) => {
          commit(MUTATION.SET_ACTION_STATUS, {
            message: USER_ACTION_MESSAGES.CREATE_SUCCESS,
            type: REQUEST_STATUS.SUCCESS,
          });
          resolve(data);
        })
        .catch((error) => {
          commit(MUTATION.SET_ACTION_STATUS, {
            message:
              USER_ACTION_MESSAGES[error.response?.data.errors[0]] ||
              USER_ACTION_MESSAGES.CREATE_GENERAL_ERROR,
            type: REQUEST_STATUS.ERROR,
          });
          reject(error);
        });
    });
  },
  [ACTION.SAVE]({ commit }, { data, token, rememberMe }) {
    const appropriateStorage = browserStorage[rememberMe ? 'local' : 'session'];

    appropriateStorage.setItem(TOKEN_KEY, token);
    appropriateStorage.setItem(DATA_KEY, JSON.stringify(data));

    setAuthorizationToken(token);
    commit('setToken', token);
    commit('setData', data);
  },
  [ACTION.LOGOUT]({ commit }) {
    browserStorage.removeItem(TOKEN_KEY);
    browserStorage.removeItem(DATA_KEY);
    browserStorage.removeItem(VUEX_PERSIST_KEY);
    commit('setToken', '');
    commit('setData', {});
  },
  [ACTION.UPDATE]({ state, commit, dispatch }, { ...data }) {
    return new Promise((resolve, reject) => {
      updateUser(data)
        .then((data) => {
          // Updates the user data and show success message
          dispatch(ACTION.SAVE, {
            token: state.token,
            data: data.user,
          });
          commit(MUTATION.SET_ACTION_STATUS, {
            message: USER_ACTION_MESSAGES.UPDATE_SUCCESS,
            type: REQUEST_STATUS.SUCCESS,
          });
          resolve(data);
        })
        .catch((error) => {
          // Shows error message - incorrect password or general error
          commit(MUTATION.SET_ACTION_STATUS, {
            message:
              USER_ACTION_MESSAGES[error.response?.data.errors[0]] ||
              USER_ACTION_MESSAGES.UPDATE_GENERAL_ERROR,
            type: REQUEST_STATUS.ERROR,
          });
          reject(error);
        });
    });
  },
};
