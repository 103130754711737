<template>
  <div
    class="confirmation"
    :class="{
      'confirmation--offline': offline,
    }"
  >
    <div>
      <HdButton
        class="confirmation__print-button"
        modifier="primary"
        :icon-src="download"
        type="button"
        @click="printPage"
      >
        {{ $t('WIZARD.CONFIRMATION.PRINT_BUTTON') }}
      </HdButton>
    </div>
    <EvaluationReport />
  </div>
</template>

<script>
// Components
import { HdButton } from 'homeday-blocks';
import EvaluationReport from '@/components/EvaluationReport.vue';
// Icons
import { mailOpen } from 'homeday-assets/L';
import { download } from 'homeday-assets';
// Services
import { sendEvent, sendPageView, EVENTS, getCustomStatistics } from '@/services/gtm';

export default {
  name: 'ConfirmationSlide',
  components: { EvaluationReport, HdButton },
  data() {
    return {
      download,
      mailOpen,
    };
  },
  computed: {
    offline() {
      return this.$store.state.offline;
    },
    title() {
      return this.offline
        ? this.$t('WIZARD.CONFIRMATION.OFFLINE.TITLE')
        : this.$t('WIZARD.CONFIRMATION.ONLINE.TITLE');
    },
    description() {
      return this.offline
        ? this.$t('WIZARD.CONFIRMATION.OFFLINE.DESCRIPTION')
        : this.$t('WIZARD.CONFIRMATION.ONLINE.DESCRIPTION');
    },
  },
  mounted() {
    // Tracking
    sendPageView({
      ...EVENTS.PAGE_VIEW.CONFIRMATION_PAGE,
      ...getCustomStatistics(this.$store, this.$route.path),
    });
  },
  methods: {
    printPage() {
      // Tracking
      sendEvent(EVENTS.INTERACTION.DOWNLOAD);

      window.print();
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins.scss';

$icon-wrapper-size: 156px;

[data-route-name='confirmationSlide'] {
  .page__header {
    background-color: getShade($quaternary-color, 26);
    box-shadow: 5px 5px 2px getShade($quaternary-color, 26);
    margin-bottom: 0;
  }
}

.page__main--confirmationSlide {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: getShade($quaternary-color, 26);
    height: $icon-wrapper-size;
  }
}
</style>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

$icon-wrapper-size: 156px;
$padding-top: $sp-l;

.confirmation {
  $root: &;
  text-align: center;

  &__icon-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: $icon-wrapper-size;
    height: $icon-wrapper-size;
    background-color: white;
    border-radius: 50%;
    animation: glowSuccess 0.3s 0.5s forwards;

    #{$root}--offline & {
      animation-name: glowOffline;
    }
  }

  &__icon {
    width: 80%;
    height: 80%;
  }

  &__title {
    margin-top: $sp-xl;
  }
  &__description {
    margin-top: $sp-l;
  }

  &__print-button {
    margin-top: $sp-m;
    margin-bottom: $sp-l;
  }
}

@keyframes glowSuccess {
  to {
    box-shadow: 0 0 0 12px $success-color;
  }
}

@keyframes glowOffline {
  to {
    box-shadow: 0 0 0 12px $tertiary-color;
  }
}
</style>
