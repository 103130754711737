import { get as _get, isNil as _isNil } from 'lodash-es';
import { CURRENT_YEAR, validateYear, validateSpace } from '@/services/validators';
import baseCalculations from '../base';
import { BUILDING_TYPES, RENT_STATUSES } from './CONSTANTS';

function getPlotAreaPrice({ plotArea, plotAreaAverage = 500, sqmPrice, scaleFactor = 1 }) {
  if (!validateSpace(plotArea) || !validateSpace(plotAreaAverage) || _isNil(sqmPrice)) {
    return 0;
  }

  const plotAreaPrice = (plotArea - plotAreaAverage) * 0.03 * sqmPrice;

  return Math.round(plotAreaPrice * scaleFactor);
}

function getBuildingTypePrice({ basePrice, buildingType, scaleFactor = 1 }) {
  const DEFAULT = 1;

  const featureFactor = _get(BUILDING_TYPES, buildingType, DEFAULT);
  const priceContribution = basePrice * featureFactor - basePrice;

  return Math.round(priceContribution * scaleFactor);
}

function getRoofRenovationPrice({
  livingSpace,
  numberOfFloors,
  lastRoofRenovationYear,
  scaleFactor = 1,
}) {
  if (
    !validateYear(lastRoofRenovationYear) ||
    !validateSpace(livingSpace) ||
    _isNil(numberOfFloors) ||
    numberOfFloors === 0
  ) {
    return 0;
  }

  const ROOF_LIFE_SPAN = 40;
  const AVERAGE_ROOF_AGE = 20;
  const ROOF_PRICE_PER_SQM = 160;

  const roofAge = Math.min(CURRENT_YEAR - lastRoofRenovationYear, AVERAGE_ROOF_AGE);
  const roofArea = livingSpace / numberOfFloors;

  const numerator = ROOF_PRICE_PER_SQM * roofArea * (AVERAGE_ROOF_AGE - roofAge);
  const denominator = ROOF_LIFE_SPAN;
  const featurePrice = numerator / denominator;

  return Math.round(featurePrice * scaleFactor);
}

function getFacadeRenovationPrice({ lastFacadeRenovationYear, numberOfFloors, scaleFactor = 1 }) {
  if (!validateYear(lastFacadeRenovationYear) || _isNil(numberOfFloors)) {
    return 0;
  }

  const FACADE_LIFE_SPAN = 50;
  const AVERAGE_FACADE_AGE = 25;
  const FACADE_PRICE_PER_SQM = 90;
  const AVERAGE_FACADE_AREA = 120;
  const AVERAGE_NUMBER_OF_FLOORS = 2;

  const facadeAge = Math.min(CURRENT_YEAR - lastFacadeRenovationYear, AVERAGE_FACADE_AGE);
  const facadeArea = (AVERAGE_FACADE_AREA * numberOfFloors) / AVERAGE_NUMBER_OF_FLOORS;

  const numerator = FACADE_PRICE_PER_SQM * facadeArea * (AVERAGE_FACADE_AGE - facadeAge);
  const denominator = FACADE_LIFE_SPAN;
  const featurePrice = numerator / denominator;

  return Math.round(featurePrice * scaleFactor);
}

function getRentStatusPrice(params) {
  return baseCalculations.getRentStatusPrice({ ...params, RENT_STATUSES });
}

export default {
  ...baseCalculations,
  getPlotAreaPrice,
  getBuildingTypePrice,
  getRoofRenovationPrice,
  getFacadeRenovationPrice,
  getRentStatusPrice,
};
