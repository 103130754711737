<template>
  <wizard-base class="capital-investor" :title="$t('WIZARD.CAPITAL_INVESTOR.TITLE')">
    <div
      :class="{
        'capital-investor__blocks--single': !isRented,
      }"
      class="capital-investor__blocks"
    >
      <calculation-block-base v-if="isRented" class="capital-investor__block">
        <HdInputFormatter
          v-model="baseRentPerSqm"
          name="baseRentPerSqm"
          type="number"
          :label="$t('WIZARD.CALCULATIONS.CURRENT_COLD_RENT_PER_SQM')"
          :formatter="formatInputPrice"
        />
        <div class="capital-investor__item">
          <dt>{{ $t('WIZARD.CALCULATIONS.CURRENT_COLD_RENT_PER_MONTH') }}</dt>
          <dd>{{ $formatPrice(currentMonthRent) }}</dd>
        </div>
        <div class="capital-investor__item">
          <dt>{{ $t('WIZARD.CALCULATIONS.CURRENT_COLD_RENT_PER_YEAR') }}</dt>
          <dd>{{ $formatPrice(currentYearRent) }}</dd>
        </div>
        <div class="capital-investor__item capital-investor__item--highlighted">
          <dt>{{ $t('WIZARD.CALCULATIONS.CURRENT_EARNINGS') }}</dt>
          <dd>{{ $formatPrice(currentEarnings) }}</dd>
        </div>
      </calculation-block-base>

      <calculation-block-base
        class="capital-investor__block capital-investor__block--highlighted"
        highlighted
      >
        <HdInputFormatter
          v-model="potentialBaseRentPerSqm"
          name="potentialBaseRentPerSqm"
          type="number"
          :label="$t('WIZARD.CALCULATIONS.POTENTIAL_COLD_RENT_PER_SQM')"
          :formatter="formatInputPrice"
        />
        <div class="capital-investor__item">
          <dt>{{ $t('WIZARD.CALCULATIONS.POTENTIAL_COLD_RENT_PER_MONTH') }}</dt>
          <dd>{{ $formatPrice(potentialMonthRent) }}</dd>
        </div>
        <div class="capital-investor__item">
          <dt>{{ $t('WIZARD.CALCULATIONS.POTENTIAL_COLD_RENT_PER_YEAR') }}</dt>
          <dd>{{ $formatPrice(potentialYearRent) }}</dd>
        </div>
        <div class="capital-investor__item capital-investor__item--highlighted">
          <dt>{{ $t('WIZARD.CALCULATIONS.POTENTIAL_EARNINGS') }}</dt>
          <dd>{{ $formatPrice(potentialEarnings) }}</dd>
        </div>
      </calculation-block-base>
    </div>

    <HdInputFormatter
      v-model="rentMultiplier"
      name="rentMultiplier"
      type="number"
      :label="$t('WIZARD.CALCULATIONS.MULTIPLIER')"
      :formatter="$formatNumber"
      class="capital-investor__multiplier"
    />
  </wizard-base>
</template>

<script>
import { HdInputFormatter } from 'homeday-blocks';
import { RENT_STATUS } from '@/config/CONSTANTS';
import CalculationBlockBase from '@/components/CalculationBlockBase.vue';
import WizardBase from '@/layout/WizardBase.vue';
import { mapFeatures } from '@/services/feature';

export default {
  name: 'CapitalInvestorSlide',
  components: {
    HdInputFormatter,
    CalculationBlockBase,
    WizardBase,
  },
  computed: {
    ...mapFeatures(['baseRentPerSqm', 'potentialBaseRentPerSqm', 'livingSpace', 'rentMultiplier']),
    isRented() {
      return this.$store.state.wizard.currentDeal.rentStatus === RENT_STATUS.RENTED;
    },
    currentMonthRent() {
      return this.getMonthRent(this.baseRentPerSqm);
    },
    currentYearRent() {
      return this.getYearRent(this.baseRentPerSqm);
    },
    currentEarnings() {
      return this.getEarnings(this.baseRentPerSqm);
    },
    potentialMonthRent() {
      return this.getMonthRent(this.potentialBaseRentPerSqm);
    },
    potentialYearRent() {
      return this.getYearRent(this.potentialBaseRentPerSqm);
    },
    potentialEarnings() {
      return this.getEarnings(this.potentialBaseRentPerSqm);
    },
  },
  methods: {
    formatInputPrice(value) {
      return this.$formatPrice(value, {
        // We use minimumSignificantDigits to ignore any number of franction digits
        // set by the currency formatter. This makes the franction digits flexible
        minimumSignificantDigits: 1,
      });
    },
    getMonthRent(rentPerSqm) {
      return this.livingSpace * rentPerSqm;
    },
    getYearRent(rentPerSqm) {
      return this.getMonthRent(rentPerSqm) * 12;
    },
    getEarnings(rentPerSqm) {
      return this.getYearRent(rentPerSqm) * this.rentMultiplier;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
$block-max-width: 415px;

@mixin card {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.35);
  border: 1px solid getShade($quaternary-color, 50);
  border-radius: 4px;
}

.capital-investor {
  padding-bottom: $sp-xl;

  &__blocks {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: auto;
    margin-left: auto;

    @include for('tablet') {
      flex-direction: row;
      @include card;

      &--single {
        max-width: $block-max-width;
      }
    }
  }

  &__block {
    @include for('mobile-only') {
      @include card;

      &--highlighted {
        margin-top: $sp-l;
      }
    }
  }

  &__item {
    $item: &;

    display: flex;
    margin-top: $sp-m;
    @include font('DS-100');
    color: $quaternary-color;

    &--highlighted {
      font-weight: bold;
      color: $primary-color;

      dd {
        color: $primary-color;
      }
    }

    dt {
      flex: 1;
    }

    dd {
      font-weight: bold;
    }
  }

  &__multiplier {
    max-width: 280px;
    margin-top: $sp-l;
    margin-right: auto;
    margin-left: auto;
  }
}
</style>
