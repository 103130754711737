import axios from 'axios';
import router from '@/router';
import * as Sentry from '@sentry/vue';

let interceptor;

function responseSuccessInterceptor(response) {
  return response;
}

function responseErrorInterceptor(error) {
  const {
    config: { url, data: requestData, errorMessage },
    request: { responseText: responseData, status },
  } = error;
  if (status === 401 && router.currentRoute.matched.some(({ meta }) => meta.authOnly)) {
    // We automatically log the user out if we receive 401 from the API
    // and the user is in an authOnly route
    router.push({ name: 'logout' });
  } else {
    // otherwise, we send the error to Sentry for logging purposes
    Sentry.withScope((scope) => {
      scope.setTag('request.status', status);
      scope.setTag('request.url', url);
      scope.setExtra('requestData', requestData);
      scope.setExtra('responseData', responseData);

      Sentry.captureMessage(errorMessage || error.message);
    });
  }

  throw error;
}

export function enable() {
  interceptor = axios.interceptors.response.use(
    responseSuccessInterceptor,
    responseErrorInterceptor,
  );
}

export function disable() {
  axios.interceptors.request.eject(interceptor);
}
