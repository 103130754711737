import { isNaN as _isNaN, isNil as _isNil } from 'lodash-es';
import {
  listDeals,
  getDeal,
  createEvaluation,
  createDeal,
  updateDeal,
  removeDeal,
  updateEvaluationReport,
} from '@/services/apiMapper';
import { cacheImages } from '@/services/cache';
import { apiToStateDeal } from '@/services/deal';
import {
  getFailedRequests,
  FAILED_REQUEST_TYPE,
  saveFailedRequest,
  removeFailedRequest,
  compareFailedRequests,
} from '@/services/failedRequest';
import { FILTERS } from '@/config/DEAL';
import { ACTION as STORE_ACTION } from '@/store/CONSTANTS';
import { BROKER_ORDER_ERROR_REASON, EVALUATION_REPORT_ERROR_REASON } from '@/config/CONSTANTS';
import { ACTION, MUTATION } from './CONSTANTS';

export default {
  async [ACTION.ADD_DEAL](_, { ...data }) {
    return new Promise((resolve, reject) => {
      createDeal(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async [ACTION.REMOVE_DEAL]({ dispatch }, { dealUid }) {
    return new Promise((resolve, reject) => {
      removeDeal({ dealUid })
        .then((response) => {
          dispatch(ACTION.REMOVE_FAILED_REQUEST, {
            dealUid,
            type: FAILED_REQUEST_TYPE.DELETE,
          });
          resolve(response);
        })
        .catch((error) => {
          dispatch(ACTION.ADD_FAILED_REQUEST, {
            dealUid,
            type: FAILED_REQUEST_TYPE.DELETE,
            actionType: STORE_ACTION.WIZARD.REMOVE_DEAL,
            actionParams: { dealUid },
            reason: 'unknown',
          });
          reject(error);
        });
    });
  },
  async [ACTION.SIGN_BROKER_ORDER]({ dispatch, commit }, { dealUid, payload }) {
    try {
      // Update seller details
      await updateDeal({ dealUid, payload });

      // Update primary contact details
      const { primaryContact } = await dispatch(ACTION.FETCH_DEAL, dealUid);
      commit('currentDeal/setFeatureValue', {
        name: 'primaryContact',
        value: primaryContact,
      });
      commit('currentDeal/setFeatureValue', {
        name: 'updateEvaluationReport',
        value: true,
      });

      // Remove failed request
      dispatch(ACTION.REMOVE_FAILED_REQUEST, {
        dealUid,
        type: FAILED_REQUEST_TYPE.BROKER_ORDER,
      });
    } catch (error) {
      let reason = BROKER_ORDER_ERROR_REASON.UNKNOWN;
      if (error.message === 'Network Error') {
        reason = BROKER_ORDER_ERROR_REASON.OFFLINE;
      } else if (error?.response.status === 422) {
        const responseData = error.response.data;
        // eslint-disable-next-line camelcase
        if (responseData?.phone_number) reason = BROKER_ORDER_ERROR_REASON.PHONE;
        if (responseData?.error && responseData.error.includes('hard-bounce'))
          reason = BROKER_ORDER_ERROR_REASON.HARD_BOUNCE;
        if (responseData?.error && responseData.error.includes('soft-bounce'))
          reason = BROKER_ORDER_ERROR_REASON.SOFT_BOUNCE;
      } else if (error?.response.status === 429) {
        reason = BROKER_ORDER_ERROR_REASON.TOO_MANY_REQUESTS;
      }

      dispatch(ACTION.ADD_FAILED_REQUEST, {
        dealUid,
        type: FAILED_REQUEST_TYPE.BROKER_ORDER,
        actionType: STORE_ACTION.WIZARD.SIGN_BROKER_ORDER,
        actionParams: { dealUid, payload },
        reason,
      });
      return Promise.reject(error);
    }
    return Promise.resolve();
  },
  async [ACTION.FETCH_DEALS]({ commit }, { filter = FILTERS.FUTURE } = {}) {
    const apiDeals = await listDeals(filter);
    const deals = apiDeals.map((apiDeal) => {
      const stateDeal = apiToStateDeal(apiDeal, { withComputed: true });
      const failedRequests = getFailedRequests({ dealUid: stateDeal.uid });

      return {
        ...stateDeal,
        filter,
        failedRequests,
      };
    });

    commit(MUTATION.ADD_DEALS, deals);

    if (filter === FILTERS.FUTURE) {
      cacheImages(deals.map((deal) => deal.mapImage));

      // The SimilarProperties slide is currently hidden. Thus, we don't need  this API call.
      // deals.forEach(async (deal) => {
      //   const similarProperties = await getSimilarProperties(deal);
      //
      //   // We cache the photos of the similar properties
      //   getSimilarPropertiesWithScreenshots(similarProperties).forEach((property) => {
      //     cacheImages(property.photos);
      //   });
      // });
    }

    return deals;
  },
  async [ACTION.FETCH_SINGLE_DEAL]({ dispatch, commit }, dealUid) {
    const stateDeal = await dispatch(ACTION.FETCH_DEAL, dealUid);
    const failedRequests = getFailedRequests({ dealUid });
    const deal = {
      ...stateDeal,
      failedRequests,
    };

    commit(MUTATION.SET_DEALS, [deal]);

    return deal;
  },
  async [ACTION.FETCH_DEAL](_, dealUid) {
    const deal = await getDeal(dealUid);

    return apiToStateDeal(deal, { withComputed: true });
  },
  async [ACTION.UPDATE_EVALUATION_REPORT]({ dispatch }, payload) {
    try {
      await updateEvaluationReport(payload);

      dispatch(ACTION.REMOVE_FAILED_REQUEST, {
        dealUid: payload.dealUid,
        type: FAILED_REQUEST_TYPE.UPDATE_REPORT,
      });
    } catch (error) {
      // backend returns the errors in various shapes so we have to find the exact reason why
      // it failed so we can show appropriate messages on the overview and instruction slide
      let reason = EVALUATION_REPORT_ERROR_REASON.UNKNOWN;
      if (error.message === 'Network Error') {
        reason = EVALUATION_REPORT_ERROR_REASON.OFFLINE;
      } else if (error.message === '422 Unprocessable Content') {
        reason = EVALUATION_REPORT_ERROR_REASON.TOO_MANY_REQUESTS;
      }

      // Save for future retry
      dispatch(ACTION.ADD_FAILED_REQUEST, {
        dealUid: payload.dealUid,
        type: FAILED_REQUEST_TYPE.UPDATE_REPORT,
        actionType: STORE_ACTION.WIZARD.UPDATE_EVALUATION_REPORT,
        actionParams: payload,
        reason,
      });
      return Promise.reject(error);
    }
    return Promise.resolve();
  },
  async [ACTION.CREATE_EVALUATION]({ commit, dispatch }, { dealUid, evaluation }) {
    try {
      // TODO: Remove this temporary solution at any time after 15/09/2020
      // Temporary solution START
      // eslint-disable-next-line no-param-reassign
      evaluation.price_contributions = evaluation.price_contributions.filter(
        ({ value }) => !_isNil(value) && !_isNaN(value),
      );
      // Temporary solution END

      await createEvaluation(evaluation);
      const updatedStateDeal = await dispatch(ACTION.FETCH_DEAL, dealUid);

      // We call the update because the new deal won't have future/past property
      commit(MUTATION.UPDATE_DEAL_PROPERTIES, {
        uid: dealUid,
        properties: {
          ...updatedStateDeal,
          unsavedEvaluation: null,
        },
      });

      dispatch(ACTION.REMOVE_FAILED_REQUEST, {
        dealUid,
        type: FAILED_REQUEST_TYPE.EVALUATION,
      });
    } catch {
      // We save the failedRequest to allow the user to try sending it later
      dispatch(ACTION.ADD_FAILED_REQUEST, {
        dealUid,
        type: FAILED_REQUEST_TYPE.EVALUATION,
        actionType: STORE_ACTION.WIZARD.CREATE_EVALUATION,
        actionParams: { dealUid, evaluation },
      });
    }
  },
  [ACTION.ADD_FAILED_REQUEST]({ commit, state }, failedRequest) {
    const { failedRequests = [] } = state.deals.find(({ uid }) => uid === failedRequest.dealUid);

    // We filter the failedRequest of the same type, as we want only one per type
    // we also sort the array to show the notifications in the order we want
    const updatedFailedRequests = [
      ...failedRequests.filter(({ type }) => type !== failedRequest.type),
      failedRequest,
    ].sort(compareFailedRequests);

    commit('updateDealProperties', {
      uid: failedRequest.dealUid,
      properties: {
        failedRequests: updatedFailedRequests,
      },
    });

    saveFailedRequest(failedRequest);
  },
  [ACTION.REMOVE_FAILED_REQUEST]({ commit, state }, { dealUid, type }) {
    const { failedRequests = [] } = state.deals.find(({ uid }) => uid === dealUid);

    commit('updateDealProperties', {
      uid: dealUid,
      properties: {
        failedRequests: failedRequests.filter(({ type: requestType }) => requestType !== type),
      },
    });

    removeFailedRequest({ dealUid, type });
  },
};
