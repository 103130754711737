<template>
  <wizard-base class="earnings-value" :title="$t('WIZARD.EARNINGS_VALUE.TITLE')">
    <div
      :class="{
        'earnings-value__blocks--single': !isRented,
      }"
      class="earnings-value__blocks"
    >
      <calculation-block-base v-if="isRented" class="earnings-value__block">
        <HdInputFormatter
          v-model="baseRentPerSqm"
          name="baseRentPerSqm"
          type="number"
          class="earnings-value__input"
          :label="$t('WIZARD.CALCULATIONS.CURRENT_COLD_RENT_PER_SQM')"
          :formatter="formatInputPrice"
        />
        <HdInputFormatter
          v-model="commercialBaseRentPerSqm"
          name="commercialBaseRentPerSqm"
          type="number"
          class="earnings-value__input"
          :label="$t('WIZARD.CALCULATIONS.CURRENT_COMMERCIAL_COLD_RENT_PER_SQM')"
          :formatter="formatInputPrice"
        />
        <div class="earnings-value__item">
          <dt>{{ $t('WIZARD.CALCULATIONS.CURRENT_COLD_RENT_PER_MONTH') }}</dt>
          <dd>{{ $formatPrice(currentMonthRent) }}</dd>
        </div>
        <div class="earnings-value__item">
          <dt>{{ $t('WIZARD.CALCULATIONS.CURRENT_COLD_RENT_PER_YEAR') }}</dt>
          <dd>{{ $formatPrice(currentYearRent) }}</dd>
        </div>
        <div class="earnings-value__item earnings-value__item--highlighted">
          <dt>{{ $t('WIZARD.CALCULATIONS.CURRENT_EARNINGS') }}</dt>
          <dd>{{ $formatPrice(currentEarnings) }}</dd>
        </div>
      </calculation-block-base>

      <calculation-block-base
        class="earnings-value__block earnings-value__block--highlighted"
        highlighted
      >
        <HdInputFormatter
          v-model="potentialBaseRentPerSqm"
          name="potentialBaseRentPerSqm"
          type="number"
          class="earnings-value__input"
          :label="$t('WIZARD.CALCULATIONS.POTENTIAL_COLD_RENT_PER_SQM')"
          :formatter="formatInputPrice"
        />
        <HdInputFormatter
          v-model="potentialCommercialBaseRentPerSqm"
          name="potentialCommercialBaseRentPerSqm"
          type="number"
          class="earnings-value__input"
          :label="$t('WIZARD.CALCULATIONS.POTENTIAL_COMMERCIAL_COLD_RENT_PER_SQM')"
          :formatter="formatInputPrice"
        />
        <div class="earnings-value__item" data-testid="value_item__cold_rent_month">
          <dt>{{ $t('WIZARD.CALCULATIONS.POTENTIAL_COLD_RENT_PER_MONTH') }}</dt>
          <dd>{{ $formatPrice(potentialMonthRent) }}</dd>
        </div>
        <div class="earnings-value__item" data-testid="value_item__cold_rent_year">
          <dt>{{ $t('WIZARD.CALCULATIONS.POTENTIAL_COLD_RENT_PER_YEAR') }}</dt>
          <dd>{{ $formatPrice(potentialYearRent) }}</dd>
        </div>
        <div
          class="earnings-value__item earnings-value__item--highlighted"
          data-testid="value_item__current_earnings"
        >
          <dt>{{ $t('WIZARD.CALCULATIONS.POTENTIAL_EARNINGS') }}</dt>
          <dd>{{ $formatPrice(potentialEarnings) }}</dd>
        </div>
      </calculation-block-base>
    </div>

    <div class="earnings-value__footer">
      <hd-link :href="preisatlasUrl" target="_blank" class="earnings-value__link">
        {{ $t('WIZARD.EARNINGS_VALUE.PREISATLAS_RENT') }}
      </hd-link>
      <HdInputFormatter
        v-model="rentMultiplier"
        name="rentMultiplier"
        type="number"
        :label="$t('WIZARD.CALCULATIONS.MULTIPLIER')"
        :formatter="$formatNumber"
        class="earnings-value__multiplier"
      />
    </div>
  </wizard-base>
</template>

<script>
// Components
import { HdLink, HdInputFormatter } from 'homeday-blocks';
import CalculationBlockBase from '@/components/CalculationBlockBase.vue';
import WizardBase from '@/layout/WizardBase.vue';
// Services
import { mapFeatures } from '@/services/feature';
import { mapPriceContributions } from '@/services/priceContribution';

export default {
  name: 'EarningsValueSlide',
  components: {
    HdLink,
    HdInputFormatter,
    CalculationBlockBase,
    WizardBase,
  },
  computed: {
    ...mapFeatures([
      'baseRentPerSqm',
      'commercialBaseRentPerSqm',
      'potentialBaseRentPerSqm',
      'potentialCommercialBaseRentPerSqm',
      'livingSpace',
      'commercialSpace',
      'rentMultiplier',
    ]),
    ...mapPriceContributions(['earningsValue']), // Just to be considered in setAppliedFeatures
    isRented() {
      return this.$store.state.wizard.currentDeal.rentStatus === 'rented';
    },
    currentMonthRent() {
      return this.getMonthRent(this.baseRentPerSqm, this.commercialBaseRentPerSqm);
    },
    currentYearRent() {
      return this.getYearRent(this.baseRentPerSqm, this.commercialBaseRentPerSqm);
    },
    currentEarnings() {
      return this.getEarnings(this.baseRentPerSqm, this.commercialBaseRentPerSqm);
    },
    potentialMonthRent() {
      return this.getMonthRent(
        this.potentialBaseRentPerSqm,
        this.potentialCommercialBaseRentPerSqm,
      );
    },
    potentialYearRent() {
      return this.getYearRent(this.potentialBaseRentPerSqm, this.potentialCommercialBaseRentPerSqm);
    },
    potentialEarnings() {
      return this.getEarnings(this.potentialBaseRentPerSqm, this.potentialCommercialBaseRentPerSqm);
    },
    preisatlasUrl() {
      return `${this.$store.state.wizard.currentDeal.preisatlasUrl}&marketing_type=rent`;
    },
  },
  methods: {
    formatInputPrice(value) {
      return this.$formatPrice(value, {
        // We use minimumSignificantDigits to ignore any number of franction digits
        // set by the currency formatter. This makes the franction digits flexible
        minimumSignificantDigits: 1,
      });
    },
    getMonthRent(rentPerSqm, commercialRentPerSqm) {
      return this.livingSpace * rentPerSqm + this.commercialSpace * commercialRentPerSqm;
    },
    getYearRent(rentPerSqm, commercialRentPerSqm) {
      return this.getMonthRent(rentPerSqm, commercialRentPerSqm) * 12;
    },
    getEarnings(rentPerSqm, commercialRentPerSqm) {
      return this.getYearRent(rentPerSqm, commercialRentPerSqm) * this.rentMultiplier;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
$block-max-width: 415px;

@mixin card {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.35);
  border: 1px solid getShade($quaternary-color, 50);
  border-radius: 4px;
}

::v-deep .wizard-base {
  &__container {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
  }
}

.earnings-value {
  $earningsValue: &;
  padding-bottom: $sp-xl;

  &__blocks {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: auto;
    margin-left: auto;

    @include for('tablet') {
      flex-direction: row;
      @include card;

      &--single {
        max-width: $block-max-width;
      }
    }
  }

  &__block {
    @include for('mobile-only') {
      @include card;

      &--highlighted {
        margin-top: $sp-l;
      }
    }
  }

  ::v-deep {
    #{$earningsValue}__input {
      margin-bottom: #{$sp-m + $sp-s};
    }
  }

  &__item {
    $item: &;

    @include font('DS-100');
    display: flex;
    color: $quaternary-color;
    margin-top: $sp-m;

    &--highlighted {
      font-weight: bold;
      color: $primary-color;

      dd {
        color: $tertiary-color;
      }
    }

    dt {
      flex: 1;
    }

    dd {
      font-weight: bold;
    }
  }

  &__footer {
    position: relative;
    margin-top: #{$sp-m + $sp-xs};
  }

  &__link {
    @include for('tablet') {
      position: absolute;
      right: 0;
      transform: translateY(50%);
    }
  }

  &__multiplier {
    margin-top: $sp-l;
    margin-right: auto;
    margin-left: auto;

    @include for('tablet') {
      max-width: 280px;
    }
  }
}
</style>
