<template>
  <wizard-base
    class="plot-area"
    :title="$t('GENERAL.PLOT_AREA')"
    :subtitle="$t('GENERAL.FIELD_REQUIRED')"
    :icon="slideIcon"
    :content-column-classes="['is-9', 'is-6-desktop']"
  >
    <div class="plot-area__fields">
      <hd-input-formatter
        v-model="plotArea"
        name="plot-area"
        type="number"
        :label="$t('GENERAL.PLOT_AREA')"
        :formatter="$formatSpace"
        :custom-rules="areaCustomRules"
      />
      <PriceContributionInput v-model="plotPrice" name="plot" :custom-rules="priceCustomRules" />
    </div>
  </wizard-base>
</template>

<script>
// @ts-check
import { toFinite as _toFinite, isNumber as _isNumber, inRange as _inRange } from 'lodash-es';

import { HdInputFormatter } from 'homeday-blocks';
import PriceContributionInput from '@/components/PriceContributionInput.vue';
import WizardBase from '@/layout/WizardBase.vue';
import { mapFeatures } from '@/services/feature';
import { mapPriceContributions } from '@/services/priceContribution';
import { landLot as slideIcon } from 'homeday-assets/L';
import { MAXIMUM_SPACE, MINIMUM_SPACE } from '@/config/CONSTANTS';

/** @type {{ plotArea: () => number, plotPrice: () => number }} */
const mappedComputed = {
  ...mapFeatures(['plotArea']),
  ...mapPriceContributions(['plot']),
};

export default {
  name: 'PlotAreaSlide',
  components: {
    HdInputFormatter,
    PriceContributionInput,
    WizardBase,
  },
  data() {
    return {
      slideIcon,
    };
  },
  computed: {
    ...mappedComputed,
    /** @returns {{ livingSpace: number | undefined | null }} */
    deal() {
      return this.$store.state.wizard.currentDeal;
    },
    /** @returns {number} */
    livingSpace() {
      return this.deal.livingSpace || MINIMUM_SPACE;
    },
    /** @returns {import('@/@types/global').ValidationRules} */
    areaCustomRules() {
      return [
        {
          validate: (value) => {
            if (!_isNumber(Number(value))) return false;
            return _inRange(_toFinite(value), MINIMUM_SPACE, MAXIMUM_SPACE);
          },
          errorMessage: this.$t('FIELD_VALIDATIONS.INCORRECT_VALUE'),
        },
      ];
    },
    /** @returns {import('@/@types/global').ValidationRules} */
    priceCustomRules() {
      return [
        {
          validate: (value) => Number(value) >= 0,
          errorMessage: this.$t('FIELD_VALIDATIONS.INCORRECT_VALUE'),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.plot-area {
  &__fields {
    display: grid;

    @include for('tablet') {
      grid-template-columns: 1fr 1.5fr;
      grid-column-gap: $sp-m;
    }
  }
}
</style>
