export const MUTATION = {
  SET: 'set',
  RESET: 'reset',
  TOGGLE_NAVIGATION: 'toggleNavigation',
  UPDATE_UNSAVED_DEALS: 'updateUnsavedDeals',
  SET_FEATURE_VALUE: 'setFeatureValue',
  SET_PRICE_CONTRIBUTION: 'setPriceContribution',
  SET_ACTUAL_COLD_RENT: 'setActualColdRent',
  SET_SUGGESTED_SQM_PRICE: 'setSuggestedSqmPrice',
  SET_MARKETING_PRICE: 'setMarketingPrice',
  SET_ADDITIONAL_FEATURES: 'setAddtionalFeatures',
  SET_BATHROOMS: 'setBathrooms',
  SET_APPLIED_PRICE_CONTRIBUTIONS: 'setAppliedPriceContributions',
  SET_WINDOWS: 'setWindows',
  SET_OPTIMAL_PRICE: 'setOptimalPrice',
  SET_SHOULD_SHOW_DETAILED_RENOVATIONS: 'setShouldShowDetailedRenovations',
};

export const ACTION = {
  UPDATE_FEATURE: 'updateFeature',
};

export const GETTER = {
  ADDITIONAL_FEATURES_PRICE: 'additionalFeaturesPrice',
  CALCULATED_SQM_PRICE: 'calculatedSqmPrice',
  CALCULATED_SQM_TOTAL_PRICE: 'calculatedSqmTotalPrice',
  MARKETING_PRICE: 'marketingPrice',
  TOTAL_APPLIED_FEATURES_PRICE: 'totalAppliedFeaturesPrice',
  TOTAL_FEATURES_PRICE: 'totalFeaturesPrice',
  TOTAL_PRICE: 'totalPrice',
  LENDING_VALUE: 'lendingValue',
};
