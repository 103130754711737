<template>
  <div ref="wrapper" class="wizard-price__wrapper">
    <div
      :class="{
        isEditMode: editMode,
        isEditable: editable,
      }"
      class="wizard-price"
    >
      <div
        ref="priceTag"
        :class="{
          isEditMode: editMode,
        }"
        class="price-tag"
        @click="toggleEnableMode"
      >
        <p class="price-tag__price-wrapper">
          <span v-if="preValueToken" ref="preValueToken" class="price-tag__average-symbol">
            {{ preValueToken }}
          </span>
          <span
            class="price-tag__price"
            data-testid="wizard-price-base"
            :data-static-value="value"
            >{{ formattedPrice }}</span
          >
          <span class="price-tag__currency">€</span>
          <span v-if="postValueToken" ref="postValueToken" class="price-tag__sqm">
            {{ postValueToken }}
          </span>
        </p>
        <p class="price-tag__label">
          {{ label }}
        </p>
        <form @submit.prevent="disableEditMode">
          <input
            ref="input"
            :value="value"
            type="text"
            inputmode="numeric"
            tabindex="-1"
            class="price-tag__input"
            @input="onInputInput"
            @keyup="setCursorToEnd"
            @blur="onInputBlur"
          />
        </form>
        <HdIcon class="wizard-price__icon" :src="edit" />
      </div>
    </div>
  </div>
</template>

<script>
// Components
import { HdIcon } from 'homeday-blocks';
// Services
import Animate from 'animate-x';
// Icons
import { edit } from 'homeday-assets';

export const MAX_PRICE = 99_999_999;
const ANIMATION_DURATION = 400;

export default {
  name: 'WizardPriceBase',
  components: {
    HdIcon,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    preValueToken: {
      type: String,
      default: '',
    },
    postValueToken: {
      type: String,
      default: '',
    },
    maxPrice: {
      type: Number,
      default: MAX_PRICE,
    },
    zeroFallback: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      editMode: false,
      animatedValue: this.value,
      edit,
    };
  },
  computed: {
    formattedPrice() {
      if (this.animatedValue === 0 && this.zeroFallback) {
        return this.zeroFallback;
      }

      return this.$formatNumber(this.animatedValue, { fractionDigits: 0 });
    },
  },
  watch: {
    value(newValue, oldValue) {
      this.internalValue = newValue;

      if (!this.editMode) {
        new Animate({
          from: oldValue,
          to: newValue,
          duration: ANIMATION_DURATION,
          onStep: (tweenedValue) => {
            this.animatedValue = Math.round(tweenedValue);
          },
        }).start();
      } else {
        this.animatedValue = newValue;
      }
    },
  },
  methods: {
    onInputInput({ target: { value } }) {
      let number = Number(value);

      if (Number.isNaN(number)) {
        return;
      }

      if (number > this.maxPrice) {
        number = this.maxPrice;
      }

      this.$emit('input', Math.trunc(number));
    },
    onInputBlur() {
      // We wait for the other events to be handled
      // to avoid unexpected behaviour when clicking the edit toggle
      setTimeout(() => {
        if (this.editMode) {
          this.disableEditMode();
        }
      }, 300);
    },
    setCursorToEnd() {
      const numberOfCharacters = this.value.toString().length;
      this.$refs.input.setSelectionRange(numberOfCharacters, numberOfCharacters);
    },
    toggleEnableMode() {
      if (this.editMode) {
        this.disableEditMode();
      } else {
        this.enableEditMode();
      }
    },
    enableEditMode() {
      if (this.editable) {
        this.editMode = true;
        this.$refs.input.focus();
      }
    },
    disableEditMode() {
      this.editMode = false;
      if (this.$refs.input) this.$refs.input.blur();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
$icon-size: 15px;
$price-tag-overflow: $sp-l + $sp-m;
$price-tag-height-mobile: 104px;

.wizard-price {
  $wizard-price: &;
  display: flex;
  position: absolute;
  left: 50%;
  bottom: -$price-tag-overflow;
  transform: translateX(calc(-50% + #{$sp-l}));
  animation: fadeIn 0.5s;

  @include for('tablet') {
    top: 0;
    bottom: auto;
  }

  @include for('mobile-only') {
    bottom: -70px;
    transform: translateX(-50%);
  }

  &.isEditable {
    cursor: pointer;
  }

  &__wrapper {
    position: relative;
    height: $price-tag-height-mobile;
    margin-top: $sp-l;

    @include for('tablet') {
      margin-top: 0;
      height: auto;
    }
  }

  &__icon {
    position: absolute;
    top: $sp-s + $sp-xxs;
    right: $sp-s + $sp-xxs;
    width: $icon-size;
    height: $icon-size;
    display: none;

    #{$wizard-price}.isEditable & {
      display: block;
    }

    ::v-deep path {
      fill: $white;
    }
  }
}

.price-tag {
  $price-tag: &;
  width: 270px;
  max-width: calc(100vw - #{$sp-xl});
  color: white;
  text-align: center;
  background-color: $primary-color;
  padding-top: $sp-m + $sp-s;
  padding-bottom: $sp-m + $sp-s;
  border-radius: 4px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);

  @include for('desktop') {
    width: 300px;
  }

  &__price-wrapper {
    @include font('DS-200');
    font-weight: 900;
    white-space: nowrap;

    @include for('desktop') {
      @include font('DS-400');
    }
  }

  &__average-symbol {
    margin-right: $sp-s;
  }

  &__price {
    position: relative;
    margin-right: $sp-s;

    #{$price-tag}.isEditMode & {
      // Text cursor (caret)
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        right: -$sp-xs;
        bottom: 2px;
        width: 2px;
        background-color: white;
        animation: blink 1s infinite;
      }
    }
  }

  &__label {
    @include font('DS-100');
  }

  &__input {
    // We make sure that it's not visible to the user
    position: absolute;
    width: 0;
    height: 0;
    border: 0;
    opacity: 0;
    z-index: -10;
  }
}
</style>
