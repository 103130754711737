<template>
  <div class="card">
    <div class="card__container"><slot /></div>
    <HdButton
      v-if="showClose"
      :icon-src="close"
      :aria-label="$t('GENERAL.REMOVE')"
      type="button"
      modifier="tertiary"
      class="card__close-button"
      @click="$emit('close')"
    />
  </div>
</template>

<script>
import { HdButton } from 'homeday-blocks';
import { close } from 'homeday-assets';

export default {
  name: 'FeatureCard',
  components: { HdButton },
  inheritAttrs: false,
  props: {
    showClose: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      close,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
$gap: $sp-m + $sp-s;

.card {
  display: grid;
  grid-template-columns: 6fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: $sp-m;
  grid-row-gap: 0;
  width: 120%;

  &__container {
    padding: $gap;
    margin-bottom: $gap;
    background-color: $white;
    border-radius: $sp-xs;
    @include elevation(5);
  }
  &__close-button {
    width: 44px;
    height: 44px;
    margin-bottom: 0;
    margin-top: $sp-xs + $gap;
    grid-area: 1 / 2 / 2 / 3;
  }
}
</style>
