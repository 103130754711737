<template>
  <wizard-base class="similar-properties" :title="title">
    <transition name="fade">
      <div v-if="loading" class="similar-properties__loading" />
      <slide-error
        v-if="showErrorFallback"
        :icon="errorIcon"
        :title="$t('WIZARD.SIMILAR_PROPERTIES.ERROR.TITLE')"
        :text="$t('WIZARD.SIMILAR_PROPERTIES.ERROR.TEXT')"
        :button-text="$t('WIZARD.SIMILAR_PROPERTIES.ERROR.BUTTON')"
      />
    </transition>

    <div v-if="success" class="similar-properties__inner">
      <button
        v-if="similarProperties.length > similarPropertiesToShow.length"
        class="similar-properties__show-more"
        type="button"
        @click="showMoreProperties"
      >
        <div class="similar-properties__dot" />
        <div class="similar-properties__dot" />
        <div class="similar-properties__dot" />
      </button>
      <HdCarousel
        ref="carousel"
        :key="similarPropertiesToShow.length"
        :options="carouselOptions"
        :flickity-destroy-delay="slideTransitionDuration"
        class="similar-properties__carousel"
      >
        <div
          v-for="(property, index) in similarPropertiesToShow"
          :key="index"
          class="similar-properties__item-wrapper"
        >
          <SimilarPropertyItem
            :property="property"
            class="similar-properties__item"
            @click.native="onPropertyClick({ propertyIndex: index })"
            @thumbnailClick="(photoIndex) => onPropertyClick({ propertyIndex: index, photoIndex })"
          />
        </div>
        <div class="similar-properties__show-more-placeholder-item" />
      </HdCarousel>
      <p class="similar-properties__footer_text">
        <span>* </span>{{ $t('WIZARD.SIMILAR_PROPERTIES.DISCLAIMER') }}
      </p>
    </div>

    <transition name="fade">
      <div v-if="overlayVisible" class="similar-properties__overlay">
        <HdZoomerGallery
          :items="galleryItems"
          :start-index="photoStartIndex"
          :class="{
            'similar-properties__gallery--blurred': offline,
          }"
          class="similar-properties__gallery"
        />
        <div v-if="offline" class="similar-properties__offline-notification">
          <img :src="warning" />
          <p>{{ $t('WIZARD.SIMILAR_PROPERTIES.OFFLINE') }}</p>
        </div>

        <button class="similar-properties__close" type="button" @click="hideOverlay">
          <HdIcon :src="close" width="75%" height="75%" />
        </button>
      </div>
    </transition>
  </wizard-base>
</template>

<script>
// Components
import { HdZoomerGallery, HdIcon } from 'homeday-blocks';
import SlideError from '@/components/SlideError.vue';
import WizardBase from '@/layout/WizardBase.vue';
import HdCarousel from '@/components/HdCarousel.vue';
import SimilarPropertyItem from '@/components/SimilarPropertyItem.vue';
// Services
import { getSimilarProperties } from '@/services/apiMapper';
import { getSimilarPropertiesWithScreenshots } from '@/services/similarProperty';
// Icons
import { germanyMapMagnifyingGlass as errorIcon } from 'homeday-assets/L';
import { warning, close } from 'homeday-assets';

const PROPERTIES_PER_PAGE = 3;
const STATE = Object.freeze({
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
});

export default {
  name: 'SimilarPropertiesSlide',
  components: {
    WizardBase,
    HdCarousel,
    SimilarPropertyItem,
    HdZoomerGallery,
    SlideError,
    HdIcon,
  },
  inject: ['slideTransitionDuration'],
  data() {
    return {
      overlayVisible: false,
      currentPropertyIndex: 0,
      photoStartIndex: 0,
      isStaticClick: false,
      similarProperties: [],
      state: STATE.LOADING,
      page: 1,
      carouselOptions: {
        on: {
          dragEnd: () => {
            this.isStaticClick = false;
          },
          staticClick: () => {
            this.isStaticClick = true;
          },
        },
      },
      errorIcon,
      warning,
      close,
    };
  },
  computed: {
    currentDeal() {
      return this.$store.state.wizard.currentDeal;
    },
    galleryItems() {
      return this.similarProperties[this.currentPropertyIndex].photos.map((url) => ({
        image: url,
      }));
    },
    offline() {
      return this.$store.state.offline;
    },
    similarPropertiesToShow() {
      return this.similarProperties.slice(0, this.page * PROPERTIES_PER_PAGE);
    },
    loading() {
      return this.state === STATE.LOADING;
    },
    error() {
      return this.state === STATE.ERROR;
    },
    isEmpty() {
      return this.success && this.similarProperties.length === 0;
    },
    success() {
      return this.state === STATE.SUCCESS;
    },
    showErrorFallback() {
      return this.error || this.isEmpty;
    },
    title() {
      if (this.success) {
        return this.$t('WIZARD.SIMILAR_PROPERTIES.TITLE');
      }

      return '';
    },
  },
  async created() {
    try {
      const similarProperties = await getSimilarProperties(this.currentDeal);

      // We add a preisatlas screenshot to each property
      this.similarProperties = getSimilarPropertiesWithScreenshots(similarProperties, {
        isApproximative: true,
      });

      this.state = STATE.SUCCESS;
    } catch (error) {
      this.state = STATE.ERROR;
    }
  },
  methods: {
    showMoreProperties() {
      const oldLastIndex = this.similarPropertiesToShow.length - 1;
      this.page += 1;
      this.$nextTick(() => {
        const newLastIndex = this.similarPropertiesToShow.length - 1;

        // For a prettier animation we start from the next item
        this.$refs.carousel.flickity.select(oldLastIndex + 1, true, true);
        this.$refs.carousel.flickity.select(newLastIndex);
      });
    },
    onPropertyClick({ propertyIndex = 0, photoIndex = 0 } = {}) {
      if (!this.isStaticClick) {
        return;
      }

      this.currentPropertyIndex = propertyIndex;
      this.photoStartIndex = photoIndex;

      this.showOverlay();
    },
    showOverlay() {
      this.overlayVisible = true;
    },
    hideOverlay() {
      this.overlayVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.similar-properties {
  overflow-x: hidden;

  ::v-deep .wizard-base__container {
    margin-right: 0;
    margin-left: 0;
  }

  &__loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
    z-index: 1;

    &::after {
      $size: 30px;

      content: '';
      display: block;
      position: absolute;
      top: calc(50% - calc(#{$size} / 2));
      right: calc(50% - calc(#{$size} / 2));
      width: $size;
      height: $size;
      border: 2px solid getShade($secondary-color, 110);
      border-right-color: transparent;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }
  }

  &__inner {
    position: relative;
  }

  &__carousel {
    ::v-deep .flickity-viewport {
      overflow: visible;
    }
  }

  &__item-wrapper {
    padding: 0 $sp-m;
    background-color: white;
  }

  &__item {
    animation: fadeIn 0.3s forwards;
  }

  &__show-more-placeholder-item {
    width: #{$sp-xl * 2};
  }

  &__show-more {
    display: flex;
    justify-content: space-between;
    width: 60px;
    position: absolute;
    right: $sp-xl;
    top: 50%;
    padding: $sp-s;
    transform: translate(50%, 50%);
    border: 0;
    background-color: transparent;
    cursor: pointer;
  }

  &__dot {
    width: $sp-s;
    height: $sp-s;
    background-color: getShade($quaternary-color, 60);
    border-radius: 50%;
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(white, 0.95);
    z-index: 30;
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: $sp-s;
    right: $sp-s;
    width: 24px;
    height: 24px;
    background: transparent;
    padding: 0;
    border: 0;
    z-index: 10;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 50%;
      transition: transform 0.15s;
      z-index: -1;
    }

    &:hover {
      &::before {
        transform: scale(1.2);
      }
    }

    ::v-deep path {
      fill: $white;
    }
  }

  &__gallery {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);

    &--blurred {
      filter: blur(5px);
    }
  }

  &__offline-notification {
    position: absolute;
    top: 30%;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: getShade($quaternary-color, 40);
    padding: $sp-m;
    @include font('DS-80');

    img {
      margin-right: $sp-m;
    }
  }

  &__footer_text {
    font-style: italic;
    font-weight: 600;
    padding-top: $sp-l;
    text-align: center;

    span {
      color: $monza-red;
    }
  }
}

.fade-enter-active {
  animation: fadeIn 0.3s forwards;
}

.fade-leave-active {
  animation: fadeOut 0.3s forwards;
}
</style>
