<template>
  <div class="file-uploader">
    <div class="container columns is-multiline is-centered">
      <section
        ref="inputArea"
        class="file-uploader__input-area column is-full"
        :class="{ 'file-uploader__input-area--error': !isValid }"
      >
        <label
          for="profile-image"
          class="file-uploader__label"
          :class="{ 'file-uploader__label--focused': fileFocus }"
          @mouseover="fileFocus = true"
          @focus="fileFocus = true"
          @mouseleave="fileFocus = false"
          @blur="fileFocus = false"
        >
          <div
            v-if="fileAsUrl"
            :style="{ backgroundImage: `url(${fileAsUrl})` }"
            class="file-uploader__image"
          />
          <HdIcon :src="camera" width="46px" height="46px" class="file-uploader__icon" />
          <div class="file-uploader__field">
            <input
              id="profile-image"
              ref="file"
              name="profile-image"
              type="file"
              accept="image/*"
              aria-labelledby="semanticLabel"
              @change="handleFileInputChange"
            />
          </div>
        </label>
      </section>
      <p
        v-if="!isValid"
        class="file-uploader__error column is-full"
        v-text="internalValidationError.errorMessage"
      />
    </div>
  </div>
</template>

<script>
import { HdIcon } from 'homeday-blocks';
import { camera } from 'homeday-assets';

export default {
  name: 'ProfileUploader',
  components: {
    HdIcon,
  },
  props: {
    value: {
      type: [Blob, String],
      default: null,
    },
    customRules: {
      type: Array,
      default: () => [],
      validator: (rulesProvided) =>
        rulesProvided.every(
          ({ validate, errorMessage }) =>
            typeof validate === 'function' && typeof errorMessage === 'string',
        ),
    },
  },
  data() {
    return {
      internalValidationError: null,
      isValid: true,
      camera,
      file: null,
      fileAsUrl: null,
      fileFocus: false,
    };
  },
  mounted() {
    this.initPicture();
  },
  methods: {
    handleFileInputChange() {
      // https://developer.mozilla.org/en-US/docs/Web/API/FileList
      this.file = this.$refs?.file?.files[0];
      this.validateFile();

      // Read as data and as binary
      const FILE_READER = new FileReader();
      FILE_READER.readAsDataURL(this.file);
      FILE_READER.onload = (event) => {
        this.fileAsUrl = event.target.result;
      };

      this.$emit('input', this.file);
    },
    initPicture() {
      if (!this.value) return;
      this.fileAsUrl = this.value;
      this.$emit('input', undefined);
    },
    validateFile() {
      this.internalValidationError = this.customRules.find(({ validate }) => !validate(this.file));
      this.isValid = !this.internalValidationError;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.file-uploader {
  $root: &;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &__title {
    text-align: center;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    cursor: pointer;
    border-radius: 50%;
    background-color: getShade($neutral-gray, 60);

    &--focused {
      #{$root}__icon {
        scale: 1.1;
        z-index: 2;
        pointer-events: none;
      }
    }
  }

  &__image {
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    z-index: 1;

    &:hover {
      opacity: 60%;
    }
  }

  &__field {
    @include font('DS-200');

    display: flex;
    flex-flow: column nowrap;
    color: $secondary-color;
    margin-top: $sp-xs;

    input {
      display: none;
    }
  }

  &__input-area {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    background-color: $primary-bg;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    border: 2px solid getShade($neutral-gray, 60);
    text-align: center;
    margin: $sp-l 0;

    &--error {
      outline: $error-color solid 2px;
    }
  }

  ::v-deep &__icon {
    position: absolute;
    transition: 0.3s;
    z-index: 0;

    path {
      fill: getShade($neutral-gray, 40);
    }
  }

  &__error {
    width: 100%;
    color: $error-color;
    @include font('DS-80');
    padding-right: $sp-m;
    padding-left: $sp-m;
  }
}
</style>
