import { i18n } from '@/services/i18n';
import { SALUTATION } from './CONSTANTS';

export const PROPERTY_TYPES = {
  HOUSE: i18n.t('GENERAL.HOUSE'),
  APARTMENT: i18n.t('GENERAL.APARTMENT'),
  MULTI_FAMILY_HOUSE: i18n.t('GENERAL.MULTI_FAMILY_HOUSE'),
};

export const APARTMENT_TYPES = {
  APARTMENT: i18n.t('WIZARD.ACCESSIBILITY.APARTMENT_TYPES.APARTMENT'),
  GROUND_FLOOR: i18n.t('WIZARD.ACCESSIBILITY.APARTMENT_TYPES.GROUND_FLOOR'),
  ROOF_STOREY: i18n.t('WIZARD.ACCESSIBILITY.APARTMENT_TYPES.ROOF_STOREY'),
  MAISONETTE: i18n.t('WIZARD.ACCESSIBILITY.APARTMENT_TYPES.MAISONETTE'),
  HALF_BASEMENT: i18n.t('WIZARD.ACCESSIBILITY.APARTMENT_TYPES.HALF_BASEMENT'),
  LOFT: i18n.t('WIZARD.ACCESSIBILITY.APARTMENT_TYPES.LOFT'),
  RAISED_GROUND_FLOOR: i18n.t('WIZARD.ACCESSIBILITY.APARTMENT_TYPES.RAISED_GROUND_FLOOR'),
  PENTHOUSE: i18n.t('WIZARD.ACCESSIBILITY.APARTMENT_TYPES.PENTHOUSE'),
  TERRACED_FLAT: i18n.t('WIZARD.ACCESSIBILITY.APARTMENT_TYPES.TERRACED_FLAT'),
};

export const ALTERNATIVE_HEATING_TYPES = {
  GEO_THERMAL_HEATING: i18n.t('WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.GEO_THERMAL_HEATING'),
  HEAT_PUMP: i18n.t('WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.HEAT_PUMP'),
  PELLET_HEATING: i18n.t('WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.PELLET_HEATING'),
  DISTRICT_HEATING: i18n.t('WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.DISTRICT_HEATING'),
  SOLAR_HEATING: i18n.t('WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.SOLAR_HEATING'),
};

export const HEATING_TYPES = {
  GAS_CENTRAL_HEATING: i18n.t('WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.GAS_CENTRAL_HEATING'),
  GAS_FLOOR_HEATING: i18n.t('WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.GAS_FLOOR_HEATING'),
  OIL_HEATING: i18n.t('WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.OIL_HEATING'),
  DISTRICT_HEATING: i18n.t('WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.DISTRICT_HEATING'),
  PELLET_HEATING: i18n.t('WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.PELLET_HEATING'),
  ELECTRIC_HEATING: i18n.t('WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.ELECTRIC_HEATING'),
  COGENERATION_UNIT: i18n.t('WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.COGENERATION_UNIT'),
  SOLAR_THERMAL_HEATING: i18n.t('WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.SOLAR_THERMAL_HEATING'),
  HEAT_PUMP: i18n.t('WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.HEAT_PUMP'),
};

export const OBSOLETE_HEATING_TYPES = {
  GAS_CENTRAL_HEATING: i18n.t('WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.GAS_CENTRAL_HEATING'),
  GAS_FLOOR_HEATING: i18n.t('WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.GAS_FLOOR_HEATING'),
  OIL_HEATING: i18n.t('WIZARD.HEATING_RENOVATION.HEATING_OPTIONS.OIL_HEATING'),
};

export const BATHROOM_TYPES = {
  BATHROOM_WITH_WINDOW: i18n.t('WIZARD.BATHROOM.BATHROOM_OPTIONS.BATHROOM_WITH_WINDOW'),
  INTERIOR_BATHROOM: i18n.t('WIZARD.BATHROOM.BATHROOM_OPTIONS.INTERIOR_BATHROOM'),
  SEPARATE_GUEST_WC: i18n.t('WIZARD.BATHROOM.BATHROOM_OPTIONS.SEPARATE_GUEST_WC'),
};

export const BASEMENT_TYPES = {
  HAS_BASEMENT_COMPARTMENT: i18n.t('WIZARD.BASEMENT.TYPE.CELLAR'),
  HAS_PARTIAL_BASEMENT: i18n.t('WIZARD.BASEMENT.TYPE.PARTIAL'),
  HAS_CELLAR: i18n.t('WIZARD.BASEMENT.TYPE.BASEMENT'),
};

export const OUTDOOR_TYPES = {
  HAS_BALCONY: i18n.t('WIZARD.BALCONY_TERRACE.TYPE.BALCONY'),
  HAS_LOGGIA: i18n.t('WIZARD.BALCONY_TERRACE.TYPE.LOGGIA'),
  HAS_TERRACE: i18n.t('WIZARD.BALCONY_TERRACE.TYPE.TERRACE'),
  HAS_WINTER_GARDEN: i18n.t('WIZARD.BALCONY_TERRACE.TYPE.WINTER_GARDEN'),
};

export const WINDOW_TYPES = {
  HAS_WINDOW_ORIGINAL_STATE: i18n.t('WIZARD.WINDOW_RENOVATION.ORIGINAL'),
  HAS_WINDOW_NEEDS_RENOVATION: i18n.t('WIZARD.WINDOW_RENOVATION.NEED_RENOVATION'),
  HAS_WINDOW_MODERNIZED: i18n.t('WIZARD.WINDOW_RENOVATION.MODERNIZED'),
};

export const SALUTATION_TYPES = {
  [SALUTATION.MALE]: i18n.t('GENERAL.SALUTATION_MALE'),
  [SALUTATION.FEMALE]: i18n.t('GENERAL.SALUTATION_FEMALE'),
};

export const USER_ACTION_MESSAGES = {
  CREATE_SUCCESS: i18n.t('USER.NOTIFICATION.CREATE_SUCCESS'),
  CREATE_GENERAL_ERROR: i18n.t('USER.NOTIFICATION.CREATE_GENERAL_ERROR'),
  UPDATE_PASSWORD_SUCCESS: i18n.t('USER.NOTIFICATION.UPDATE_PASSWORD_SUCCESS'),
  UPDATE_SUCCESS: i18n.t('USER.NOTIFICATION.UPDATE_SUCCESS'),
  UPDATE_GENERAL_ERROR: i18n.t('USER.NOTIFICATION.UPDATE_GENERAL_ERROR'),
  EMAIL_TAKEN: i18n.t('USER.NOTIFICATION.EMAIL_TAKEN'),
  PROFILE_CURRENT_PASSWORD_INVALID: i18n.t('USER.NOTIFICATION.PROFILE_CURRENT_PASSWORD_INVALID'),
};
