<template>
  <div class="bank_valuation__pie-chart-section">
    <ul class="bank_valuation__list">
      <li class="bank_valuation__list-item">
        {{
          $t(
            `WIZARD.BANK_VALUATION.LIST_ITEM.${
              isLendingValueGreaterThanTotalCost ? 'ESTIMATED_LOAN_CLAIMED' : 'MAX_LOAN'
            }`,
          )
        }}
      </li>
      <li class="bank_valuation__list-item">
        {{ $t('WIZARD.BANK_VALUATION.LIST_ITEM.EQUITY') }}
      </li>
      <li class="bank_valuation__list-item">
        {{
          $t('WIZARD.BANK_VALUATION.LIST_ITEM.ACQUISITION_COSTS', {
            value: Math.round(additionalPurchaseCosts * 100),
          })
        }}
      </li>
      <li class="bank_valuation__list-item">
        {{ $t('WIZARD.BANK_VALUATION.LIST_ITEM.ADDITIONAL_EQUITY') }}
      </li>
    </ul>
    <HdPieChart
      :data-set="dataset"
      :hidden-labels="[2]"
      :colors="colors"
      :chart-bottom-text="`${$t('WIZARD.BANK_VALUATION.PIE_CHART_SUB_TITLE')} ${$formatPrice(
        totalCost,
      )}`"
    />
  </div>
</template>

<script>
import HdPieChart from '@/components/HdPieChart.vue';
import { GETTER } from '@/store/CONSTANTS';

export default {
  name: 'BankValuationChart',
  components: {
    HdPieChart,
  },
  data() {
    return {
      dataset: [],
      colors: [],
    };
  },
  computed: {
    currentDeal() {
      return this.$store.state.wizard.currentDeal;
    },
    startingPrice() {
      return this.$store.getters[GETTER.WIZARD.CURRENT_DEAL.MARKETING_PRICE] || 0;
    },
    lendingValue() {
      return this.$store.getters[GETTER.WIZARD.CURRENT_DEAL.LENDING_VALUE] * 1.2;
    },
    deposit() {
      return this.totalCost * 0.15;
    },
    additionalPurchaseCosts() {
      return this.currentDeal.additionalPurchaseCosts;
    },
    additionalPurchaseCostsSum() {
      return this.startingPrice * this.additionalPurchaseCosts;
    },
    totalCost() {
      return Math.round(this.startingPrice + this.additionalPurchaseCostsSum);
    },
    isLendingValueGreaterThanTotalCost() {
      return this.lendingValue >= this.totalCost - this.deposit;
    },
  },
  watch: {
    startingPrice: {
      handler() {
        this.setValues();
      },
      immediate: true,
    },
  },
  mounted() {
    this.setPieChartColors();
  },
  methods: {
    setValues() {
      let data;

      if (this.isLendingValueGreaterThanTotalCost) {
        data = [this.totalCost - this.deposit, this.additionalPurchaseCostsSum, this.deposit];
      } else {
        data = [
          this.lendingValue,
          this.additionalPurchaseCostsSum,
          this.deposit,
          this.totalCost - this.lendingValue - this.deposit,
        ];
      }

      this.dataset = data;
    },
    setPieChartColors() {
      // Generate Html5 canvas pattern
      const canvas = document.getElementById('pie-chart');
      const ctx = canvas.getContext('2d');

      const p = document.createElement('canvas');
      p.width = 300;
      p.height = 300;
      const pctx = p.getContext('2d');

      const color1 = '#788FAB';
      const color2 = '#1C3553';
      const numberOfStripes = 130;

      for (let i = 0; i < numberOfStripes * 2; i += 1) {
        const thickness = 260 / numberOfStripes;
        pctx.beginPath();
        pctx.strokeStyle = i % 2 ? color1 : color2;
        pctx.lineWidth = thickness;
        pctx.lineCap = 'round';

        pctx.moveTo(i * thickness + thickness / 2 - 300, 0);
        pctx.lineTo(0 + i * thickness + thickness / 2, 300);
        pctx.stroke();
      }

      ctx.fillStyle = ctx.createPattern(p, 'no-repeat');
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      this.colors = ['#2988ff', pctx, '#1C3553', '#F76923'];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.bank_valuation {
  &__pie-chart-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include for('tablet') {
      flex-direction: row;
    }
  }

  &__list {
    max-width: 400px;

    &-item {
      @include font('DS-100');
      margin-left: $sp-l;
      position: relative;

      &:nth-child(1):before {
        background-color: getShade($secondary-color, 110);
      }

      &:nth-child(2):before {
        background-color: $primary-color;
      }

      &:nth-child(3):before {
        background: repeating-linear-gradient(
          45deg,
          getShade($quaternary-color, 80),
          $primary-color 2px
        );
      }

      &:nth-child(4):before {
        background-color: getShade($tertiary-color, 90);
      }
    }

    &-item:not(:last-child) {
      margin-bottom: $sp-m;
    }

    &-item:before {
      border-radius: 50%;
      bottom: 50%;
      content: '';
      height: $sp-s;
      left: -$sp-l;
      position: absolute;
      transform: translateY(50%);
      width: $sp-s;
    }
  }
}
</style>
