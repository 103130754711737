import { isNil as _isNil } from 'lodash-es';

const SPACE_UNIT = 'm²';

export function formatDateTime(dateTime) {
  return dateTime.toLocaleString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
}

export function formatDate(dateTime) {
  return dateTime.toLocaleString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
}

export function formatMonth(dateTime) {
  return dateTime.toLocaleString('de-DE', {
    month: '2-digit',
    year: 'numeric',
  });
}

export function formatNumber(value, { fractionDigits, ...options } = {}) {
  if (fractionDigits !== undefined) {
    Object.assign(options, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });
  }

  return new Intl.NumberFormat('de-DE', options).format(value);
}

export function formatPrice(value, options = {}) {
  return formatNumber(value, {
    style: 'currency',
    currency: 'EUR',
    fractionDigits: 0,
    ...options,
  });
}

export function formatSpace(value, options = {}) {
  if (!value) return null;
  const formattedNumber = formatNumber(value, { ...options });

  // To always keep the value next to its unit, we use a non-breaking space
  // eslint-disable-next-line no-irregular-whitespace
  return `${formattedNumber} ${SPACE_UNIT}`;
}

export function formatSqmPrice(value, options = {}) {
  const formattedPrice = formatPrice(value, { ...options });

  // To always keep the value next to its unit, we use a non-breaking space
  // eslint-disable-next-line no-irregular-whitespace
  return `${formattedPrice}/${SPACE_UNIT}`;
}

export function formatAverageSqmPrice(value) {
  if (_isNil(value)) {
    return '';
  }

  return `Ø ${formatPrice(value)} pro m²`;
}
