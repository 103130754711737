<template>
  <wizard-base
    class="explanation"
    :title="$t('WIZARD.EXPLANATION.TITLE')"
    :subtitle="$t('WIZARD.EXPLANATION.SUBTITLE')"
  >
    <div class="explanation__icon-background">
      <HdIcon :src="handShakeIcon" width="80px" height="80px" />
    </div>
    <ul class="explanation__grid">
      <li v-for="(item, index) in gridItems" :key="index" class="explanation__grid-item">
        <div class="explanation__grid-item-title">
          <HdIcon :src="item.icon" width="35" height="40" />
          <h3>{{ item.title }}</h3>
        </div>
        <p class="explanation__grid-item-content" v-html="item.textContent"></p>
      </li>
    </ul>
  </wizard-base>
</template>

<script>
import WizardBase from '@/layout/WizardBase.vue';
import { HdIcon } from 'homeday-blocks';
import { locationQuality, propertyMarketing, handshake, houseEuro } from 'homeday-assets/L';

export default {
  name: 'ExplanationSlide',
  components: {
    HdIcon,
    WizardBase,
  },
  data() {
    return {
      gridItems: [
        {
          icon: locationQuality,
          title: this.$t('WIZARD.EXPLANATION.SLIDE_PROPERTY_CHARACTERISTICS.TITLE'),
          textContent: this.$t('WIZARD.EXPLANATION.SLIDE_PROPERTY_CHARACTERISTICS.CONTENT'),
        },
        {
          icon: houseEuro,
          title: this.$t('WIZARD.EXPLANATION.SLIDE_EVALUATION_BUYER.TITLE'),
          textContent: this.$t('WIZARD.EXPLANATION.SLIDE_EVALUATION_BUYER.CONTENT'),
        },
        {
          icon: propertyMarketing,
          title: this.$t('WIZARD.EXPLANATION.SLIDE_COMPARATIVE_OFFERS.TITLE'),
          textContent: this.$t('WIZARD.EXPLANATION.SLIDE_COMPARATIVE_OFFERS.CONTENT'),
        },
      ],
    };
  },
  computed: {
    handShakeIcon() {
      return handshake;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.wizard-base.explanation {
  background-color: getShade($secondary-color, 60);
  height: fit-content;
  margin-top: #{$sp-xl + $sp-xl};
  padding: #{$sp-l + $sp-s} $sp-m $sp-xl;
  position: relative;

  @include for('tablet') {
    margin-top: $sp-l;
    padding: $sp-xl 0;
  }

  ::v-deep .wizard-base {
    &__container {
      margin-top: #{$sp-m + $sp-xs};
    }

    &__title {
      @include font('DS-300');
      font-weight: 700;
    }

    &__subtitle {
      @include font('DS-100');
    }
  }
}

.explanation {
  &__icon-background {
    background-color: $primary-bg;
    border-radius: 50%;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
  }

  &__grid {
    display: grid;
    grid-gap: $sp-m;
    grid-template-columns: 1fr;
    justify-content: space-between;

    @include for('tablet') {
      grid-gap: $sp-s;
      grid-template-columns: repeat(3, minmax(150px, 260px));
      margin: 0 $sp-m;
    }

    @include for('desktop') {
      margin: 0 #{$sp-xl - $sp-m};
    }

    &-item {
      background-color: $primary-bg;
      border-radius: $sp-s;
      padding: #{$sp-m - $sp-xs};

      @include for('tablet') {
        min-height: 250px;
        padding: $sp-m #{$sp-l - $sp-s};
      }

      &-content {
        @include font('DS-90');
      }

      &:nth-child(1) {
        border-top: $sp-xs solid getShade($success-color, 70);
      }

      &:nth-child(2) {
        border-top: $sp-xs solid getShade($error-color, 60);
      }

      &:nth-child(3) {
        border-top: $sp-xs solid getShade($tertiary-color, 70);
      }
    }

    &-item-title {
      display: flex;
      margin-bottom: $sp-m;

      @include for('mobile-only') {
        align-items: center;
      }

      > h3 {
        @include font('DS-100');
        font-weight: 600;
        hyphens: auto;
        padding-left: #{$sp-m - $sp-xs};
      }

      ::v-deep > svg {
        min-height: 45px;
        min-width: 35px;
      }
    }
  }
}
</style>
