<template>
  <section
    class="calculation-block"
    :class="{
      'calculation-block--highlighted': highlighted,
    }"
  >
    <slot />
  </section>
</template>

<script>
export default {
  name: 'CalculationBlockBase',
  props: {
    highlighted: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.calculation-block {
  padding: #{$sp-m + $sp-xs} #{$sp-s + $sp-xs};
  width: 100%;

  @include for('tablet') {
    padding: #{$sp-l + $sp-s} $sp-l;
  }

  &--highlighted {
    background-color: getShade($primary-color, 40);
  }
}
</style>
