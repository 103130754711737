<template>
  <wizard-base
    class="additional-features"
    :title="$t('GENERAL.APPRECIATION_AND_DEPRECIATION')"
    :icon="slideIcon"
    :content-column-classes="['is-9', 'is-8-desktop']"
  >
    <transition-group name="item-anim">
      <div
        v-for="(item, i) in editableFeatures"
        :key="`feature-${i}-item`"
        class="additional-features-item"
      >
        <hd-input
          v-model="editableFeatures[i].name"
          :name="`feature-${i}`"
          :label="$t('WIZARD.ADDITIONAL_FEATURES.INPUT_LABEL')"
          class="additional-features-item__first-part"
          :required="!!editableFeatures[i].price"
        />
        <price-input
          v-model="editableFeatures[i].price"
          :name="`feature-${i}-price`"
          :label="$t('WIZARD.CHANGE_IN_VALUE')"
          class="additional-features-item__second-part"
        />
      </div>
    </transition-group>
    <div class="additional-features-item additional-features-item--footer">
      <div class="additional-features-item__first-part">
        <HdButton
          modifier="tertiary"
          :icon-src="plus"
          :aria-label="$t('GENERAL.ADD')"
          type="button"
          @click.native="addItem"
        />
        <p>{{ $t('WIZARD.SUM') }}</p>
      </div>
      <div class="additional-features-item__second-part">
        <p data-testid="additional-features-price-sum">{{ priceFormatter(sum) }}</p>
      </div>
    </div>
  </wizard-base>
</template>

<script>
// Components
import { HdInput, HdButton } from 'homeday-blocks';
import PriceInput from '@/components/PriceInput.vue';
import WizardBase from '@/layout/WizardBase.vue';
// Services
import { trim as _trim } from 'lodash-es';
// Icons
import { euroChange as slideIcon } from 'homeday-assets/L';
import { plus } from 'homeday-assets';
// Constants
import { MUTATION, GETTER } from '@/store/CONSTANTS';

const MIN_ITEMS = 3;
const ITEM_PLACEHOLDER = {
  feature: '',
  price: 0,
};

export default {
  name: 'AdditionalFeaturesSlide',
  components: {
    HdInput,
    PriceInput,
    HdButton,
    WizardBase,
  },
  data() {
    return {
      editableFeatures: [],
      plus,
      slideIcon,
    };
  },
  computed: {
    additionalFeatures() {
      return this.$store.state.wizard.currentDeal.additionalFeatures;
    },
    sum() {
      return this.$store.getters[GETTER.WIZARD.CURRENT_DEAL.ADDITIONAL_FEATURES_PRICE];
    },
  },
  watch: {
    editableFeatures: {
      handler(features) {
        // We filter empty entries
        const filteredFeatures = features.filter(({ name }) => {
          const featureName = _trim(name);

          return !!featureName;
        });
        this.$store.commit(MUTATION.WIZARD.CURRENT_DEAL.SET_ADDITIONAL_FEATURES, filteredFeatures);
      },
      deep: true,
    },
  },
  created() {
    this.editableFeatures = this.createEditableFeatures();
  },
  methods: {
    addItem() {
      this.editableFeatures = [...this.editableFeatures, { ...ITEM_PLACEHOLDER }];
    },
    createEditableFeatures() {
      if (this.additionalFeatures.length >= MIN_ITEMS) {
        return [...this.additionalFeatures];
      }

      // We make sure to show at least MIN_ITEMS
      const paddingLength = MIN_ITEMS - this.additionalFeatures.length;
      // We create an array with unique placeholder objects
      // we can't use `fill()` as it uses the same object instance
      const arrayOfPlaceholders = Array.from(Array(paddingLength), () => ({ ...ITEM_PLACEHOLDER }));

      return [...this.additionalFeatures, ...arrayOfPlaceholders];
    },
    priceFormatter(value) {
      return this.$formatPrice(value, {
        fractionDigits: 0,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.additional-features {
  ::v-deep .wizard-base__container {
    align-items: flex-start;
  }

  &-item {
    $item: &;

    @include for('tablet') {
      display: flex;
    }

    &--footer {
      @include font('DS-300');
      font-weight: bold;
    }

    &__first-part {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include for('tablet') {
        width: 50%;
        margin-right: $sp-m;
      }
      @include for('desktop') {
        width: 60%;
      }

      > p {
        @include font('DS-200');
      }
    }
    &__second-part {
      display: flex;
      flex: 1;
      margin-top: $sp-m;

      @include for('tablet') {
        margin-top: 0;
      }

      #{$item}--footer & {
        justify-content: flex-end;
        margin-top: 0;

        @include for('tablet') {
          justify-content: flex-start;
        }
      }
      > p {
        @include font('DS-200');
      }
    }
  }
}

.item-anim-enter-active,
.item-anim-leave-active {
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}
.item-anim-enter,
.item-anim-leave-to {
  opacity: 0;
  transform: translateY(-50%);
}
</style>
