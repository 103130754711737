import { SPECIFIC_PROPERTY_TYPE, BUILDING_TYPES } from '@/config/CONSTANTS';

import APARTMENT from './apartment';
import HOUSE from './house';
import MULTI_FAMILY_HOUSE from './multiFamilyHouse';
import PLOT from './plot';

export default {
  [SPECIFIC_PROPERTY_TYPE.APARTMENT]: APARTMENT,
  [SPECIFIC_PROPERTY_TYPE.HOUSE]: HOUSE,
  [BUILDING_TYPES.MULTI_FAMILY_HOUSE]: MULTI_FAMILY_HOUSE,
  [SPECIFIC_PROPERTY_TYPE.PLOT]: PLOT,
};
