<template>
  <div class="property-address columns is-vcentered is-centered">
    <HdIcon :src="icon.svg" class="property-address__icon" :class-from-fill="defaultIconColors" />
    <p class="property-address__address">
      {{ address }}
    </p>
  </div>
</template>

<script>
import { HdIcon } from 'homeday-blocks';
import { apartmentFloor, apartmentCommercial, houseSingleFamily } from 'homeday-assets/L';
import { SVG_DEFAULT_COLORS } from '@/config/CONSTANTS';

export default {
  name: 'PropertyAddress',
  components: { HdIcon },
  data() {
    return {
      propertyIcon: {
        house: houseSingleFamily,
        apartment: apartmentFloor,
        commercial: apartmentCommercial,
      },
    };
  },
  computed: {
    defaultIconColors() {
      return SVG_DEFAULT_COLORS;
    },
    icon() {
      return {
        svg:
          this.propertyIcon[this.$store.state.wizard.currentDeal.propertyType] ||
          this.propertyIcon.house,
        type: this.$store.state.wizard.currentDeal.propertyType,
      };
    },
    address() {
      return this.$store.state.wizard.currentDeal.address.formatted_address;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.property-address {
  &.columns {
    margin-bottom: $sp-xl + $sp-l;
  }

  &__icon {
    width: $sp-xl;
    height: auto;
  }

  &__address {
    @include fontGroup(300, 900);
    margin-left: $sp-m;
  }
}
</style>
