import axios from 'axios';
import { UtilsService } from 'homeday-blocks';
import { stringify as queryStringify } from 'qs';
import CONFIG from '@/config/env';
import { FILTERS } from '@/config/DEAL';

function getParamsWithSerializer(params) {
  return {
    params,
    paramsSerializer: queryStringify,
  };
}

export async function createSession({ email, password } = {}) {
  const response = await axios.post(CONFIG.api.sessions, {
    email,
    password,
  });

  return response.data;
}

export async function requestNewPassword({ email } = {}) {
  const response = await axios.post(CONFIG.api.request_new_password, {
    email,
  });

  return response.data;
}

export async function confirmNewPassword({ token, password } = {}) {
  const response = await axios.post(CONFIG.api.confirm_new_password, {
    confirmation_token: token,
    new_password: password,
  });

  return response.data;
}

export async function registerNewUser(user) {
  const response = await axios.post(CONFIG.api.register_user, {
    user,
  });

  return response.data;
}

export async function listDeals(filter = FILTERS.FUTURE) {
  const url = UtilsService.populateTemplate(CONFIG.api.deals, { dealUid: '' });
  const response = await axios.get(url, {
    params: {
      filter,
    },
    errorMessage: 'Failed to fetch deals',
  });

  return response.data.data;
}

export async function getDeal(dealUid) {
  const url = UtilsService.populateTemplate(CONFIG.api.deals, { dealUid });
  const response = await axios.get(url, {
    errorMessage: `Failed to get deal: ${dealUid}`,
  });

  return response.data.data;
}

export function updateEvaluationReport(payload) {
  const payloadFormatted = UtilsService.keysToSnakeCase(payload);
  const formData = Object.keys(payloadFormatted).reduce((acc, currentKey) => {
    acc.append(currentKey, payloadFormatted[currentKey]);
    return acc;
  }, new FormData());

  return axios.post(CONFIG.api.evaluation_report, formData, {
    errorMessage: `Failed to update evaluation report: ${payload.dealUid}`,
  });
}

export async function getPotentialBuyers({ dealUid, prices }) {
  const response = await axios.get(
    CONFIG.api.potential_buyers,
    getParamsWithSerializer({
      deal_uid: dealUid,
      prices: prices.join(','),
    }),
  );

  return response.data;
}

export function createEvaluation(evaluation) {
  return axios.post(
    CONFIG.api.evaluations,
    { evaluation },
    {
      errorMessage: 'Failed to create evaluation',
    },
  );
}

export async function createDeal(property) {
  const url = UtilsService.populateTemplate(CONFIG.api.deals, { dealUid: '' });
  const deal = UtilsService.keysToSnakeCase(property);
  const response = await axios.post(url, { deal });
  return response.data;
}

export async function updateDeal({ dealUid, payload }) {
  const url = UtilsService.populateTemplate(CONFIG.api.deals, { dealUid });
  const deal = UtilsService.keysToSnakeCase(payload);
  const response = await axios.patch(url, { deal });
  return response.data;
}

export async function removeDeal({ dealUid }) {
  const url = UtilsService.populateTemplate(CONFIG.api.deals, { dealUid });
  const response = await axios.delete(url);
  return response.data;
}

export async function getSimilarProperties(deal) {
  const LIVING_SPACE_DEVIATION = 30;
  const isApartment = deal.propertyType === 'apartment';
  const params = {
    lat: deal.location.lat,
    lng: deal.location.lng,
    property_type: deal.propertyType,
    living_space_min: 0,
    living_space_max: 9999,
  };

  if (isApartment) {
    const livingSpaceMin = deal.livingSpace - LIVING_SPACE_DEVIATION;
    const livingSpaceMax = deal.livingSpace + LIVING_SPACE_DEVIATION;

    params.living_space_min = Math.max(0, livingSpaceMin);
    params.living_space_max = livingSpaceMax;
  }

  const response = await axios.get(CONFIG.api.similar_properties, getParamsWithSerializer(params), {
    errorMessage: 'Failed to fetch similar properties',
  });
  return response.data.data;
}

export async function updateUser(form) {
  const formData = Object.keys(form).reduce((acc, currentKey) => {
    acc.append(`user[${currentKey}]`, form[currentKey]);
    return acc;
  }, new FormData());
  const response = await axios.post(CONFIG.api.update_user, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
}
