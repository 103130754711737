<template>
  <flickity class="hd-carousel" :options="flickityOptions" @init="onFlickityInit">
    <slot />
  </flickity>
</template>

<script>
// eslint-disable-next-line import/extensions
import Flickity from 'vue-flickity';

// We handle the destroying of flickity manually
// (check the `beforeDestroy` hook of HdCarousel)
Flickity.beforeDestroy = undefined;

const DEFAULT_OPTIONS = {
  contain: true,
  prevNextButtons: false,
  pageDots: false,
};

export default {
  name: 'HdCarousel',
  components: {
    Flickity,
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    flickityDestroyDelay: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      flickity: null,
    };
  },
  created() {
    this.flickityOptions = {
      ...DEFAULT_OPTIONS,
      ...this.options,
    };
  },
  beforeDestroy() {
    setTimeout(() => {
      this.flickity.destroy();
    }, this.flickityDestroyDelay);
  },
  methods: {
    onFlickityInit(flickity) {
      this.flickity = flickity;
    },
  },
};
</script>

<style lang="scss" scoped>
.hd-carousel {
  width: 100%;
}
</style>
