<template>
  <div class="pie_chart">
    <Pie
      :chart-options="chartOptions"
      :chart-data="chartData"
      :plugins="pieChartPlugins"
      dataset-id-key="label"
      chart-id="pie-chart"
      :height="height"
      :width="width"
    />
    <p class="pie_chart__sub-title">{{ chartBottomText }}</p>
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: 'HdPieChart',
  components: {
    Pie,
  },
  props: {
    dataSet: {
      type: Array,
      required: true,
    },
    colors: {
      type: Array,
      default: () => ['#2988ff', '#1C3553', '#F76923'],
    },
    chartBottomText: {
      type: String,
      default: () => '',
    },
    height: {
      type: Number,
      default: () => 280,
    },
    width: {
      type: Number,
      default: () => 280,
    },
    hiddenLabels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        datasets: [
          {
            backgroundColor: this.colors,
            data: this.dataSet,
            borderWidth: 0,
            hiddenLabels: this.hiddenLabels,
          },
        ],
      },
      chartOptions: {
        animation: {
          colors: false,
        },
        plugins: {
          tooltip: {
            enabled: false,
          },
          datalabels: {
            color(context) {
              // Pass in an array of indexes of labels that you want to hide
              const { hiddenLabels } = context.dataset;
              const index = context.dataIndex;
              const hideCurrent = hiddenLabels.find((val) => val === index);

              return hideCurrent ? 'transparent' : '#FFFFFF';
            },
            font: {
              family: "'Immo Sans', sans-serif",
              size: 16,
              weight: '600',
            },
            formatter(value) {
              return `${Math.round(value).toLocaleString('de')} €`;
            },
          },
        },
      },
    };
  },
  computed: {
    pieChartPlugins() {
      return [ChartDataLabels];
    },
  },
  watch: {
    dataSet(newVal) {
      this.chartData.datasets[0].data = newVal;
    },
    colors() {
      this.chartData.datasets[0].backgroundColor = this.colors;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

::v-deep #pie-chart {
  pointer-events: none;
}

.pie_chart {
  > div {
    margin: $sp-xl auto 0;
    padding: 0 $sp-xs #{$sp-s + $sp-xxs};

    @include for('tablet') {
      margin: 0 0 0 auto;
    }
  }

  &__sub-title {
    @include font('DS-90');
    color: getShade($quaternary-color, 90);
    padding-top: $sp-s;
    text-align: center;
  }
}
</style>
