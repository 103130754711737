import * as formatters from '@/services/formatters';

export default {
  install(Vue) {
    Object.keys(formatters).forEach((functionName) => {
      // eslint-disable-next-line no-param-reassign
      Vue.prototype[`$${functionName}`] = formatters[functionName];
    });
  },
};
