<template>
  <li class="deal-card" data-cy="deal-item">
    <DealNotifications :deal="deal" />
    <deal-item
      ref="dealItem"
      class="deal-card__inner deal-card__inner--top"
      :class="{
        'deal-card__inner--top--hidden': topCardHidden,
      }"
      :deal="deal"
      :highlight="highlight"
      @info-click="toggleTopCard"
      @cta-click="startEvaluation"
    />
    <deal-item-options class="deal-card__inner deal-card__inner--bottom" :deal="deal" />
  </li>
</template>

<script>
// Components
import DealNotifications from '@/components/DealNotifications.vue';
import DealItem from '@/components/DealItem.vue';
import DealItemOptions from '@/components/DealItemOptions.vue';
// Constants
import { SLIDES as SLIDES_ROUTE_NAMES } from '@/router/wizard/ROUTE_NAMES';

export default {
  name: 'DealCard',
  components: {
    DealNotifications,
    DealItem,
    DealItemOptions,
  },
  props: {
    deal: {
      type: Object,
      required: true,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      topCardHidden: false,
    };
  },
  methods: {
    toggleTopCard() {
      this.topCardHidden = !this.topCardHidden;
    },
    startEvaluation() {
      this.$router.push({
        name: SLIDES_ROUTE_NAMES.INDEX,
        params: {
          dealUid: this.deal.uid,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.deal-card {
  border: 1px solid getShade($quaternary-color, 40);
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &__inner {
    min-height: 205px;
    padding: $sp-l;

    @include for('mobile-only') {
      padding: $sp-m;
    }

    &--top {
      position: relative;
      z-index: 10; // HdInput has some high z-index
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
      transition: transform 500ms cubic-bezier(0.2, 0, 0, 1) 0s;

      &--hidden {
        transform: translateX(calc(-100% + 80px));

        @include for('mobile-only') {
          transform: translateX(calc(-100% + 60px));
        }
      }
    }

    &--bottom {
      position: absolute;
      top: 0;
      left: 0;
      padding-left: 100px;

      @include for('mobile-only') {
        padding-left: 80px;
      }
    }
  }
}
</style>
