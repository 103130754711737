<template>
  <PageBase class="deals">
    <template #headerRight>
      <AccountControls />
    </template>
    <div class="deals">
      <div class="deals__navigation">
        <HdTabsMenu v-model="routeName" class="deals__navigation__wrapper" :items="menuItems" />
        <HdButton
          modifier="tertiary"
          :icon-src="refresh"
          :aria-label="$t('GENERAL.REFRESH')"
          type="button"
          @click.native="refreshPage"
        />
      </div>
      <div class="deals__controls">
        <HdButton
          modifier="primary"
          :aria-label="$t('WIZARD.DEAL.NEW')"
          type="button"
          @click="isNewDealOpen = true"
          >{{ $t('WIZARD.DEAL.NEW') }}</HdButton
        >
      </div>
      <router-view />
    </div>
    <DealModal v-if="isNewDealOpen" @close="isNewDealOpen = false" @submit="refreshPage" />
  </PageBase>
</template>

<script>
// Components
import { HdTabsMenu, HdButton } from 'homeday-blocks';
import PageBase from '@/layout/PageBase.vue';
import AccountControls from '@/components/AccountControls.vue';
import DealModal from '@/components/DealModal.vue';
// Icons
import { warningTriangle as errorIcon } from 'homeday-assets/L';
import { refresh } from 'homeday-assets';
// Constants
import { MUTATION } from '@/store/CONSTANTS';
// Services
import { sendPageView, EVENTS, getCustomStatistics } from '@/services/gtm';

export default {
  name: 'Deals',
  components: {
    HdTabsMenu,
    PageBase,
    AccountControls,
    HdButton,
    DealModal,
  },
  data() {
    return {
      menuItems: [
        {
          label: this.$t('WIZARD.LIST.MENU.FUTURE_DEALS'),
          value: 'futureDeals',
        },
        {
          label: this.$t('WIZARD.LIST.MENU.PAST_DEALS'),
          value: 'pastDeals',
        },
      ],
      refresh,
      errorIcon,
      isNewDealOpen: false,
    };
  },
  computed: {
    routeName: {
      get() {
        return this.$route.name;
      },
      set(routeName) {
        if (routeName !== this.$route.name) {
          this.$router.push({ name: routeName });
        }
      },
    },
  },
  created() {
    // Tracking
    sendPageView({
      ...EVENTS.PAGE_VIEW.DEALS,
      ...getCustomStatistics(this.$store, this.$route.path),
    });
  },
  methods: {
    refreshPage() {
      this.$store.commit(MUTATION.WIZARD.CLEAN_DEALS_CACHE);
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.deals {
  &__navigation {
    display: flex;
    align-items: flex-end;
    margin-bottom: $sp-m;

    @include for('tablet') {
      margin-bottom: $sp-l;
    }

    &__wrapper {
      flex-grow: 1;
    }
  }
  &__controls {
    display: flex;
    justify-content: flex-end;
    margin-bottom: $sp-m;
  }
}
</style>

<!-- Overwrite HdTabsMenu styles -->
<style lang="scss">
@import '@/styles/mixins.scss';

.deals {
  &__navigation {
    &__wrapper {
      .tabs-menu__item {
        margin-right: 0;
        min-width: 49%; // Works as we're dealing with 2 items only
      }
    }
  }
}
</style>
