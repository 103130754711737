import { defaultGetters } from '@/services/deal';
import { priceContributionGetters } from '@/services/priceContribution';
import { isNil as _isNil } from 'lodash-es';
import { GETTER } from './CONSTANTS';

export default {
  ...defaultGetters,
  ...priceContributionGetters,
  [GETTER.ADDITIONAL_FEATURES_PRICE](state) {
    return state.additionalFeatures.reduce((sum, { price }) => sum + price, 0);
  },
  [GETTER.TOTAL_FEATURES_PRICE](_state, getters) {
    const mainFeaturesPrice = Object.keys(priceContributionGetters).reduce(
      (prices, getterName) => prices + getters[getterName],
      0,
    );

    return mainFeaturesPrice + getters[GETTER.ADDITIONAL_FEATURES_PRICE];
  },
  [GETTER.TOTAL_APPLIED_FEATURES_PRICE](state, getters) {
    return state.appliedPriceContributions.reduce((price, getterName) => {
      const value = getters[getterName];
      if (!_isNil(value)) {
        return price + value;
      }
      return price;
    }, 0);
  },
  [GETTER.TOTAL_PRICE](_state, getters) {
    return Math.round(getters.basePrice + getters[GETTER.TOTAL_APPLIED_FEATURES_PRICE]);
  },
  [GETTER.MARKETING_PRICE](state, getters) {
    if (state.marketingPrice >= 0) {
      return state.marketingPrice;
    }

    return getters[GETTER.TOTAL_PRICE];
  },
  [GETTER.CALCULATED_SQM_PRICE](state, getters) {
    // TODO: Add more magic here for houses, in case we want to consider the rest of the plot
    if (state.livingSpace === 0) return 0;
    return Math.round(getters[GETTER.MARKETING_PRICE] / state.livingSpace);
  },
  [GETTER.CALCULATED_SQM_TOTAL_PRICE](state, getters) {
    if (state.livingSpace === 0) return 0;
    return Math.round(getters[GETTER.TOTAL_PRICE] / state.livingSpace);
  },
  [GETTER.LENDING_VALUE](state) {
    return state.lendingValue;
  },
};
