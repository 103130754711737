<template>
  <wizard-base
    class="facade-renovation"
    :title="$t('GENERAL.FACADE')"
    :icon="slideIcon"
    :content-column-classes="['is-9', 'is-6-desktop']"
  >
    <div class="facade-renovation__fields">
      <hd-input
        v-model="lastFacadeRenovationYear"
        name="lastFacadeRenovationYear"
        type="number"
        :label="$t('GENERAL.LAST_RENOVATION')"
        :custom-rules="customRules"
        :required="isPriceDataChanged"
      />
      <PriceContributionInput v-model="facadeRenovationPrice" name="facadeRenovation" />
    </div>
  </wizard-base>
</template>

<script>
// Components
import { HdInput } from 'homeday-blocks';
import PriceContributionInput from '@/components/PriceContributionInput.vue';
import WizardBase from '@/layout/WizardBase.vue';
// Services
import { mapFeatures } from '@/services/feature';
import { mapPriceContributions } from '@/services/priceContribution';
// Icons
import { facadeRepair as slideIcon } from 'homeday-assets/L';
import { toFinite as _toFinite, isNumber as _isNumber, inRange as _inRange } from 'lodash-es';
import { MAXIMUM_YEAR, MINIMUM_YEAR } from '@/config/CONSTANTS';

export default {
  name: 'FacadeRenovationSlide',
  components: {
    HdInput,
    PriceContributionInput,
    WizardBase,
  },
  data() {
    return {
      slideIcon,
    };
  },
  computed: {
    ...mapFeatures(['lastFacadeRenovationYear']),
    ...mapPriceContributions(['facadeRenovation']),
    /** @returns {boolean} */
    isPriceDataChanged() {
      return this.facadeRenovationPrice > 0;
    },
    customRules() {
      return [
        {
          validate: (value) => {
            if (!_isNumber(Number(value))) return false;
            return _inRange(_toFinite(value), MINIMUM_YEAR, MAXIMUM_YEAR);
          },
          errorMessage: this.$t('FIELD_VALIDATIONS.INCORRECT_CONSTRUCTION_YEAR'),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.facade-renovation {
  &__fields {
    display: grid;

    @include for('tablet') {
      grid-template-columns: 1fr 1.5fr;
      grid-column-gap: $sp-m;
    }
  }
}
</style>
