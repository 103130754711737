import { snakeCase as _snakeCase } from 'lodash-es';

export default {
  normalize() {
    // todo: src/services/decoders/bathrooms.js
    return [];
  },
  denormalize(payload) {
    if (!payload) return [];

    // map properties to correct BE naming but avoiding the types since it's an array
    const bathroomsWithoutTypes = Object.entries(payload)
      .filter(([key]) => key !== 'types')
      .map(([key, value]) => ({ name: _snakeCase(key), value }));

    // map types to correct BE naming
    const bathroomTypes = payload.types.map(({ name, price }) => ({
      name: `${_snakeCase(name)}_price`,
      value: price,
    }));

    // creating one object with all the bathroom properties
    return [...bathroomsWithoutTypes, ...bathroomTypes].filter(
      (obj) => Object.keys(obj).length !== 0,
    );
  },
};
