<template>
  <wizard-base
    class="outdoor"
    :title="$t('WIZARD.BALCONY_TERRACE.TITLE')"
    :icon="slideIcon"
    :content-column-classes="['is-6']"
  >
    <div class="outdoor__fields">
      <!-- TODO: Use <fieldset><legend> (Flexbox/Grid does not work on <fieldset> yet) -->
      <h3 class="outdoor__legend">
        {{ $t('GENERAL.BALCONY') }}
      </h3>
      <TileSelectWithReset
        v-model="hasOutdoor"
        :items="outdoorItems"
        type="Boolean"
        name="outdoorValue"
        class="outdoor__select"
        @input="(value) => !value && resetEverything()"
      />
      <template v-if="hasOutdoor">
        <FeatureCard
          v-for="(option, index) in selectedOutdoorTypes"
          :key="option"
          :show-close="selectedOutdoorTypes.length > 1"
          @close="removeCard(option)"
        >
          <BatchSelect
            v-model="selectedOutdoorTypes"
            name="outdoor-type"
            class="outdoor__type-selector"
            :options="outdoorTypeItems"
            :index="index"
            :label="$t('WIZARD.BALCONY_TERRACE.TYPE.LABEL')"
            no-repetition
            @change="afterChange"
            @beforeChange="beforeChange"
          />
          <HdTileSelect
            v-if="featuresMapper[option].roof"
            :name="`${option}_roofing_tile`"
            :items="roofItems"
            :value="getValue(featuresMapper[option].roof)"
            @input="(value) => updateValue(featuresMapper[option].roof, value)"
          />
          <HdTileSelect
            v-if="featuresMapper[option].windProtection"
            :name="`${option}_wind_protection_tile`"
            :items="windBreakItems"
            :value="getValue(featuresMapper[option].windProtection)"
            @input="(value) => updateValue(featuresMapper[option].windProtection, value)"
          />
          <div class="columns">
            <HdInputFormatter
              class="column is-6"
              :name="`${option}_size`"
              :label="$t('GENERAL.SURFACE')"
              type="number"
              :formatter="$formatSpace"
              :value="getValue(featuresMapper[option].size)"
              @input="(value) => updateValue(featuresMapper[option].size, value)"
            />
            <PriceInput
              class="column is-6"
              :name="`${option}_value`"
              :label="$t('WIZARD.CHANGE_IN_VALUE')"
              :parse-to-number="true"
              :value="getValue(featuresMapper[option].value)"
              @input="(value) => updateValue(featuresMapper[option].value, value)"
            />
          </div>
        </FeatureCard>
        <FormControlBar
          :show-add-button="selectedOutdoorTypes.length < outdoorTypeItems.length"
          :show-total="selectedOutdoorTypes.length > 1"
          :total="total"
          @add="addCard"
        />
      </template>
      <div v-else class="columns">
        <PriceContributionInput
          v-model="outdoorPrice"
          name="outdoor"
          class="column is-6 outdoor__input"
        />
      </div>
    </div>
  </wizard-base>
</template>

<script>
// Components
import { HdTileSelect, HdInputFormatter } from 'homeday-blocks';
import WizardBase from '@/layout/WizardBase.vue';
import PriceContributionInput from '@/components/PriceContributionInput.vue';
import TileSelectWithReset from '@/components/TileSelectWithReset.vue';
import FormControlBar from '@/components/FormControlBar.vue';
import BatchSelect from '@/components/BatchSelect.vue';
import FeatureCard from '@/components/FeatureCard.vue';
import PriceInput from '@/components/PriceInput.vue';
// Services
import { mapFeatures } from '@/services/feature';
import { mapPriceContributions } from '@/services/priceContribution';
import { getItemsFromTranslationObject } from '@/services/helpers';
import { camelCase as _camelCase, isNil as _isNil } from 'lodash-es';
// Icons
import { balcony as slideIcon } from 'homeday-assets/L';
import { OUTDOOR_TYPES } from '@/config/TRANSLATION_CONSTANTS';

const featuresMapper = {
  has_balcony: {
    size: 'balconySize',
    value: 'balconyValue',
    roof: 'hasBalconyWithRoofing',
    windProtection: 'hasBalconyWithWindProtection',
  },
  has_terrace: {
    size: 'terraceSize',
    value: 'terraceValue',
    roof: 'hasTerraceWithRoofing',
    windProtection: 'hasTerraceWithWindProtection',
  },
  has_loggia: {
    size: 'loggiaSize',
    value: 'loggiaValue',
  },
  has_winter_garden: {
    size: 'winterGardenSize',
    value: 'winterGardenValue',
  },
};

export default {
  name: 'BalconyAndTerraceSlide',
  components: {
    FormControlBar,
    WizardBase,
    PriceContributionInput,
    TileSelectWithReset,
    BatchSelect,
    HdTileSelect,
    FeatureCard,
    PriceInput,
    HdInputFormatter,
  },
  data() {
    return {
      selectedOutdoorTypes: [], // ['has_balcony', ..]
      featuresMapper,
      outdoorItems: [
        {
          text: this.$t('WIZARD.BALCONY_TERRACE.WITH_OUTDOOR'),
          value: true,
        },
        {
          text: this.$t('WIZARD.BALCONY_TERRACE.WITHOUT_OUTDOOR'),
          value: false,
        },
      ],
      windBreakItems: [
        {
          text: this.$t('WIZARD.BALCONY_TERRACE.WITH_WINDBREAK'),
          value: true,
        },
        {
          text: this.$t('WIZARD.BALCONY_TERRACE.WITHOUT_WINDBREAK'),
          value: false,
        },
      ],
      roofItems: [
        {
          text: this.$t('WIZARD.BALCONY_TERRACE.WITH_ROOF'),
          value: true,
        },
        {
          text: this.$t('WIZARD.BALCONY_TERRACE.WITHOUT_ROOF'),
          value: false,
        },
      ],
      slideIcon,
    };
  },
  computed: {
    ...mapFeatures([
      'hasOutdoor',
      'hasBalcony',
      'hasLoggia',
      'hasTerrace',
      'hasWinterGarden',
      'balconySize',
      'balconyValue',
      'hasBalconyWithRoofing',
      'hasBalconyWithWindProtection',
      'hasTerraceWithRoofing',
      'hasTerraceWithWindProtection',
      'terraceSize',
      'terraceValue',
      'loggiaSize',
      'loggiaValue',
      'winterGardenSize',
      'winterGardenValue',
    ]),
    ...mapPriceContributions(['outdoor']),
    outdoorTypeItems() {
      return getItemsFromTranslationObject(OUTDOOR_TYPES); // [{ label, value }]
    },
    total() {
      return Object.values(this.featuresMapper).reduce((acc, { value }) => {
        return acc + (this[value] || 0);
      }, 0);
    },
  },
  watch: {
    selectedOutdoorTypes: {
      handler(selected) {
        // options = [{ key: 'has_something', value: true }]
        const options = this.outdoorTypeItems.map((option) => {
          return {
            key: option.value,
            value: _isNil(this.hasOutdoor) ? undefined : selected.includes(option.value),
          };
        });

        // Save selected outdoor options in store
        options.forEach(({ key, value }) => {
          this[_camelCase(key)] = value; // this.hasSomething = true/false
        });

        // Re-calculate total value and save
        this.setTotal();
      },
      deep: true,
    },
    total: {
      handler() {
        this.setTotal();
      },
      deep: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    beforeChange(option) {
      this.removeCard(option);
    },
    afterChange(selectedOptions) {
      this.selectedOutdoorTypes = selectedOptions;
    },
    setTotal() {
      // Save total value only if there is an outdoor space, otherwise we use `getCalculatedPrice` as fallback function
      if (this.hasOutdoor) {
        this.outdoorPrice = this.total;
      }
    },
    init() {
      // Sync local state with store
      this.selectedOutdoorTypes = this.outdoorTypeItems.reduce((acc, { value }) => {
        if (this[_camelCase(value)]) acc.push(value);
        return acc;
      }, []);
    },
    removeCard(option) {
      const subFeatures = Object.values(this.featuresMapper[option]);
      // Unset sub-features value in store
      subFeatures.forEach((featureName) => {
        this[_camelCase(featureName)] = undefined;
      });

      // Remove feature card
      this.selectedOutdoorTypes = this.selectedOutdoorTypes.filter((i) => i !== option);
    },
    addCard() {
      const nextOption = this.outdoorTypeItems.find(
        ({ value }) => !this.selectedOutdoorTypes.includes(value),
      )?.value;
      this.selectedOutdoorTypes = [...this.selectedOutdoorTypes, nextOption];
    },
    updateValue(key, value) {
      this[_camelCase(key)] = value;
    },
    getValue(key) {
      return this[_camelCase(key)];
    },
    resetEverything() {
      this.outdoorTypeItems.forEach(({ value }) => {
        this.removeCard(value);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.outdoor {
  ::v-deep .wizard-base__container {
    align-items: flex-start;
  }

  &__legend {
    margin-top: $sp-m;
    font-weight: normal;

    @include for('tablet') {
      display: none;
    }
  }
}
</style>
