import { MUTATION } from '@/store/CONSTANTS';
import PRICE_CONTRIBUTIONS from '@/config/PRICE_CONTRIBUTIONS';
import { getFeatureValidator } from '@/services/feature';

export function getPriceContributionPriceName(priceContributionName) {
  return `${priceContributionName}Price`;
}

export function mapPriceContributions(priceContributionNames) {
  return priceContributionNames.reduce((computedPropeties, priceContributionName) => {
    if (!PRICE_CONTRIBUTIONS.find(({ name }) => name === priceContributionName)) {
      console.warn(`The price contribution "${priceContributionName}" is not supported,
        did you add it to the PRICE_CONTRIBUTIONS array?`);
      return computedPropeties;
    }

    return {
      ...computedPropeties,
      [getPriceContributionPriceName(priceContributionName)]: {
        set(value) {
          this.$store.commit(MUTATION.WIZARD.CURRENT_DEAL.SET_PRICE_CONTRIBUTION, {
            name: priceContributionName,
            value,
          });
        },
        get() {
          return this.$store.getters[
            `wizard/currentDeal/${getPriceContributionPriceName(priceContributionName)}`
          ];
        },
      },
    };
  }, {});
}

export const priceContributionGetters = PRICE_CONTRIBUTIONS.reduce(
  (getters, priceContribution) => ({
    ...getters,
    [getPriceContributionPriceName(priceContribution.name)]: (storeState, storeGetters) => {
      if (typeof storeState.priceContributions[priceContribution.name] === 'number') {
        return storeState.priceContributions[priceContribution.name];
      }

      const usedFeatures =
        priceContribution.usedFeatures || priceContribution.getUsedFeatures(storeState);
      const isValid = usedFeatures.every((featureName) =>
        getFeatureValidator(featureName)(storeState[featureName]),
      );

      if (isValid) {
        return priceContribution.getCalculatedPrice(storeState, storeGetters);
      }

      return 0;
    },
  }),
  {},
);
