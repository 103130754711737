<template>
  <wizard-base
    class="owner-share"
    :title="$t('WIZARD.OWNER_SHARE.TITLE')"
    :content-column-classes="['is-9', 'is-6-desktop']"
  >
    <calculation-block-base class="owner-share__block" highlighted>
      <HdInputFormatter
        v-model="buildingSpace"
        name="buildingSpace"
        type="number"
        :label="$t('WIZARD.OWNER_SHARE.BUILDING_SPACE')"
        :formatter="$formatSpace"
        :custom-rules="customRules"
      />
      <div class="owner-share__item">
        <dt>{{ $t('WIZARD.OWNER_SHARE.LIVING_SPACE') }}</dt>
        <dd>{{ $formatSpace(livingSpace) }}</dd>
      </div>
      <div class="owner-share__item owner-share__item--highlighted">
        <dt>{{ $t('WIZARD.OWNER_SHARE.YOUR_SHARE') }}</dt>
        <dd>
          <owner-share-input v-model="ownerShare" />
        </dd>
      </div>
    </calculation-block-base>
  </wizard-base>
</template>

<script>
// @ts-check
import {
  isNil as _isNil,
  toFinite as _toFinite,
  isNumber as _isNumber,
  inRange as _inRange,
} from 'lodash-es';

// Components
import { HdInputFormatter } from 'homeday-blocks';
import OwnerShareInput from '@/components/OwnerShareInput.vue';
import CalculationBlockBase from '@/components/CalculationBlockBase.vue';
import WizardBase from '@/layout/WizardBase.vue';
// Services
import { mapFeatures } from '@/services/feature';
// Constants
import { ACTION } from '@/store/CONSTANTS';
import { MAXIMUM_SPACE, MINIMUM_SPACE } from '@/config/CONSTANTS';

const DEFAULT_OWNER_SHARE = 0.45;

export default {
  name: 'OwnerShareSlide',
  components: {
    OwnerShareInput,
    HdInputFormatter,
    CalculationBlockBase,
    WizardBase,
  },
  data() {
    return {
      livingSpace: this.$store.state.wizard.currentDeal.livingSpace,
    };
  },
  computed: {
    ...mapFeatures(['ownerShare']),
    /** @returns {import('@/@types/global').ValidationRules} */
    customRules() {
      return [
        {
          validate: (value) => {
            if (!_isNumber(Number(value))) return false;
            return _inRange(_toFinite(value), MINIMUM_SPACE, MAXIMUM_SPACE);
          },
          errorMessage: this.$t('FIELD_VALIDATIONS.INCORRECT_VALUE'),
        },
      ];
    },
    // This feature has a special behavior of resetting ownerShare
    buildingSpace: {
      get() {
        return this.$store.state.wizard.currentDeal.buildingSpace;
      },
      set(value) {
        this.$store.dispatch(ACTION.WIZARD.CURRENT_DEAL.UPDATE_FEATURE, {
          name: 'buildingSpace',
          value,
        });

        this.$store.dispatch(ACTION.WIZARD.CURRENT_DEAL.UPDATE_FEATURE, {
          name: 'ownerShare',
          value: Math.min(this.livingSpace / value, 1),
        });
      },
    },
  },
  created() {
    // We set a default value in case ownerShare is nil
    if (_isNil(this.ownerShare)) {
      this.ownerShare = DEFAULT_OWNER_SHARE;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

@mixin card {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.35);
  border: 1px solid getShade($quaternary-color, 50);
  border-radius: 4px;
}

.owner-share {
  padding-bottom: $sp-xl;

  &__block {
    @include card;
  }

  &__item {
    $item: &;

    display: flex;
    margin-top: $sp-m;
    @include font('DS-100');
    color: $quaternary-color;

    &--highlighted {
      align-items: center;
      font-weight: bold;
      color: $primary-color;

      dd {
        color: $tertiary-color;
      }
    }

    dt {
      flex: 1;
    }

    dd {
      font-weight: bold;
    }
  }
}
</style>
