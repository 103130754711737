import { stubTrue as _stubTrue } from 'lodash-es';
import { ACTION } from '@/store/CONSTANTS';
import FEATURES from '@/config/FEATURES';

export function mapFeatures(arrayOfFeatureNames) {
  return arrayOfFeatureNames.reduce((computedPropeties, featureName) => {
    if (!FEATURES.find(({ name }) => name === featureName)) {
      console.warn(
        `The feature "${featureName}" is not supported, did you add it to the features array?`,
      );
      return computedPropeties;
    }

    return {
      ...computedPropeties,
      [featureName]: {
        set(value) {
          this.$store.dispatch(ACTION.WIZARD.CURRENT_DEAL.UPDATE_FEATURE, {
            name: featureName,
            value,
          });
        },
        get() {
          return this.$store.state.wizard.currentDeal[featureName];
        },
      },
    };
  }, {});
}

export function getFeatureValidator(feature) {
  return feature.validator || _stubTrue;
}
