<template>
  <PageBase class="forgot">
    <div class="forgot__wrapper columns is-mobile is-centered is-vcentered">
      <section class="forgot__inner column is-half-tablet is-4-desktop">
        <HdAlert v-if="error" type="error" :no-icon="false" class="forgot__error">
          {{ error }}
        </HdAlert>

        <h3>{{ $t('RESET_PASSWORD.CONFIRM.TITLE') }}</h3>

        <p class="forgot__description">
          {{ $t('RESET_PASSWORD.CONFIRM.DESCRIPTION') }}
        </p>

        <HdDynamicForm
          :items="formItems"
          :submit-label="$t('GENERAL.SEND')"
          class="forgot__form"
          @submit="submitHandler"
        />
      </section>
    </div>
  </PageBase>
</template>

<script>
// Components
import { HdAlert, HdDynamicForm } from 'homeday-blocks';
import PageBase from '@/layout/PageBase.vue';
// Constants
import { ACTION } from '@/store/CONSTANTS';
// Services
import { getPasswordStrength } from 'homeday-blocks/src/services/utils';

export default {
  name: 'ConfirmNewPasswordView',
  components: {
    HdDynamicForm,
    PageBase,
    HdAlert,
  },
  data() {
    return {
      token: this.$route.params.token,
      password: '',
      error: '',
      isConfirmed: false,
    };
  },
  computed: {
    formItems() {
      return [
        {
          type: 'inputPassword',
          name: 'password',
          initialValue: this.password,
          props: {
            required: true,
            label: this.$t('FORM.PASSWORD.LABEL_INFORMAL'),
            customRules: this.customRules,
          },
        },
      ];
    },
    customRules() {
      return [
        {
          validate: (value) => {
            const minValidStrength = 6; // 0-10
            return getPasswordStrength(value, 10) >= minValidStrength;
          },
          errorMessage: this.$t('FIELD_VALIDATIONS.WEAK_PASSWORD'),
        },
      ];
    },
  },
  methods: {
    submitHandler({ data, isValid }) {
      if (!isValid) {
        return;
      }

      this.$store
        .dispatch(ACTION.USER.CONFIRM_NEW_PASS, { ...data, token: this.token })
        .then(() => {
          this.$router.push('/login');
        })
        .catch(({ response: { status } = {} }) => {
          this.error = '';
          this.$nextTick(() => this.setErrorFromStatus(status));
        });
    },
    setErrorFromStatus(status) {
      switch (status) {
        case 401:
          this.error = this.$t('USER.NOTIFICATION.RESET_PASSWORD_EXPIRED');
          break;
        default:
          this.error = this.$t('LOGIN.GENERIC_ERROR');
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.forgot {
  height: 100vh;

  &__description {
    font-size: 18px;
    line-height: 28px;
    margin: $sp-s auto;
  }

  &__wrapper {
    height: 100%;
    padding-top: $sp-l;
    padding-bottom: $sp-l;
  }

  &__error {
    margin-bottom: $sp-m;
    animation: fadeIn 0.5s forwards;
  }

  &__form {
    margin-top: $sp-m;
    text-align: left;
  }

  &__link-to {
    text-align: center;
    margin-top: $sp-m;
  }

  &__link-to-page {
    @include font('DS-100');
    display: inline-block;
    -webkit-box-shadow: inset 0 -1px 0 $secondary-color;
    box-shadow: inset 0 -1px 0 $secondary-color;
    &:hover {
      color: $secondary-color;
    }
  }

  &__login-btn {
    margin-top: $sp-s;
    width: 100%;
  }

  ::v-deep .dynamicForm__submit {
    margin-top: $sp-s;
  }
}
</style>
