<template>
  <li class="tree-option" :class="customClasses">
    <template v-if="target && target.items">
      <!-- Renders toggle -->
      <div class="tree-option__collapsable" @click="toggleChildren">
        <HdIcon
          v-if="target.icon && isRootCategory"
          class="tree-option__category-icon"
          width="27px"
          height="27px"
          :src="target.icon"
        />
        <span>{{ $t(`${target.title}`) }}</span>
        <HdIcon :src="smallArrow" class="tree-option__toggle-icon" />
      </div>
      <!-- Renders subgroup -->
      <ul class="tree-option__subgroup-wrapper" :aria-label="`${$t(`${target.title}`)} navigation`">
        <NavigationTree
          v-for="(subItem, subItemIndex) in target.items"
          :key="subItemIndex"
          :target="subItem"
          :depth="depth + 1"
          :parent-title="target.title"
        />
      </ul>
    </template>

    <!-- Renders link -->
    <template v-else>
      <router-link
        :to="{ name: target.name }"
        class="tree-option__link"
        :aria-label="`${target.meta.title} navigation`"
      >
        {{ target.meta.title }}
      </router-link>
    </template>
  </li>
</template>

<script>
import { HdIcon } from 'homeday-blocks';
import { smallArrow } from 'homeday-assets';

export default {
  name: 'NavigationTree',
  components: {
    HdIcon,
  },
  props: {
    target: {
      type: Object,
      required: true,
    },
    depth: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    parentTitle: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return { showChildren: false, smallArrow };
  },
  computed: {
    customClasses() {
      return [
        `tree-option--depth-${this.depth}`,
        {
          'tree-option--active': this.showChildren,
        },
      ];
    },
    isRootCategory() {
      return this.depth === 0;
    },
  },
  watch: {
    $route(to) {
      this.openOptionIfNavigationOccurs(to);
    },
  },
  mounted() {
    this.showChildren = this.isActive;
    this.$root.$on('toggleOption', this.hideOptionIfNotSelected);
    this.openOptionIfNavigationOccurs(this.$route);
  },
  beforeDestroy() {
    this.$root.$off('toggleOption', this.hideOptionIfNotSelected);
  },
  methods: {
    toggleChildren() {
      this.showChildren = !this.showChildren;
      this.$root.$emit('toggleOption', {
        selectedTitle: this.target.title,
        selectedParentTitle: this.parentTitle,
      });
    },
    hideOptionIfNotSelected({ selectedTitle, selectedParentTitle }) {
      // Early return to improve perf - no need to close if it already is or if is the parent of the selected one
      if (!this.showChildren || this.target.title === selectedParentTitle) return;
      if (this.target.title !== selectedTitle) {
        this.showChildren = false;
      }
    },
    openOptionIfNavigationOccurs(to) {
      this.showChildren = this.target.items?.some((option) => {
        if (option.items) {
          return option.items.some((subOption) => subOption.name === to.name);
        }
        return option.name === to.name;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

$transition-timing: 0.3s;

.tree-option {
  $root: &;

  &__collapsable {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: $primary-color;

      ::v-deep #{$root}__category-icon path {
        fill: $primary-color;
      }
    }
    #{$root}--active > & {
      color: $primary-color;

      & #{$root}__category-icon ::v-deep path {
        fill: $primary-color;
      }
    }
  }

  &__category-icon {
    width: 27px;
    height: 27px;
    margin-right: $sp-m;
  }

  &__link {
    @include font('DS-100');
    color: $immowelt-secondary-500;
    font-weight: 400;
    min-height: 22px;
    text-decoration: none;
    max-width: 170px;

    &:hover,
    &.router-link-active {
      color: $primary-color;
    }
  }

  &__subgroup-wrapper {
    max-height: 0;
    transition: max-height 0.5s, padding-bottom 0.3s;
    overflow: hidden;

    li {
      margin-top: $sp-m;
    }

    #{$root}--active > & {
      transition: max-height 0.5s, padding-bottom 0.3s;
      max-height: 999px;
    }
  }

  &--depth-0 {
    margin-bottom: $sp-m;

    #{$root}__subgroup-wrapper {
      padding-left: $sp-l + $sp-xs;
    }
    #{$root}__collapsable {
      @include font('DS-200');
      font-weight: 700;
    }
  }
  &--depth-1 {
    #{$root}__subgroup-wrapper {
      padding-left: $sp-s;
    }
    #{$root}__collapsable {
      @include font('DS-100');
      font-weight: 600;

      span {
        max-width: 148px;
      }
    }
    #{$root}__link {
      @include font('DS-100');
      font-weight: 600;
    }
  }
  &--depth-2 {
    #{$root}__link {
      @include font('DS-100');
      font-weight: 400;
    }
  }

  &__toggle-icon {
    margin-left: $sp-xs;
    transition: transform $transition-timing;
    transform: rotate(90deg);

    #{$root}--active > * > & {
      transform: rotate(-90deg);
      transition: transform $transition-timing;
    }
  }
}
</style>
