<template>
  <div id="app">
    <service-worker-notifications />
    <transition name="fade">
      <div v-if="loading" id="loading-overlay">
        <HdLoadingSpinner />
      </div>
    </transition>

    <router-view />
  </div>
</template>

<script>
import HdLoadingSpinner from '@/components/HdLoadingSpinner.vue';
import ServiceWorkerNotifications from '@/components/RootNotifications.vue';
import { load as loadGtm } from '@/services/gtm';
import { MUTATION } from '@/store/CONSTANTS';

export default {
  name: 'App',
  components: {
    HdLoadingSpinner,
    ServiceWorkerNotifications,
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  created() {
    window.addEventListener('offline', () => {
      this.$store.commit(MUTATION.SET_OFFLINE, true);
    });

    window.addEventListener('online', () => {
      this.$store.commit(MUTATION.SET_OFFLINE, false);
    });

    loadGtm();
  },
};
</script>

<style lang="scss" src="@/styles/main.scss"></style>

<style lang="scss" scoped>
#loading-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 50;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
