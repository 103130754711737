<template>
  <HdSelect
    :value="value[index]"
    :name="`${name}-${index}`"
    :label="label"
    :options="computedOptions"
    @input="inputHandler"
  />
</template>

<script>
import { HdSelect } from 'homeday-blocks';

export default {
  name: 'BatchSelect',
  components: { HdSelect },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array, // [{ label, value }]
      required: true,
    },
    value: {
      type: Array,
      default: null,
    },
    noRepetition: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    index: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    computedOptions() {
      if (!this.noRepetition) {
        return this.options;
      }

      return this.options.filter((option) => {
        return option.value === this.value[this.index] ? true : !this.value.includes(option.value);
      });
    },
  },
  methods: {
    inputHandler(newValue) {
      const selected = this.options.reduce((array, _, currentIndex) => {
        const value = currentIndex === Number(this.index) ? newValue : this.value[currentIndex];

        if (!value) {
          return array; // Remove falsy values
        }

        array.push(value);
        return array;
      }, []);

      this.$emit('change', selected); // Array of selected values: [x, y]
      this.$emit('beforeChange', this.value[this.index]);
    },
  },
};
</script>
