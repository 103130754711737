<template>
  <div class="evaluation">
    <header class="evaluation_header">
      <div class="evaluation_header__container">
        <h2 class="evaluation_header__title">{{ headerPresentationTitle }}</h2>
        <p
          class="evaluation_header__text_evaluation_report"
          v-html="$t('EVALUATION_PRESENTATION.REALTOR_INTRODUCTION')"
        ></p>
        <ul class="evaluation_header__contact">
          <li v-if="realtor.phone">
            <p class="evaluation_header__contact_label">{{ $t('EVALUATION_REPORT.PHONE') }}</p>
            <address>{{ realtor.phone }}</address>
          </li>
          <li v-if="realtor.email">
            <p class="evaluation_header__contact_label">{{ $t('EVALUATION_REPORT.EMAIL') }}</p>
            <address>{{ realtor.email }}</address>
          </li>
        </ul>
        <div
          class="evaluation_header__image-wrapper"
          :class="{ 'evaluation_header__image-wrapper--avatar': !realtor.contact_photo }"
        >
          <div
            :style="`background-image:url('${realtorPictureDataUrl}')`"
            class="evaluation_header__image"
            role="img"
            cross-origin="anonymous"
          />
        </div>
      </div>
    </header>
    <main class="evaluation__main">
      <section class="property-info">
        <div class="property-info__details">
          <div class="property-info__property_type_wrapper">
            <HdIcon
              width="40px"
              height="40px"
              :src="icon.houseSingleFamily"
              :class-from-fill="defaultIconColorsCustomHouse"
            />
            <p class="property-info__type">{{ propertyTypeText }}</p>
          </div>
        </div>
        <p class="property-info__address">{{ property.address }}</p>
        <ul class="property-info__list">
          <li v-for="characteristic in propertyCharacteristicsSummary" :key="characteristic">
            {{ characteristic }}
          </li>
        </ul>
      </section>
      <section class="marketing">
        <div class="marketing__price">
          <div class="valuation__icon_background">
            <HdIcon
              :src="icon.handshake"
              width="90px"
              height="90px"
              :class-from-fill="defaultIconColors"
            />
          </div>
          <div class="valuation__price_block">
            <h2 class="valuation__title">
              {{ $t('EVALUATION_PRESENTATION.DETAILED_RESULTS.PRICE.TITLE') }}
            </h2>
            <h3 class="valuation__price">
              {{ marketingPrice }}
            </h3>
            <p>
              {{ $t('EVALUATION_PRESENTATION.DETAILED_RESULTS.PRICE.CTA.HEADLINE') }}
            </p>
          </div>
        </div>
        <div class="marketing__description">
          <h2 class="description__title">
            {{ $t('EVALUATION_PRESENTATION.DETAILED_RESULTS.PRICE.DESCRIPTION.TITLE') }}
          </h2>
          <div v-if="!setExpanderLines">
            <span class="description__text" v-html="descriptionAccordingToRealtorGender" />
            <p class="description__text" v-html="priceReductionDisclaimer" />
          </div>
          <HdExpandText
            v-else
            :lines="setExpanderLines"
            :margin-top="0"
            :margin-bottom="0"
            lang="de"
            :approximate-line-height="32"
            :lines-fallback="10"
          >
            <span
              v-if="realtor.salutation === 'male'"
              class="description__text"
              v-html="descriptionAccordingToRealtorGender"
            />
            <p class="description__text" v-html="priceReductionDisclaimer" />
          </HdExpandText>
        </div>
        <div class="marketing__based-on">
          <div class="valuation__icon_background">
            <HdIcon
              :src="icon.handshake"
              width="52px"
              height="52px"
              :class-from-fill="defaultIconColors"
            />
          </div>
          <div class="valuation__price_block">
            <h2 class="valuation__title">
              {{ $t('EVALUATION_PRESENTATION.DETAILED_RESULTS.PRICE.TITLE') }}
            </h2>
            <h3 class="valuation__price">
              {{ marketingPrice }}
            </h3>
            <p>{{ $t('EVALUATION_PRESENTATION.DETAILED_RESULTS.PRICE.DESCRIPTION.SUBTITLE') }}</p>
          </div>
          <div class="valuation__features">
            <div class="feature__propertyAttributes">
              <HdIcon
                :src="icon.locationQuality"
                width="40px"
                height="40px"
                :class-from-fill="defaultIconColors"
              />
              <p>{{ $t('EVALUATION_PRESENTATION.DETAILED_RESULTS.PRICE.FEATURES.FEATURE_1') }}</p>
            </div>
            <div class="feature__forInvestors">
              <HdIcon
                :src="icon.houseEuro"
                width="40px"
                height="40px"
                :class-from-fill="defaultIconColors"
              />
              <p>{{ $t('EVALUATION_PRESENTATION.DETAILED_RESULTS.PRICE.FEATURES.FEATURE_2') }}</p>
            </div>
            <div class="feature__comparingOffers">
              <HdIcon
                :src="icon.propertyMarketing"
                width="40px"
                height="40px"
                :class-from-fill="defaultIconColors"
              />
              <p>{{ $t('EVALUATION_PRESENTATION.DETAILED_RESULTS.PRICE.FEATURES.FEATURE_3') }}</p>
            </div>
          </div>
        </div>
      </section>
      <section class="price-details">
        <div class="price-details_wrapper">
          <HdIcon :src="icon.locationQuality" :class-from-fill="defaultIconColors" />
          <div class="price-details_wrapper_inner">
            <header class="price-details__header">
              <h3 class="header-title">{{ $t('EVALUATION_PRESENTATION.DETAILS.TITLE') }}</h3>
              <p class="header-description">
                {{ $t('EVALUATION_PRESENTATION.DETAILS.DESCRIPTION') }}
              </p>
            </header>
            <div
              class="price-details__information"
              :class="{
                multiFamilyHouse: isMultiFamilyHouse,
              }"
            >
              <div
                v-for="{ name, title, table, description } in informationTableConfiguration"
                :key="name"
              >
                <h3 class="information-title">
                  {{ title }}
                </h3>
                <p class="information-description">{{ description }}</p>
                <table :class="['information__table', name]">
                  <tr v-for="({ name: cName, value, effect }, index) in table" :key="index">
                    <td>{{ cName }}</td>
                    <td>{{ value }}</td>
                    <td v-html="effect"></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div v-if="priceInfo.rentMultiplier" class="price-details_wrapper">
          <HdIcon :src="icon.houseEuro" :class-from-fill="defaultIconColors" />
          <div class="price-details_wrapper_inner">
            <div class="price-details__information investors-table">
              <h3 class="investor-title">{{ $t('EVALUATION_PRESENTATION.INVESTORS.TITLE') }}</h3>
              <p class="investor-description">
                {{ $t('EVALUATION_PRESENTATION.INVESTORS.DESCRIPTION') }}
              </p>
              <div v-for="{ name, title, table } in investorsTableConfiguration" :key="name">
                <p class="investors__table-title">
                  {{ title }}
                </p>
                <table :class="['information__table', name]">
                  <tr v-for="{ name: cName, value } in table" :key="cName">
                    <td>{{ cName }}</td>
                    <td>{{ value }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="evaluation-cta">
        <div class="evaluation-cta__icon">
          <HdIcon :src="icon.handshake" :class-from-fill="defaultIconColors" />
        </div>
        <h3 class="evaluation-cta__heading">Mit Ihnen vereinbarter Angebotspreis</h3>
        <p class="evaluation-cta__price">{{ marketingPrice }}</p>
      </section>
    </main>
    <footer class="evaluation_footer">
      <section
        v-if="realtorPictureDataUrl"
        class="footer__photo"
        :class="{ 'footer__photo--avatar': !realtor.contact_photo }"
      >
        <div
          :style="`background-image:url('${realtorPictureDataUrl}')`"
          class="footer__photo__img"
          role="img"
          cross-origin="anonymous"
        />
      </section>
      <section class="footer__contact">
        <div v-if="realtor.full_name" class="footer__contact__item">
          <div class="footer__contact__item__info">
            <p class="footer__contact__item__info__line footer__contact__item__info__line--name">
              {{ realtor.full_name }}
            </p>
            <p class="footer__contact__item__info__line footer__contact__item__info__line--role">
              {{ realtor.role }}
            </p>
          </div>
        </div>

        <hd-link
          v-if="realtor.phone"
          :href="`tel:${realtor.phone}`"
          modifier="secondary"
          class="footer__contact__item"
        >
          <div class="footer__contact__item__icon">
            <hd-icon
              class="footer__contact__item__icon__img"
              :src="icon.phoneCall"
              width="48px"
              height="48px"
              :class-from-fill="defaultIconColors"
            />
          </div>
          <div class="footer__contact__item__info">
            <p class="footer__contact__item__info__line footer__contact__item__info__line--small">
              {{ $t('EVALUATION_REPORT.FOOTER.PHONE') }}
            </p>
            <p class="footer__contact__item__info__line">
              {{ realtor.phone }}
            </p>
          </div>
        </hd-link>

        <div class="footer__contact__separator" />

        <hd-link
          v-if="realtor.email"
          :href="`mailto:${realtor.email}`"
          modifier="secondary"
          class="footer__contact__item"
        >
          <div class="footer__contact__item__icon">
            <hd-icon
              class="footer__contact__item__icon__img footer__contact__item__icon__img--mail"
              :src="icon.envelopeMailLetter"
              width="48px"
              height="48px"
              :class-from-fill="defaultIconColors"
            />
          </div>
          <div class="footer__contact__item__info">
            <p class="footer__contact__item__info__line footer__contact__item__info__line--small">
              {{ $t('EVALUATION_REPORT.FOOTER.EMAIL') }}
            </p>
            <p class="footer__contact__item__info__line">{{ realtor.email }}</p>
          </div>
        </hd-link>
      </section>
    </footer>
  </div>
</template>

<script>
// Icons
import { houseSingleFamily } from 'homeday-assets/M';
import {
  handshake,
  locationQuality,
  houseEuro,
  propertyMarketing,
  phoneCall,
  envelopeMailLetter,
} from 'homeday-assets/L';

// Services
import html2canvas from 'html2canvas';
import { jsPDF as JsPDF } from 'jspdf';
import { get as _get } from 'lodash-es';
import { getRealtorPhoto } from '@/services/content';
import { isDesktop, isTablet } from '@/services/breakpoints';
import {
  CHARACTERISTICS_EVALUATION_CONFIG,
  CHARACTERISTIC_VALUE_FORMATS,
} from '@/config/evaluation';

// Components
import { HdIcon, HdExpandText, HdLink } from 'homeday-blocks';

// Constants
import { GETTER, MUTATION } from '@/store/CONSTANTS';
import { SVG_DEFAULT_COLORS } from '@/config/CONSTANTS';
import { REQUEST_STATUS } from '@/config/CONSTANTS';
import { ACTION } from '../store/CONSTANTS';

export default {
  name: 'EvaluationReport',
  components: { HdIcon, HdExpandText, HdLink },
  data() {
    return {
      currentURL: window.location.href,
      currentPageTitle: document.title,
      realtorPictureDataUrl: '',
      icon: {
        houseSingleFamily,
        handshake,
        locationQuality,
        houseEuro,
        propertyMarketing,
        phoneCall,
        envelopeMailLetter,
      },
    };
  },
  computed: {
    currentDeal() {
      return this.$store.state.wizard.currentDeal;
    },
    dealState() {
      return this.$store.state.wizard.deals.find((deal) => deal.uid === this.currentDeal.uid);
    },
    evaluation() {
      return this.dealState.lastEvaluation;
    },
    realtor() {
      const userData = this.$store.state.user.data;
      return {
        ...userData,
        full_name: this.$store.getters[GETTER.USER.FULL_NAME],
        formal_name: this.$store.getters[GETTER.USER.FORMAL_NAME],
        role: this.$t(`GENERAL.CONSULTANT_GENDER_${userData.salutation.toUpperCase()}`),
      };
    },
    seller() {
      const details = this.currentDeal.primaryContact;
      const formalName = `${this.$t(`GENERAL.SALUTATION_${details.salutation.toUpperCase()}`)} ${
        details.first_name
      } ${details.last_name}`;
      return { ...details, formal_name: formalName };
    },
    property() {
      const { livingSpace, constructionYear, numberOfRooms, rentStatus, plotArea } =
        this.evaluation.features;

      return {
        address: this.currentDeal.address.formatted_address,
        propertyType: this.currentDeal.propertyType,
        buildingType: this.currentDeal.buildingType,
        livingSpace,
        constructionYear,
        numberOfRooms,
        rentStatus,
        plotArea,
      };
    },
    priceFactor() {
      const marketingPrice = this.$store.getters[GETTER.WIZARD.CURRENT_DEAL.MARKETING_PRICE];
      const recommendedStartingPrice = _get(this.evaluation, 'recommendedStartingPrice');

      if (recommendedStartingPrice && marketingPrice) {
        const factorCalculation = recommendedStartingPrice / marketingPrice;
        if (factorCalculation > 1) {
          return Math.ceil(factorCalculation * 10) / 10;
        }
        return Math.round(factorCalculation * 10) / 10;
      }

      return 1;
    },
    headerPresentationTitle() {
      return this.$t('EVALUATION_PRESENTATION.PRELIMINARY_RESULTS.HEADER.TITLE', {
        sellerName: this.seller?.first_name
          ? this.seller.formal_name
          : this.$t('EVALUATION_PRESENTATION.PRELIMINARY_RESULTS.HEADER.TITLE_FALLBACK'),
      });
    },
    realtorPhoto() {
      return getRealtorPhoto(this.realtor, { preset: 'BROKER_ORDER_REALTOR' });
    },
    propertyTypeText() {
      if (!this.property.propertyType) {
        return this.$t('PROPERTY_TYPES.FALLBACK');
      }

      if (this.property.propertyType === 'apartment' && this.property.buildingType) {
        return this.$t(`PROPERTY_TYPES.${this.property.buildingType?.toUpperCase()}`);
      }

      return this.$t(`PROPERTY_TYPES.${this.property.propertyType?.toUpperCase()}`);
    },
    propertyCharacteristicsSummary() {
      const characteristics = [];

      if (this.property?.livingSpace)
        characteristics.push(
          `${this.$t('GENERAL.LIVING_SPACE')}: ${this.$formatSpace(this.property.livingSpace)}`,
        );

      if (this.property?.plotArea)
        characteristics.push(
          `${this.$t('GENERAL.PLOT_AREA')}: ${this.$formatSpace(this.property.plotArea)}`,
        );

      if (this.property?.constructionYear)
        characteristics.push(
          `${this.$t('GENERAL.CONSTRUCTION_YEAR')}: ${this.property.constructionYear}`,
        );

      if (this.property?.numberOfRooms)
        characteristics.push(`${this.$t('GENERAL.NUMBER_ROOMS')}: ${this.property.numberOfRooms}`);

      if (this.property?.rentStatus)
        characteristics.push(
          this.$t(`WIZARD.RENT_STATUS.${this.property.rentStatus.toUpperCase()}`),
        );

      return characteristics;
    },
    setExpanderLines() {
      if (isTablet()) {
        return 3;
      }
      if (!isTablet() && !isDesktop()) {
        return 7;
      }
      return false;
    },
    descriptionAccordingToRealtorGender() {
      if (this.realtor.salutation === 'male') {
        return this.$t(
          'EVALUATION_PRESENTATION.DETAILED_RESULTS.PRICE.DESCRIPTION.DESCRIPTION.MALE',
        );
      }
      return this.$t(
        'EVALUATION_PRESENTATION.DETAILED_RESULTS.PRICE.DESCRIPTION.DESCRIPTION.FEMALE',
      );
    },
    priceReductionDisclaimer() {
      if (this.priceFactor > 1) {
        return this.$t('EVALUATION_PRESENTATION.DETAILED_RESULTS.PRICE_REDUCTION.HIGH_DISCLAIMER');
      }
      return this.$t('EVALUATION_PRESENTATION.DETAILED_RESULTS.PRICE_REDUCTION.LOW_DISCLAIMER');
    },
    // Price Info
    priceInfo() {
      const sqmPrice =
        _get(this.evaluation, 'sqmPrice') || _get(this.currentDeal, 'suggestedSqmPrice', 0);
      const livingSpace = _get(this.evaluation, 'features.livingSpace', 0) || null;
      const quality = _get(this.evaluation, 'features.quality', 0);
      const avgPrice = sqmPrice * livingSpace || null;
      const commercialSpace = _get(this.evaluation, 'features.commercialSpace', 0);
      const ownerShare = _get(this.evaluation, 'features.ownerShare', 0);
      const rentMultiplier = _get(this.evaluation, 'features.rentMultiplier', 0);
      const baseRentPerSqm = _get(this.evaluation, 'features.baseRentPerSqm', 0);
      const commercialBaseRentPerSqm = _get(
        this.evaluation,
        'features.commercialBaseRentPerSqm',
        0,
      );
      const potentialBaseRentPerSqm = _get(this.evaluation, 'features.potentialBaseRentPerSqm', 0);
      const potentialCommercialBaseRentPerSqm = _get(
        this.evaluation,
        'features.potentialCommercialBaseRentPerSqm',
        0,
      );

      return {
        sqmPrice,
        livingSpace,
        quality,
        avgPrice,
        commercialSpace,
        ownerShare,
        rentMultiplier,
        baseRentPerSqm,
        commercialBaseRentPerSqm,
        potentialBaseRentPerSqm,
        potentialCommercialBaseRentPerSqm,
      };
    },
    isMultiFamilyHouse() {
      return _get(this.evaluation, 'features.buildingType') === 'multi_family_house';
    },
    additionalFeaturesList() {
      return _get(this.evaluation, 'custom_features').map(({ name, price }) => ({
        name,
        value: '',
        effect: this.formatPotentialValue(price),
      }));
    },
    coOwnershipField() {
      return {
        name: this.$t('EVALUATION_REPORT.CO_OWNERSHIP'),
        value: `${this.priceInfo.ownerShare * 1000}/1000`,
        effect: this.priceContributions.ownerShare
          ? this.formatPotentialValue(this.priceContributions.ownerShare)
          : this.formatPotentialValue(0),
      };
    },
    informationTableConfiguration() {
      return [
        {
          name: 'location-table',
          title: this.$t('EVALUATION_PRESENTATION.PRELIMINARY_RESULTS.PRICE_DETAILS.TOPIC1'),
          description: this.$t('EVALUATION_PRESENTATION.LOCATION_DESCRIPTION'),
          table: this.locationCharacteristics,
        },
        {
          name: 'property-table',
          title: this.$t('EVALUATION_PRESENTATION.PRELIMINARY_RESULTS.PRICE_DETAILS.TOPIC2'),
          description: this.$t('EVALUATION_PRESENTATION.PROPERTY_DESCRIPTION'),
          table: this.propertyCharacteristics,
        },
        {
          name: 'renovation-table',
          title: this.$t('EVALUATION_PRESENTATION.PRELIMINARY_RESULTS.PRICE_DETAILS.TOPIC3'),
          description: this.$t('EVALUATION_PRESENTATION.RENOVATION_DESCRIPTION'),
          table: [
            this.coOwnershipField,
            ...this.generateTable(CHARACTERISTICS_EVALUATION_CONFIG.RENOVATIONS),
          ],
        },
        {
          name: 'additional-information',
          title: this.$t('EVALUATION_PRESENTATION.PRELIMINARY_RESULTS.PRICE_DETAILS.TOPIC4'),
          description: this.$t('EVALUATION_PRESENTATION.ADDITIONAL_INFO_DESCRIPTION'),
          table: [
            ...this.generateTable(CHARACTERISTICS_EVALUATION_CONFIG.ADDITIONAL_INFORMATION),
            ...this.additionalFeaturesList,
          ],
        },
      ];
    },
    investorsTableConfiguration() {
      const investorTables = [
        {
          name: 'investor-multiplicator',
          table: this.investorFields.RENT_MULTIPLICATOR,
        },
      ];

      if (this.priceInfo.baseRentPerSqm) {
        investorTables.push({
          name: 'investor-earnings',
          title: this.$t('EVALUATION_PRESENTATION.INVESTORS.EARNINGS_VALUE'),
          table: this.investorFields.EARNINGS,
        });
      }

      if (this.priceInfo.potentialBaseRentPerSqm) {
        investorTables.push({
          name: 'investor-potential-earnings',
          title: this.$t('EVALUATION_PRESENTATION.INVESTORS.POTENTIAL_EARNINGS'),
          table: this.investorFields.POTENTIAL_EARNINGS,
        });
      }

      return investorTables;
    },
    locationCharacteristics() {
      const locationCharacteristics = [
        {
          name: this.$t('GENERAL.LIVING_SPACE'),
          value: this.$formatSpace(this.priceInfo.livingSpace),
          effect: '',
        },
      ];

      if (this.isMultiFamilyHouse) {
        return [
          {
            name: this.$t('LISTING.COMMERCIAL_SPACE'),
            value: this.$formatSpace(this.priceInfo.commercialSpace),
            effect: '',
          },
          ...locationCharacteristics,
        ];
      }

      return [
        ...locationCharacteristics,
        {
          name: this.$t('EVALUATION_REPORT.AREA'),
          value: this.priceInfo.quality
            ? this.$t('EVALUATION_REPORT.QUALITIES')[this.priceInfo.quality - 1]
            : '-',
          effect: '',
        },
        {
          name: this.$t('EVALUATION_REPORT.AVERAGE_OFFER_PRICE'),
          value: this.$formatAverageSqmPrice(this.priceInfo.sqmPrice),
          effect: this.$formatPrice(this.priceInfo.avgPrice),
        },
      ];
    },
    propertyCharacteristics() {
      const CONFIG_LIST = CHARACTERISTICS_EVALUATION_CONFIG.FEATURES;

      return CONFIG_LIST.reduce((list, propertyItem) => {
        if (
          !this.evaluation.features[propertyItem.key] &&
          this.evaluation.features[propertyItem.key] !== false
        ) {
          return list;
        }

        if (propertyItem.details && this.evaluation.features[propertyItem.key]) {
          return [...list, ...this.generateTable(propertyItem.details)];
        }

        return [
          ...list,
          {
            name: this.$t(propertyItem.titleKey),
            value: this.formatValue(
              this.evaluation.features[propertyItem.key],
              propertyItem.valueType,
            ),
            effect:
              this.priceContributions[propertyItem.key] != null
                ? this.formatPotentialValue(this.priceContributions[propertyItem.key])
                : this.formatPotentialValue(0),
          },
        ];
      }, []);
    },
    investorFields() {
      return {
        RENT_MULTIPLICATOR: [
          {
            name: this.$t('EVALUATION_PRESENTATION.INVESTORS.RENT_MULTIPLICATOR'),
            value: this.priceInfo.rentMultiplier,
          },
        ],
        EARNINGS: [
          {
            name: this.$t('EVALUATION_PRESENTATION.INVESTORS.COLD_RENT_PER_MONTH'),
            value: this.$formatPrice(
              this.getMonthlyRent({
                baseRentPerSqm: this.priceInfo.baseRentPerSqm,
                commercialBaseRentPerSqm: this.priceInfo.commercialBaseRentPerSqm,
              }),
            ),
          },
          {
            name: this.$t('EVALUATION_PRESENTATION.INVESTORS.COLD_RENT_PER_YEAR'),
            value: this.$formatPrice(
              this.getYearlyRent({
                baseRentPerSqm: this.priceInfo.baseRentPerSqm,
                commercialBaseRentPerSqm: this.priceInfo.commercialBaseRentPerSqm,
              }),
            ),
          },
          {
            name: this.$t('EVALUATION_PRESENTATION.INVESTORS.EARNINGS_VALUE'),
            value: this.$formatPrice(
              this.getEarnings({
                baseRentPerSqm: this.priceInfo.baseRentPerSqm,
                commercialBaseRentPerSqm: this.priceInfo.commercialBaseRentPerSqm,
              }),
            ),
          },
        ],
        POTENTIAL_EARNINGS: [
          {
            name: this.$t('EVALUATION_PRESENTATION.INVESTORS.ACHIEVABLE_COLD_RENT_PER_MONTH'),
            value: this.$formatPrice(
              this.getMonthlyRent({
                baseRentPerSqm: this.priceInfo.potentialBaseRentPerSqm,
              }),
            ),
          },
          {
            name: this.$t('EVALUATION_PRESENTATION.INVESTORS.ACHIEVABLE_COLD_RENT_PER_YEAR'),
            value: this.$formatPrice(
              this.getYearlyRent({
                baseRentPerSqm: this.priceInfo.potentialBaseRentPerSqm,
                commercialBaseRentPerSqm: this.priceInfo.potentialCommercialBaseRentPerSqm,
              }),
            ),
          },
          {
            name: this.$t('EVALUATION_PRESENTATION.INVESTORS.POTENTIAL_EARNINGS'),
            value: this.$formatPrice(
              this.getEarnings({
                baseRentPerSqm: this.priceInfo.potentialBaseRentPerSqm,
                commercialBaseRentPerSqm: this.priceInfo.potentialCommercialBaseRentPerSqm,
              }),
            ),
          },
        ],
      };
    },
    priceContributions() {
      const { price_contributions: priceContributions, features } = this.evaluation;

      // we look for features that are appearing more than once and dynamically
      // adding them to the price contribution list with the value
      // enforced as a parseFloat
      const additionalPriceContributions = {};
      const matchingNames = this.findMatchingProperties(features);

      matchingNames.forEach((name) => {
        additionalPriceContributions[name] = parseFloat(features[name]);
      });

      return {
        apartmentType: priceContributions.accessibility,
        buildingType: priceContributions.buildingType,
        constructionYear: priceContributions.construction,
        lastRenovationYear: priceContributions.generalRenovation,
        lastFacadeRenovationYear: priceContributions.facadeRenovation,
        lastHeatingRenovationYear: priceContributions.heating,
        lastRoofRenovationYear: priceContributions.roofRenovation,
        lastWindowRenovationYear: priceContributions.windowRenovation,
        hasBalcony: parseFloat(features.balconyValue),
        hasTerrace: parseFloat(features.terraceValue),
        hasLoggia: parseFloat(features.loggiaValue),
        hasWinterGarden: parseFloat(features.winterGardenValue),
        hasGarden: priceContributions.garden,
        hasParkingSpace: priceContributions.parking,
        numberOfRooms: priceContributions.rooms,
        numberOfBasementRooms: priceContributions.basement,
        hasCellar: parseFloat(features.hasCellar),
        hasBasementCompartment: parseFloat(features.hasBasementCompartment),
        hasPartialBasement: parseFloat(features.hasPartialBasement),
        interiorBathroomPrice: parseFloat(features.interiorBathroomPrice),
        separateGuestWcPrice: parseFloat(features.separateGuestWcPrice),
        bathroomWithWindowPrice: parseFloat(features.bathroomWithWindowPrice),
        ownerShare: priceContributions.ownerShare,
        plotArea: priceContributions.plot,
        rentStatus: priceContributions.availability,
        gasCentralHeatingPrice: parseFloat(features.gasCentralHeatingPrice),
        gasFloorHeatingPrice: parseFloat(features.gasFloorHeatingPrice),
        oilHeatingPrice: parseFloat(features.oilHeatingPrice),
        districtHeatingPrice: parseFloat(features.districtHeatingPrice),
        pelletHeatingPrice: parseFloat(features.pelletHeatingPrice),
        electricHeatingPrice: parseFloat(features.electricHeatingPrice),
        cogenerationUnitPrice: parseFloat(features.cogenerationUnitPrice),
        solarThermalHeatingPrice: parseFloat(features.solarThermalHeatingPrice),
        heatPumpPrice: parseFloat(features.heatPumpPrice),
        ...additionalPriceContributions,
      };
    },
    marketingPrice() {
      const marketingPrice = this.$store.getters[GETTER.WIZARD.CURRENT_DEAL.MARKETING_PRICE];

      return this.$formatPrice(marketingPrice);
    },
    defaultIconColors() {
      return SVG_DEFAULT_COLORS;
    },
    defaultIconColorsCustomHouse() {
      return {
        '#8FC1FF': 'icon-primary-color',
        '#0F1D2E': 'icon-secondary-color',
      };
    },
  },
  mounted() {
    window.addEventListener('beforeprint', this.beforePrintHandler);
    window.addEventListener('afterprint', this.afterPrintHandler);

    this.loadRealtorImages();
  },
  beforeDestroy() {
    window.removeEventListener('beforeprint', this.beforePrintHandler);
    window.removeEventListener('afterprint', this.afterPrintHandler);
  },
  methods: {
    loadRealtorImages() {
      this.imageToBase64(this.realtorPhoto, (dataUrl) => {
        this.realtorPictureDataUrl = dataUrl;

        if (this.currentDeal.updateEvaluationReport) {
          setTimeout(this.printPDF, 100);
        }
      });
    },
    async imageToBase64(url, callback) {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    },
    async updatePdfReport(pdfBlob) {
      try {
        // Add progress message
        this.$store.commit(MUTATION.USER.SET_ACTION_STATUS, {
          message: this.$t('WIZARD.DEAL_NOTIFICATIONS.EMAIL_IN_PROGRESS.MESSAGE'),
          type: REQUEST_STATUS.INFO,
        });

        await this.$store.dispatch(ACTION.WIZARD.UPDATE_EVALUATION_REPORT, {
          dealUid: this.currentDeal.uid,
          email: this.currentDeal.primaryContact.email,
          phone: this.currentDeal.primaryContact.phone,
          pdf: pdfBlob,
        });

        // Add success message
        this.$store.commit(MUTATION.USER.SET_ACTION_STATUS, {
          message: this.$t('WIZARD.DEAL_NOTIFICATIONS.EMAIL_SUBMITTED_SUCCESS.MESSAGE'),
          type: REQUEST_STATUS.SUCCESS,
        });
      } catch (error) {
        this.$store.commit(MUTATION.USER.SET_ACTION_STATUS, {
          message: this.$t('WIZARD.DEAL_NOTIFICATIONS.FAILED_UPDATE_REPORT.MESSAGE'),
          type: REQUEST_STATUS.ERROR,
        });
      } finally {
        this.$store.commit(MUTATION.WIZARD.CURRENT_DEAL.SET_FEATURE_VALUE, {
          name: 'updateEvaluationReport',
          value: false,
        });
        this.$store.commit(MUTATION.WIZARD.CURRENT_DEAL.SET_FEATURE_VALUE, {
          name: 'isEvaluationSubmitted',
          value: true,
        });
      }
    },
    printPDF() {
      const element = document.getElementsByClassName('evaluation')[0];

      html2canvas(element, {
        scale: 1,
        useCORS: true,
      }).then(async (canvas) => {
        const imgData = canvas.toDataURL('image/png');

        // Calculate the ratio to fit the element in the PDF
        const pdfWidth = 210; // A4 width in mm
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        // Create a new jsPDF instance and add the image
        const pdf = new JsPDF({
          orientation: 'portrait',
          unit: 'mm',
          format: [pdfWidth, pdfHeight],
        });

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        const pdfBlob = await pdf.output('blob');
        // Enable only if we want to force download
        // pdf.save(`evaluation_${this.currentDeal.uid}.pdf`);
        this.updatePdfReport(pdfBlob);
      });
    },
    beforePrintHandler() {
      // Todo: I am testing this part of the code.
      window.history.replaceState(window.history.state, '', '/');
      document.title = 'immowelt | Detaillierten Bewertung Ihrer Immobilie';
    },
    afterPrintHandler() {
      window.history.replaceState(window.history.state, '', this.currentURL);
      document.title = this.currentPageTitle;
    },
    // Price Info section
    /**
     * We look for features that could appear multiple times based on a list
     * of hardcoded names. If the featureKey matches a name from the list and
     * has a number it will be stored in the matching properties array.
     * @param features
     * @returns {*[]}
     */
    findMatchingProperties(features) {
      const potentialMultipleProperties = [
        'interiorBathroomPrice',
        'separateGuestWcPrice',
        'bathroomWithWindowPrice',
      ];

      const matchingProperties = [];

      Object.keys(features).forEach((featureKey) => {
        potentialMultipleProperties.forEach((property) => {
          if (featureKey.startsWith(property) && /\d$/.test(featureKey)) {
            matchingProperties.push(featureKey);
          }
        });
      });

      return matchingProperties;
    },
    formatValue(value, type) {
      const formattedValue = CHARACTERISTIC_VALUE_FORMATS[type](value);
      if (type === 'STRING' || type === 'BOOLEAN') return this.$t(formattedValue);

      return formattedValue;
    },
    formatPotentialValue(potentialValue) {
      const isPositiveNumber = Math.sign(potentialValue) === 1;
      const isNegativeNumber = Math.sign(potentialValue) === -1;

      if (isPositiveNumber) {
        return `<span>+ ${this.$formatPrice(potentialValue)}</span><span class="positive">↑</span>`;
      }
      if (isNegativeNumber) {
        return `<span>- ${this.$formatPrice(
          Math.abs(potentialValue),
        )}</span><span class="negative">↓</span>`;
      }

      return this.$formatPrice(potentialValue);
    },
    getMonthlyRent({ baseRentPerSqm = 0, commercialBaseRentPerSqm = 0 }) {
      return (
        this.priceInfo.livingSpace * baseRentPerSqm +
        this.priceInfo.commercialSpace * commercialBaseRentPerSqm
      );
    },
    getYearlyRent({ baseRentPerSqm, commercialBaseRentPerSqm }) {
      return this.getMonthlyRent({ baseRentPerSqm, commercialBaseRentPerSqm }) * 12;
    },
    getEarnings({ baseRentPerSqm, commercialBaseRentPerSqm }) {
      return (
        this.getYearlyRent({ baseRentPerSqm, commercialBaseRentPerSqm }) *
        (this.priceInfo.rentMultiplier ? this.priceInfo.rentMultiplier : 1)
      );
    },
    /**
     * This will go through the features to see which ones are duplicates and then create copies of the original object
     * for the corresponding feature but with the correct key, e.g the feature  "interBathroomPrice2" would copy the object
     * for "interBathroomPrice" but set "interBathroomPrice2" as the key.
     * @param config
     * @returns {*[]}
     */
    groomConfigStructure(config) {
      const { features } = this.evaluation;
      const filterKeysEndingWithNumber = Object.keys(features)
        .filter((key) => /\d$/.test(key))
        .reduce((filteredObj, key) => {
          return { ...filteredObj, [key]: features[key] };
        }, {});

      const newArray = Object.keys(filterKeysEndingWithNumber)
        .filter((key) => /\d/.test(key))
        .flatMap((key) => {
          const matchingObjects = config.filter((item) => item.key === key.replace(/\d/g, ''));
          return matchingObjects.map((item) => ({ ...item, key }));
        });

      return [...config, ...newArray];
    },
    generateTable(config) {
      const { features } = this.evaluation;
      const groomedConfigStructure = this.groomConfigStructure(config);
      return groomedConfigStructure
        .filter((item) => features[item.key] === false || features[item.key])
        .flatMap((item) => {
          return {
            name: this.$t(item.titleKey),
            value: this.formatValue(features[item.key], item.valueType),
            effect: this.priceContributions[item.key]
              ? this.formatPotentialValue(this.priceContributions[item.key])
              : this.formatPotentialValue(0),
          };
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

// Component styles
.evaluation {
  $root: &;
  //display: none;
  width: 100%;
  text-align: left;
  padding: 0 $sp-m;

  @media print {
    padding: 0 $sp-l;
    display: block;
  }

  &__main {
    max-width: 900px;
    margin: 0 auto;
    position: relative;
    top: -($sp-xl + $sp-xs + $sp-xxs);
  }
}

.evaluation_header {
  background-color: getShade($secondary-color, 60);
  padding: 40px 20px 70px;
  margin-left: -$sp-m;
  width: calc(100% + #{$sp-m + $sp-m}); // Container width minus container padding

  @media print {
    margin-left: -$sp-l;
    width: calc(100% + #{$sp-l + $sp-l}); // Container width minus container padding
  }

  &__container {
    padding: 0 $sp-m $sp-m $sp-m;
  }
  &__text,
  &__text_evaluation_report {
    @include font('DS-100');
  }
  &__text_evaluation_report {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__title {
    @include font('DS-400');
    margin-bottom: $sp-m;
  }
  &__image-wrapper {
    display: none;
  }
  &__image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &__contact {
    display: flex;
    flex-direction: column;
    @include font('DS-100');
    gap: $sp-m;
    list-style: none;
    margin-top: $sp-m;
    padding: 0 0 $sp-m;
    &_label {
      margin-bottom: $sp-xs;
    }
  }

  @include for('desktop') {
    &__container {
      margin: 0 auto;
      max-width: 900px;
      padding-right: 180px;
      position: relative;
    }

    &__image-wrapper {
      background-color: $white;
      bottom: -80px;
      display: block;
      max-height: 180px;
      position: absolute;
      right: $sp-m;
      height: 180px;
      width: 180px;
      border-radius: 50%;
      overflow: hidden;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &--avatar {
        border: 10px solid getShade($secondary-color, 60);
        background-color: $white;

        img {
          object-fit: contain;
        }
      }
    }
  }
  @media (min-width: 1024px) {
    &__contact {
      flex-direction: row;
      gap: $sp-l;
    }
  }
}

.property-info {
  background-color: $white;
  border-top-left-radius: $sp-s;
  border-top-right-radius: $sp-s;
  padding: $sp-m $sp-m $sp-l;

  &__property_type_wrapper,
  &__details {
    align-items: center;
    display: flex;
  }
  &__type {
    margin-left: $sp-s;
    @include font('DS-100');
  }
  &__address {
    @include font('DS-200');
    font-weight: 600;
  }
  &__list {
    @include font('DS-100');
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      &:not(:first-child)::before {
        content: '•';
        color: getShade($secondary-color, 50);
        margin: 0 $sp-s;
      }
    }
  }

  @include for('tablet') {
    position: relative;
    &__details {
      flex-direction: row;
      line-height: 1.7;
    }
    &__address {
      @include font('DS-300');
    }
    &__details {
      margin-bottom: $sp-s;
    }
  }

  @include for('desktop') {
    padding: $sp-m + $sp-s $sp-xl;
    &__address,
    &__list {
      margin-left: $sp-l + $sp-m;
    }
  }
}

.marketing {
  &__price {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 $sp-m $sp-l;
    border-radius: $sp-s;
    background-color: getShade($secondary-color, 50);

    @include for('desktop') {
      padding: 0 $sp-l $sp-l;
    }

    .valuation {
      &__icon_background {
        position: relative;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 94px;
        height: 94px;
        background-color: $primary-bg;
        border-radius: 50%;
      }

      &__price_block {
        width: 100%;
        margin-top: -50px;
        padding-top: $sp-s;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .valuation__title {
          @include font('DS-200');
          font-weight: 600;
          text-align: center;
        }

        .valuation__price {
          @include font('DS-500');

          @include for('dekstop') {
            margin-top: -50px;
          }
        }

        p {
          margin-top: $sp-s;
        }

        button {
          margin-top: $sp-m;
          width: 100%;
          @include font('DS-100');
          font-weight: 600;

          @include for('tablet') {
            width: 50%;
          }

          @include for('desktop') {
            width: 33%;
          }
        }
      }
    }
  }
  &__description {
    margin-top: 50px;
    border-radius: $sp-s;
    background-color: $primary-bg;

    .description__title {
      @include font('DS-400');
      margin-bottom: 22px;
    }

    .description__text {
      @include font('DS-100');
    }

    &::v-deep .expand-text__toggle {
      font-size: inherit;
    }
  }
  &__based-on {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    padding: 55px $sp-s $sp-l;
    border-radius: $sp-s;
    background-color: getShade($secondary-color, 50);

    @include for('tablet') {
      padding: 55px $sp-m $sp-l;
    }

    .valuation__icon_background {
      position: relative;
      transform: translateY(-140%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      background-color: $primary-bg;
      border-radius: 50%;
    }

    .valuation__price_block {
      width: 100%;
      margin-top: -85px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .valuation__title {
        @include font('DS-200');
        font-weight: 600;
        text-align: center;
      }

      .valuation__price {
        @include font('DS-300');
        font-weight: 800;
      }
    }

    .valuation__features {
      width: 100%;
      margin-top: 19px;
      display: flex;
      flex-direction: column;
      padding: $sp-xs;
      gap: 8px;
      justify-content: space-evenly;
      align-items: stretch;

      @include for('tablet') {
        flex-direction: row;
        padding: 0;
      }

      @media print {
        flex-direction: row;
        padding: 0;
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        width: 100%;
        min-height: 86px;
        padding: $sp-m;
        border-radius: $sp-s;
        background-color: $primary-bg;

        @include for('desktop') {
          width: 33%;
        }
      }

      svg {
        display: none;
        min-width: 40px;

        @include for('desktop') {
          display: inline;
        }
      }

      p {
        display: inline;
        @include font('DS-100');
        font-weight: 600;
        text-align: center;

        @include for('tablet') {
          @include font('DS-90');
        }

        @include for('desktop') {
          @include font('DS-100');
          text-align: left;
        }
      }
      .feature__propertyAttributes {
        border-top: 4px solid #a5d9a0;
      }
      .feature__forInvestors {
        border-top: 4px solid #ffadb5;
      }
      .feature__comparingOffers {
        border-top: 4px solid #fccdb5;
      }
    }
  }
}

.price-details {
  display: flex;
  margin-top: $sp-l;
  justify-content: flex-end;
  flex-direction: column;

  @include for('desktop') {
    padding: $sp-m 0;
  }

  &_wrapper {
    display: flex;

    &_inner {
      margin-left: auto;
      max-width: 820px + $sp-s;
    }

    svg {
      height: 48px;
      min-width: 48px;
      width: 48px;

      @include for('mobile-only') {
        display: none;
      }
    }
  }

  &__header {
    margin-bottom: $sp-l + $sp-s + $sp-xs; // 44px
    .header-title {
      margin-bottom: $sp-s;
      display: flex;
      align-items: center;
      @include font('DS-300');

      > span {
        margin-left: $sp-s;
      }
    }
  }

  &__information {
    > div {
      margin-bottom: $sp-l;
    }

    .investor-multiplicator {
      background-color: getShade($secondary-color, 50);
      margin-top: $sp-m;
    }

    .information-title {
      @include font('DS-200');
      margin-bottom: $sp-s;
    }
    .information-description {
      @include font('DS-90');
      color: getShade($quaternary-color, 90);
      margin-bottom: $sp-m;
    }
    &.investors-table {
      @include for('mobile-only') {
        .information__table tr > td:first-child {
          font-weight: 500;
        }

        .information__table tr:last-child > td:first-child {
          font-weight: 700;
        }
      }

      .investors__table-title {
        font-weight: 600;

        @include for('mobile-only') {
          padding-left: $sp-s;
        }
      }

      tr:last-child > td {
        font-weight: 700;
      }
      tr:first-child > td {
        font-weight: 500;
      }
    }

    .information__table {
      table-layout: fixed;
      width: 100%;

      ::v-deep .positive {
        color: $success-color;
        font-size: 1.2em;
        margin-left: $sp-s;
      }
      ::v-deep .negative {
        color: $error-color;
        font-size: 1.2em;
        margin-left: $sp-s;
      }

      &.location-table {
        > tr:last-child > td:last-child,
        > tr:last-child > td:nth-last-child(3) {
          font-weight: 700;
        }
      }

      > tr {
        height: 32px;
        border-radius: 8px;
        > td {
          padding: 0 $sp-s;
          vertical-align: middle;
        }
        > td:first-child {
          width: 42%;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        > td:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          text-align: right;
          margin-left: auto;
        }
        &:nth-child(even) {
          background-color: getShade($secondary-color, 50);
        }

        @include for('mobile-only') {
          padding: $sp-s 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          height: auto;
          > td {
            padding: $sp-xs $sp-s;
          }
          > td:first-child {
            font-weight: 700;
            width: 100%;
          }
        }
      }
    }
  }

  .multiFamilyHouse .location-table {
    > tr:last-child > td:nth-last-child(3) {
      font-weight: 500;
    }
  }

  header,
  .investor-title,
  .investor-description,
  .information-title,
  .information-description,
  .investors__table-title {
    @include for('tablet') {
      padding-left: $sp-s;
    }
  }
}

.evaluation-cta {
  align-items: center;
  border: 2px solid getShade($quaternary-color, 50);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $sp-l $sp-m;
  position: relative;

  @media print {
    margin-top: $sp-s;
  }

  &__icon {
    background-color: $primary-bg;
    border-radius: 50%;
    padding: $sp-xs;
    position: absolute;
    top: 0;
    transform: translateY(-50%);

    svg {
      height: 80px;
      width: 80px;
    }
  }

  &__heading {
    font-weight: 600;
    margin-top: $sp-xs;
    margin-bottom: $sp-m;
    text-align: center;

    @include for('tablet') {
      margin-bottom: 0;
    }
  }

  &__price {
    @include font('DS-300');
    font-weight: 700;
    margin-bottom: $sp-m;
  }
}

.evaluation_footer {
  background-color: getShade($quaternary-color, 26);
  display: flex;
  padding: $sp-l $sp-m 0 245px;
  flex-direction: column-reverse;
  flex-shrink: 0;
  justify-content: center;
  margin-top: $sp-xl;
  position: relative;
  z-index: 5;
  // To avoid breaking the sections when printing in the shared evaluation view
  break-inside: avoid;

  @include for('tablet') {
    margin-top: #{$sp-xl + $sp-l};
    flex-direction: row;
  }
  @include for('desktop-wide') {
    margin-top: #{$sp-xl + $sp-xl + $sp-xl};
    padding-left: 90px;
  }

  .footer {
    &__photo {
      overflow: hidden;
      border-radius: 50%;
      position: absolute;
      width: 200px;
      height: 200px;
      box-sizing: border-box;
      background: getShade($secondary-color, 60);
      bottom: 20px;
      left: 20px;

      &__img {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      &--avatar {
        .footer__photo__img {
          background-size: contain;
        }
      }

      @include for('desktop-wide') {
        bottom: $sp-m;
        width: 155px;
        height: 155px;
        left: $sp-m;
      }
    }
    &__contact {
      @include for('tablet') {
        width: 100%;
      }
      @include for('desktop') {
        padding-left: 60px;
      }
      @include for('desktop-wide') {
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        margin-bottom: $sp-m;
        width: auto;
      }
      &__separator {
        width: $sp-m;
        display: none;
        flex-shrink: 0;
        @include for('desktop-wide') {
          display: block;
        }
      }
      &__item {
        display: flex;
        align-items: center;
        margin-bottom: $sp-m;
        color: inherit;
        text-decoration: none;
        &__info {
          &__line {
            font-weight: 700;
            word-break: break-all;
            &--name {
              @include font('DS-200');
              word-break: normal;

              @include for('desktop-wide') {
                max-width: 210px;
              }
            }
            &--role {
              @include font('DS-80');
              color: $primary-color;
              font-weight: 400;
            }
            &--small {
              @include font('DS-100');
              font-weight: normal;
            }
          }
        }
        &__icon {
          margin-right: $sp-s;

          &__img {
            display: block;
            margin-top: $sp-xs;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
// Print styles
@page {
  size: A4 portrait;
  margin: 12mm 0;
}

@media print {
  * {
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .slides__slide-wrapper {
    width: 100% !important;
  }

  .page-container {
    max-width: 100% !important;
    padding: 0 !important;
  }

  .confirmation {
    padding: 0 !important;
  }

  .page__main--confirmationSlide {
    background-color: white !important;
  }

  [data-route-name='confirmationSlide'] {
    .page__header {
      display: none;
    }

    .slides__slide-wrapper {
      width: 100%;
      margin: 0;
    }

    .confirmation > :not(.evaluation) {
      display: none;
    }
  }
  .page__footer {
    display: none;
  }
}
</style>
