import { setAuthorizationToken } from '@/services/axios/authorization';
import browserStorage from '@/services/browserStorage';
import { TOKEN_KEY, DATA_KEY } from './CONSTANTS';

const savedToken = browserStorage.getItem(TOKEN_KEY) || '';

setAuthorizationToken(savedToken);

function getSavedUser() {
  try {
    const savedUser = JSON.parse(browserStorage.getItem(DATA_KEY));

    if (!savedUser) {
      throw new Error('No saved user');
    } else {
      return savedUser;
    }
  } catch (e) {
    return {};
  }
}

export default {
  token: savedToken,
  data: getSavedUser(),
  registrationStatus: null,
};
