/* eslint-disable global-require */
import { BUILDING_TYPES, SPECIFIC_PROPERTY_TYPE } from '@/config/CONSTANTS';
import {
  APARTMENT_SLIDES_NAVIGATION,
  HOUSE_SLIDES_NAVIGATION,
  MULTI_FAMILY_HOUSE_SLIDES_NAVIGATION,
  PLOT_SLIDES_NAVIGATION,
} from '@/config/NAVIGATION_FLOWS';

function flattenNestedArrays(acc, navigationItem) {
  if (navigationItem.items) {
    const items = navigationItem.items.reduce(flattenNestedArrays, []);
    return [...acc, ...items];
  }
  return [...acc, navigationItem];
}

// Only the slides, without navigation wrappers. Flattening Array.
const APARTMENT_SLIDES = APARTMENT_SLIDES_NAVIGATION.reduce(flattenNestedArrays, []);
const HOUSE_SLIDES = HOUSE_SLIDES_NAVIGATION.reduce(flattenNestedArrays, []);
const MULTI_FAMILY_HOUSE_SLIDES = MULTI_FAMILY_HOUSE_SLIDES_NAVIGATION.reduce(
  flattenNestedArrays,
  [],
);
const PLOT_SLIDES = PLOT_SLIDES_NAVIGATION.reduce(flattenNestedArrays, []);

export default {
  [SPECIFIC_PROPERTY_TYPE.APARTMENT]: APARTMENT_SLIDES,
  [SPECIFIC_PROPERTY_TYPE.HOUSE]: HOUSE_SLIDES,
  [BUILDING_TYPES.MULTI_FAMILY_HOUSE]: MULTI_FAMILY_HOUSE_SLIDES,
  [SPECIFIC_PROPERTY_TYPE.PLOT]: PLOT_SLIDES,
};
