import { isNumber as _isNumber, toFinite as _toFinite, inRange as _inRange } from 'lodash-es';

export const CURRENT_YEAR = new Date().getFullYear();
export const MINIMUM_YEAR = 1700;
export const MAXIMUM_YEAR = CURRENT_YEAR + 1;
export const MINIMUM_SPACE = 0;
export const MAXIMUM_SPACE = 10_000_000;

export function validateNumberInRange({ value, min, max }) {
  if (!_isNumber(value)) {
    return false;
  }

  return _inRange(_toFinite(value), min, max);
}

export function validateYear(year) {
  return validateNumberInRange({
    value: year,
    min: MINIMUM_YEAR,
    max: MAXIMUM_YEAR,
  });
}

export function validateSpace(space) {
  return validateNumberInRange({
    value: space,
    min: MINIMUM_SPACE,
    max: MAXIMUM_SPACE,
  });
}

export function validatePhone(tel) {
  const MIN_LENGTH = 8;
  const digitCount = tel.match(/\d/g);

  if (digitCount !== null && digitCount.length < MIN_LENGTH) {
    return false;
  }

  const telRegex = /^\+?(\d+)?(\(\d+\))?\d+$/g;

  if (!telRegex.test(tel.replace(/\s/g, ''))) {
    return false;
  }

  const allSameDigit = digitCount.every((element) => element === digitCount[0]);

  if (allSameDigit) {
    return false;
  }

  const digitCountLength = digitCount.length;
  const digitsInSequence = digitCount.every((element, index) => {
    if (index + 1 === digitCountLength) {
      return true;
    }

    return parseInt(element, 10) + 1 === parseInt(digitCount[index + 1], 10);
  });

  return !digitsInSequence;
}

export default {
  CURRENT_YEAR,
  MINIMUM_YEAR,
  MAXIMUM_YEAR,
  MINIMUM_SPACE,
  validateYear,
  validatePhone,
};
