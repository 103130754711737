<template>
  <div class="breadcrumb">
    <router-link :to="{ name: indexPage }" class="breadcrumb__link">
      {{ $t('GENERAL.HOME_PAGE') }}
    </router-link>
    > {{ pageName }}
  </div>
</template>

<script>
import { DEALS as DEALS_ROUTES } from '@/router/wizard/ROUTE_NAMES';

export default {
  name: 'Breadcrumb',
  props: {
    pageName: {
      type: String,
      required: true,
    },
  },
  computed: {
    indexPage() {
      return DEALS_ROUTES.INDEX;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.breadcrumb {
  display: inline;
  color: $quaternary-color;

  a:visited,
  a:link {
    color: $quaternary-color;
    text-decoration: none;
  }
}
</style>
