import { isNil as _isNil } from 'lodash-es';
import { RENT_STATUS } from '@/config/CONSTANTS';
import { CURRENT_YEAR, validateYear, validateSpace } from '@/services/validators';
import baseCalculations from '../base';

function getEarningsValuePrice({
  rentStatus,
  livingSpace,
  commercialSpace,
  baseRentPerSqm,
  commercialBaseRentPerSqm,
  rentMultiplier,
}) {
  if (_isNil(rentMultiplier) || rentStatus !== RENT_STATUS.RENTED) {
    return 0;
  }

  const apartmentRentPerMonth =
    !validateSpace(livingSpace) || _isNil(baseRentPerSqm) ? 0 : livingSpace * baseRentPerSqm;
  const commercialRentPerMonth =
    !validateSpace(commercialSpace) || _isNil(commercialBaseRentPerSqm)
      ? 0
      : commercialSpace * commercialBaseRentPerSqm;
  const yearRent = 12 * (apartmentRentPerMonth + commercialRentPerMonth);

  return yearRent * rentMultiplier;
}

function getRoofRenovationPrice({
  livingSpace,
  numberOfFloors,
  lastRoofRenovationYear,
  scaleFactor = 1,
}) {
  if (
    !validateYear(lastRoofRenovationYear) ||
    !validateSpace(livingSpace) ||
    _isNil(numberOfFloors) ||
    numberOfFloors === 0
  ) {
    return 0;
  }

  const ROOF_LIFE_SPAN = 40;
  const AVERAGE_ROOF_AGE = 20;
  const ROOF_PRICE_PER_SQM = 160;

  const roofAge = Math.min(CURRENT_YEAR - lastRoofRenovationYear, AVERAGE_ROOF_AGE);
  const roofArea = livingSpace / numberOfFloors;

  const numerator = ROOF_PRICE_PER_SQM * roofArea * (AVERAGE_ROOF_AGE - roofAge);
  const denominator = ROOF_LIFE_SPAN;
  const featurePrice = numerator / denominator;

  return Math.round(featurePrice * scaleFactor);
}

function getFacadeRenovationPrice({ lastFacadeRenovationYear, numberOfFloors, scaleFactor = 1 }) {
  if (!validateYear(lastFacadeRenovationYear) || _isNil(numberOfFloors)) {
    return 0;
  }

  const FACADE_LIFE_SPAN = 50;
  const AVERAGE_FACADE_AGE = 25;
  const FACADE_PRICE_PER_SQM = 90;
  const AVERAGE_FACADE_AREA = 120;
  const AVERAGE_NUMBER_OF_FLOORS = 1.5;

  const facadeAge = Math.min(CURRENT_YEAR - lastFacadeRenovationYear, AVERAGE_FACADE_AGE);
  const facadeArea = (AVERAGE_FACADE_AREA * numberOfFloors) / AVERAGE_NUMBER_OF_FLOORS;

  const numerator = FACADE_PRICE_PER_SQM * facadeArea * (AVERAGE_FACADE_AGE - facadeAge);
  const denominator = FACADE_LIFE_SPAN;
  const featurePrice = numerator / denominator;

  return Math.round(featurePrice * scaleFactor);
}

export function getRentStatusPrice() {
  return 0;
}

export default {
  ...baseCalculations,
  getEarningsValuePrice,
  getRoofRenovationPrice,
  getFacadeRenovationPrice,
  getRentStatusPrice,
};
