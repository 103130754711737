<template>
  <wizard-base
    class="floors"
    :title="$t('WIZARD.FLOORS.TITLE')"
    :subtitle="$t('GENERAL.FIELD_REQUIRED')"
    :icon="slideIcon"
  >
    <hd-input
      v-model="numberOfFloors"
      name="number-of-floors"
      type="number"
      :min="1"
      :label="$t('GENERAL.TOTAL_FLOORS')"
      :custom-rules="customRules"
    />
  </wizard-base>
</template>

<script>
// @ts-check
import { toFinite as _toFinite, isNumber as _isNumber, inRange as _inRange } from 'lodash-es';
import { HdInput } from 'homeday-blocks';
import WizardBase from '@/layout/WizardBase.vue';
import { mapFeatures } from '@/services/feature';
import { floorStairs as slideIcon } from 'homeday-assets/L';
import { MINIMUM_FLOORS, MAXIMUM_FLOORS } from '@/config/CONSTANTS';

export default {
  name: 'FloorsSlide',
  components: {
    HdInput,
    WizardBase,
  },
  data() {
    return {
      slideIcon,
    };
  },
  computed: {
    ...mapFeatures(['numberOfFloors']),
    /** @returns {import('@/@types/global').ValidationRules} */
    customRules() {
      return [
        {
          validate: (value) => {
            if (!_isNumber(Number(value))) return false;
            return _inRange(_toFinite(Number(value)), MINIMUM_FLOORS, MAXIMUM_FLOORS);
          },
          errorMessage: this.$t('FIELD_VALIDATIONS.INCORRECT_VALUE'),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.floors {
  ::v-deep .wizard-base {
    &__container {
      justify-content: center;
    }

    &__content {
      flex-grow: 0;
    }
  }
}
</style>
