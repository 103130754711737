import { get as _get, snakeCase as _snakeCase } from 'lodash-es';

export default {
  normalize(apiDeal) {
    const features = _get(apiDeal, 'last_evaluation.features', []);

    const types = features
      .filter((feature) => feature.name === 'heating')
      .map(({ value: heatingOption }) => ({
        name: heatingOption,
        price: features.find((i) => i.name === `${heatingOption}_price`)?.value,
        renovationYear: features.find((i) => i.name === `${heatingOption}_renovation_year`)?.value,
        possibleAlternatives: {},
      }));

    return {
      types,
    };
  },
  denormalize(payload) {
    return payload?.types?.reduce((acc, cur) => {
      const type = _snakeCase(cur.name);
      acc.push({
        name: 'heating',
        value: type,
      });
      acc.push({
        name: `${type}_price`,
        value: cur.price,
      });
      acc.push({
        name: `${type}_renovation_year`,
        value: cur.renovationYear,
      });
      return acc;
    }, []);
  },
};
