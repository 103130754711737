<template>
  <wizard-base
    class="bathroom"
    :title="$t('WIZARD.BATHROOM.TITLE')"
    :icon="slideIcon"
    :content-column-classes="['is-9', 'is-6-desktop']"
  >
    <TransitionGroup name="item-anim">
      <div
        v-for="(item, i) in editableBathrooms"
        :key="`bathroom-${i}-item`"
        class="bathroom__types columns"
      >
        <HdSelect
          v-model="editableBathrooms[i].name"
          class="column is-5"
          :name="`bathroom-type-${item.name}-${i}`"
          :label="$t('WIZARD.BATHROOM.TITLE')"
          :options="availableOptions"
        />
        <PriceInput
          v-model="editableBathrooms[i].value"
          class="bathroom__input column is-5"
          :name="`${i}Price`"
          :label="$t('WIZARD.CHANGE_IN_VALUE')"
          :parse-to-number="true"
        />
        <div class="column is-2">
          <HdButton
            class="bathroom__remove"
            modifier="tertiary"
            :icon-src="close"
            @click.prevent="removeItem(i)"
          />
        </div>
      </div>
    </TransitionGroup>
    <div class="bathroom__totals columns">
      <div class="column is-2">
        <HdButton
          modifier="tertiary"
          :icon-src="plus"
          data-testid="button-add-bathroom"
          @click.prevent="addItem"
        />
      </div>
      <template v-if="editableBathrooms.length > 1">
        <p class="bathroom__sum-label column is-3">{{ $t('WIZARD.SUM') }}</p>
        <p class="column is-5" v-text="formattedSumValue" />
      </template>
    </div>
  </wizard-base>
</template>

<script>
// Components
import { HdSelect, HdButton } from 'homeday-blocks';
import WizardBase from '@/layout/WizardBase.vue';
import PriceInput from '@/components/PriceInput.vue';

// Services
import { mapPriceContributions } from '@/services/priceContribution';
import { getItemsFromTranslationObject } from '@/services/helpers';
import { camelCase as _camelCase, snakeCase as _snakeCase } from 'lodash-es';

// Icons
import { bathroom as slideIcon } from 'homeday-assets/L';
import { close, plus, restore } from 'homeday-assets';

// Constants
import { BATHROOM_TYPES } from '@/config/TRANSLATION_CONSTANTS';
import { MUTATION } from '@/store/CONSTANTS';

const bathroomTypes = getItemsFromTranslationObject(BATHROOM_TYPES);
const ITEM_PLACEHOLDER = {
  name: '',
  value: 0,
};

export default {
  name: 'BathroomSlide',
  components: {
    PriceInput,
    WizardBase,
    HdSelect,
    HdButton,
  },
  data() {
    return {
      availableOptions: bathroomTypes,
      editableBathrooms: [],
      bathrooms: {
        types: [],
        hasBathroomWithWindow: false,
        hasInteriorBathroom: false,
        hasSeparateGuestWc: false,
        numberOfBathrooms: 0,
        numberOfGuestBathrooms: 0,
      },
      slideIcon,
      restore,
      plus,
      close,
    };
  },
  computed: {
    ...mapPriceContributions(['bathroom']),
    formattedSumValue() {
      return `+${this.bathroomPrice?.toLocaleString('de')} €`;
    },
  },
  watch: {
    editableBathrooms: {
      handler() {
        // Update only after saving other fields in store to avoid reset
        this.updateSumValue();
        // this.updateCounter();
        this.setBathrooms();
      },
      deep: true,
    },
  },
  created() {
    this.initializeBathrooms();
  },
  methods: {
    setBathrooms() {
      this.copyBathrooms();
      this.setFeatureFlags();
      this.updateCounter();
      const cleanObject = JSON.stringify(this.bathrooms);
      this.$store.commit(MUTATION.WIZARD.CURRENT_DEAL.SET_BATHROOMS, JSON.parse(cleanObject));
    },
    copyBathrooms() {
      const filteredBathrooms = [];

      // We filter empty entries and zeros
      this.editableBathrooms.forEach((bathroom) => {
        if (bathroom.value !== 0 && bathroom.name !== '') {
          filteredBathrooms.push({
            name: _camelCase(bathroom.name).replace('Price', ''),
            price: bathroom?.value,
          });
        }
      });

      this.bathrooms.types = filteredBathrooms;
    },
    setFeatureFlags() {
      bathroomTypes.forEach((type) => {
        const typeIsUsed = this.editableBathrooms.find((element) => element.name === type.value);

        // find a way to set corresponding boolean as false.
        if (!typeIsUsed) return;
        const asCamelCase = _camelCase(`has_${typeIsUsed.name}`);
        this.bathrooms[asCamelCase] = !!typeIsUsed;
      });
    },
    updateCounter() {
      let bathroomCounter = 0;
      let guestBathroomCounter = 0;
      this.bathrooms.types.forEach((bathroom) => {
        if (bathroom.name === 'bathroomWithWindow' || bathroom.name === 'interiorBathroom') {
          bathroomCounter += 1;
        }
        if (bathroom.name === 'separateGuestWc') {
          guestBathroomCounter += 1;
        }
        this.bathrooms.numberOfBathrooms = bathroomCounter;
        this.bathrooms.numberOfGuestBathrooms = guestBathroomCounter;
      });
    },
    updateSumValue() {
      const value = this.editableBathrooms.reduce((acc, current) => {
        return current.name && current.value ? current.value + acc : acc;
      }, 0);
      this.bathroomPrice = Math.round(value);
    },
    addItem() {
      this.editableBathrooms = [...this.editableBathrooms, { ...ITEM_PLACEHOLDER }];
    },
    removeItem(index) {
      if (this.editableBathrooms.length === 1) this.editableBathrooms = [{ ...ITEM_PLACEHOLDER }];
      else this.editableBathrooms.splice(index, 1);
    },
    initializeBathrooms() {
      const bathroomTypesFromStore = this.$store.state.wizard.currentDeal.bathrooms?.types;
      let convertedBathroomTypes;
      if (bathroomTypesFromStore && bathroomTypesFromStore.length > 0) {
        convertedBathroomTypes = bathroomTypesFromStore.map((bathroom) => {
          return {
            name: _snakeCase(bathroom.name),
            value: Number(bathroom.price),
          };
        });
      } else convertedBathroomTypes = [ITEM_PLACEHOLDER];

      this.editableBathrooms = convertedBathroomTypes;

      this.updateSumValue();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.bathroom {
  ::v-deep .wizard-base__container {
    align-items: flex-start;
  }
}
.item-anim-enter-active,
.item-anim-leave-active {
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}
.item-anim-enter,
.item-anim-leave-to {
  opacity: 0;
  transform: translateY(-50%);
}
</style>
