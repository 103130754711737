function getSessionStorage() {
  try {
    window.sessionStorage.setItem('availabilityTest', 'test');
    window.sessionStorage.removeItem('availabilityTest');
    return window.sessionStorage;
  } catch (e) {
    return null;
  }
}

const sessionStorage = getSessionStorage();

function getItem(key) {
  return sessionStorage !== null ? sessionStorage.getItem(key) : undefined;
}

function setItem(key, value) {
  if (sessionStorage !== null) sessionStorage.setItem(key, value);
}

function removeItem(key) {
  if (sessionStorage !== null) sessionStorage.removeItem(key);
}

const sessionStorageService = {
  getItem,
  setItem,
  removeItem,
};
export default sessionStorageService;
