import { get as _get } from 'lodash-es';
import { WINDOW_TYPES } from '@/config/TRANSLATION_CONSTANTS';

const WINDOW_TYPE_KEYS = Object.keys(WINDOW_TYPES);

export default {
  normalize(apiDeal) {
    const features = _get(apiDeal, 'last_evaluation.features', []);
    const type = features.find((feat) => WINDOW_TYPE_KEYS.includes(feat.name?.toUpperCase()))?.name;
    return {
      type,
    };
  },
  denormalize(payload) {
    return payload.type
      ? [
          {
            name: payload.type,
            value: true,
          },
        ]
      : [];
  },
};
