<template>
  <wizard-base class="target-group" :title="$t('WIZARD.TARGET_GROUP.TITLE')">
    <HdCheckboxCardGroup v-model="targetGroups" name="target-group" :mode="checkboxCardMode">
      <HdCheckboxCard
        v-for="option in targetGroupOptions"
        :key="option.value"
        :native-value="option.value"
      >
        <template #icon>
          <img :src="option.icon" />
        </template>
        {{ option.text }}
      </HdCheckboxCard>
    </HdCheckboxCardGroup>
  </wizard-base>
</template>

<script>
/* eslint-disable global-require */
import { mapFeatures } from '@/services/feature';
import WizardBase from '@/layout/WizardBase.vue';
import { HdCheckboxCard, HdCheckboxCardGroup, OnResizeService } from 'homeday-blocks';
import { isMobile } from '@/services/breakpoints';

export default {
  name: 'TargetGroupsSlide',
  components: {
    WizardBase,
    HdCheckboxCard,
    HdCheckboxCardGroup,
  },
  data() {
    return {
      checkboxCardMode: isMobile() ? 'tile' : 'card',
      targetGroupOptions: [
        {
          value: 'singles',
          text: this.$t('WIZARD.TARGET_GROUP.OPTIONS.SINGLES'),
          icon: require('@/assets/target-group/singles.jpeg'),
        },
        {
          value: 'family',
          text: this.$t('WIZARD.TARGET_GROUP.OPTIONS.FAMILY'),
          icon: require('@/assets/target-group/family.jpeg'),
        },
        {
          value: 'capital_investor',
          text: this.$t('WIZARD.TARGET_GROUP.OPTIONS.CAPITAL_INVESTOR'),
          icon: require('@/assets/target-group/capital-investor.jpeg'),
        },
        {
          value: 'young_couple',
          text: this.$t('WIZARD.TARGET_GROUP.OPTIONS.YOUNG_COUPLE'),
          icon: require('@/assets/target-group/young-couple.jpeg'),
        },
        {
          value: 'senior',
          text: this.$t('WIZARD.TARGET_GROUP.OPTIONS.SENIOR'),
          icon: require('@/assets/target-group/senior.jpeg'),
        },
      ],
    };
  },
  computed: {
    ...mapFeatures(['targetGroups']),
  },
  created() {
    OnResizeService.onThrottled(this.updateCheckboxCardMode);
  },
  destroyed() {
    OnResizeService.offThrottled(this.updateCheckboxCardMode);
  },
  methods: {
    updateCheckboxCardMode() {
      if (isMobile()) {
        this.checkboxCardMode = 'tile';
      } else {
        this.checkboxCardMode = 'card';
      }
    },
  },
};
</script>

<style scoped>
::v-deep .card img {
  width: 90px;
  height: 90px;
  border-radius: 45px;
}

::v-deep .card--tile img {
  width: 44px;
  height: 44px;
  border-radius: 22px;
}
</style>
