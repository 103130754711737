<template>
  <div class="price-input">
    <price-input-button
      ref="button"
      class="price-input__button"
      :value="value"
      @click="clickHandler"
    />
    <hd-input-formatter
      ref="input"
      class="price-input__input-formatter"
      type="number"
      :formatter="priceFormatter"
      :value="boundValue"
      :custom-rules="customRules"
      v-bind="$attrs"
      @input="inputHandler"
      @focus="focusHandler"
      @blur="blurHandler"
    />
  </div>
</template>

<script>
import Animate from 'animate-x';
import { HdInputFormatter } from 'homeday-blocks';
import PriceInputButton from '@/components/PriceInputButton.vue';

const ANIMATION_DURATION = 300;
const FRACTION_DIGITS = 0;

export default {
  name: 'PriceInput',
  components: {
    PriceInputButton,
    HdInputFormatter,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      default: 0,
    },
    /*
      PriceContributionInput needs to know once the input is empty
      so it can fallback to the calculated price and not to 0
    */
    parseToNumber: {
      type: Boolean,
      default: true,
    },
    customRules: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      boundValue: this.value,
      isFocused: false,
    };
  },
  watch: {
    value: {
      handler(newValue, oldValue) {
        if (newValue !== this.normalizeValue(newValue)) {
          this.emitValue();

          return;
        }

        // We don't animate when focused
        if (!this.isFocused && typeof oldValue === 'number') {
          const animation = new Animate({
            from: oldValue,
            to: newValue,
            duration: ANIMATION_DURATION,
            onStep: (tweenedValue) => {
              this.boundValue = Math.round(tweenedValue);
            },
          });

          animation.start();
        } else {
          this.boundValue = newValue;
        }
      },
      immediate: true,
    },
  },
  methods: {
    normalizeValue(value) {
      let normalizedValue = value;

      if (this.parseToNumber) {
        normalizedValue = Number(normalizedValue);
      }

      if (typeof normalizedValue === 'number' && !this.isFocused) {
        // We round the value only when the input is not focused, for a better UX
        normalizedValue = this.roundValue(normalizedValue);
      }

      return normalizedValue;
    },
    roundValue(value) {
      return parseFloat(value.toFixed(FRACTION_DIGITS));
    },
    priceFormatter(value) {
      return this.$formatPrice(value, {
        fractionDigits: FRACTION_DIGITS,
        signDisplay: 'always',
      });
    },
    inputHandler(value) {
      this.emitValue(value);
    },
    clickHandler() {
      this.emitValue(-this.value);
    },
    focusHandler() {
      this.isFocused = true;
      this.$emit('focus');
    },
    blurHandler() {
      this.isFocused = false;
      this.$emit('blur');
      this.emitValue();
    },
    emitValue(value = this.value) {
      this.$emit('input', this.normalizeValue(value));
    },
    showError(...args) {
      return this.$refs.input.showError(...args);
    },
    showHelper(...args) {
      return this.$refs.input.showHelper(...args);
    },
    validate(...args) {
      return this.$refs.input.validate(...args);
    },
    hideError(...args) {
      return this.$refs.input.hideError(...args);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.price-input {
  &__button {
    position: absolute;
    z-index: 1;
  }

  &__input-formatter {
    ::v-deep > .field__body > .field__main > input {
      padding-left: $sp-xl;
    }

    ::v-deep .field__label {
      padding-left: $sp-xl;
    }

    ::v-deep .field__border {
      z-index: 10;
    }
  }
}
</style>
