<template>
  <wizard-base class="garden" :title="$t('WIZARD.GARDEN.TITLE')" :icon="slideIcon">
    <div class="garden__fields">
      <TileSelectWithReset
        v-model="hasGarden"
        :items="gardenItems"
        type="number"
        name="gardenValue"
        class="garden__select"
      />
      <PriceContributionInput v-model="gardenPrice" name="garden" class="garden__input" />
    </div>
  </wizard-base>
</template>

<script>
// Components
import WizardBase from '@/layout/WizardBase.vue';
import PriceContributionInput from '@/components/PriceContributionInput.vue';
import TileSelectWithReset from '@/components/TileSelectWithReset.vue';
// Services
import { mapFeatures } from '@/services/feature';
import { mapPriceContributions } from '@/services/priceContribution';
// Icons
import { garden as slideIcon } from 'homeday-assets/L';

export default {
  name: 'GardenSlide',
  components: {
    WizardBase,
    PriceContributionInput,
    TileSelectWithReset,
  },
  data() {
    return {
      gardenItems: [
        {
          text: this.$t('GENERAL.YES'),
          value: true,
        },
        {
          text: this.$t('GENERAL.NO'),
          value: false,
        },
      ],
      slideIcon,
    };
  },
  computed: {
    ...mapFeatures(['hasGarden']),
    ...mapPriceContributions(['garden']),
  },
};
</script>
