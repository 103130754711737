<template>
  <section class="calculated-price">
    <div class="calculated-price__content">
      <div class="calculated-price__icon">
        <HdIcon
          :src="locationQuality"
          width="68px"
          height="68px"
          :class-from-fill="defaultIconColors"
        />
      </div>
      <div class="calculated-price__content__base-price">
        <AnimatedPrice
          class="calculated-price__number"
          :price="basePrice"
          :duration="animationDuration"
          :delay="animationDelay"
        />
        <p class="calculated-price__text" v-html="text" />
        <p class="calculated-price__text_description">
          <span>* </span>{{ $t('WIZARD.COMMISION_TEXT') }}
        </p>
      </div>
    </div>
    <div class="calculated-price__button-wrapper">
      <SlideSubmitButtonWrapper
        v-slot="{ disabled }"
        :class="{
          'calculated-price__button--visible': showButton,
        }"
        class="calculated-price__button"
      >
        <HdButton :disabled="disabled" modifier="primary" type="submit">
          {{ $t('WIZARD.CALCULATED_PRICE.TO_PROPERTY') }}
        </HdButton>
      </SlideSubmitButtonWrapper>
    </div>
  </section>
</template>

<script>
import { locationQuality } from 'homeday-assets/L';
import { UtilsService, HdButton, HdIcon } from 'homeday-blocks';
import AnimatedPrice from '@/components/AnimatedPrice.vue';
import SlideSubmitButtonWrapper from '@/components/SlideSubmitButtonWrapper.vue';
import { SVG_DEFAULT_COLORS } from '@/config/CONSTANTS';

export default {
  name: 'CalculatedPriceSlide',
  components: {
    HdIcon,
    HdButton,
    AnimatedPrice,
    SlideSubmitButtonWrapper,
  },
  data() {
    return {
      animationDuration: 500,
      animationDelay: 1000,
      showButton: false,
      locationQuality,
    };
  },
  computed: {
    defaultIconColors() {
      return SVG_DEFAULT_COLORS;
    },
    currentDeal() {
      return this.$store.state.wizard.currentDeal;
    },
    propertyType() {
      return this.currentDeal.propertyType;
    },
    livingSpace() {
      return this.currentDeal.livingSpace;
    },
    basePrice() {
      return this.$store.getters['wizard/currentDeal/basePrice'];
    },
    formattedLivingSpace() {
      return this.$formatSpace(this.livingSpace);
    },
    propertyTypeLabel() {
      return this.$t(`GENERAL.${this.propertyType.toUpperCase()}_WITH_ARTICLE`);
    },
    text() {
      return UtilsService.populateTemplate(this.$t('WIZARD.CALCULATED_PRICE.TEXT'), {
        propertyType: this.propertyTypeLabel,
        livingSpace: this.formattedLivingSpace,
      });
    },
  },
  created() {
    setTimeout(() => {
      this.showButton = true;
    }, this.animationDelay + this.animationDuration);
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.calculated-price {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.slides__slide {
    background-color: getShade($secondary-color, 60);
    position: relative;
    height: fit-content;
    margin: unset;
    padding: unset;
    margin-top: $sp-xl + $sp-xl;
    padding-top: $sp-l + $sp-s;

    @include for('tablet') {
      margin-top: $sp-l + $sp-s;
      padding-top: $sp-xl - $sp-s;
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: $primary-bg;
    padding: $sp-m - $sp-xs;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 850px;
    padding: 0 $sp-m;
    margin-bottom: $sp-l;

    &__base-price {
      margin-top: $sp-s;
    }

    &__lending-price {
      margin-top: $sp-l;

      @include for('tablet') {
        margin-top: $sp-l + $sp-s;
      }
    }
  }

  &__text {
    @include font('DS-100');
    text-align: center;
    font-weight: 700;
    max-width: 730px;
    margin: 0 auto;

    @include for('tablet') {
      @include font('DS-300');
      font-weight: 900;
      margin-bottom: $sp-m;
    }
  }

  &__text_description {
    @include font('DS-90');
    font-style: italic;
    padding-top: $sp-s;
    text-align: center;

    @include for('tablet') {
      @include font('DS-200');
    }
  }

  &__text::v-deep span,
  &__text_description span {
    color: $error-color;
  }

  .animated-price {
    @include font('DS-400');
    height: 40px;

    @include for('tablet') {
      @include font('DS-800');
      height: 80px;
      font-weight: 900;
      margin-bottom: $sp-m + $sp-s;
      margin-top: $sp-m + $sp-s;
    }
  }

  &__button {
    padding-right: $sp-l;
    padding-left: $sp-l;
    opacity: 0;
    pointer-events: none;

    @include for('tablet') {
      width: auto;
    }

    &--visible {
      opacity: 1;
      pointer-events: all;
    }

    &-wrapper {
      width: 100%;
      padding: $sp-l 0;
      background: $primary-bg;
      text-align: center;
    }
  }
}
</style>
