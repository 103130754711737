<template>
  <wizard-base class="apartment-accessibility" :title="$t('WIZARD.FLOORS.TITLE')">
    <div class="apartment-accessibility__fields">
      <HdSelect
        v-model="apartmentType"
        :options="apartmentTypes"
        name="apartment-type"
        type="number"
        :label="$t('WIZARD.ACCESSIBILITY.APARTMENT_TYPE_LABEL')"
        class="apartment-accessibility__select"
      />
      <HdInput
        v-model="floor"
        name="floor"
        type="number"
        :label="$t('WIZARD.ACCESSIBILITY.FLOOR_LABEL')"
        :custom-rules="customRules"
        class="apartment-accessibility__floor-input"
      />
      <HdInput
        v-model="numberOfFloors"
        name="numberOfFloors"
        type="number"
        :label="$t('GENERAL.TOTAL_FLOORS')"
        :custom-rules="customRules"
        class="apartment-accessibility__total-floors-input"
      />
      <TileSelectWithReset
        v-model="hasElevator"
        :items="elevatorItems"
        type="number"
        name="elevatorValue"
        class="apartment-accessibility__tile-select"
      />
      <PriceContributionInput
        v-model="accessibilityPrice"
        name="accessibility"
        class="apartment-accessibility__price-input"
      />
    </div>
  </wizard-base>
</template>

<script>
// Components
import { HdInput, HdSelect } from 'homeday-blocks';
import WizardBase from '@/layout/WizardBase.vue';
import PriceContributionInput from '@/components/PriceContributionInput.vue';
import TileSelectWithReset from '@/components/TileSelectWithReset.vue';
// Services
import { mapFeatures } from '@/services/feature';
import { mapPriceContributions } from '@/services/priceContribution';
import { getItemsFromTranslationObject } from '@/services/helpers';
import { toFinite as _toFinite, inRange as _inRange, isNumber as _isNumber } from 'lodash-es';

import { APARTMENT_TYPES } from '@/config/TRANSLATION_CONSTANTS';

export default {
  name: 'ApartmentAccessibilitySlide',
  components: {
    TileSelectWithReset,
    WizardBase,
    HdInput,
    HdSelect,
    PriceContributionInput,
  },
  data() {
    return {
      elevatorItems: [
        {
          text: this.$t('WIZARD.ACCESSIBILITY.WITH_ELEVATOR'),
          value: true,
        },
        {
          text: this.$t('WIZARD.ACCESSIBILITY.WITHOUT_ELEVATOR'),
          value: false,
        },
      ],
      apartmentTypes: getItemsFromTranslationObject(APARTMENT_TYPES),
    };
  },
  computed: {
    ...mapFeatures(['apartmentType', 'floor', 'numberOfFloors', 'hasElevator']),
    floorsInBuilding() {
      return this.numberOfFloors ? this.numberOfFloors + 1 : null;
    },
    /** @returns {import('@/@types/global').ValidationRules} */
    customRules() {
      return [
        {
          validate: (value) => {
            if (!_isNumber(Number(value))) return false;
            return _inRange(_toFinite(value), 0, this.floorsInBuilding);
          },
          errorMessage: this.$t('FIELD_VALIDATIONS.INCORRECT_VALUE'),
        },
      ];
    },
    ...mapPriceContributions(['accessibility']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.apartment-accessibility {
  &__fields {
    display: grid;
    column-gap: $sp-m;
    grid-template-columns: repeat(6, 1fr);
    margin-right: auto;
    margin-left: auto;

    @include for('tablet') {
      max-width: 50%;
    }
  }

  &__select,
  &__tile-select {
    grid-column: 1 / span 6;
  }
  &__floor-input {
    grid-column: 1 / span 3;
  }
  &__total-floors-input {
    grid-column: 4 / span 3;
  }
  &__price-input {
    margin-top: $sp-l;
    grid-column: 2 / span 4;
  }

  &__tile-select {
    @include font('DS-80');
    max-width: none;
  }
}
</style>
