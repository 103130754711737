export const TOKEN_KEY = 'authToken';
export const DATA_KEY = 'userData';
export const VUEX_PERSIST_KEY = 'vuex';

export const MUTATION = {
  SET_DATA: 'setData',
  SET_TOKEN: 'setToken',
  SET_ACTION_STATUS: 'setActionStatus',
};

export const ACTION = {
  REGISTER: 'register',
  LOGIN: 'login',
  SAVE: 'save',
  LOGOUT: 'logout',
  UPDATE: 'update',
  REQUEST_NEW_PASS: 'request_new_pass',
  CONFIRM_NEW_PASS: 'confirm_new_pass',
};

export const GETTER = {
  AUTHENTICATED: 'authenticated',
  FULL_NAME: 'fullName',
  FORMAL_NAME: 'formalName',
  UID: 'uid',
};
