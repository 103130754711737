<template>
  <wizard-base
    class="basement"
    :title="$t('WIZARD.BASEMENT.TITLE')"
    :icon="slideIcon"
    :content-column-classes="['is-7', 'is-8-desktop']"
  >
    <div class="basement__fields">
      <div class="basement__options columns">
        <TileSelectWithReset
          v-model="numberOfBasementRooms"
          :items="basementAvailability"
          type="number"
          name="basementValue"
          class="basement__select"
          @input="resetForm"
        />
      </div>
      <template v-if="numberOfBasementRooms !== undefined">
        <template v-if="numberOfBasementRooms">
          <TransitionGroup name="item-anim">
            <div
              v-for="(item, i) in basements"
              :key="`basement-${i}-item`"
              class="basement__types columns"
            >
              <HdSelect
                v-model="basements[i].name"
                class="column is-5"
                :name="`basement-type-${i}`"
                :label="$t('WIZARD.BASEMENT.TYPE.LABEL')"
                :options="availableOptions(basements[i])"
              />
              <PriceInput
                v-model="basements[i].value"
                class="basement__input column is-5"
                :name="`${i}Price`"
                :label="$t('WIZARD.CHANGE_IN_VALUE')"
                :parse-to-number="true"
              />
              <div class="column is-2">
                <HdButton
                  class="basement__remove"
                  modifier="tertiary"
                  :icon-src="close"
                  @click.prevent="removeItem(i)"
                />
              </div>
            </div>
          </TransitionGroup>
          <div class="basement__totals columns">
            <div class="column is-2">
              <HdButton
                v-show="basements.length < 3"
                modifier="tertiary"
                :icon-src="plus"
                data-testid="button-add-basement"
                @click.prevent="addItem"
              />
            </div>
            <template v-if="basements.length > 1">
              <p class="basement__sum-label column is-3" v-text="$t('WIZARD.SUM')" />
              <p class="column is-5" v-text="formattedTotalValue" />
            </template>
          </div>
        </template>
        <template v-else>
          <div class="basement_changed-value columns">
            <PriceContributionInput
              v-model="basementPrice"
              name="basement"
              class="basement__input column is-4"
            />
          </div>
        </template>
      </template>
    </div>
  </wizard-base>
</template>

<script>
// Components
import { HdButton, HdSelect } from 'homeday-blocks';
import WizardBase from '@/layout/WizardBase.vue';
import PriceContributionInput from '@/components/PriceContributionInput.vue';
import PriceInput from '@/components/PriceInput.vue';
import TileSelectWithReset from '@/components/TileSelectWithReset.vue';
// Services
import { mapFeatures } from '@/services/feature';
import { mapPriceContributions } from '@/services/priceContribution';
import { getItemsFromTranslationObject } from '@/services/helpers';
// Icons
import { basementCellar as slideIcon } from 'homeday-assets/L';
import { restore, plus, close } from 'homeday-assets';
// Constants
import { BASEMENT_TYPES } from '@/config/TRANSLATION_CONSTANTS';

const ITEM_PLACEHOLDER = {
  name: '',
  value: 0,
};
const ROW_LIMIT = 3;

export default {
  name: 'BasementSlide',
  components: {
    WizardBase,
    HdButton,
    HdSelect,
    PriceContributionInput,
    PriceInput,
    TileSelectWithReset,
  },
  data() {
    return {
      basementAvailability: [
        {
          text: this.$t('WIZARD.BASEMENT.OPTION.AVAILABLE'),
          // If we know how many basement rooms the property has already
          // we use it here to avoid setting it explicitly to 1 and loose the initial value
          value: this.$store.state.wizard.currentDeal.numberOfBasementRooms || 1,
        },
        {
          text: this.$t('WIZARD.BASEMENT.OPTION.NOT_AVAILABLE'),
          value: 0,
        },
      ],
      slideIcon,
      restore,
      plus,
      close,
      ROW_LIMIT,
      sumValue: 0,
    };
  },
  computed: {
    ...mapFeatures(['numberOfBasementRooms', 'basements']),
    ...mapPriceContributions(['basement']),
    unselectedOptions() {
      const selectedOptions = this.basements.map(({ name }) => name).filter((value) => value); // Gets the name of the selected valid options
      const allOptions = getItemsFromTranslationObject(BASEMENT_TYPES);
      return allOptions.filter(({ value }) => !selectedOptions.includes(value));
    },
    totalBasementsValue() {
      return this.basements.reduce((acc, { value }) => {
        return acc + value;
      }, 0);
    },
    formattedTotalValue() {
      return `+${this.totalBasementsValue.toLocaleString('de')} €`;
    },
  },
  watch: {
    totalBasementsValue(newValue) {
      if (!this.numberOfBasementRooms) return;
      this.basementPrice = newValue;
    },
  },
  methods: {
    resetForm(newValue) {
      this.numberOfBasementRooms = newValue;
      this.basements = [{ ...ITEM_PLACEHOLDER }];
    },
    addItem() {
      this.basements.push({ ...ITEM_PLACEHOLDER });
    },
    removeItem(index) {
      if (this.basements.length === 1) this.basements = [{ ...ITEM_PLACEHOLDER }];
      else this.basements.splice(index, 1);
    },
    availableOptions({ name }) {
      if (name) {
        const currentOption = getItemsFromTranslationObject(BASEMENT_TYPES).find(
          ({ value }) => name === value,
        );
        return [currentOption, ...this.unselectedOptions];
      }
      return this.unselectedOptions;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.basement {
  &__reset {
    margin-top: $sp-xs;
  }
  &__remove {
    margin-top: $sp-xs + $sp-xxs;
  }
  &__totals {
    align-items: center;

    p {
      @include font('DS-300');
      font-weight: 700;
    }
  }
  &__sum-label {
    text-align: right;
  }
}

.item-anim-enter-active,
.item-anim-leave-active {
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}
.item-anim-enter,
.item-anim-leave-to {
  opacity: 0;
  transform: translateY(-50%);
}
</style>
