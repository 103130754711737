import ImgixClient from '@imgix/js-core';
import { get } from 'lodash-es';

let client;
let clientParams;
let presets;

export function configure({ clientParams: cp = {}, presets: p = {} } = {}) {
  if (!cp.domain || !cp.secureURLToken) {
    console.error('Imgix.configure: `domain` and `secureURLToken` are required');
    return;
  }

  clientParams = cp;
  presets = p;
}

export function getClient() {
  if (client === undefined) {
    client = new ImgixClient(clientParams);
  }

  return client;
}

export function buildURL(url, params) {
  return getClient().buildURL(url, params);
}

export function getPreset(path) {
  const preset = get(presets, path);

  if (preset === undefined) {
    console.error(`preset '${path}' not found`);
  }

  return preset;
}

export default {
  getClient,
  buildURL,
  getPreset,
};
