<template>
  <wizard-base
    class="window-renovation"
    :title="$t('WIZARD.WINDOW_RENOVATION.TITLE')"
    :icon="slideIcon"
    :content-column-classes="['is-9-mobile', 'is-7', 'is-8-desktop']"
  >
    <TileSelectWithReset
      v-model="windows.type"
      :items="windowOptions"
      name="windowValue"
      class="window__select"
      @input="resetValues"
    />
    <div class="window-renovation__fields">
      <PriceContributionInput v-model="windowRenovationPrice" name="windowRenovation" />
    </div>
  </wizard-base>
</template>

<script>
// Components
import PriceContributionInput from '@/components/PriceContributionInput.vue';
import WizardBase from '@/layout/WizardBase.vue';
import TileSelectWithReset from '@/components/TileSelectWithReset.vue';
// Services
import { mapPriceContributions } from '@/services/priceContribution';
import { mapFeatures } from '@/services/feature';
import { getItemsFromTranslationObject } from '@/services/helpers';
// Icons
import { window as slideIcon } from 'homeday-assets/L';
// Constants
import { WINDOW_TYPES } from '@/config/TRANSLATION_CONSTANTS';

export default {
  name: 'WindowRenovationSlide',
  components: {
    PriceContributionInput,
    WizardBase,
    TileSelectWithReset,
  },
  data() {
    return {
      slideIcon,
    };
  },
  computed: {
    ...mapPriceContributions(['windowRenovation']),
    ...mapFeatures(['windows']),
    windowOptions() {
      return getItemsFromTranslationObject(WINDOW_TYPES);
    },
  },
  methods: {
    resetValues() {
      if (this.windows.type) return;
      this.windowRenovationPrice = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.window-renovation {
  &__fields {
    display: grid;

    @include for('tablet') {
      grid-template-columns: 1fr 1.5fr;
      grid-column-gap: $sp-m;
    }
  }
}
</style>
