import { validateYear, validateSpace } from '@/services/validators';
import { heatingNormalizer, windowNormalizer, basementNormalizer } from '../services/normalizers';

const FEATURES = [
  {
    name: 'actualColdRent',
  },
  {
    name: 'apartmentType',
  },
  {
    name: 'baseRentPerSqm',
  },
  {
    name: 'commercialBaseRentPerSqm',
  },
  {
    name: 'commercialSpace',
    validator: validateSpace,
  },
  {
    name: 'buildingSpace',
    validator: validateSpace,
  },
  {
    name: 'buildingType',
  },
  {
    name: 'constructionYear',
    validator: validateYear,
  },
  {
    name: 'floor',
  },
  {
    name: 'hasOutdoor',
  },
  {
    name: 'hasBalcony',
  },
  {
    name: 'hasBalconyWithRoofing',
  },
  {
    name: 'hasBalconyWithWindProtection',
  },
  {
    name: 'balconySize',
  },
  {
    name: 'balconyValue',
  },
  {
    name: 'hasTerrace',
  },
  {
    name: 'hasTerraceWithRoofing',
  },
  {
    name: 'hasTerraceWithWindProtection',
  },
  {
    name: 'terraceSize',
  },
  {
    name: 'terraceValue',
  },
  {
    name: 'hasWinterGarden',
  },
  {
    name: 'winterGardenSize',
  },
  {
    name: 'winterGardenValue',
  },
  {
    name: 'hasLoggia',
  },
  {
    name: 'loggiaSize',
  },
  {
    name: 'loggiaValue',
  },
  {
    name: 'hasElevator',
  },
  {
    name: 'hasGarden',
  },
  {
    name: 'hasParkingSpace',
  },
  {
    name: 'basements',
    normalizer: basementNormalizer,
  },
  {
    name: 'heating',
    normalizer: heatingNormalizer,
  },
  {
    name: 'lastFacadeRenovationYear',
    validator: validateYear,
  },
  {
    name: 'lastRenovationYear',
    validator: validateYear,
  },
  {
    name: 'lastRoofRenovationYear',
    validator: validateYear,
  },
  {
    name: 'windows',
    normalizer: windowNormalizer,
  },
  {
    name: 'livingSpace',
    validator: validateSpace,
  },
  {
    name: 'numberOfBasementRooms',
  },
  {
    name: 'numberOfFloors',
  },
  {
    name: 'numberOfRooms',
  },
  {
    name: 'ownerShare',
  },
  {
    name: 'plotArea',
    validator: validateSpace,
  },
  {
    name: 'potentialBaseRentPerSqm',
  },
  {
    name: 'potentialCommercialBaseRentPerSqm',
  },
  {
    name: 'quality',
  },
  {
    name: 'rentMultiplier',
  },
  {
    name: 'rentStatus',
  },
  {
    name: 'targetGroups',
  },
];

export default FEATURES;
