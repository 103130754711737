import { namespace } from '@/store/helpers';
import { MUTATION as APP_MUTATION } from './app/CONSTANTS';
import {
  MUTATION as USER_MUTATION,
  ACTION as USER_ACTION,
  GETTER as USER_GETTER,
} from './user/CONSTANTS';
import {
  MUTATION as WIZARD_MUTATION,
  ACTION as WIZARD_ACTION,
  GETTER as WIZARD_GETTER,
} from './wizard/CONSTANTS';

export const WIZARD_MODULE_NAME = 'wizard';
export const USER_MODULE_NAME = 'user';

export const MUTATION = {
  ...APP_MUTATION,
  ...namespace(WIZARD_MUTATION, WIZARD_MODULE_NAME),
  ...namespace(USER_MUTATION, USER_MODULE_NAME),
};

export const ACTION = {
  ...namespace(WIZARD_ACTION, WIZARD_MODULE_NAME),
  ...namespace(USER_ACTION, USER_MODULE_NAME),
};

export const GETTER = {
  ...namespace(WIZARD_GETTER, WIZARD_MODULE_NAME),
  ...namespace(USER_GETTER, USER_MODULE_NAME),
};
