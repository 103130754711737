<template>
  <wizard-base class="parking" :title="$t('WIZARD.PARKING.TITLE')" :icon="slideIcon">
    <div class="parking__fields">
      <TileSelectWithReset
        v-model="hasParkingSpace"
        :items="parkingSpaceItems"
        type="number"
        name="parkingValue"
        class="parking__select"
      />
      <PriceContributionInput v-model="parkingPrice" name="parking" class="parking__input" />
    </div>
  </wizard-base>
</template>

<script>
// Components
import WizardBase from '@/layout/WizardBase.vue';
import PriceContributionInput from '@/components/PriceContributionInput.vue';
import TileSelectWithReset from '@/components/TileSelectWithReset.vue';
// Services
import { mapFeatures } from '@/services/feature';
import { mapPriceContributions } from '@/services/priceContribution';
// Icons
import { parkingLot as slideIcon } from 'homeday-assets/L';

export default {
  name: 'ParkingSlide',
  components: {
    WizardBase,
    PriceContributionInput,
    TileSelectWithReset,
  },
  data() {
    return {
      parkingSpaceItems: [
        {
          text: this.$t('GENERAL.YES'),
          value: true,
        },
        {
          text: this.$t('GENERAL.NO'),
          value: false,
        },
      ],
      slideIcon,
    };
  },
  computed: {
    ...mapFeatures(['hasParkingSpace']),
    ...mapPriceContributions(['parking']),
  },
};
</script>
