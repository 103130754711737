import { get as _get } from 'lodash-es';

// Services
import { i18n } from '@/services/i18n';

// Dynamic wizard section components
import WizardPriceSuggestedSqmPrice from '@/components/WizardPriceSuggestedSqmPrice.vue';
import WizardPriceTotalPrice from '@/components/WizardPriceTotalPrice.vue';
import WizardPriceMarketingPrice, { PRICE_LABEL } from '@/components/WizardPriceMarketingPrice.vue';
import WizardPriceEarningsValuePrice from '@/components/WizardPriceEarningsValuePrice.vue';

// Views
import AdditionalFeaturesSlide from '@/views/wizard/AdditionalFeatures.vue';
import ApartmentAccessibilitySlide from '@/views/wizard/ApartmentAccessibility.vue';
import BalconyAndTerraceSlide from '@/views/wizard/BalconyAndTerrace.vue';
import BankValuationSlide from '@/views/wizard/BankValuation.vue';
import BathroomSlide from '@/views/wizard/Bathroom.vue';
import BasementSlide from '@/views/wizard/Basement.vue';
import BuildingTypeSlide from '@/views/wizard/BuildingType.vue';
import CalculatedPriceSlide from '@/views/wizard/CalculatedPrice.vue';
import CapitalInvestorSlide from '@/views/wizard/CapitalInvestor.vue';
import ConfirmationSlide from '@/views/wizard/Confirmation.vue';
import ConstructionAndRenovationSlide from '@/views/wizard/ConstructionAndRenovation.vue';
import EarningsValueSlide from '@/views/wizard/EarningsValue.vue';
import Explanation from '@/views/wizard/Explanation.vue';
import FacadeRenovationSlide from '@/views/wizard/FacadeRenovation.vue';
import FloorsSlide from '@/views/wizard/Floors.vue';
import GardenSlide from '@/views/wizard/Garden.vue';
import HeatingRenovationSlide from '@/views/wizard/HeatingRenovation.vue';
import InstructionSlide from '@/views/wizard/Instruction.vue';
import ListingRotationSlide from '@/views/wizard/ListingRotation.vue';
import LivingAndCommercialSpace from '@/views/wizard/LivingAndCommercialSpace.vue';
import LivingSpaceSlide from '@/views/wizard/LivingSpace.vue';
import LocationSlide from '@/views/wizard/Location.vue';
import MarketDevelopment from '@/views/wizard/MarketDevelopment.vue';
import OwnerShareSlide from '@/views/wizard/OwnerShare.vue';
import ParkingSlide from '@/views/wizard/Parking.vue';
import PlotAreaSlide from '@/views/wizard/PlotArea.vue';
import ProcedureMultiFamilyHouse from '@/views/wizard/ProcedureMultiFamilyHouse.vue';
import ProcedureSlide from '@/views/wizard/Procedure.vue';
import RentStatusMultiFamilyHouseSlide from '@/views/wizard/RentStatusMultiFamilyHouse.vue';
import RentStatusSlide from '@/views/wizard/RentStatusDefault.vue';
import RoofRenovationSlide from '@/views/wizard/RoofRenovation.vue';
import RoomsSlide from '@/views/wizard/Rooms.vue';
import SellingStrategySlide from '@/views/wizard/SellingStrategy.vue';
import SimilarPropertiesSlide from '@/views/wizard/SimilarProperties.vue';
import TargetGroupsSlide from '@/views/wizard/TargetGroups.vue';
import WindowRenovationSlide from '@/views/wizard/WindowRenovation.vue';

// Icons
import { chart } from 'homeday-assets';

export const PRICE_COMPONENT = {
  SQM: WizardPriceSuggestedSqmPrice,
  TOTAL: WizardPriceTotalPrice,
  MARKETING: WizardPriceMarketingPrice,
  EARNINGS_VALUE: WizardPriceEarningsValuePrice,
};

// Here we register all the slides used by the wizard
export const SLIDES = {
  explanation: {
    path: 'explanation',
    name: 'explanationSlide',
    component: Explanation,
    meta: {
      title: i18n.t('WIZARD.EXPLANATION.TITLE'),
    },
  },
  procedure: {
    path: 'procedure',
    name: 'procedureSlide',
    component: ProcedureSlide,
    meta: {
      title: i18n.t('WIZARD.PROCEDURE.TITLE'),
    },
  },
  procedureMultiFamilyHouse: {
    path: 'procedureMultiFamilyHouse',
    name: 'procedureMultiFamilyHouseSlide',
    component: ProcedureMultiFamilyHouse,
    meta: {
      title: i18n.t('WIZARD.PROCEDURE.TITLE'),
    },
  },
  location: {
    path: 'location',
    name: 'locationSlide',
    component: LocationSlide,
    meta: {
      title: i18n.t('GENERAL.LOCATION_SITUATION'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.SQM,
      props: {
        editable: true,
      },
    },
  },
  livingSpace: {
    path: 'livingSpace',
    name: 'livingSpaceSlide',
    component: LivingSpaceSlide,
    meta: {
      title: i18n.t('WIZARD.LIVING_SPACE.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.SQM,
    },
  },
  calculatedPrice: {
    path: 'calculatedPrice',
    name: 'calculatedPriceSlide',
    component: CalculatedPriceSlide,
    meta: {
      title: i18n.t('WIZARD.CALCULATED_PRICE.TITLE'),
    },
  },
  constructionAndRenovation: {
    path: 'constructionAndRenovation',
    name: 'constructionAndRenovationSlide',
    component: ConstructionAndRenovationSlide,
    meta: {
      title: i18n.t('WIZARD.CONSTRUCTION_AND_RENOVATION.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
  },
  apartmentAccessibility: {
    path: 'apartmentAccessibility',
    name: 'apartmentAccessibilitySlide',
    component: ApartmentAccessibilitySlide,
    meta: {
      title: i18n.t('WIZARD.FLOORS.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
  },
  plotArea: {
    path: 'plotArea',
    name: 'plotAreaSlide',
    component: PlotAreaSlide,
    meta: {
      title: i18n.t('GENERAL.PLOT_AREA'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
  },
  rooms: {
    path: 'rooms',
    name: 'roomsSlide',
    component: RoomsSlide,
    meta: {
      title: i18n.t('WIZARD.ROOMS.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
  },
  rentStatus: {
    path: 'rentStatus',
    name: 'rentStatusSlide',
    component: RentStatusSlide,
    meta: {
      title: i18n.t('WIZARD.RENT_STATUS.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
  },
  rentStatusMultiFamilyHouse: {
    path: 'rentStatusMultiFamilyHouse',
    name: 'rentStatusMultiFamilyHouseSlide',
    component: RentStatusMultiFamilyHouseSlide,
    meta: {
      title: i18n.t('WIZARD.RENT_STATUS.TITLE'),
    },
  },
  parking: {
    path: 'parking',
    name: 'parkingSlide',
    component: ParkingSlide,
    meta: {
      title: i18n.t('WIZARD.PARKING.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
  },
  balconyAndTerrace: {
    path: 'balconyAndTerrace',
    name: 'balconyAndTerraceSlide',
    component: BalconyAndTerraceSlide,
    meta: {
      title: i18n.t('WIZARD.BALCONY_TERRACE.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
  },
  garden: {
    path: 'garden',
    name: 'gardenSlide',
    component: GardenSlide,
    meta: {
      title: i18n.t('WIZARD.GARDEN.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
  },
  additionalFeatures: {
    path: 'additionalFeatures',
    name: 'additionalFeaturesSlide',
    component: AdditionalFeaturesSlide,
    meta: {
      title: i18n.t('GENERAL.APPRECIATION_AND_DEPRECIATION'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
  },
  targetGroups: {
    path: 'targetGroups',
    name: 'targetGroupsSlide',
    component: TargetGroupsSlide,
    meta: {
      title: i18n.t('WIZARD.TARGET_GROUP.PAGE_TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
  },
  capitalInvestor: {
    path: 'capitalInvestor',
    name: 'capitalInvestorSlide',
    component: CapitalInvestorSlide,
    meta: {
      title: i18n.t('WIZARD.CAPITAL_INVESTOR.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.MARKETING,
      props: {
        editable: true,
        label: PRICE_LABEL.SQM_PRICE,
      },
    },
    shouldBeSkipped: ({ state }) => {
      const deal = state.wizard.currentDeal;

      // If capital investors are not selected as target group
      // we skip this slide
      return !deal.targetGroups || !deal.targetGroups.includes('capital_investor');
    },
  },
  bankValuation: {
    path: 'bankValuation',
    name: 'bankValuationSlide',
    component: BankValuationSlide,
    meta: {
      title: i18n.t('WIZARD.BANK_VALUATION.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.MARKETING,
      props: {
        editable: true,
        label: PRICE_LABEL.SQM_PRICE,
      },
    },
    // Hidden as part of: https://homeday.atlassian.net/browse/TOFU-5386
    shouldBeSkipped: () => true,
  },
  similarProperties: {
    path: 'similarProperties',
    name: 'similarPropertiesSlide',
    component: SimilarPropertiesSlide,
    meta: {
      title: i18n.t('WIZARD.SIMILAR_PROPERTIES.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.MARKETING,
      props: {
        editable: true,
        label: PRICE_LABEL.SQM_PRICE,
      },
    },
  },
  sellingStrategy: {
    path: 'sellingStrategy',
    name: 'sellingStrategySlide',
    component: SellingStrategySlide,
    meta: {
      title: i18n.t('WIZARD.SELLING_STRATEGY.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.MARKETING,
      props: {
        editable: true,
        label: PRICE_LABEL.STARTING_PRICE,
      },
    },
    // Hidden as part of: https://homeday.atlassian.net/browse/TOFU-5386
    shouldBeSkipped: () => true,
  },
  listingRotation: {
    path: 'listingRotation',
    name: 'listingRotationSlide',
    component: ListingRotationSlide,
    meta: {
      title: i18n.t('WIZARD.LISTING_ROTATION.PAGE_TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.MARKETING,
      props: {
        label: PRICE_LABEL.STARTING_PRICE,
      },
    },
    // Hidden as part of: https://homeday.atlassian.net/browse/TOFU-5386
    shouldBeSkipped: () => true,
  },
  instruction: {
    path: 'instruction',
    name: 'instructionSlide',
    component: InstructionSlide,
    meta: {
      title: i18n.t('WIZARD.INSTRUCTION.PAGE_TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.MARKETING,
      props: {
        editable: true,
        label: PRICE_LABEL.STARTING_PRICE,
      },
    },
  },
  confirmation: {
    path: 'confirmation',
    name: 'confirmationSlide',
    component: ConfirmationSlide,
    meta: {
      title: i18n.t('WIZARD.CONFIRMATION.PAGE_TITLE'),
    },
    shouldBeSkipped: ({ state }) => {
      return !(
        state.wizard.currentDeal.updateEvaluationReport ||
        state.wizard.currentDeal.lastEvaluationReport ||
        state.wizard.currentDeal.isEvaluationSubmitted
      );
    },
  },
  floors: {
    path: 'floors',
    name: 'floorsSlide',
    component: FloorsSlide,
    meta: {
      title: i18n.t('WIZARD.FLOORS.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
  },
  facadeRenovation: {
    path: 'facadeRenovation',
    name: 'facadeRenovationSlide',
    component: FacadeRenovationSlide,
    meta: {
      title: i18n.t('GENERAL.FACADE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
    shouldBeSkipped: ({ state }) =>
      !_get(state, 'wizard.currentDeal.shouldShowDetailedRenovations', false),
  },
  windowRenovation: {
    path: 'windowRenovation',
    name: 'windowRenovationSlide',
    component: WindowRenovationSlide,
    meta: {
      title: i18n.t('WIZARD.WINDOW_RENOVATION.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
    shouldBeSkipped: ({ state }) =>
      !_get(state, 'wizard.currentDeal.shouldShowDetailedRenovations', false),
  },
  roofRenovation: {
    path: 'roofRenovation',
    name: 'roofRenovationSlide',
    component: RoofRenovationSlide,
    meta: {
      title: i18n.t('WIZARD.ROOF_RENOVATION.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
    shouldBeSkipped: ({ state }) =>
      !_get(state, 'wizard.currentDeal.shouldShowDetailedRenovations', false),
  },
  marketDevelopment: {
    path: 'marketDevelopment',
    name: 'marketDevelopment',
    component: MarketDevelopment,
    meta: {
      title: i18n.t('WIZARD.MARKET_DEVELOPMENT'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
    overlay: {
      icon: chart,
      titleAccent: '04',
      title: 'GENERAL.ASSESSMENT_REPORT',
      description: 'WIZARD.OVERLAY_DESCRIPTION.SELLING_STRATEGY',
    },
    // Hidden as part of: https://homeday.atlassian.net/browse/TOFU-5386
    shouldBeSkipped: () => true,
  },
  heatingRenovation: {
    path: 'heatingRenovation',
    name: 'heatingRenovationSlide',
    component: HeatingRenovationSlide,
    meta: {
      title: i18n.t('WIZARD.HEATING_RENOVATION.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
    shouldBeSkipped: ({ state }) =>
      !_get(state, 'wizard.currentDeal.shouldShowDetailedRenovations', false),
  },
  bathroom: {
    path: 'bathroom',
    name: 'bathroomSlide',
    component: BathroomSlide,
    meta: {
      title: i18n.t('WIZARD.BATHROOM.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
  },
  buildingType: {
    path: 'buildingType',
    name: 'buildingTypeSlide',
    component: BuildingTypeSlide,
    meta: {
      title: i18n.t('WIZARD.BUILDING_TYPE.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
  },
  basement: {
    path: 'basement',
    name: 'basementSlide',
    component: BasementSlide,
    meta: {
      title: i18n.t('WIZARD.BASEMENT.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
  },
  ownerShare: {
    path: 'ownerShare',
    name: 'ownerShareSlide',
    component: OwnerShareSlide,
    meta: {
      title: i18n.t('WIZARD.OWNER_SHARE.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.TOTAL,
    },
    shouldBeSkipped: ({ state }) =>
      !_get(state, 'wizard.currentDeal.shouldShowDetailedRenovations', false),
  },
  earningsValue: {
    path: 'earningsValue',
    name: 'earningsValueSlide',
    component: EarningsValueSlide,
    meta: {
      title: i18n.t('WIZARD.EARNINGS_VALUE.TITLE'),
    },
    headerCenter: {
      component: PRICE_COMPONENT.EARNINGS_VALUE,
    },
  },
  livingAndCommercialSpace: {
    path: 'livingAndCommercialSpace',
    name: 'livingAndCommercialSpaceSlide',
    component: LivingAndCommercialSpace,
    meta: {
      title: i18n.t('WIZARD.LIVING_AND_COMMERCIAL_SPACE.TITLE'),
    },
  },
};

export default Object.values(SLIDES).map((slide) => ({
  path: slide.path,
  name: slide.name,
  component: slide.component,
  meta: {
    title: _get(slide, 'meta.title'),
  },
}));
