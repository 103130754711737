<template>
  <div
    class="deal-item"
    :class="{
      'deal-item--highlight': highlight,
    }"
    :data-deal-id="deal.uid"
  >
    <div class="deal-item__row deal-item__row--top">
      <div class="deal-item__inner deal-item__inner--left">
        <span ref="address" class="deal-item__address">{{ addressLine }}</span>
        <span ref="owner" class="deal-item__owner">{{ deal.ownerSalutation }}</span>
        <div ref="appointments" class="deal-item__appointments">
          <div
            v-if="isFirstAppointment"
            ref="firstAppointment"
            class="deal-item__appointment deal-item__appointment--first"
          >
            <span class="deal-item__appointment deal-item__appointment--bold">
              {{ $t('WIZARD.LIST.FIRST_APPOINTMENT') }}
            </span>
            <span>
              {{ $formatDate(appointment.from) }}
            </span>
          </div>
          <div
            v-else-if="isFollowUpAppointment"
            ref="followUpAppointment"
            class="deal-item__appointment deal-item__appointment--follow-up"
          >
            <span class="deal-item__appointment deal-item__appointment--bold">
              {{ $t('WIZARD.LIST.FOLLOW_UP_APPOINTMENT') }}
            </span>
            <span>
              {{ $formatDate(appointment.from) }}
            </span>
          </div>
          <div
            v-if="hasLastEvaluation"
            ref="lastEvaluation"
            class="deal-item__appointment deal-item__appointment--last-evaluation"
          >
            <span class="deal-item__appointment deal-item__appointment--bold">
              {{ $t('WIZARD.LIST.LAST_VALUATION') }}
            </span>
            <span>
              {{ $formatDate(deal.lastEvaluation.createdAt) }}
            </span>
          </div>
        </div>
      </div>
      <div class="deal-item__inner deal-item__inner--right">
        <hd-button
          modifier="primary"
          type="button"
          data-cy="deal-start-evaluation"
          @click.native="$emit('cta-click')"
        >
          {{ $t('WIZARD.LIST.START_VALUATION') }}
        </hd-button>
      </div>
      <div ref="info" class="deal-item__info" @click="$emit('info-click')">
        <HdIcon :src="info" width="100%" height="100%" />
      </div>
    </div>
  </div>
</template>

<script>
// Components
import { HdButton, HdIcon } from 'homeday-blocks';
// Icons
import { info } from 'homeday-assets';

export default {
  name: 'DealItem',
  components: {
    HdButton,
    HdIcon,
  },
  props: {
    deal: {
      type: Object,
      required: true,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      info,
    };
  },
  computed: {
    addressLine() {
      const propertyTypeKey = this.deal.propertyType.toUpperCase();
      const propertyType = this.$t(`GENERAL.${propertyTypeKey}`);

      return `${propertyType}, ${this.deal.address.formatted_address}`;
    },
    appointment() {
      // If a deal doesn't have an upcoming appointment we fall back to the last one
      return this.deal?.nextAppointment || this.deal?.lastAppointment;
    },
    appointmentType() {
      return this.appointment?.type;
    },
    isFirstAppointment() {
      return this.appointmentType === 'first_appointment';
    },
    isFollowUpAppointment() {
      return this.appointmentType === 'follow_up_appointment';
    },
    hasLastEvaluation() {
      return this.deal.lastEvaluation.createdAt;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.deal-item {
  $dealItemContainer: &;
  background-color: white;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  &--highlight {
    color: $quaternary-color;
    background-color: getShade($secondary-color, 60);
  }

  &__row {
    flex-basis: 100%;

    &--top {
      display: flex;
      justify-content: space-between;
      position: relative;

      @include for('mobile-only') {
        flex-direction: column;
      }
    }

    &--bottom {
      margin-top: $sp-s;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;

    &--left {
      flex-grow: 4;

      @include for('mobile-only') {
        padding-right: $sp-l; // Avoid overflow with icon
      }
    }

    &--right {
      flex-grow: 1;
      flex-shrink: 0;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  &__address {
    @include font('DS-200');
    font-weight: 900;
    @include for('mobile-only') {
      @include font('DS-100');
    }
  }

  &__owner {
    @include font('DS-100');
  }

  &__appointments {
    @include font('DS-100');

    margin-top: auto;
  }

  &__appointment {
    &--bold {
      font-weight: bold;
    }
  }

  &__info {
    background-color: transparent;
    width: 36px;
    height: 36px;
    border: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;

    ::v-deep path {
      fill: currentColor;
    }

    @include for('mobile-only') {
      right: $sp-m;
      top: $sp-m;
    }
  }

  .btn {
    width: 100%;
  }
}
</style>
