<template>
  <wizard-base
    class="heating-renovation"
    :title="$t('WIZARD.HEATING_RENOVATION.TITLE')"
    :icon="slideIcon"
    :content-column-classes="['is-9']"
  >
    <div v-for="(item, index) in editablePayload" :key="index">
      <div class="columns heating-renovation__fields">
        <div class="column is-10">
          <div class="columns">
            <hd-select
              v-model="editablePayload[index].name"
              class="column is-4"
              :options="availableHeatingOptions(editablePayload[index].name)"
              :name="`heating-type-${item.name}-${index}`"
              type="number"
              :label="$t('WIZARD.HEATING_RENOVATION.HEATING_LABEL')"
            />
            <hd-input
              v-model="editablePayload[index].renovationYear"
              :name="`renovationYear-${index}`"
              type="number"
              :label="$t('GENERAL.LAST_RENOVATION')"
              class="column is-4 heating-renovation__year-input"
              :custom-rules="customRules"
            />
            <PriceInput
              v-model="editablePayload[index].price"
              class="column is-4"
              :name="`price-${index}`"
              :label="$t('WIZARD.CHANGE_IN_VALUE')"
              :parse-to-number="true"
            />
          </div>
        </div>
        <div class="column is-2 heating-renovation__actions">
          <HdButton
            v-show="false"
            class="heating-renovation__action-button"
            modifier="tertiary"
            :icon-src="calculatorIcon"
            data-testid="calculator-button"
          />
          <HdButton
            :icon-src="removeIcon"
            :aria-label="$t('GENERAL.REMOVE')"
            type="button"
            modifier="tertiary"
            class="heating-renovation__action-button"
            data-testid="remove-button"
            @click="removeItem(index)"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column is-11">
          <EnergyLawInformation
            v-if="obsoleteHeatingOptions.includes(editablePayload[index].name)"
            :possible-alternatives="editablePayload[index].possibleAlternatives"
            @update="(value) => (editablePayload[index].possibleAlternatives = value)"
          />
        </div>
      </div>
    </div>
    <FormControlBar
      :show-add-button="editablePayload.length < heatingOptions.length"
      :show-total="editablePayload.length > 1"
      :total="heatingPrice"
      @add="addItem"
    />
  </wizard-base>
</template>

<script>
// Components
import { HdInput, HdSelect, HdButton } from 'homeday-blocks';
import WizardBase from '@/layout/WizardBase.vue';
import EnergyLawInformation from '@/components/EnergyLawInformation.vue';
import PriceInput from '@/components/PriceInput.vue';
import FormControlBar from '@/components/FormControlBar.vue';
// Services
import { mapFeatures } from '@/services/feature';
import { mapPriceContributions } from '@/services/priceContribution';
import { getItemsFromTranslationObject } from '@/services/helpers';
import {
  isNumber as _isNumber,
  inRange as _inRange,
  camelCase as _camelCase,
  snakeCase as _snakeCase,
  toFinite as _toFinite,
} from 'lodash-es';
import { CURRENT_YEAR } from '@/config/CONSTANTS';
import { HEATING_TYPES, OBSOLETE_HEATING_TYPES } from '@/config/TRANSLATION_CONSTANTS';
import {
  calculator as calculatorIcon,
  close as closeIcon,
  restore as restoreIcon,
} from 'homeday-assets';
import { boiler as slideIcon } from 'homeday-assets/L';

const ITEM_PLACEHOLDER = {
  name: '',
  price: 0,
  renovationYear: null,
  possibleAlternatives: {},
};

export default {
  name: 'HeatingRenovationSlide',
  components: {
    EnergyLawInformation,
    FormControlBar,
    WizardBase,
    HdSelect,
    HdInput,
    PriceInput,
    HdButton,
  },
  data() {
    return {
      editablePayload: [{ ...ITEM_PLACEHOLDER }],
      submittingPayload: {
        types: [],
      },
      heatingOptions: getItemsFromTranslationObject(HEATING_TYPES),
      slideIcon,
      calculatorIcon,
      closeIcon,
      restoreIcon,
    };
  },
  computed: {
    deal() {
      return this.$store.state.wizard.currentDeal;
    },
    ...mapFeatures(['heating']),
    ...mapPriceContributions(['heating']),
    customRules() {
      return [
        {
          validate: (value) => {
            if (!_isNumber(Number(value))) return false;
            return _inRange(_toFinite(value), this.deal.constructionYear, CURRENT_YEAR + 1);
          },
          errorMessage: this.$t('FIELD_VALIDATIONS.INCORRECT_CONSTRUCTION_YEAR'),
        },
      ];
    },
    removeIcon() {
      return this.editablePayload.length === 1 ? restoreIcon : closeIcon;
    },
    obsoleteHeatingOptions() {
      return getItemsFromTranslationObject(OBSOLETE_HEATING_TYPES).map((i) => i.value);
    },
  },
  watch: {
    editablePayload: {
      handler() {
        // Update only after saving other fields in store to avoid reset
        this.updateSumValue();
        this.submitPayloadToStore();
      },
      deep: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.heating?.types?.length) {
        this.editablePayload = this.heating.types.map((option) => {
          return {
            name: _snakeCase(option.name),
            price: Number(option.price),
            renovationYear: Number(option.renovationYear),
            possibleAlternatives: option.possibleAlternatives || {},
          };
        });
      }

      this.updateSumValue();
    },
    updateSumValue() {
      const price = this.editablePayload.reduce((acc, current) => {
        // No need to calculate value of obsolete heating types due to changed law. Will be worth 0 from 2024 on.
        if (this.obsoleteHeatingOptions.includes(current.name)) {
          return acc;
        }

        return current.name && current.price ? current.price + acc : acc;
      }, 0);

      this.heatingPrice = Math.round(price);
    },
    submitPayloadToStore() {
      this.copyTypes();
      this.heating = this.submittingPayload;
    },
    copyTypes() {
      const filteredTypes = [];
      // We filter empty entries and zeros
      this.editablePayload.forEach((item) => {
        if (item.price !== 0 && item.name !== '') {
          filteredTypes.push({
            name: _camelCase(item.name),
            price: item?.price,
            renovationYear: item.renovationYear,
            possibleAlternatives: item.possibleAlternatives || {},
          });
        }
      });
      this.submittingPayload.types = filteredTypes;
    },
    availableHeatingOptions(currentOption) {
      // Each entry is unique and cannot be added more than one time.
      const unavailableOptions = this.editablePayload
        .map((i) => i.name)
        .filter((i) => i !== currentOption);
      return this.heatingOptions.filter((option) => !unavailableOptions.includes(option.value));
    },
    addItem() {
      this.editablePayload = [...this.editablePayload, { ...ITEM_PLACEHOLDER }];
    },
    removeItem(index) {
      if (this.editablePayload.length === 1) this.editablePayload = [{ ...ITEM_PLACEHOLDER }];
      else this.editablePayload.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.heating-renovation {
  ::v-deep .wizard-base__container {
    align-items: flex-start;
  }

  &__actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: $sp-xs;
    grid-row-gap: 0;
  }
  &__action-button {
    width: 44px;
    height: 44px;
    margin-bottom: 0;
    margin-top: $sp-xs;
    grid-area: 1 / 1 / 2 / 2;

    & + & {
      grid-area: 1 / 2 / 2 / 3;
    }
  }
}
</style>
