<template>
  <div class="energy-law">
    <details :open="isToggleOpen">
      <summary class="energy-law__heading" @click.prevent="toggleHandler">
        <hd-icon class="energy-law__heading-arrow" :src="chevronIcon" :height="32" :width="32" />
        <hd-icon :src="infoIcon" class="energy-law__info-icon" />
        <p class="energy-law__heading-text">
          Gebäudeenergiegesetz ab 1.1.2024 (nach aktuellem Kenntnisstand)
        </p>
      </summary>
      <section>
        <p class="energy-law__description">
          Ab 2024 müssen in Deutschland Heizungen verwendet werden, die zu mindestens 65% mit
          erneuerbaren Energien betrieben werden. Wenn eine Gas- oder Ölheizung defekt wird oder
          seit 30 Jahren in Betrieb ist, muss sie durch eine umweltfreundlichere Heizung ersetzt
          werden.
        </p>
        <p class="energy-law__question">
          Falls aktuell keine alternativen Heizsysteme in der Immobilie vorhanden sind, welche sind
          für neue Eigentümer möglich?
        </p>
        <div class="energy-law__options">
          <div
            v-for="option in heatingSystemTypes"
            :key="option.label"
            class="energy-law__option columns"
          >
            <HdCheckbox
              class="column is-4 energy-law__checkbox"
              :name="option.label"
              :inner-label="option.label"
              lang="de"
              :value="state[option.value]"
              @input="(isChecked) => updateCheckbox(option.value, isChecked)"
            />
            <price-input
              v-if="state[option.value]"
              ref="input"
              v-model="state[`${option.value}Price`]"
              :name="`${option.value}_price`"
              type="number"
              :label="$t('WIZARD.COSTS.ESTIMATED_INSTALLATION_COST')"
              class="column is-6 energy-law__input"
              :parse-to-number="false"
            />
          </div>
        </div>
      </section>
    </details>
  </div>
</template>

<script>
// Components
import { HdCheckbox, HdIcon } from 'homeday-blocks';
import PriceInput from '@/components/PriceInput.vue';

// Icons
import { info as infoIcon, chevron as chevronIcon } from 'homeday-assets';
import { ALTERNATIVE_HEATING_TYPES } from '@/config/TRANSLATION_CONSTANTS';
import { getItemsFromTranslationObject } from '@/services/helpers';

// Services
import { camelCase as _camelCase } from 'lodash-es';

export default {
  name: 'EnergyLawInformation',
  components: { HdCheckbox, PriceInput, HdIcon },
  props: {
    possibleAlternatives: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      infoIcon,
      chevronIcon,
      isToggleOpen: false,
    };
  },
  computed: {
    state: {
      get() {
        return this.possibleAlternatives;
      },
      set(value) {
        this.$emit('update', value);
      },
    },
    heatingSystemTypes() {
      return getItemsFromTranslationObject(ALTERNATIVE_HEATING_TYPES).map((i) => ({
        label: i.label,
        value: _camelCase(i.value),
      }));
    },
  },
  methods: {
    toggleHandler() {
      this.isToggleOpen = !this.isToggleOpen;
    },
    updateCheckbox(option, isChecked) {
      const state = { ...this.state, [option]: isChecked };
      if (!isChecked) {
        // Remove price value
        state[`${option}Price`] = undefined;
      }
      this.state = state;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
$gap: $sp-m + $sp-s;

.energy-law {
  $root: &;
  border: 1px solid getShade($quaternary-color, 60);
  border-radius: $sp-xs;
  padding: $gap;
  margin-bottom: $sp-m + $sp-s;

  &__heading {
    position: relative;
    padding-left: $gap + $sp-s;
    padding-right: $gap + $sp-s;

    &::marker {
      display: none;
      content: '';
    }

    &-text {
      @include fontGroup(200, 900);
    }

    &-arrow {
      display: block;
      position: absolute;
      height: $sp-l;
      width: $sp-l;
      top: 50%;
      margin-top: -$sp-m;
      right: 0;
      transform: rotate(90deg);
      transition: transform 0.3s ease-in-out;
      color: inherit;
    }
  }

  details[open] {
    #{$root}__heading-arrow {
      transform: rotate(-90deg);
    }
  }

  &__info-icon {
    position: absolute;
    left: 0;
    top: $sp-xs;
  }

  &__description {
    margin-top: $sp-m;
    margin-bottom: $sp-m;
  }

  &__question {
    @include fontGroup(200, 900);
    margin-bottom: $sp-m;
  }

  &__checkbox {
    margin-top: $sp-m;
  }

  &__input {
    ::v-deep .field__label {
      white-space: nowrap;
    }
  }
}
</style>
