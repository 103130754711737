<template>
  <div
    :class="{
      'inner-notification--loading': loading,
    }"
    class="inner-notification"
  >
    <span class="inner-notification__message">
      {{ notification.message }}
    </span>
    <button
      v-if="notification.buttonLabel"
      ref="button"
      type="button"
      class="inner-notification__button inner-notification__label-button"
      @click="notification.buttonAction"
    >
      {{ notification.buttonLabel }}
      <div class="inner-notification__loading-spinner" />
    </button>
    <button
      v-if="notification.isClosable"
      type="button"
      class="inner-notification__button inner-notification__close-button"
      @click.stop="notification.closeButtonAction"
    >
      <HdIcon class="inner-notification__close-icon" :src="closeIcon" width="24" height="24" />
    </button>
  </div>
</template>

<script>
import { HdIcon } from 'homeday-blocks';
import { close as closeIcon } from 'homeday-assets';

export default {
  name: 'NotificationItem',
  components: {
    HdIcon,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      closeIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.inner-notification {
  display: flex;
  align-items: center;
  $notification: &;

  &__message {
    width: 97%;
    margin-right: $sp-xs;
  }
  &__button {
    position: relative;
    color: inherit;
    border: 0;
    padding: 0;
    background: transparent;
    font: inherit;
    cursor: pointer;
    outline: 0;
    transition: color 0.15s ease-in-out;
    text-align: center;

    #{$notification}--loading & {
      pointer-events: none;
      color: transparent;
    }
  }

  &__label-button {
    box-shadow: inset 0 -2px 0 0 currentColor;
  }
  ::v-deep &__close-icon {
    color: $primary-bg;
    path {
      fill: white;
    }
  }

  &__loading-spinner {
    $size: 20px;

    display: none;
    position: absolute;
    top: calc(50% - calc(#{$size} / 2));
    left: calc(50% - calc(#{$size} / 2));
    width: $size;
    height: $size;
    border: 2px solid $white;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    animation: spin 0.5s infinite linear;

    #{$notification}--loading & {
      display: block;
      opacity: 1;
    }
  }
}
</style>
