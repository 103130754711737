import Vue from 'vue';
import { get } from 'lodash-es';
import { BreakpointsService } from 'homeday-blocks';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/registerServiceWorker';
import CONFIG from '@/config/env';
import IMGIX_PRESETS from '@/config/IMGIX_PRESETS';
import { init as initSentry } from '@/services/sentry';
import { enable as enableInterceptors } from '@/services/axios/interceptors';
import { configure as configureImgix } from '@/services/imgix';
import { i18n } from '@/services/i18n';
import breakpoints from '@/styles/breakpoints.scss';
import formattersPlugin from '@/plugins/formatters';

Vue.config.productionTip = false;

// Services initialization
BreakpointsService.setBreakpoints(breakpoints);

initSentry({
  environment: get(CONFIG, 'environment'),
  dsn: get(CONFIG, 'integrations.sentry.dsn'),
});

configureImgix({
  clientParams: {
    domain: get(CONFIG, 'integrations.imgix.domain'),
    secureURLToken: get(CONFIG, 'integrations.imgix.secureURLToken'),
  },
  presets: IMGIX_PRESETS,
});

enableInterceptors();

// Plugins installation
Vue.use(formattersPlugin);

// Vue instantiation
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
