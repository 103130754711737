import { render, staticRenderFns } from "./Instruction.vue?vue&type=template&id=400c7871&scoped=true&"
import script from "./Instruction.vue?vue&type=script&lang=js&"
export * from "./Instruction.vue?vue&type=script&lang=js&"
import style0 from "./Instruction.vue?vue&type=style&index=0&id=400c7871&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "400c7871",
  null
  
)

export default component.exports