import { get as _get, isNil as _isNil } from 'lodash-es';
import linearInterpolator from 'linear-interpolator';
import { CURRENT_YEAR, validateYear, validateSpace } from '@/services/validators';
import { findInRange } from '../helper';
import {
  AVERAGE_CONSTRUCTION_YEAR_RANGES,
  LIVING_SPACE_TO_ROOMS_RANGES,
  HEATING_LIFE_SPAN,
  HEATING_PRICE,
  WINDOW_LIFE_SPAN,
  AVERAGE_WINDOW_AGE,
  WINDOW_PRICE_PER_UNIT,
  AVERAGE_WINDOW_AREA_PER_WINDOW,
} from './CONSTANTS';

export function getConstructionYearPrice({
  basePrice,
  constructionYear,
  constructionYearAverage = 1972,
  scaleFactor = 1,
}) {
  if (!validateYear(constructionYear)) {
    return 0;
  }

  const yearFactorPairs = findInRange({
    ranges: AVERAGE_CONSTRUCTION_YEAR_RANGES,
    value: constructionYearAverage,
    defaultValue: [],
  });

  const interpolate = linearInterpolator(yearFactorPairs);

  const featureFactor = interpolate(constructionYear);

  return Math.round(basePrice * featureFactor * scaleFactor) - basePrice;
}

export function getNumberOfRoomsPrice({ basePrice, livingSpace, numberOfRooms, scaleFactor = 1 }) {
  if (!validateSpace(livingSpace) || _isNil(numberOfRooms)) {
    return 0;
  }

  const DEFAULT = 1;

  const featureFactor = findInRange({
    ranges: findInRange({
      ranges: LIVING_SPACE_TO_ROOMS_RANGES,
      value: livingSpace,
      defaultValue: [],
    }),
    value: numberOfRooms,
    defaultValue: DEFAULT,
  });

  return Math.round(basePrice * featureFactor * scaleFactor) - basePrice;
}

export function getRentStatusPrice({ basePrice, rentStatus, RENT_STATUSES, scaleFactor = 1 }) {
  const DEFAULT = 1;

  const featureFactor = _get(RENT_STATUSES, rentStatus, DEFAULT);

  return Math.round(basePrice * featureFactor * scaleFactor) - basePrice;
}

export function getParkingPrice({ hasParkingSpace, sqmPrice, scaleFactor = 1 }) {
  if (_isNil(hasParkingSpace)) {
    return 0;
  }

  const DEFAULT = 0;
  const NO_PAKING_PRICE = -1_000;
  const PAKING_TO_SQM_FACTOR = 1.25;

  // We explicitly check just for `true` or `false`
  // for the other values we fall back to the default price (0)
  let parkingPrice = DEFAULT;

  if (hasParkingSpace === true) {
    parkingPrice = PAKING_TO_SQM_FACTOR * sqmPrice;
  } else if (hasParkingSpace === false) {
    parkingPrice = NO_PAKING_PRICE;
  }

  return Math.round(parkingPrice * scaleFactor);
}

export function getGardenPrice({ basePrice, hasGarden, scaleFactor = 1 }) {
  if (_isNil(hasGarden)) {
    return 0;
  }

  const DEFAULT = 1;
  const GARDEN_FACTOR = 1.09;

  const featureFactor = hasGarden ? GARDEN_FACTOR : DEFAULT;

  return Math.round(basePrice * featureFactor * scaleFactor) - basePrice;
}

export function getBalconyAndTerracePrice(args) {
  const { basePrice, hasOutdoor, scaleFactor = 1 } = args;
  // hasOutdoor is not set
  if (_isNil(hasOutdoor)) {
    return 0;
  }

  const DEFAULT = 1;
  const NO_OUTDOOR_FACTOR = 0.95;

  const featureFactor = hasOutdoor ? DEFAULT : NO_OUTDOOR_FACTOR;

  return Math.round(basePrice * featureFactor * scaleFactor) - basePrice;
}

export function getBasementPrice({
  basePrice,
  numberOfBasementRooms,
  hasCellar,
  hasBasementCompartment,
  hasPartialBasement,
  scaleFactor = 1,
}) {
  if (
    _isNil(numberOfBasementRooms) &&
    _isNil(hasCellar) &&
    _isNil(hasBasementCompartment) &&
    _isNil(hasPartialBasement)
  ) {
    return 0;
  }

  const DEFAULT = 1;
  const BASEMENT_FACTOR = 1.03;
  const NO_BASEMENT_FACTOR = 0.97;

  let featureFactor = DEFAULT;

  if (numberOfBasementRooms > 0) {
    featureFactor = BASEMENT_FACTOR;
  } else if (numberOfBasementRooms === 0) {
    featureFactor = NO_BASEMENT_FACTOR;
  }

  return Math.round(basePrice * featureFactor * scaleFactor) - basePrice;
}

// TODO: The basePrice is just a placeholder since we don't have any clue what the calculated value is.
// Calculated value is to be decided by PM.
export function getBathroomPrice(basePrice) {
  return Math.round(basePrice);
}

export function getWindowRenovationPrice({
  lastWindowRenovationYear,
  livingSpace,
  scaleFactor = 1,
}) {
  if (!validateYear(lastWindowRenovationYear) || !validateSpace(livingSpace)) {
    return 0;
  }

  const windowAge = Math.min(CURRENT_YEAR - lastWindowRenovationYear, AVERAGE_WINDOW_AGE);
  const numberOfWindows = livingSpace / 7 / AVERAGE_WINDOW_AREA_PER_WINDOW;

  const numerator = WINDOW_PRICE_PER_UNIT * numberOfWindows * (AVERAGE_WINDOW_AGE - windowAge);
  const denominator = WINDOW_LIFE_SPAN;
  const featurePrice = numerator / denominator;

  return Math.round(featurePrice * scaleFactor);
}

export function getHeatingRenovationPrice({
  heating,
  lastHeatingRenovationYear,
  ownerShare = 1,
  scaleFactor = 1,
}) {
  if (_isNil(heating) || !validateYear(lastHeatingRenovationYear)) {
    return 0;
  }

  const heatingLifeSpan = _get(HEATING_LIFE_SPAN, heating, 20);

  const AVERAGE_HEATING_AGE = heatingLifeSpan / 2;

  const heatingPrice = _get(HEATING_PRICE, heating, 8000);
  const heatingAge = Math.min(CURRENT_YEAR - lastHeatingRenovationYear, AVERAGE_HEATING_AGE);

  const numerator = heatingPrice * (AVERAGE_HEATING_AGE - heatingAge);
  const denominator = heatingLifeSpan;
  const featurePrice = numerator / denominator;

  return Math.round(featurePrice * ownerShare * scaleFactor);
}

export default {
  getConstructionYearPrice,
  getNumberOfRoomsPrice,
  getRentStatusPrice,
  getParkingPrice,
  getGardenPrice,
  getBalconyAndTerracePrice,
  getBasementPrice,
  getBathroomPrice,
  getWindowRenovationPrice,
  getHeatingRenovationPrice,
};
