<script>
// Components
import DealsBaseView from '@/views/wizard/deals/DealsBaseView.vue';
// Constants
import { FILTERS } from '@/config/DEAL';

export default {
  name: 'DealsFutureView',
  extends: DealsBaseView,
  data() {
    return {
      filter: FILTERS.FUTURE,
    };
  },
};
</script>
