import { MINIMUM_YEAR } from '@/services/validators';

export const AVERAGE_CONSTRUCTION_YEAR_RANGES = [
  {
    // The average construction year range
    range: [MINIMUM_YEAR, 1900],
    value: [
      // The values in pairs format: [year, factor]
      [MINIMUM_YEAR, 1],
      [1923, 0.9631518118],
      [1954, 0.933985995],
      [1969, 0.9104629818],
      [1980, 0.8879597525],
      [1990, 0.9014888844],
      [1999, 0.9139982324],
      [2004, 1.003603236],
      [2008, 1.007070501],
      [2012, 1.027670134],
      [2018, 1.045210415],
    ],
  },
  {
    range: [1900, 1946],
    value: [
      [MINIMUM_YEAR, 1.041427807],
      [1923, 1],
      [1954, 0.9672093557],
      [1969, 0.940762822],
      [1980, 0.9154628143],
      [1990, 0.930673393],
      [1999, 0.9447374455],
      [2004, 1.045478866],
      [2008, 1.049377054],
      [2012, 1.07253688],
      [2018, 1.092257128],
    ],
  },
  {
    range: [1946, 1961],
    value: [
      [MINIMUM_YEAR, 1.082316039],
      [1923, 1.03636826],
      [1954, 1],
      [1969, 0.9706680231],
      [1980, 0.9426076636],
      [1990, 0.9594777891],
      [1999, 0.975076297],
      [2004, 1.086809088],
      [2008, 1.091132587],
      [2012, 1.116819261],
      [2018, 1.138691082],
    ],
  },
  {
    range: [1961, 1977],
    value: [
      [MINIMUM_YEAR, 1.122420524],
      [1923, 1.072039413],
      [1954, 1.032162112],
      [1969, 1],
      [1980, 0.9692321993],
      [1990, 0.9877300613],
      [1999, 1.004833612],
      [2004, 1.127347091],
      [2008, 1.132087749],
      [2012, 1.160252835],
      [2018, 1.184234988],
    ],
  },
  {
    range: [1977, 1983],
    value: [
      [MINIMUM_YEAR, 1.168766001],
      [1923, 1.113261649],
      [1954, 1.069329237],
      [1969, 1.033896569],
      [1980, 1],
      [1990, 1.020378904],
      [1999, 1.03922171],
      [2004, 1.174193548],
      [2008, 1.179416283],
      [2012, 1.210445469],
      [2018, 1.236866359],
    ],
  },
  {
    range: [1983, 1996],
    value: [
      [MINIMUM_YEAR, 1.139837869],
      [1923, 1.087531365],
      [1954, 1.046130091],
      [1969, 1.012738854],
      [1980, 0.9807952133],
      [1990, 1],
      [1999, 1.01775719],
      [2004, 1.144952712],
      [2008, 1.149874542],
      [2012, 1.179116001],
      [2018, 1.204014669],
    ],
  },
  {
    range: [1996, 2001],
    value: [
      [MINIMUM_YEAR, 1.115906176],
      [1923, 1.06624519],
      [1954, 1.026937878],
      [1969, 0.9952354774],
      [1980, 0.9649074583],
      [1990, 0.9831409199],
      [1999, 1],
      [2004, 1.120762324],
      [2008, 1.125435221],
      [2012, 1.153197728],
      [2018, 1.17683709],
    ],
  },
  {
    range: [2001, 2006],
    value: [
      [MINIMUM_YEAR, 0.9964352973],
      [1923, 0.9599811676],
      [1954, 0.9311272532],
      [1969, 0.9078557977],
      [1980, 0.8855932203],
      [1990, 0.8989776702],
      [1999, 0.9113532419],
      [2004, 1],
      [2008, 1.003430186],
      [2012, 1.023809524],
      [2018, 1.041162228],
    ],
  },
  {
    range: [2006, 2010],
    value: [
      [MINIMUM_YEAR, 0.9930763598],
      [1923, 0.9569935424],
      [1954, 0.9284335264],
      [1969, 0.9053991079],
      [1980, 0.8833632914],
      [1990, 0.8966114107],
      [1999, 0.908860928],
      [2004, 0.9966047533],
      [2008, 1],
      [2012, 1.02017176],
      [2018, 1.037347713],
    ],
  },
  {
    range: [2010, 2014],
    value: [
      [MINIMUM_YEAR, 0.9744507219],
      [1923, 0.9404268675],
      [1954, 0.9134965474],
      [1969, 0.8917765223],
      [1980, 0.8709981168],
      [1990, 0.8834902699],
      [1999, 0.8950408035],
      [2004, 0.9777777778],
      [2008, 0.9809792844],
      [2012, 1],
      [2018, 1.016195857],
    ],
  },
  {
    range: [2014, 2021],
    value: [
      [MINIMUM_YEAR, 0.9601891762],
      [1923, 0.9277418582],
      [1954, 0.902059387],
      [1969, 0.8813457854],
      [1980, 0.8615301724],
      [1990, 0.8734434866],
      [1999, 0.8844588123],
      [2004, 0.963362069],
      [2008, 0.9664152299],
      [2012, 0.9845545977],
      [2018, 1],
    ],
  },
];

export const LIVING_SPACE_TO_ROOMS_RANGES = [
  {
    range: [0, 51], // Living space range
    value: [
      // Number of rooms interval
      {
        range: [0, 2.51], // Number of rooms range
        value: 1,
      },
      {
        range: [2.51, 3.51],
        value: 0.95,
      },
      {
        range: [3.51, 4.51],
        value: 0.9,
      },
      {
        range: [4.51, 5.51],
        value: 0.85,
      },
      {
        range: [5.51, Infinity],
        value: 0.8,
      },
    ],
  },
  {
    range: [51, 81],
    value: [
      {
        range: [0, 1.51],
        value: 0.9,
      },
      {
        range: [1.51, 3.51],
        value: 1,
      },
      {
        range: [3.51, 4.51],
        value: 0.95,
      },
      {
        range: [4.51, 5.51],
        value: 0.9,
      },
      {
        range: [5.51, 6.51],
        value: 0.85,
      },
      {
        range: [6.51, Infinity],
        value: 0.8,
      },
    ],
  },
  {
    range: [81, 121],
    value: [
      {
        range: [0, 1.51],
        value: 0.85,
      },
      {
        range: [1.51, 2.51],
        value: 0.95,
      },
      {
        range: [2.51, 5.51],
        value: 1,
      },
      {
        range: [5.51, 6.51],
        value: 0.95,
      },
      {
        range: [6.51, 7.51],
        value: 0.9,
      },
      {
        range: [7.51, Infinity],
        value: 0.8,
      },
    ],
  },
  {
    range: [121, 161],
    value: [
      {
        range: [0, 1.51],
        value: 0.8,
      },
      {
        range: [1.51, 2.51],
        value: 0.8,
      },
      {
        range: [2.51, 3.51],
        value: 0.95,
      },
      {
        range: [3.51, 6.51],
        value: 1,
      },
      {
        range: [6.51, 7.51],
        value: 0.95,
      },
      {
        range: [7.51, Infinity],
        value: 0.9,
      },
    ],
  },
  {
    range: [161, 201],
    value: [
      {
        range: [0, 1.51],
        value: 0.8,
      },
      {
        range: [1.51, 2.51],
        value: 0.85,
      },
      {
        range: [2.51, 3.51],
        value: 0.9,
      },
      {
        range: [3.51, 4.51],
        value: 0.95,
      },
      {
        range: [4.51, 7.51],
        value: 1,
      },
      {
        range: [7.51, Infinity],
        value: 0.95,
      },
    ],
  },
  {
    range: [201, Infinity],
    value: [
      {
        range: [0, 1.51],
        value: 0.7,
      },
      {
        range: [1.51, 2.51],
        value: 0.8,
      },
      {
        range: [2.51, 3.51],
        value: 0.85,
      },
      {
        range: [3.51, 4.51],
        value: 0.9,
      },
      {
        range: [4.51, 5.51],
        value: 0.95,
      },
      {
        range: [5.51, 7.51],
        value: 1,
      },
      {
        range: [7.51, Infinity],
        value: 0.95,
      },
    ],
  },
];

export const HEATING_LIFE_SPAN = {
  gas_central_heating: 15,
  gas_floor_heating: 15,
  oil_heating: 20,
  district_heating: 40,
  pellet_heating: 20,
  electric_heating: 20,
  cogeneration_unit: 20,
  solar_thermal_heating: 25,
  heat_pump: 20,
};

export const HEATING_PRICE = {
  gas_central_heating: 8000,
  gas_floor_heating: 5000,
  oil_heating: 8000,
  district_heating: 5000,
  pellet_heating: 25000,
  electric_heating: 9500,
  cogeneration_unit: 14000,
  solar_thermal_heating: 9000,
  heat_pump: 25000,
};

// Window renovation
export const WINDOW_LIFE_SPAN = 40;
export const AVERAGE_WINDOW_AGE = 20;
export const WINDOW_PRICE_PER_UNIT = 600;
export const AVERAGE_WINDOW_AREA_PER_WINDOW = 2.6;
