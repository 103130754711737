<template>
  <div class="wizard-navigation">
    <HdButton
      modifier="tertiary"
      :icon-src="arrow"
      class="wizard-navigation__item wizard-navigation__item--back"
      @click="goToPreviousSlide"
    />
    <SlideSubmitButtonWrapper v-if="showNextButton" v-slot="{ disabled }">
      <HdButton
        modifier="primary"
        :icon-src="nextIcon"
        :form="slideFormId"
        :disabled="disabled"
        type="submit"
        class="wizard-navigation__item wizard-navigation__item--next"
        data-cy="button-next"
        data-testid="button-next"
      />
    </SlideSubmitButtonWrapper>
  </div>
</template>

<script>
// Components
import { HdButton } from 'homeday-blocks';
import SlideSubmitButtonWrapper from '@/components/SlideSubmitButtonWrapper.vue';
// Icons
import { close, arrow } from 'homeday-assets';

export default {
  name: 'WizardNavigation',
  components: {
    HdButton,
    SlideSubmitButtonWrapper,
  },
  inject: ['goToPreviousSlide', 'isLastSlide', 'slideFormId'],
  props: {
    showNextButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      arrow,
    };
  },
  computed: {
    nextIcon() {
      return this.isLastSlide() ? close : arrow;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.wizard-navigation {
  @include for('mobile-only') {
    background-color: $white;
    bottom: 0;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    padding: 0 $sp-m;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 99;

    .btn {
      background-color: transparent;

      &:before {
        border-color: transparent;
      }
    }

    &__item--back {
      margin-left: 10px !important;
    }

    &__item--next {
      box-shadow: none;
      margin-right: 10px;

      ::v-deep {
        svg path {
          fill: getShade($secondary-color, 110);
        }
      }
    }
  }
}

.wizard-navigation__item {
  margin-left: $sp-m;
}

.wizard-navigation__item--back ::v-deep svg {
  transform: rotateZ(180deg) !important;
}
</style>
