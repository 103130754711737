<template>
  <ul class="deals-list" data-cy="deals-list">
    <deal-card
      v-for="(deal, index) in deals"
      :key="deal.uid"
      :deal="deal"
      :highlight="highlight(deal, index)"
      class="deals-list__item"
    />
  </ul>
</template>

<script>
import DealCard from '@/components/DealCard.vue';
import { FILTERS } from '@/config/DEAL';

export default {
  name: 'DealsList',
  components: {
    DealCard,
  },
  props: {
    deals: {
      type: Array,
      required: true,
    },
  },
  methods: {
    // FIXME: Extract this logic from here
    highlight(deal, index) {
      const { filter } = deal;

      return index === 0 && filter === FILTERS.FUTURE;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.deals-list {
  &__item {
    display: block;
    margin-bottom: $sp-m;
    text-decoration: none;
  }
}
</style>
