<template>
  <div
    :class="{
      isOpen,
    }"
    class="account-controls"
  >
    <button
      class="account-controls__button"
      data-testid="account-controls-button"
      @click="openDropdown"
    >
      <HdIcon :src="userCircle" class="account-controls__icon" />
    </button>
    <HdDropdown
      v-if="isOpen"
      :items="dropdownItems"
      class="account-controls__dropdown"
      @itemClick="({ action }) => action()"
      @close="closeDropdown"
    />
  </div>
</template>

<script>
// Components
import { HdIcon } from 'homeday-blocks';
import HdDropdown from '@/components/HdDropdown.vue';
// Icons
import { userCircle } from 'homeday-assets';

export default {
  name: 'AccountControls',
  components: {
    HdDropdown,
    HdIcon,
  },
  data() {
    return {
      isOpen: false,
      dropdownItems: [
        {
          label: this.$t('ACCOUNT_CONTROLS.PROFILE'),
          action: () => this.$router.push({ name: 'profile' }),
        },
        {
          label: this.$t('ACCOUNT_CONTROLS.LOGOUT'),
          action: () => this.$router.push({ name: 'logout' }),
        },
      ],
      userCircle,
    };
  },
  methods: {
    openDropdown() {
      this.isOpen = true;
    },
    closeDropdown() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

@mixin iconHoverAndOpenState($root) {
  transform: translateY(-1px);

  #{$root}__icon {
    ::v-deep path {
      fill: $quaternary-color;
    }
  }
}

$transition-duration: 150ms;

.account-controls {
  $root: &;

  position: relative;

  &__button {
    display: flex;
    align-items: flex-end;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition-duration: $transition-duration;

    @media (hover: hover) and (pointer: fine) {
      // Ensure hover state is possible
      &:hover {
        @include iconHoverAndOpenState($root);
      }
    }

    #{$root}.isOpen & {
      @include iconHoverAndOpenState($root);
    }
  }

  &__icon {
    width: 32px;
    height: 32px;

    ::v-deep path {
      transition-duration: $transition-duration;
    }
  }

  &__dropdown {
    min-width: 180px;
    margin-top: $sp-xs;
    right: 0;
    z-index: 10;
  }
}
</style>
