<template>
  <wizard-price-base v-model="model" :label="computedLabel" zero-fallback="-" editable />
</template>

<script>
// Components
import WizardPriceBase from '@/components/WizardPriceBase.vue';
// Constants
import { GETTER, MUTATION } from '@/store/CONSTANTS';

export const PRICE_LABEL = {
  DEFAULT: 'DEFAULT',
  SQM_PRICE: 'SQM_PRICE',
};

export default {
  name: 'WizardPriceEarningsValuePrice',
  components: {
    WizardPriceBase,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: PRICE_LABEL.SQM_PRICE,
      validator(value) {
        return PRICE_LABEL[value];
      },
    },
  },
  computed: {
    model: {
      get() {
        return this.$store.getters['wizard/currentDeal/earningsValuePrice'];
      },
      set(value) {
        this.$store.commit(MUTATION.WIZARD.CURRENT_DEAL.SET_PRICE_CONTRIBUTION, {
          name: 'earningsValue',
          value,
        });
      },
    },
    computedLabel() {
      switch (this.label) {
        case PRICE_LABEL.SQM_PRICE: {
          const calculatedSqmPrice =
            this.$store.getters[GETTER.WIZARD.CURRENT_DEAL.CALCULATED_SQM_TOTAL_PRICE];

          return `(${this.$formatSqmPrice(calculatedSqmPrice)})`;
        }
        case PRICE_LABEL.DEFAULT:
        default:
          return this.$t('WIZARD.PRICE_WIDGET.RECOMMENDED_PRICE');
      }
    },
  },
};
</script>
