<template>
  <rent-status-base :title="title" :rent-statuses="rentStatuses" />
</template>

<script>
// Component
import RentStatusBase from '@/views/wizard/RentStatusBase.vue';
// Services
import { mapFeatures } from '@/services/feature';
import { mapPriceContributions } from '@/services/priceContribution';
// Icons
import {
  handEuro as handEuroL,
  family as familyL,
  crossCircle as crossCircleL,
} from 'homeday-assets/L';

import {
  handEuro as handEuroM,
  family as familyM,
  crossCircle as crossCircleM,
} from 'homeday-assets/M';
// Constants
import { RENT_STATUS } from '@/config/CONSTANTS';

export default {
  name: 'RentStatusSlide',
  components: { RentStatusBase },
  data() {
    return {
      title: this.$t('WIZARD.RENT_STATUS.TITLE'),
      rentStatuses: [
        {
          text: this.$t('WIZARD.RENT_STATUS.RENTED'),
          value: RENT_STATUS.RENTED,
          icon: handEuroL,
          mobileIcon: handEuroM,
        },
        {
          text: this.$t('WIZARD.RENT_STATUS.OWNER_OCCUPATION'),
          value: RENT_STATUS.OWNER_OCCUPATION,
          icon: familyL,
          mobileIcon: familyM,
        },
        {
          text: this.$t('WIZARD.RENT_STATUS.VACANT'),
          value: RENT_STATUS.VACANT,
          icon: crossCircleL,
          mobileIcon: crossCircleM,
        },
      ],
    };
  },
  // It is necessary because we use computed to know which features are being applied
  computed: {
    ...mapFeatures(['rentStatus']),
    ...mapPriceContributions(['availability']),
  },
};
</script>
