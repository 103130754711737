<template>
  <div
    class="animated-price"
    data-testid="animated-price"
    :class="{
      'animated-price--animated': animated,
    }"
  >
    <div
      v-for="(item, index) in items"
      :key="index"
      :style="getCharStyles({ ...item, index })"
      class="animated-price__char-wrapper"
    >
      <div
        v-if="item.isDecorator"
        class="animated-price__char animated-price__char--decorator"
        v-text="item.char"
      />
      <template v-else>
        <div
          v-for="n in 10"
          :key="`number-${n - 1}`"
          class="animated-price__char animated-price__char--number"
          v-text="n - 1"
        />
        <div class="animated-price__char animated-price__char--number" v-text="0" />
      </template>
    </div>
  </div>
</template>

<script>
// Because we want to animate zeros we render 11 digits [0,..,9,0]
const NUMBER_OF_ITEMS = 11;
const ITEM_OFFSET = 100 / NUMBER_OF_ITEMS;

export default {
  name: 'AnimatedPrice',
  props: {
    price: {
      type: Number,
      required: true,
    },
    duration: {
      type: Number,
      default: 4000,
    },
    delay: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      formattedPrice: '',
      animated: false,
    };
  },
  computed: {
    items() {
      return this.formattedPrice.split('').map((char) => {
        const number = parseInt(char, 10);
        const isSeparator = ['.', ',', ' ', ' '].includes(char);
        const isDecorator = isSeparator || Number.isNaN(number);

        return {
          char: isDecorator ? char : number,
          isDecorator,
          isSeparator,
        };
      });
    },
  },
  created() {
    // We show a placeholder with zeros first
    this.formattedPrice = this.getZeroedFormattedPrice();

    setTimeout(() => {
      this.animated = true;
      this.formattedPrice = this.$formatPrice(this.price, { fractionDigits: 0 });
    }, this.delay);
  },
  methods: {
    getZeroedFormattedPrice() {
      const formattedPrice = this.$formatPrice(this.price, { fractionDigits: 0 });

      return formattedPrice
        .split('')
        .map((char) => {
          const isNumber = !Number.isNaN(parseInt(char, 10));

          return isNumber ? '0' : char;
        })
        .join('');
    },
    getNumberOffset(number) {
      const offset = number * ITEM_OFFSET;
      return offset;
    },
    getCharStyles({ char, isDecorator, index }) {
      if (isDecorator) {
        return {};
      }

      if (!this.animated) {
        return {
          transitionDuration: '0ms',
          transform: index % 2 ? `translateY(-${100 - ITEM_OFFSET}%)` : '',
        };
      }

      return {
        transitionDuration: `${this.duration}ms`,
        transform: `translateY(-${this.getNumberOffset(char)}%)`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
$height: 66px;
$height-mobile: 43px;

.animated-price {
  $root: &;
  height: $height-mobile;
  @include font('DS-600');
  font-weight: 900;
  text-align: center;
  overflow: hidden;

  @include for('tablet') {
    height: $height;
    @include font('DS-800');
  }

  &__char-wrapper {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    top: -7%;
    transition-property: transform;
    transition-timing-function: ease-out;
  }

  &__char {
    opacity: 0.5;
    transition: opacity 300ms;

    #{$root}--animated & {
      opacity: 1;
    }

    &--decorator {
      white-space: pre;
      opacity: 1;
    }
  }
}
</style>
