<template>
  <wizard-price-base v-model="model" :label="computedLabel" />
</template>

<script>
// Components
import WizardPriceBase from '@/components/WizardPriceBase.vue';
// Constants
import { GETTER } from '@/store/CONSTANTS';

export const PRICE_LABEL = {
  DEFAULT: 'DEFAULT',
  SQM_PRICE: 'SQM_PRICE',
};

export default {
  name: 'WizardPriceTotalPrice',
  components: {
    WizardPriceBase,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: PRICE_LABEL.SQM_PRICE,
      validator(value) {
        return PRICE_LABEL[value];
      },
    },
  },
  computed: {
    model() {
      return this.$store.getters[GETTER.WIZARD.CURRENT_DEAL.TOTAL_PRICE];
    },
    computedLabel() {
      switch (this.label) {
        case PRICE_LABEL.SQM_PRICE: {
          const calculatedSqmPrice =
            this.$store.getters[GETTER.WIZARD.CURRENT_DEAL.CALCULATED_SQM_TOTAL_PRICE];

          return `(${this.$formatSqmPrice(calculatedSqmPrice)})`;
        }
        case PRICE_LABEL.DEFAULT:
        default:
          return this.$t('WIZARD.PRICE_WIDGET.RECOMMENDED_PRICE');
      }
    },
  },
};
</script>
