<template>
  <HdNotifications
    :notifications="notifications"
    class="deal-notifications"
    @heightChange="onNotificationsHeightChange"
  >
    <template #default="{ notification }">
      <NotificationItem :notification="notification" :loading="loading" />
    </template>
  </HdNotifications>
</template>

<script>
// Components
import { HdNotifications } from 'homeday-blocks';
import NotificationItem from '@/components/NotificationItem.vue';
// Services
import { get as _get } from 'lodash-es';
// Constants
import { BROKER_ORDER_ERROR_REASON } from '@/config/CONSTANTS';
import { ACTION } from '@/store/CONSTANTS';

export default {
  name: 'DealNotifications',
  components: {
    HdNotifications,
    NotificationItem,
  },
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    notifications() {
      // Here we return all the notifications we want to show
      return (
        [
          this.brokerOrderSentNotification,
          this.unsavedEvaluationNotification,
          ...this.failedRequestsNotifications,
        ]
          // We filter out falsy notifications
          .filter((notification) => notification)
      );
    },
    failedRequestsNotifications() {
      const failedRequests = _get(this.deal, 'failedRequests', []);

      return failedRequests.map((failedRequest) => {
        const requestType = failedRequest.type.toUpperCase();

        let buttonAction = () => this.dispatchAction(failedRequest);
        let type = 'notification';
        let message = this.$t(`WIZARD.DEAL_NOTIFICATIONS.FAILED_${requestType}.MESSAGE`);
        let buttonLabel = this.$t(`WIZARD.DEAL_NOTIFICATIONS.FAILED_${requestType}.BUTTON_LABEL`);

        if (
          [BROKER_ORDER_ERROR_REASON.HARD_BOUNCE, BROKER_ORDER_ERROR_REASON.SOFT_BOUNCE].includes(
            failedRequest.reason,
          )
        ) {
          message = this.$t(`WIZARD.DEAL_NOTIFICATIONS.FAILED_${requestType}.EMAIL.MESSAGE`);
          buttonLabel = this.$t(
            `WIZARD.DEAL_NOTIFICATIONS.FAILED_${requestType}.EMAIL.BUTTON_LABEL`,
          );
          buttonAction = () =>
            this.$router.push({ name: 'instructionSlide', params: { dealUid: this.deal.uid } });
          type = 'error';
        } else if (failedRequest.reason === BROKER_ORDER_ERROR_REASON.PHONE) {
          message = this.$t(`WIZARD.DEAL_NOTIFICATIONS.FAILED_${requestType}.PHONE.MESSAGE`);
          buttonLabel = this.$t(
            `WIZARD.DEAL_NOTIFICATIONS.FAILED_${requestType}.PHONE.BUTTON_LABEL`,
          );
          buttonAction = () =>
            this.$router.push({ name: 'instructionSlide', params: { dealUid: this.deal.uid } });
          type = 'error';
        }

        return {
          id: `field-request-${failedRequest.type}`,
          message,
          buttonLabel,
          buttonAction,
          type,
        };
      });
    },
    unsavedEvaluationNotification() {
      if (!this.deal.unsavedEvaluation) {
        return null;
      }

      return {
        id: 'unsaved-evaluation',
        message: this.$t('WIZARD.DEAL_NOTIFICATIONS.UNSAVED_EVALUATION.MESSAGE'),
        buttonLabel: this.$t('WIZARD.DEAL_NOTIFICATIONS.UNSAVED_EVALUATION.BUTTON_LABEL'),
        buttonAction: () => this.sendUnsavedEvaluation(),
        type: 'notification',
      };
    },
    brokerOrderSentNotification() {
      if (!this.deal.lastEvaluation.public) return null;

      return {
        id: 'broker-order-sent',
        message: this.$t('WIZARD.DEAL_NOTIFICATIONS.BROKER_ORDER_EMAIL_SENT.MESSAGE'),
        type: 'success',
      };
    },
  },
  methods: {
    async dispatchAction({ actionType, actionParams }) {
      this.loading = true;

      try {
        await this.$store.dispatch(actionType, actionParams);
      } catch {
        console.log('failed to dispatch notification action', actionType, actionParams);
      }

      this.loading = false;
    },
    async sendUnsavedEvaluation() {
      this.dispatchAction({
        actionType: ACTION.WIZARD.CREATE_EVALUATION,
        actionParams: {
          dealUid: this.deal.uid,
          evaluation: this.deal.unsavedEvaluation,
        },
      });
    },
    onNotificationsHeightChange(value) {
      this.notificationsHeight = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.deal-notifications {
  position: relative;
  width: 100%;
  z-index: 20;

  ::v-deep .notification {
    padding: $sp-s $sp-m;
  }
}
</style>
