import { get as _get, set as _set } from 'lodash-es';
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import appModule from './app';
import userModule from './user';
import wizardModule from './wizard';
import { USER_MODULE_NAME, WIZARD_MODULE_NAME, MUTATION } from './CONSTANTS';
import { VUEX_PERSIST_KEY } from './user/CONSTANTS';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: VUEX_PERSIST_KEY,
  reducer: (state) => ({
    wizard: {
      deals: state.wizard.deals,
      unsavedDeals: {
        ...state.wizard.unsavedDeals,
        [state.wizard.currentDeal.uid]: state.wizard.currentDeal,
      },
    },
  }),
  restoreState: (key, storage) => {
    const state = JSON.parse(storage.getItem(key));

    if (state) {
      const deals = state.wizard.deals.map((deal) => {
        const array = [
          'nextAppointment.from',
          'nextAppointment.to',
          'lastAppointment.from',
          'lastAppointment.to',
          'lastEvaluation.createdAt',
        ];

        const newDeal = array
          // convert array of path to tuple [path, date]
          .map((path) => [path, new Date(_get(path, deal))])
          .filter(([_path, date]) => !Number.isNaN(date.getTime()))
          .reduce((acc, [path, date]) => _set(path, date, acc), deal);

        const appointments =
          newDeal.appointments &&
          newDeal.appointments.map((appointment) => ({
            ...appointment,
            from: new Date(appointment.from),
            to: new Date(appointment.to),
          }));

        return {
          ...newDeal,
          appointments,
        };
      });

      state.wizard.deals = deals;
    }

    return state;
  },
  filter: (mutation) => {
    // Choose which mutations trigger store save, and which don't, using filter function.
    return (
      mutation.type === MUTATION.WIZARD.UPDATE_DEAL_PROPERTIES ||
      mutation.type === MUTATION.WIZARD.CLEAN_DEALS_CACHE
    );
  },
});

export default new Vuex.Store({
  ...appModule,
  modules: {
    [USER_MODULE_NAME]: {
      ...userModule,
      namespaced: true,
    },
    [WIZARD_MODULE_NAME]: {
      ...wizardModule,
      namespaced: true,
    },
  },
  plugins: [vuexLocal.plugin],
});
