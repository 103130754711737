import { snakeCase as _snakeCase, mapValues as _mapValues } from 'lodash-es';

function namespaceValues(object, moduleName) {
  return _mapValues(object, (value) => {
    if (typeof value === 'string') {
      return `${moduleName}/${value}`;
    }

    return namespaceValues(value, moduleName);
  });
}

/**
 * Returns an object with namespaced values following Vuex pattern
 * and transformed keys (upper case snake case)
 * @param {object} object the object to namespace
 * @param {string} moduleName the module name to use as a namespace
 * @returns {object} a new object with namespaced values
 */
export function namespace(object, moduleName) {
  const key = _snakeCase(moduleName).toUpperCase();
  const mappedObject = namespaceValues(object, moduleName);

  return {
    [key]: mappedObject,
  };
}
