<template>
  <wizard-base class="selling-strategy" :title="$t('WIZARD.SELLING_STRATEGY.TITLE')">
    <section
      :class="{
        'selling-strategy__items--loading': loading,
      }"
      class="selling-strategy__items"
    >
      <div class="columns is-multiline is-centered">
        <div
          v-for="(item, i) in items"
          :key="`result-item-${i}`"
          class="column is-three-quarters-tablet is-one-third-desktop"
        >
          <div class="selling-strategy-item">
            <div
              :style="{ backgroundImage: `url('${item.icon}')` }"
              class="selling-strategy-item__icon"
            />
            <div class="selling-strategy-item__content">
              <p class="selling-strategy-item__title">
                {{ item.title }}
              </p>
              <p class="selling-strategy-item__description" v-html="item.description" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="selling-strategy__footer">
      <p class="selling-strategy__label selling-strategy__label--left">
        {{ $t('WIZARD.SELLING_STRATEGY.CHEAP_OFFER') }}
      </p>
      <HdRange
        v-model="rangePriceIndex"
        name="selling-strategy"
        :max="max"
        :labels="rangeLabels"
        display-step-bullets
        display-tooltip
        :tooltip-value="formattedRangePrice"
        progress-background="transparent"
        track-background="radial-gradient(circle at center, #4CBA38, #FFE713, #E00016)"
        class="selling-strategy__range"
      />
      <p class="selling-strategy__label">
        {{ $t('WIZARD.SELLING_STRATEGY.EXPENSIVE_OFFER') }}
      </p>
    </div>
    <div v-if="lendingValue" v-show="showValuation" class="selling-strategy__lending-value">
      <HdIcon
        :src="icons.documentsHandover"
        class="lending-value__icon"
        width="40px"
        height="40px"
      />
      <p class="lending-value__message">
        {{ $t('WIZARD.SELLING_STRATEGY.LENDING_VALUE_MESSAGE') }}
        <em class="lending-value__price">{{ lendingPrice }}</em>
      </p>
    </div>
  </wizard-base>
</template>

<script>
// Components
import { HdRange, HdIcon } from 'homeday-blocks';
import WizardBase from '@/layout/WizardBase.vue';
// Services
import { getPotentialBuyers } from '@/services/apiMapper';
// Icons
import {
  marketDemand1,
  marketDemand2,
  marketDemand3,
  marketDemand4,
  marketDemand5,
  womanRating1,
  womanRating2,
  womanRating3,
  womanRating4,
  womanRating5,
  timer1,
  timer2,
  timer3,
  timer4,
  timer5,
  documentsHandover,
} from 'homeday-assets/L';
// Constants
import { MUTATION, GETTER } from '@/store/CONSTANTS';

const RESULTS = {
  marketDemand: [
    {
      icon: marketDemand5,
      level: 'HIGH',
    },
    {
      icon: marketDemand4,
      level: 'GOOD',
    },
    {
      icon: marketDemand3,
      level: 'AVERAGE',
    },
    {
      icon: marketDemand2,
      level: 'LOW',
    },
    {
      icon: marketDemand1,
      level: 'VERY_LOW',
    },
  ],
  buyerInterest: [
    {
      icon: womanRating5,
    },
    {
      icon: womanRating4,
    },
    {
      icon: womanRating3,
    },
    {
      icon: womanRating2,
    },
    {
      icon: womanRating1,
    },
  ],
  sellingTime: [
    {
      icon: timer1,
      months: '1-3',
    },
    {
      icon: timer2,
      months: '2-4',
    },
    {
      icon: timer3,
      months: '3-6',
    },
    {
      icon: timer4,
      months: '9-12',
    },
    {
      icon: timer5,
      months: '12-24',
    },
  ],
};

const OPTIMAL_STARTING_PRICE = 0.95;
const FACTORS = [
  {
    price: 0.8,
    potentialBuyers: 1.71,
  },
  {
    price: 0.9,
    potentialBuyers: 1.292,
  },
  {
    price: 1,
    potentialBuyers: 1,
    optimalPrice: true,
  },
  {
    price: 1.1,
    potentialBuyers: 0.188,
  },
  {
    price: 1.2,
    potentialBuyers: 0.056,
  },
];

export default {
  name: 'SellingStrategy',
  components: {
    WizardBase,
    HdRange,
    HdIcon,
  },
  data() {
    return {
      // We start from optimal price
      rangePriceIndex: FACTORS.findIndex(({ optimalPrice }) => optimalPrice),
      // We initialize the `potentialBuyers` property with an array of zeros
      potentialBuyers: Array(FACTORS.length).fill(0),
      loading: true,
      optimalPrice: this.getOptimalPrice(),
    };
  },
  computed: {
    rangePrice() {
      return Math.round(FACTORS[this.rangePriceIndex].price * this.optimalPrice);
    },
    rangeLabels() {
      return FACTORS.map(({ price, optimalPrice }) => {
        if (optimalPrice) {
          return this.$t('WIZARD.SELLING_STRATEGY.OPTIMAL_STARTING_PRICE');
        }

        return `${Math.round(price * 100)}%`;
      });
    },
    max() {
      return FACTORS.length - 1;
    },
    formattedRangePrice() {
      return this.$formatPrice(this.rangePrice, { fractionDigits: 0 });
    },
    items() {
      const items = [this.marketDemand, this.sellingTime];

      if (this.shouldShowBuyerInterest) {
        items.splice(1, 0, this.buyerInterest);
      }

      return items;
    },
    marketDemand() {
      const currentMarketDemand = RESULTS.marketDemand[this.rangePriceIndex];
      return {
        ...currentMarketDemand,
        title: this.$t(`WIZARD.SELLING_STRATEGY.${currentMarketDemand.level}`),
        description: this.$t('WIZARD.SELLING_STRATEGY.MARKET_DEMAND_DESCRIPTION'),
      };
    },
    buyerInterest() {
      const potentialBuyers = this.potentialBuyers[this.rangePriceIndex];
      const potentialBuyersVerbose = this.$tc(
        'WIZARD.SELLING_STRATEGY.POTENTIAL_BUYER',
        potentialBuyers,
      );
      const description = this.$t('WIZARD.SELLING_STRATEGY.BUYER_INTEREST_DESCRIPTION', {
        potentialBuyers: potentialBuyersVerbose,
      });

      return {
        ...RESULTS.buyerInterest[this.rangePriceIndex],
        title: potentialBuyers,
        description,
      };
    },
    sellingTime() {
      const { months } = RESULTS.sellingTime[this.rangePriceIndex];
      return {
        ...RESULTS.sellingTime[this.rangePriceIndex],
        title: `${months} ${this.$tc('GENERAL.MONTH', 2)}`,
        description: this.$t('WIZARD.SELLING_STRATEGY.SELLING_TIME_DESCRIPTION'),
      };
    },
    shouldShowBuyerInterest() {
      // We return `false` if we don't have potential buyers
      return !this.potentialBuyers.every((value) => !value);
    },
    lendingValue() {
      return this.$store.getters[GETTER.WIZARD.CURRENT_DEAL.LENDING_VALUE] * 1.2;
    },
    lendingPrice() {
      return this.lendingValue ? this.$formatPrice(this.lendingValue) : null;
    },
    icons() {
      return { documentsHandover };
    },
    showValuation() {
      return (
        this.lendingValue &&
        this.$store.getters[GETTER.WIZARD.CURRENT_DEAL.MARKETING_PRICE] > this.lendingValue
      );
    },
  },
  async created() {
    const MAX_POTENTIAL_BUYERS = 30;

    try {
      const data = await getPotentialBuyers({
        // TODO: Request it from action
        dealUid: this.$store.state.wizard.currentDeal.uid,
        prices: [this.rangePrice],
      });

      let numberOfPotentialBuyers = data.potential_buyers[0];

      if (numberOfPotentialBuyers > MAX_POTENTIAL_BUYERS) {
        // For business reasons we cap the number of potential buyers here
        // we substract the first digit from the max to have a random number
        // but unique to the number of BRs we receive
        // to avoid showing different numbers on slide reload
        const firstDigit = numberOfPotentialBuyers % 10;

        numberOfPotentialBuyers = MAX_POTENTIAL_BUYERS - firstDigit;
      }

      this.potentialBuyers = FACTORS.map(({ potentialBuyers: factor }) =>
        Math.round(numberOfPotentialBuyers * factor),
      );
    } catch (error) {
      console.error('getPotentialBuyers error', error);
    }

    this.loading = false;
  },
  methods: {
    getOptimalPrice() {
      if (this.$store.state.wizard.currentDeal.optimalPrice) {
        return this.$store.state.wizard.currentDeal.optimalPrice;
      }

      const optimalPrice =
        this.$store.getters[GETTER.WIZARD.CURRENT_DEAL.MARKETING_PRICE] * OPTIMAL_STARTING_PRICE;
      this.$store.commit(MUTATION.WIZARD.CURRENT_DEAL.SET_OPTIMAL_PRICE, optimalPrice);

      return optimalPrice;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.selling-strategy {
  &__items {
    margin-bottom: $sp-l;

    @include for('tablet') {
      margin-bottom: $sp-xl;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.3s ease-in-out 0s;
    }

    &::before {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: white;
      transition-delay: 0.3s;
    }

    &::after {
      $size: 30px;

      top: calc(50% - calc(#{$size} / 2));
      right: calc(50% - calc(#{$size} / 2));
      width: $size;
      height: $size;
      border: 2px solid getShade($secondary-color, 110);
      border-right-color: transparent;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }

    &--loading {
      &::before,
      &::after {
        transition-delay: 0s;
        opacity: 1;
      }
    }
  }

  &-item {
    display: flex;
    flex: 1;

    @include for('tablet') {
      align-items: center;
    }

    @include for('desktop') {
      flex-direction: column;
      text-align: center;
    }

    &__icon {
      flex-shrink: 0;
      width: 80px;
      height: 80px;
      background-size: 100%;
      transition: background-image 0.3s;

      @include for('tablet') {
        width: 120px;
        height: 120px;
      }
    }

    &__content {
      margin-left: $sp-m;

      @include for('desktop') {
        margin-left: 0;
      }
    }

    &__title {
      @include font('DS-200');
      font-weight: 700;

      @include for('tablet') {
        @include font('DS-400');
      }
    }

    &__description {
      word-break: break-word;
      hyphens: manual;
      @include font('DS-80');

      @include for('tablet') {
        @include font('DS-100');
      }
    }
  }

  &__footer {
    display: flex;
    align-items: flex-end;
  }

  &__range {
    margin-right: $sp-l;
    margin-left: $sp-l;
    flex: 4;

    ::v-deep .range__tooltip {
      font-weight: 600;
    }
  }

  &__label {
    flex: 1;
    @include font('DS-80');
    font-weight: 600;
    margin-bottom: $sp-s;

    &--left {
      text-align: right;
    }

    @include for('mobile-only') {
      display: none;
    }
  }

  &__lending-value {
    max-width: 700px;
    margin: $sp-xl auto 0;
    padding: $sp-m;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: getShade($secondary-color, 60);
    border-radius: 8px;

    .lending-value__icon {
      min-width: 40px;
    }

    .lending-value__message {
      margin-left: $sp-s;
      font-weight: 600;
    }

    .lending-value__price {
      @include font('DS-200');
      font-weight: 700;
    }
  }
}
</style>
