import { get as _get, inRange as _inRange } from 'lodash-es';

// Array of objects with range and value keys --> [{ range: [min, max], value: Number|[{}] }]
export function findInRange({
  ranges,
  value, // Value we're looking for
  defaultValue,
}) {
  const foundRange = ranges.find(({ range }) => _inRange(value, range[0], range[1]));

  return _get(foundRange, 'value', defaultValue);
}
