<template>
  <div class="control-bar columns">
    <div :class="`column is-${grid[0]}`">
      <HdButton
        v-show="showAddButton"
        modifier="tertiary"
        :icon-src="plus"
        data-testid="control-bar-add-button"
        @click.prevent="$emit('add')"
      />
    </div>
    <p
      v-show="showTotal"
      class="control-bar__total-label"
      :class="`column is-${grid[1]}`"
      v-text="formattedTotalLabel"
    />
    <p
      v-show="showTotal"
      :class="`column is-${grid[2]}`"
      data-testid="control-bar-total"
      v-text="formattedTotal"
    />
  </div>
</template>

<script>
// Components
import { HdButton } from 'homeday-blocks';

// Icons
import { plus } from 'homeday-assets';

export default {
  name: 'FormControlBar',
  components: { HdButton },
  props: {
    showAddButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTotal: {
      type: Boolean,
      required: false,
      default: false,
    },
    total: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    totalLabel: {
      type: String,
      required: false,
      default: '',
    },
    grid: {
      type: Array,
      required: false,
      default: () => [3, 3, 6],
    },
  },
  data() {
    return {
      plus,
    };
  },
  computed: {
    formattedTotal() {
      return `${this.total > 0 ? `+` : ''}${this.total} €`;
    },
    formattedTotalLabel() {
      return this.totalLabel || this.$t('WIZARD.SUM');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.control-bar {
  align-items: center;

  p {
    @include font('DS-300');
    font-weight: 700;
  }

  &__total-label {
    text-align: right;
  }
}
</style>
