import { get as _get } from 'lodash-es';
import { BASEMENT_TYPES } from '@/config/TRANSLATION_CONSTANTS';

const BASEMENT_TYPE_KEYS = Object.keys(BASEMENT_TYPES);

export default {
  normalize(apiDeal) {
    const features = _get(apiDeal, 'last_evaluation.features', []);
    return features
      .filter((feat) => BASEMENT_TYPE_KEYS.includes(feat.name?.toUpperCase()))
      .map(({ name, value }) => ({
        name,
        value,
      }));
  },
  denormalize(payload) {
    return payload.filter(({ name, value }) => name && value);
  },
};
