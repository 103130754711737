const EVENTS = {
  PAGE_VIEW: {
    REGISTRATION: {
      event: 'page_view',
      pageTitle: 'sign_up',
      category: 'account',
    },
    LOGIN: {
      event: 'page_view',
      pageTitle: 'login',
      category: 'account',
    },
    DEALS: {
      event: 'page_view',
      pageTitle: 'deals',
      category: 'other',
    },
    WIZARD_VALUATION_FUNNEL: {
      event: 'page_view',
      pageTitle: 'wizard_valuation_funnel',
      category: 'other',
    },
    CONFIRMATION_PAGE: {
      event: 'page_view',
      pageTitle: 'confirmation_valuation_funnel',
      category: 'other',
    },
  },
  INTERACTION: {
    SIGN_UP: {
      event: 'sign_up',
      category: 'account',
    },
    LOGIN: {
      event: 'login',
      category: 'account',
    },
    DEAL_CREATION: {
      event: 'deal_creation',
      category: 'other',
    },
    DOWNLOAD: {
      event: 'interaction',
      category: 'other',
      linkText: 'download_review',
    },
  },
};

export default EVENTS;
