<template>
  <section class="procedure-items">
    <template v-for="(item, index) in items">
      <div v-if="index !== 0" :key="`arrow-${index}`" class="procedure-items__arrow" />

      <div :key="`item-${index}`" class="procedure-item">
        <HdIcon
          class="procedure-item__icon"
          :src="item.icon"
          :class-from-fill="defaultIconColors"
        />
        <p class="procedure-item__details">
          <span class="procedure-item__number">{{ getFormattedNumber(index) }}</span>
          <span class="procedure-item__label">{{ item.label }}</span>
        </p>
      </div>
    </template>
  </section>
</template>

<script>
import { SVG_DEFAULT_COLORS } from '@/config/CONSTANTS';
import { HdIcon } from 'homeday-blocks';

export default {
  name: 'ProcedureItems',
  components: { HdIcon },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    defaultIconColors() {
      return SVG_DEFAULT_COLORS;
    },
  },
  methods: {
    getFormattedNumber(index) {
      const number = index + 1;
      return number >= 10 ? number : `0${number}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

$arrow-thickness: 3px;
$arrow-head-size: 16px;
$arrow-color: getShade($quaternary-color, 80);
$arrow-min-width: 40px;

.procedure-items {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  max-width: 950px;
  margin: $sp-xl auto;
  overflow-x: auto;

  @include for('tablet') {
    margin-top: $sp-m;
  }

  &__arrow {
    position: relative;
    align-self: center;
    flex: 1 0 $arrow-min-width;
    height: $arrow-thickness;
    background-color: $arrow-color;
    margin-right: $sp-s;
    border-radius: calc(#{$arrow-thickness} / 2);

    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: calc(-1 * calc(#{$arrow-thickness} / 2));
      width: $arrow-head-size;
      height: 100%;
      background-color: $arrow-color;
      border-radius: calc(#{$arrow-thickness} / 2);
      transform-origin: center right;
      transform: rotateZ(45deg) translateY(calc(#{$arrow-thickness} / 2));
    }

    &::before {
      transform: rotateZ(-45deg) translateY(calc(-1 * calc(#{$arrow-thickness} / 2)));
    }
  }
}

.procedure-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  &__icon {
    width: 120px;
    height: 120px;
  }

  &__details {
    @include font('DS-200');
    font-weight: 700;
    margin-top: $sp-s;
  }

  &__number {
    color: $primary-color;
    margin-right: $sp-xs;
  }

  &__label {
    font-weight: 600;
  }
}
</style>
