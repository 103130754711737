<template>
  <HdNotifications :v-if="isVisible" :notifications="notifications" class="root-notifications">
    <template #default="{ notification }">
      <NotificationItem :notification="notification" />
    </template>
  </HdNotifications>
</template>

<script>
import { HdNotifications } from 'homeday-blocks';
import NotificationItem from '@/components/NotificationItem.vue';
import { SERVICE_WORKER_STATUS } from '@/config/CONSTANTS';
import { mapState } from 'vuex';
import { MUTATION } from '@/store/CONSTANTS';

export default {
  name: 'RootNotifications',
  components: {
    HdNotifications,
    NotificationItem,
  },
  data() {
    return {
      isVisible: true,
    };
  },
  computed: {
    ...mapState('user', ['userActionStatus']),
    ...mapState(['serviceWorkerStatus']),
    notifications() {
      const notifications = [];

      if (this.serviceWorkerStatus === SERVICE_WORKER_STATUS.UPDATED) {
        notifications.push(this.getUpdatedNotification());
      }
      if (this.userActionStatus) notifications.push(this.getUserNotification());

      return notifications;
    },
  },
  watch: {
    notifications(val) {
      if (val.length) window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
  methods: {
    closeUserNotification() {
      this.$store.commit(MUTATION.USER.SET_ACTION_STATUS, null);
      this.isVisible = false;
    },
    getUserNotification() {
      return {
        id: 'user-message',
        message: this.userActionStatus.message,
        type: this.userActionStatus.type,
        isClosable: true,
        closeButtonAction: this.closeUserNotification,
      };
    },
    getUpdatedNotification() {
      return {
        type: HdNotificationsTypes.SUCCESS,
        message: this.$t('SERVICE_WORKER.UPDATED.MESSAGE'),
        buttonLabel: this.$t('SERVICE_WORKER.UPDATED.BUTTON_LABEL'),
        buttonAction: this.updateServiceWorkerAndRefresh,
      };
    },
    async updateServiceWorkerAndRefresh() {
      try {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        const waitingServiceWorker = serviceWorkerRegistration.waiting;

        // Service worker in control of the page has changed which means
        // that the waiting service worker is ready and we can use it
        navigator.serviceWorker.addEventListener('controllerchange', this.refresh);

        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
      } catch (e) {
        this.refresh();
      }
    },
    refresh() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.root-notifications {
  text-align: center;

  &__item {
    cursor: pointer;
    box-shadow: inset 0 -2px 0 0 currentColor;
  }
  ::v-deep svg {
    transform: translateY(3px);
  }
}
</style>
