import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import { GETTER } from '@/store/CONSTANTS';
import { REDIRECT_TO } from '@/config/QUERY_PARAMS';

// Services
import { i18n } from '@/services/i18n';

// Views
import LoginView from '@/views/LoginView.vue';
import RequestNewPasswordView from '@/views/RequestNewPasswordView.vue';
import ConfirmNewPasswordView from '@/views/ConfirmNewPasswordView.vue';
import LogoutView from '@/views/LogoutView.vue';
import WizardView from '@/views/WizardView.vue';
import ProfileView from '@/views/ProfileView.vue';
import RegistrationView from '@/views/RegistrationView.vue';
import ImprintView from '@/views/ImprintView.vue';
import DataProtectionView from '@/views/DataProtectionView.vue';
import TermsAndConditionsView from '@/views/TermsAndConditionsView.vue';

// Route names
import { DEALS as DEALS_ROUTE_NAMES } from '@/router/wizard/ROUTE_NAMES';

// Children routes
import wizardRoutes from '@/router/wizard/routes';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/wizard',
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      meta: {
        unauthOnly: true,
        title: i18n.t('GENERAL.LOGIN'),
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: LogoutView,
    },
    {
      path: '/profile',
      name: 'profile',
      component: ProfileView,
      meta: {
        authOnly: true,
      },
    },
    {
      path: '/registration',
      name: 'registration',
      component: RegistrationView,
      meta: {
        unauthOnly: true,
        title: i18n.t('GENERAL.REGISTER'),
      },
    },
    {
      path: '/reset-password/request',
      name: 'request-new-password',
      component: RequestNewPasswordView,
      meta: {
        unauthOnly: true,
        title: i18n.t('GENERAL.REQUEST_NEW_PASSWORD'),
      },
    },
    {
      path: '/reset-password/confirm/:token',
      name: 'confirm-new-password',
      component: ConfirmNewPasswordView,
      meta: {
        unauthOnly: true,
        title: i18n.t('GENERAL.CONFIRM_NEW_PASSWORD'),
      },
    },
    {
      path: '/agb',
      name: 'terms-and-conditions',
      component: TermsAndConditionsView,
      meta: {
        title: i18n.t('TERMS_AND_CONDITIONS.TITLE'),
      },
    },
    {
      path: '/impressum',
      name: 'imprint',
      component: ImprintView,
      meta: {
        title: i18n.t('IMPRINT.TITLE'),
      },
    },
    {
      path: '/datenschutz',
      name: 'data-protection',
      component: DataProtectionView,
      meta: {
        title: i18n.t('DATA_PROTECTION.TITLE'),
      },
    },
    {
      path: '/wizard',
      component: WizardView,
      children: wizardRoutes,
      meta: {
        authOnly: true,
      },
    },
    {
      path: '*',
      redirect: { name: 'login' },
    },
  ],
});

router.beforeEach((to, _from, next) => {
  if (to.matched.some((_) => _.meta.authOnly) && !store.getters[GETTER.USER.AUTHENTICATED]) {
    return next({
      name: 'login',
      query: {
        [REDIRECT_TO]: to.path,
      },
    });
  }

  if (to.matched.some((_) => _.meta.unauthOnly) && store.getters[GETTER.USER.AUTHENTICATED]) {
    return next({ name: DEALS_ROUTE_NAMES.INDEX });
  }

  document.title = `${to.meta.title ? `${to.meta.title} | ` : ''}${process.env.VUE_APP_TITLE}`;

  return next();
});

export default router;
