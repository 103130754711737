<template>
  <div class="deal-item-options">
    <HdToast
      ref="toast"
      :primary-label="$t('GENERAL.YES')"
      :secondary-label="$t('GENERAL.NO')"
      @primaryClick="removeDeal"
      @secondaryClick="toastCloseHandler"
      >{{ $t('GENERAL.CONFIRMATION_TOAST_TITLE') }}</HdToast
    >
    <button class="btn btn--ghost btn--ghost--white" @click.prevent="$refs.toast.$emit('open')">
      {{ $t('WIZARD.LIST.REMOVE_DEAL') }}
    </button>
    <a
      ref="googleMaps"
      class="btn btn--ghost btn--ghost--white"
      target="_blank"
      :href="googleMapsUrl"
      >{{ $t('WIZARD.LIST.GO_TO_GOOGLE_MAPS') }}</a
    >
    <a
      ref="preisatlas"
      class="btn btn--ghost btn--ghost--white"
      target="_blank"
      :href="immoweltUrl"
      >{{ $t('WIZARD.LIST.GO_TO_IMMOWELT') }}</a
    >
    <hd-input
      ref="dealSuggestedSquareMeterPrice"
      v-model="suggestedSqmPrice"
      class="deal-item-options__suggested-square-meters"
      type="number"
      name="deal-suggested-square-meters-price"
      :label="$t('WIZARD.LIST.SQUARE_METERS_PRICE')"
    />
  </div>
</template>

<script>
// Components
import { HdInput, HdToast } from 'homeday-blocks';
// Constants
import { ACTION, MUTATION } from '@/store/CONSTANTS';

export default {
  name: 'DealItemOptions',
  components: {
    HdInput,
    HdToast,
  },
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
  computed: {
    unsavedDeal() {
      return this.$store.state.wizard.unsavedDeals[this.deal.uid];
    },
    suggestedSqmPrice: {
      // https://homeday.atlassian.net/browse/TOFU-5439
      set(value) {
        // Update suggestedSqmPrice in store.
        this.$store.commit(MUTATION.WIZARD.UPDATE_DEAL_PROPERTIES, {
          uid: this.deal.uid,
          properties: {
            suggestedSqmPrice: value,
          },
        });

        // This deal may have an unsaved version in the store, so we need to update it there as well.
        this.$store.commit(MUTATION.WIZARD.SET_UNSAVED_DEAL_SUGGESTED_SQM_PRICE, {
          uid: this.deal.uid,
          suggestedSqmPrice: value,
        });
      },
      get() {
        return this.unsavedDeal ? this.unsavedDeal.suggestedSqmPrice : this.deal.suggestedSqmPrice;
      },
    },
    googleMapsUrl() {
      return this.deal.googleMapsUrl;
    },
    immoweltUrl() {
      return this.deal.immoweltUrl;
    },
  },
  methods: {
    toastCloseHandler() {
      this.$refs.toast?.$emit('close');
    },
    removeDeal() {
      this.$store
        .dispatch(ACTION.WIZARD.REMOVE_DEAL, { dealUid: this.deal.uid })
        .then(() => {
          this.$store.commit(MUTATION.WIZARD.CLEAN_DEALS_CACHE);
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.toastCloseHandler();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.deal-item-options {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  @include for('mobile-only') {
    flex-direction: column;
  }

  .btn {
    color: $quaternary-color;
    border: none;
    background-color: $white;

    @include for('mobile-only') {
      height: 30px;
      margin: 0;
    }
  }

  &__suggested-square-meters {
    margin-bottom: 0;

    @include for('tablet') {
      max-width: 180px;
    }
  }

  > * {
    margin-left: $sp-m;
    margin-top: $sp-m;
  }
}
</style>
