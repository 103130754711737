<template>
  <button type="button" class="price-input-button" v-on="$listeners">
    <HdIcon :src="signIcon" class="price-input-button__sign" width="22" height="22" />
  </button>
</template>

<script>
// Components
import { HdIcon } from 'homeday-blocks';
// Icons
import { plus, minus } from 'homeday-assets';

export default {
  name: 'PriceInputButton',
  components: {
    HdIcon,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    signIcon() {
      if (this.value >= 0) {
        return plus;
      }

      return minus;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

$baseSize: 56px;

.price-input-button {
  background-color: getShade($quaternary-color, 30);
  border: 0;
  border-radius: $default-border-radius 0 0 0;
  cursor: pointer;
  height: $baseSize;
  padding: 0;
  width: $baseSize;

  &__sign {
    vertical-align: middle;

    ::v-deep path {
      fill: $quaternary-color;
    }
  }
}
</style>
