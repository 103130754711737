import dealModule from './deal';
import state from './state';
import mutations from './mutations';
import actions from './actions';
import { DEAL_MODULE_NAME } from './CONSTANTS';

export default {
  modules: {
    [DEAL_MODULE_NAME]: {
      ...dealModule,
      namespaced: true,
    },
  },
  state,
  mutations,
  actions,
};
