// Store
import store from '@/store';
import { MUTATION, ACTION } from '@/store/CONSTANTS';

// Route names
import {
  DEALS as DEALS_ROUTE_NAMES,
  SLIDES as SLIDES_ROUTE_NAMES,
} from '@/router/wizard/ROUTE_NAMES';

// Views
import Deals from '@/views/wizard/Deals.vue';
import SlideItem from '@/views/wizard/SlideItem.vue';

// Children routes
import dealsChildren from '@/router/wizard/deals/routes';
import slidesChildren from '@/router/wizard/slides';

// Services
import { sendPageView, EVENTS, getCustomStatistics } from '@/services/gtm';

export default [
  {
    path: '',
    redirect: { name: DEALS_ROUTE_NAMES.INDEX },
  },
  {
    path: 'deals',
    component: Deals,
    redirect: { name: DEALS_ROUTE_NAMES.INDEX },
    children: dealsChildren,
  },
  {
    path: 'deal/:dealUid',
    name: SLIDES_ROUTE_NAMES.INDEX,
    component: SlideItem,
    children: slidesChildren,
    async beforeEnter(to, _from, next) {
      const { dealUid } = to.params;
      const deal = store.state.wizard.deals.find(({ uid }) => uid === dealUid);
      const unsavedDeal = store.state.wizard.unsavedDeals[dealUid];

      // https://homeday.atlassian.net/browse/TOFU-5439
      // Load an unsaved deal from store if there is any.
      if (unsavedDeal) {
        store.commit(MUTATION.WIZARD.CURRENT_DEAL.SET, unsavedDeal);
        return next();
      }

      let dealState = { ...deal };
      try {
        store.commit('setLoading', true);
        dealState = await store.dispatch(ACTION.WIZARD.FETCH_SINGLE_DEAL, dealUid);
        store.commit('setLoading', false);
      } catch (e) {
        store.commit('setLoading', false);
        if (!dealState) return next({ name: DEALS_ROUTE_NAMES.INDEX });
      }

      store.commit(MUTATION.WIZARD.CURRENT_DEAL.SET, dealState);
      sendPageView({
        ...EVENTS.PAGE_VIEW.WIZARD_VALUATION_FUNNEL,
        ...getCustomStatistics(store, to.path),
      });

      return next();
    },
  },
];
