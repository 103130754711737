export const getApartmentTypeFloorElevatorFactors = ({ floor, numberOfFloors }) => ({
  half_basement: [
    {
      range: [-Infinity, Infinity],
      value: {
        withoutElevator: 0.71,
        withElevator: 0.71,
      },
    },
  ],
  ground_floor: [
    {
      range: [-Infinity, Infinity],
      value: {
        withoutElevator: 0.9,
        withElevator: 0.9,
      },
    },
  ],
  raised_ground_floor: [
    {
      range: [-Infinity, Infinity],
      value: {
        withoutElevator: 0.95,
        withElevator: 0.95,
      },
    },
  ],
  terraced_flat: [
    {
      range: [-Infinity, Infinity],
      value: {
        withoutElevator: 1.02,
        withElevator: 1.02,
      },
    },
  ],
  apartment: [
    {
      range: [-Infinity, Infinity],
      value: {
        withoutElevator: 1 - 0.01 * floor,
        withElevator: 1.05,
      },
    },
  ],
  maisonette: [
    {
      range: [-Infinity, 0],
      value: {
        withoutElevator: 1.01,
        withElevator: 1.02,
      },
    },
    {
      range: [0, numberOfFloors - 1],
      value: {
        withoutElevator: 1 - 0.01 * floor,
        withElevator: 1.05,
      },
    },
    {
      range: [numberOfFloors - 1, numberOfFloors],
      value: {
        withoutElevator: 1.15 - 0.01 * floor,
        withElevator: 1.19,
      },
    },
  ],
  loft: [
    {
      range: [-Infinity, Infinity],
      value: {
        withoutElevator: 1.1 - 0.01 * floor,
        withElevator: 1.15,
      },
    },
  ],
  penthouse: [
    {
      range: [-Infinity, Infinity],
      value: {
        withoutElevator: 1.18 - 0.01 * floor,
        withElevator: 1.24,
      },
    },
  ],
  roof_storey: [
    {
      range: [-Infinity, Infinity],
      value: {
        withoutElevator: 1.15 - 0.01 * floor,
        withElevator: 1.19,
      },
    },
  ],
});

export const RENT_STATUSES = {
  vacant: 1.03,
  owner_occupation: 1.03,
  rented: 0.97,
};
