<template>
  <transition name="dropdownAnim">
    <div class="dropdown">
      <h3 v-if="title" class="dropdown__title">
        {{ title }}
      </h3>
      <button
        v-for="(item, i) in items"
        :key="`dropdown-item-${i}`"
        :style="{
          animationDelay: `${250 + 50 * i}ms`,
        }"
        class="dropdown__item"
        type="button"
        @click="$emit('itemClick', item)"
      >
        <slot :item="item">
          <div class="dropdown__item-inner" v-text="item.label" />
        </slot>
      </button>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'HdDropdown',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },
  mounted() {
    // Using timeout to avoid firing the event when the user tries to open the panel
    // And it also covers teh case of double clicks (as witnessed in some hotjar recordings)
    setTimeout(() => {
      window.addEventListener('click', this.onWindowClick);
      window.addEventListener('keyup', this.onEscPress);
    }, 500);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onWindowClick);
    window.removeEventListener('keyup', this.onEscPress);
  },
  methods: {
    onWindowClick(e) {
      if (!this.$el.contains(e.target)) {
        this.$emit('close');
        this.$emit('close:click');
      }
    },
    onEscPress(e) {
      if (['Escape', 'Esc'].includes(e.key) || e.keyCode === 27) {
        this.$emit('close');
        this.$emit('close:esc');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.dropdown {
  position: absolute;
  max-height: 314px;
  background-color: $white;
  padding: 0;
  z-index: 10;
  overflow-y: auto;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 5px 5px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  animation: fadeIn 0.5s, topToNormal 0.5s;

  &__title {
    display: block;
    padding: $sp-m $sp-m $sp-s;
    color: $primary-color;
    @include font('DS-100');
  }

  &__item {
    display: block;
    width: 100%;
    color: inherit;
    font: inherit;
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    opacity: 0;
    animation: leftToNormal 0.3s, fadeIn 0.3s forwards;
    &:hover,
    &:focus {
      background-color: getShade($quaternary-color, 40);
    }
  }

  &__item-inner {
    padding: $sp-s $sp-m;
    text-align: left;
  }
}

.dropdownAnim-leave-active {
  animation: fadeOut 0.3s forwards, normalToTop 0.3s forwards;
}
</style>
