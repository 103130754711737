<template>
  <HdModal class="deal">
    <div class="deal__modal">
      <HdNotification v-if="error" type="error" :message="$t('WIZARD.DEAL.ERROR_MESSAGE', error)" />
      <HdForm ref="form" class="container" @submit="submit">
        <div class="deal__group columns is-multiline">
          <h4 class="deal__group-title column is-full">{{ $t('WIZARD.DEAL.PROPERTY_DATA') }}</h4>
          <div class="deal__row column is-full">
            <div class="columns">
              <HdSelect
                v-model="property.propertyType"
                class="column is-5"
                :required="true"
                name="property-type"
                :label="$t('WIZARD.DEAL.PROPERTY_TYPE')"
                :options="propertyTypes"
              />
              <HdInputFormatter
                v-model="property.livingSpace"
                class="column is-2"
                :required="true"
                type="number"
                name="property-size"
                :label="$t('GENERAL.LIVING_SPACE')"
                :formatter="$formatSpace"
              />
              <HdInput
                v-model="property.location"
                class="column is-5"
                :required="true"
                name="location"
                :label="$t('WIZARD.DEAL.ZIP_AND_LOCATION')"
                :custom-rules="locationValidation"
              />
            </div>
          </div>
          <div class="deal__row column is-full">
            <div class="columns">
              <HdInput
                v-model="property.address"
                class="column is-12"
                :required="true"
                name="address"
                :label="$t('WIZARD.DEAL.LOCATION')"
                :custom-rules="addressValidation"
              />
            </div>
          </div>
        </div>
        <div class="deal__group columns is-multiline">
          <div class="column is-6">
            <div class="columns is-multiline">
              <h4 class="deal__group-title column is-full">{{ $t('WIZARD.DEAL.OWNER_DATA') }}</h4>
              <HdSelect
                ref="salutation"
                v-model="property.ownerSalutation"
                class="deal__column column is-4"
                :required="true"
                name="salutation"
                :label="$t('FORM.SALUTATION.LABEL')"
                :options="salutationOptions"
              />
              <HdInput
                v-model="property.ownerFirstName"
                class="deal__field column is-8"
                :required="true"
                name="first-name"
                :label="$t('FORM.FIRST_NAME.LABEL')"
              />
              <HdInput
                v-model="property.ownerLastName"
                class="deal__field column is-full"
                :required="true"
                name="last-name"
                :label="$t('FORM.LAST_NAME.LABEL')"
              />
            </div>
          </div>
          <div class="column is-6">
            <div class="columns is-multiline">
              <h4 class="deal__group-title column is-full">{{ $t('WIZARD.DEAL.MEETING_DATA') }}</h4>
              <HdInput
                v-model="property.valuationAppointmentDate"
                class="column is-5"
                :required="true"
                name="appointment-date"
                :label="$t('WIZARD.DEAL.APPOINTMENT_DATE')"
                type="date"
                :custom-rules="appointmentValidation"
              />
            </div>
          </div>
        </div>
        <div class="deal__actions columns is-full">
          <HdButton class="column is-2" modifier="secondary" type="reset" @click="close">{{
            $t('FORM.BUTTON.CLOSE')
          }}</HdButton>
          <HdButton data-testid="submit" class="column is-3" modifier="primary" type="submit">{{
            $t('WIZARD.DEAL.NEW')
          }}</HdButton>
        </div>
      </HdForm>
    </div>
  </HdModal>
</template>

<script>
// Components
import {
  HdModal,
  HdForm,
  HdInput,
  HdButton,
  HdInputFormatter,
  HdSelect,
  HdNotification,
} from 'homeday-blocks';
// Services
import { getItemsFromTranslationObject } from '@/services/helpers';
import { sendEvent, EVENTS } from '@/services/gtm';
// Constants
import { PROPERTY_TYPES, SALUTATION_TYPES } from '@/config/TRANSLATION_CONSTANTS';
import { ACTION, GETTER } from '@/store/CONSTANTS';

const PROPERTY_FLOWS = {
  APARTMENT: {
    propertyType: 'apartment',
    propertyTypeFlow: 'apartment',
  },
  HOUSE: {
    propertyType: 'house',
    propertyTypeFlow: 'house',
  },
  MULTI_FAMILY_HOUSE: {
    propertyType: 'house',
    propertyTypeFlow: 'multi_family_house',
  },
};

const PROPERTY_OPTIONS = getItemsFromTranslationObject(PROPERTY_TYPES);
const SALUTATION_OPTIONS = getItemsFromTranslationObject(SALUTATION_TYPES);
const CURRENT_DATE = new Date().toLocaleString('en-CA', {
  // For browser input[type=date] compatibility
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

export default {
  name: 'DealModal',
  components: {
    HdModal,
    HdForm,
    HdInput,
    HdButton,
    HdInputFormatter,
    HdSelect,
    HdNotification,
  },
  data() {
    return {
      error: null,
      property: {
        propertyType: PROPERTY_OPTIONS[0].value,
        livingSpace: 100,
        valuationAppointmentDate: CURRENT_DATE,
        ownerSalutation: SALUTATION_OPTIONS[0].value,
      },
    };
  },
  computed: {
    propertyTypes() {
      return PROPERTY_OPTIONS;
    },
    salutationOptions() {
      return SALUTATION_OPTIONS;
    },
    isFormValid() {
      return this.$refs.form.$data.fields.every((field) => field.isValid !== false); // some fields don't have this attribute
    },
    addressValidation() {
      return [
        {
          validate(address) {
            return (
              Boolean(address?.match(/\D{3,}/)?.[0] && address?.match(/\d+/)?.[0]) &&
              address?.match(/\D{3,55}\D{0,5}/)?.[0]
            );
          },
          errorMessage: this.$t('FORM.ADDRESS.ERROR.INVALID'),
        },
        {
          validate(address) {
            return address?.toString().length <= 60;
          },
          errorMessage: this.$t('PROFILE.FORM.MAX_CHARS', { size: 60 }),
        },
      ];
    },
    locationValidation() {
      return [
        {
          validate(location) {
            return Boolean(location?.match(/\D{3,}/)?.[0] && location?.match(/\d{5}/)?.[0]);
          },
          errorMessage: this.$t('FORM.LOCATION.ERROR.INVALID'),
        },
        {
          validate(address) {
            return address?.toString().length <= 32;
          },
          errorMessage: this.$t('PROFILE.FORM.MAX_CHARS', { size: 32 }),
        },
      ];
    },
    appointmentValidation() {
      return [
        {
          validate(data) {
            return data && new Date(data) !== 'Invalid Date' && !Number.isNaN(new Date(data));
          },
          errorMessage: this.$t('FORM.DATE.ERROR.INVALID'),
        },
      ];
    },
    formattedLocation() {
      const { location } = this.property;
      const cityRegex = /\D{3,}/;
      const postalCodeRegex = /\d{5}/;
      const city = location?.match(cityRegex)?.[0].trim();
      const postalCode = location?.match(postalCodeRegex)?.[0];

      return {
        isValid: Boolean(city && postalCode),
        city, // Avoid lodash's _startCase - it normalizes the string (replaces ß with ss)
        postalCode,
      };
    },
    formattedAddress() {
      const { address } = this.property;
      // One or more digit/s followed by one or more non-whitespace character/s
      // The Regex should work with following examples: `MyStr. 13`, `MyStr. 13a`, `13a/b MyStr.`, `13-14 MyStr.`, `13/1 MyStr.`
      const streetNumberRegex = /\d+\S*/;
      const street = address?.replace(streetNumberRegex, '').trim();
      const streetNumber = address?.match(streetNumberRegex)?.[0];

      return {
        isValid: Boolean(street && streetNumber),
        street, // Avoid lodash's _startCase - it normalizes the string (replaces ß with ss)
        streetNumber,
      };
    },
  },
  mounted() {
    this.$refs.salutation.$el.focus();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      if (!this.isFormValid) {
        return;
      }

      // Setup address and location
      const { postalCode, city } = this.formattedLocation;
      const { street, streetNumber } = this.formattedAddress;
      const propertyFlow = PROPERTY_FLOWS[this.property.propertyType.toUpperCase()] || {};

      const deal = {
        ...this.property,
        ...propertyFlow,
        postalCode,
        city,
        street,
        streetNumber,
        address: `${street} ${streetNumber}`,
        location: `${postalCode} ${city}`,
        userId: this.$store.getters[GETTER.USER.UID],
      };

      this.$store
        .dispatch(ACTION.WIZARD.ADD_DEAL, deal)
        .then(() => {
          // Tracking
          sendEvent(EVENTS.INTERACTION.DEAL_CREATION);

          this.$emit('submit');
        })
        .catch((error = {}) => {
          this.error = error;

          setTimeout(() => {
            this.error = null;
          }, 5000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.deal {
  &__modal {
    z-index: 9999;
    width: 835px;
    height: fit-content;
    background-color: $white;
    padding: $sp-m $sp-m $sp-m;
  }
  &__actions {
    justify-content: flex-end;
    gap: $sp-m;
    margin-top: $sp-s;
  }
}
</style>
