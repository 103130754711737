import { isNil as _isNil, camelCase as _camelCase } from 'lodash-es';

// Decodes bathroom from flat key value pair structure as used in the BE, to a bathrooms object
// used in state.
export function bathroomsDecoder(apiDeal, getFeature) {
  if (!_isNil(apiDeal.last_evaluation?.features)) {
    const { features } = apiDeal.last_evaluation;

    const bathroomTypes = [
      'separate_guest_wc_price',
      'interior_bathroom_price',
      'bathroom_with_window_price',
    ];
    const matchingBathrooms = [];
    features.forEach((feature) => {
      if (bathroomTypes.includes(feature.name)) {
        matchingBathrooms.push({
          // this converts the feature name to camelCase and removes price from the name.
          name: _camelCase(feature.name).replace(/price/gi, ''),
          price: getFeature({ path: `last_evaluation.${feature.name}` }),
        });
      }
    });
    return {
      hasSeparateGuestWc: getFeature({
        path: 'has_separate_guest_wc',
        defaultValue: false,
      }),
      hasInteriorBathroom: getFeature({
        path: 'has_interior_bathroom',
        defaultValue: false,
      }),
      hasBathroomWithWindow: getFeature({
        path: 'has_bathroom_with_window',
        defaultValue: false,
      }),
      numberOfBathrooms: getFeature({
        path: 'number_of_bathrooms',
        defaultValue: 0,
      }),
      numberOfGuestBathrooms: getFeature({
        path: 'number_of_guest_bathrooms',
        defaultValue: 0,
      }),
      types: matchingBathrooms,
    };
  }
  return undefined;
}
