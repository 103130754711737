<template>
  <wizard-base
    class="bank_valuation"
    :title="$t('WIZARD.BANK_VALUATION.TITLE')"
    :subtitle="$t('WIZARD.BANK_VALUATION.SUB_TITLE')"
  >
    <div v-show="showValuation" class="bank_valuation__max-loan">
      <HdIcon :src="documentsHandover" width="48" height="48" class="account-controls__icon" />
      <h3>
        {{ $t('WIZARD.BANK_VALUATION.MAX_LOAN') }}
        <span class="bank_valuation__amount"> {{ $formatPrice(lendingValue) }}</span
        >*
      </h3>
    </div>
    <BankValuationChart />
    <p v-show="showValuation" class="bank_valuation__footer-text">
      {{ $t('WIZARD.BANK_VALUATION.FOOTER_TEXT') }}
    </p>
  </wizard-base>
</template>

<script>
import WizardBase from '@/layout/WizardBase.vue';
import { HdIcon } from 'homeday-blocks';
import { documentsHandover } from 'homeday-assets/L';
import BankValuationChart from '@/components/BankValuationChart.vue';
import { GETTER } from '@/store/CONSTANTS';

export default {
  name: 'BankValuationSlide',
  components: {
    WizardBase,
    BankValuationChart,
    HdIcon,
  },
  data() {
    return {
      documentsHandover,
    };
  },
  computed: {
    lendingValue() {
      return this.$store.getters[GETTER.WIZARD.CURRENT_DEAL.LENDING_VALUE] * 1.2;
    },
    showValuation() {
      return this.$store.getters[GETTER.WIZARD.CURRENT_DEAL.MARKETING_PRICE] > this.lendingValue;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.wizard-base.bank_valuation {
  padding-top: #{$sp-xl - $sp-s};
}

.bank_valuation {
  ::v-deep {
    > .wizard-base__subtitle {
      @include font('DS-100');
      margin: $sp-xs auto 0;
      max-width: 675px;
    }

    > .wizard-base__container {
      margin: $sp-m auto $sp-l;
      max-width: 790px;
    }
  }

  &__max-loan {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: getShade($secondary-color, 60);
    margin-bottom: #{$sp-l - $sp-s};
    padding: $sp-s;

    h3 {
      font-weight: 600;
    }

    svg {
      margin-right: $sp-m;
    }
  }

  &__amount {
    @include font('DS-300');
    font-weight: 700;
    margin-left: $sp-xs;
  }

  &__footer-text {
    font-style: italic;
    font-weight: 600;
    margin-top: $sp-l;
    text-align: center;
  }
}
</style>
