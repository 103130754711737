<template>
  <wizard-base class="rent-status" :title="title">
    <div class="rent-status__fields">
      <HdRadioCardGroup v-model="rentStatus" name="rentStatus" :mode="radioCardMode">
        <HdRadioCard
          v-for="status in rentStatuses"
          :key="status.value"
          :native-value="status.value"
        >
          <template #icon>
            <HdIcon
              :src="radioCardMode === 'card' ? status.icon : status.mobileIcon"
              :class-from-fill="defaultIconColors"
            />
          </template>
          {{ status.text }}
        </HdRadioCard>
      </HdRadioCardGroup>
      <PriceContributionInput
        v-model="availabilityPrice"
        name="availability"
        class="rent-status__input"
      />
    </div>
  </wizard-base>
</template>

<script>
// Components
import WizardBase from '@/layout/WizardBase.vue';
import PriceContributionInput from '@/components/PriceContributionInput.vue';
import { HdRadioCard, HdRadioCardGroup, OnResizeService, HdIcon } from 'homeday-blocks';
// Services
import { mapFeatures } from '@/services/feature';
import { mapPriceContributions } from '@/services/priceContribution';
import { isMobile } from '@/services/breakpoints';
import { SVG_DEFAULT_COLORS } from '@/config/CONSTANTS';

export default {
  name: 'RentStatusBaseSlide',
  components: {
    WizardBase,
    HdRadioCard,
    HdRadioCardGroup,
    PriceContributionInput,
    HdIcon,
  },
  inject: ['slideTransitionDuration'],
  props: {
    title: {
      type: String,
      required: true,
    },
    rentStatuses: {
      type: Array,
      required: true,
      validator(values) {
        const KEYS = ['text', 'value', 'icon'];
        const hasValues = values.length > 0;
        const hasKeys = values.every((value) => {
          const keys = Object.keys(value);
          const missingKeys = KEYS.filter((key) => !keys.includes(key));

          return missingKeys.length === 0;
        });

        return hasValues && hasKeys;
      },
    },
  },
  data() {
    return {
      radioCardMode: isMobile() ? 'tile' : 'card',
    };
  },
  computed: {
    ...mapFeatures(['rentStatus']),
    ...mapPriceContributions(['availability']),
    defaultIconColors() {
      return SVG_DEFAULT_COLORS;
    },
  },
  created() {
    OnResizeService.onThrottled(this.updateRadioCardMode);
  },
  destroyed() {
    OnResizeService.offThrottled(this.updateRadioCardMode);
  },
  methods: {
    updateRadioCardMode() {
      if (isMobile()) {
        this.radioCardMode = 'tile';
      } else {
        this.radioCardMode = 'card';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.rent-status {
  overflow-x: hidden;

  ::v-deep .wizard-base__container {
    margin-right: 0;
    margin-left: 0;
  }

  &__input {
    width: 200px;
    margin: $sp-xl auto $sp-l;
  }

  ::v-deep .card img {
    width: 85px;
    height: 85px;
  }

  ::v-deep .card--tile img {
    width: 44px;
    height: 44px;
  }
}
</style>
