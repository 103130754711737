<template>
  <div class="tile-select-with-reset">
    <hd-tile-select
      :value="value"
      :name="`${name}TileSelect`"
      :items="items"
      class="tile-select-with-reset__select"
      @input="inputHandler"
    />
    <hd-button
      v-if="valueSelected"
      :name="`${name}TileReset`"
      :icon-src="restore"
      :aria-label="$t('GENERAL.RESET_SELECTION')"
      type="button"
      modifier="tertiary"
      class="tile-select-with-reset__button"
      @click="$emit('input', undefined)"
    />
  </div>
</template>

<script>
import { HdTileSelect, HdButton } from 'homeday-blocks';
import { isNil as _isNil } from 'lodash-es';
import { restore } from 'homeday-assets';

export default {
  name: 'TileSelectWithReset',
  components: {
    HdButton,
    HdTileSelect,
  },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
  },
  data() {
    return {
      restore,
    };
  },
  computed: {
    valueSelected() {
      // checks if null and/or undefined
      return !_isNil(this.value);
    },
  },
  methods: {
    inputHandler(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.tile-select-with-reset {
  @include for('tablet') {
    display: grid;
    grid-template-columns: 6fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: $sp-m;
    grid-row-gap: 0;
    width: 120%;
  }
  &__select {
    @include for('tablet') {
      grid-area: 1 / 1 / 2 / 2;
    }
  }
  &__button {
    margin-bottom: $sp-s;
    height: 44px;
    width: 100%;
    @include for('tablet') {
      width: 44px;
      margin-bottom: 0;
      margin-top: $sp-xs;
      grid-area: 1 / 2 / 2 / 3;
    }
  }
}
</style>
