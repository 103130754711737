<template>
  <nav class="navigation" :aria-expanded="menuIsOpen">
    <button class="navigation__toggle_button" type="button" @click="toggleMenu">
      <HdIcon :src="icons.close" class="navigation__toggle_icon" />
    </button>
    <div class="navigation__logo_wrapper">
      <router-link :to="{ name: DEALS_ROUTES.INDEX }">
        <img :src="icons.logo" class="navigation__logo" alt="Homeday" />
        <img :src="icons.logoSquare" class="navigation__logo" alt="Homeday" />
      </router-link>
      <div class="navigation__hamburger_wrapper">
        <HdButton
          class="navigation__notes_button_small navigation__notes_button_small_mobile"
          modifier="secondary"
          :icon-src="icons.addNote"
        />
        <button class="navigation__hamburger" @click="toggleMenu">
          <span class="navigation__hamburger_lines" />
        </button>
      </div>
    </div>
    <ul class="navigation__wrapper">
      <NavigationTree v-for="(item, idx) in navigationItems" :key="idx" :target="item" :depth="0" />
    </ul>
    <div class="navigation__notes_button_wrapper">
      <HdButton
        class="navigation__notes_button_small navigation__notes_button_small_desktop"
        modifier="secondary"
        :icon-src="icons.addNote"
      />
      <HdButton class="navigation__notes_button_big" modifier="secondary" :icon-src="icons.addNote">
        {{ $t('WIZARD.MENU.NOTES_BUTTON') }}
      </HdButton>
    </div>
  </nav>
</template>

<script>
import { HdButton, HdIcon } from 'homeday-blocks';
import { close, smallArrow } from 'homeday-assets';
import { addNote } from 'homeday-assets/S';
import logo from '@/assets/logo/immowelt-logo-default.svg';
import logoSquare from '@/assets/logo/immowelt-logo-square.svg';
import { DEALS as DEALS_ROUTES } from '@/router/wizard/ROUTE_NAMES';
import { MUTATION } from '@/store/CONSTANTS';
import NavigationTree from './NavigationTree.vue';

export default {
  name: 'SlideNavigation',
  components: {
    HdButton,
    HdIcon,
    NavigationTree,
  },
  props: {
    flowSlides: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      DEALS_ROUTES,
      menuIsOpen: true,
    };
  },
  computed: {
    navigationItems() {
      // Removing first and last screen to not include them in the menu grouping
      const filteredItems = this.flowSlides.slice(1, this.flowSlides.length);
      return filteredItems;
    },
    icons() {
      return {
        close,
        addNote,
        logo,
        logoSquare,
        smallArrow,
      };
    },
  },
  created() {
    this.menuIsOpen = !(window.screen.width <= 659);
    this.$store.commit(MUTATION.WIZARD.TOGGLE_NAVIGATION, this.menuIsOpen);
  },
  methods: {
    toggleMenu() {
      this.menuIsOpen = !this.menuIsOpen;
      this.$store.commit(MUTATION.WIZARD.TOGGLE_NAVIGATION, this.menuIsOpen);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

$transition-timing: 0.3s;

.navigation__wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 140px);
  justify-content: flex-start;
  margin-top: $sp-m;
  opacity: 0;
  overflow-y: auto;
  pointer-events: none;
  position: relative;

  @include for('mobile-only') {
    margin-top: 38px;
  }
}

.navigation {
  $root: &;
  background-color: $primary-bg;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.25);
  color: $quaternary-color;
  height: 100%;
  left: 0;
  max-width: 400px;
  padding: 25px 20px;
  position: fixed;
  top: 0;
  transition: width $transition-timing, height 0.5s;
  width: 67px;
  z-index: 99;

  @include for('mobile-only') {
    box-shadow: none;
    height: 110px;
    max-width: none;
    padding: 30px 40px;
    width: 100%;
  }

  &__logo {
    display: none;
    max-width: 30px;
    margin-bottom: $sp-m;
    &:last-child {
      display: block;
    }
  }

  &[aria-expanded='true'] {
    width: 305px;
    z-index: 100;

    #{$root}__logo {
      display: none;
      max-width: 110px;
      &:first-child {
        display: block;
      }
    }

    @include for('mobile-only') {
      height: 100%;
      width: 100%;
    }

    .navigation__wrapper,
    .navigation__toggle_button {
      opacity: 1;
      pointer-events: auto;
      transition: opacity $transition-timing;
      transition-delay: $transition-timing;
    }

    .navigation__toggle_button {
      @include for('mobile-only') {
        display: none;
      }
    }

    .navigation__hamburger {
      opacity: 0;
      pointer-events: none;

      @include for('mobile-only') {
        opacity: 1;
        pointer-events: auto;
      }

      &_lines,
      &_lines:before,
      &_lines:after {
        background-color: $primary-color;
        height: 2px;
        width: 18px;

        @include for('mobile-only') {
          height: 3px;
          width: 24px;
        }
      }

      &_lines {
        @include for('mobile-only') {
          background-color: $primary-bg;
        }
      }

      &_lines:before,
      &_lines:after {
        @include for('mobile-only') {
          opacity: 1;
          top: 0;
        }
      }

      &_lines:before {
        @include for('mobile-only') {
          transform: rotate(45deg);
        }
      }

      &_lines:after {
        @include for('mobile-only') {
          transform: rotate(-45deg);
        }
      }
    }

    .navigation__logo_wrapper {
      height: auto;
      width: 100%;
    }

    .navigation__notes_button_wrapper {
      @include for('mobile-only') {
        height: 45px;
        padding-bottom: 25px;
      }
    }

    .navigation__notes_button {
      &_small {
        max-height: 0;
        max-width: 0;
        padding: 0;
        opacity: 0;
        transition: max-height $transition-timing, padding $transition-timing;

        span.btn__icon {
          height: 0;
          width: 0;
        }
      }

      &_big {
        width: auto;
        opacity: 1;
        padding: 8px 32px;
        transition: opacity $transition-timing;
        transition-delay: $transition-timing;

        @include for('mobile-only') {
          width: calc(100% - 80px); // 100% - mobile gutter padding (40px * 2)
        }
      }
    }
  }

  &__toggle_button {
    align-items: center;
    background-color: $primary-bg;
    border: 1px solid #d6dde6;
    border-radius: 50%;
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    color: $primary-color;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: -16px;
    top: 83px;
    transition: opacity $transition-timing;
    width: 32px;
  }

  &__toggle_icon {
    width: 16px;
  }

  &__logo_wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: width $transition-timing;
    width: 40px;

    @include for('mobile-only') {
      flex-direction: row;
      overflow: visible;
      width: 100%;
    }
  }

  &__hamburger_wrapper {
    @include for('mobile-only') {
      align-items: center;
      display: flex;
      gap: $sp-m;
      justify-content: flex-end;
      width: 100%;
    }
  }

  &__hamburger {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    height: 15px;
    opacity: 1;
    transition: opacity $transition-timing;

    &_lines {
      display: block;
      position: relative;

      &:after,
      &:before {
        content: '';
        left: 0;
        position: absolute;
      }
    }

    &_lines,
    &_lines:before,
    &_lines:after {
      background-color: $primary-color;
      height: 2px;
      width: 18px;

      @include for('mobile-only') {
        height: 3px;
        transition: transform $transition-timing, background-color 0.2s;
        width: 24px;
      }
    }

    &_lines:before {
      top: -4px;

      @include for('mobile-only') {
        top: -7px;
      }
    }

    &_lines:after {
      top: 4px;

      @include for('mobile-only') {
        top: 7px;
      }
    }
  }

  &__notes_button_wrapper {
    align-items: center;
    background-color: inherit;
    bottom: $sp-m;
    // Hide this button until note taking function is live
    display: none;
    height: 45px;
    justify-content: center;
    left: 0;
    padding: 35px 0 25px;
    position: absolute;
    width: 100%;

    @include for('mobile-only') {
      height: 0;
      padding: 0;
    }
  }

  &__notes_button_small_mobile {
    display: none;
  }

  &__notes_button_small_desktop {
    @include for('mobile-only') {
      display: none;
    }
  }

  &__notes_button_small {
    max-height: 32px;
    max-width: 32px;
    opacity: 1;
    transition: opacity $transition-timing;
    transition-delay: $transition-timing;
  }

  &__notes_button_big {
    opacity: 0;
    padding: 0;
    width: 0;
  }
}
</style>
