<template>
  <WizardBase class="instruction" :title="$t('WIZARD.INSTRUCTION.TITLE')">
    <HdNotification
      v-if="checkInvalidFields.length"
      ref="notificationMissingData"
      class="notification__missing_data"
      :class="{ menuIsOpen }"
      type="notification"
    >
      <p>{{ $t('WIZARD.INSTRUCTION.ERROR.PANEL') }}</p>
      <button class="notification__close_button" type="button" @click="closePanel()">
        <HdIcon :src="icons.close" class="notification__close_icon" />
      </button>
    </HdNotification>
    <div class="instruction__wrapper">
      <HdDynamicForm ref="form" :items="formItems" class="instruction-form">
        <template #before>
          <h3 class="instruction-form__legend">
            {{ $t('WIZARD.INSTRUCTION.FORM_LEGEND') }}
          </h3>
        </template>
      </HdDynamicForm>
      <div class="instruction-notes">
        <HdIcon
          class="instruction-notes__icon"
          :src="myHomedayAppIcon"
          :class-from-fill="defaultIconColors"
        />
        <SlideSubmitButtonWrapper v-slot="{ disabled }" class="instruction-notes__button-wrapper">
          <HdButton
            :disabled="disabled || isDataInvalid || checkInvalidFields.length > 0"
            modifier="primary"
            type="submit"
            class="instruction-notes__button"
            data-cy="button-next"
            data-testid="button-submit"
          >
            {{ $t('WIZARD.INSTRUCTION.SUBMIT_BUTTON') }}
          </HdButton>
        </SlideSubmitButtonWrapper>
        <div v-if="checkInvalidFields.length" class="instruction-missing_fields">
          <p class="instruction-missing_fields__title">
            {{ $t('WIZARD.INSTRUCTION.MISSING_DATA') }}
          </p>
          <ul>
            <li
              v-for="item in checkInvalidFields"
              :key="item.field"
              class="instruction-missing_fields__item"
            >
              <router-link class="instruction-missing_fields__link" :to="{ name: item.route }">
                {{ item.field }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </WizardBase>
</template>

<script>
// Lodash
import {
  get as _get,
  inRange as _inRange,
  isNumber as _isNumber,
  isString as _isString,
  toFinite as _toFinite,
} from 'lodash-es';
// Components
import { HdButton, HdDynamicForm, HdIcon, HdNotification } from 'homeday-blocks';
import WizardBase from '@/layout/WizardBase.vue';
import SlideSubmitButtonWrapper from '@/components/SlideSubmitButtonWrapper.vue';
// Services
import { createEvaluationFromDeal } from '@/services/evaluation';
import { SVG_DEFAULT_COLORS } from '@/config/CONSTANTS';
import { email as validateEmail } from 'homeday-blocks/src/services/formValidation';
import { validatePhone } from '@/services/validators';
// Icons
import { close, mail, phone, userCircle as user } from 'homeday-assets';
import { myHomedayApp as myHomedayAppIcon } from 'homeday-assets/L';
// Constants
import { FAILED_REQUEST_TYPE } from '@/services/failedRequest';
import {
  BROKER_ORDER_ERROR_REASON,
  MAXIMUM_FLOORS,
  MAXIMUM_SPACE,
  MAXIMUM_YEAR,
  MINIMUM_FLOORS,
  MINIMUM_SPACE,
  MINIMUM_YEAR,
  PROPERTY_TYPE_FLOW,
} from '@/config/CONSTANTS';
import { ACTION, MUTATION } from '@/store/CONSTANTS';

const TELEPHONE = 'telephone';
const EMAIL = 'email';

export default {
  name: 'InstructionSlide',
  components: {
    WizardBase,
    HdDynamicForm,
    HdButton,
    HdIcon,
    HdNotification,
    SlideSubmitButtonWrapper,
  },
  data() {
    return {
      myHomedayAppIcon,
    };
  },
  computed: {
    defaultIconColors() {
      return SVG_DEFAULT_COLORS;
    },
    checkInvalidFields() {
      const { currentDeal } = this.$store.state.wizard;

      let requiredFields = [
        {
          field: String(this.$t('WIZARD.ROOMS.TITLE')),
          route: 'roomsSlide',
          valid: _inRange(_toFinite(currentDeal.numberOfRooms), MINIMUM_FLOORS, MAXIMUM_FLOORS),
        },
        {
          field: String(this.$t('WIZARD.FLOORS.TITLE')),
          route: 'floorsSlide',
          valid: !!(
            _isNumber(currentDeal.numberOfFloors) &&
            _inRange(_toFinite(currentDeal.numberOfFloors), MINIMUM_FLOORS, MAXIMUM_FLOORS)
          ),
        },
        {
          field: String(this.$t('WIZARD.CONSTRUCTION_AND_RENOVATION.TITLE')),
          route: 'constructionAndRenovationSlide',
          valid: !!(
            _isNumber(currentDeal.constructionYear) &&
            _inRange(_toFinite(currentDeal.constructionYear), MINIMUM_YEAR, MAXIMUM_YEAR)
          ),
        },
        {
          field: String(this.$t('WIZARD.RENT_STATUS.TITLE')),
          route: 'rentStatusSlide',
          valid: !!_isString(currentDeal.rentStatus),
        },
        {
          field: String(this.$t('WIZARD.LIVING_SPACE.TITLE')),
          route: 'livingSpaceSlide',
          valid: !!(
            _isNumber(Number(currentDeal.livingSpace)) &&
            _inRange(_toFinite(currentDeal.livingSpace), MINIMUM_SPACE, MAXIMUM_SPACE)
          ),
        },
      ];

      if (currentDeal.propertyType === PROPERTY_TYPE_FLOW.HOUSE) {
        if (currentDeal.buildingType === PROPERTY_TYPE_FLOW.MULTI_FAMILY_HOUSE) {
          const i = requiredFields.findIndex((item) => item.route === 'livingSpaceSlide');

          requiredFields[i].field = String(this.$t('WIZARD.LIVING_AND_COMMERCIAL_SPACE.TITLE'));
          requiredFields[i].route = 'livingAndCommercialSpaceSlide';
        } else {
          requiredFields = [
            ...requiredFields,
            {
              field: String(this.$t('GENERAL.PLOT_AREA')),
              route: 'plotAreaSlide',
              valid:
                _isNumber(Number(currentDeal.plotArea)) &&
                _inRange(_toFinite(currentDeal.plotArea), MINIMUM_SPACE, MAXIMUM_SPACE),
            },
            {
              field: String(this.$t('WIZARD.BUILDING_TYPE.TITLE')),
              route: 'buildingTypeSlide',
              valid: !!_isString(currentDeal.buildingType),
            },
          ];
        }
      }

      const invalidFields = requiredFields.filter((field) => !field.valid);

      return invalidFields;
    },
    icons() {
      return {
        close,
      };
    },
    currentDeal() {
      return this.$store.state.wizard.currentDeal;
    },
    dealState() {
      return this.$store.state.wizard.deals.find((deal) => deal.uid === this.currentDeal.uid);
    },
    failedBrokerOrderRequest() {
      return this.dealState.failedRequests.find(
        (fail) => fail.type === FAILED_REQUEST_TYPE.BROKER_ORDER,
      );
    },
    primaryContact() {
      return this.currentDeal.primaryContact;
    },
    marketingStart() {
      // We use today's date as a fallback
      const marketingStart = this.currentDeal.marketingStart || new Date(Date.now());

      // We grab just the YYYY-MM-DD part
      return marketingStart.toISOString().split('T')[0];
    },
    formItems() {
      return [
        [
          {
            type: 'select',
            name: 'salutation',
            initialValue: this.primaryContact.salutation,
            props: {
              icon: user,
              label: this.$t('FORM.SALUTATION.LABEL'),
              options: [
                {
                  label: this.$t('GENERAL.SALUTATION_MALE'),
                  value: 'male',
                },
                {
                  label: this.$t('GENERAL.SALUTATION_FEMALE'),
                  value: 'female',
                },
              ],
              style: { flexGrow: 1 },
            },
          },
          {
            type: 'input',
            name: 'firstName',
            initialValue: this.primaryContact.first_name,
            props: {
              required: true,
              label: this.$t('FORM.FIRST_NAME.LABEL'),
              placeholder: this.$t('FORM.FIRST_NAME.PLACEHOLDER'),
              customRules: this.nameValidations,
              style: { flexGrow: 1 },
            },
          },
          {
            type: 'input',
            name: 'lastName',
            initialValue: this.primaryContact.last_name,
            props: {
              required: true,
              label: this.$t('FORM.LAST_NAME.LABEL'),
              placeholder: this.$t('FORM.LAST_NAME.PLACEHOLDER'),
              customRules: this.nameValidations,
              style: { flexGrow: 1 },
            },
          },
        ],
        {
          type: 'input',
          name: TELEPHONE,
          initialValue: this.primaryContact.phone,
          props: {
            icon: phone,
            type: 'tel',
            required: true,
            label: this.$t('FORM.TELEPHONE.LABEL'),
            customRules: this.phoneValidations,
            withValidationCheck: true,
          },
        },
        {
          type: 'input',
          name: EMAIL,
          initialValue: this.primaryContact.email,
          ref: 'emailInput',
          props: {
            icon: mail,
            type: 'email',
            required: true,
            label: this.$t('FORM.EMAIL.LABEL'),
            placeholder: this.$t('FORM.EMAIL.PLACEHOLDER'),
            customRules: this.emailValidations,
            withValidationCheck: true,
          },
        },
        // {
        //   type: 'input',
        //   name: 'marketingStart',
        //   initialValue: this.marketingStart,
        //   props: {
        //     icon: event,
        //     type: 'date',
        //     required: true,
        //     label: this.$t('WIZARD.INSTRUCTION.MARKETING_START_LABEL'),
        //     placeholder: this.$t('FORM.DATE.PLACEHOLDER'),
        //   },
        // },
      ];
    },
    isDataInvalid() {
      return !this.$refs.form.validate();
    },
    menuIsOpen() {
      return this.$store.state.wizard.menuIsOpen;
    },
    nameValidations() {
      return [
        {
          validate: (value) => value.toString().length <= 20,
          errorMessage: this.$t('PROFILE.FORM.MAX_CHARS', { size: 20 }),
        },
      ];
    },
    phoneValidations() {
      return [
        {
          validate: validatePhone,
          errorMessage: this.$t('FORM.TELEPHONE.ERROR.INVALID_FORMAT'),
        },
        {
          validate: (value) => value.toString().length <= 15,
          errorMessage: this.$t('PROFILE.FORM.MAX_CHARS', { size: 15 }),
        },
      ];
    },
    emailValidations() {
      return [
        {
          validate: validateEmail,
          errorMessage: this.$t('WIZARD.INSTRUCTION.VALIDATION.EMAIL'),
        },
        {
          validate: (value) => value.toString().length <= 40,
          errorMessage: this.$t('PROFILE.FORM.MAX_CHARS', { size: 40 }),
        },
      ];
    },
  },
  created() {
    this.finishEvaluation();
  },
  mounted() {
    this.highlightErroredInput();
  },
  methods: {
    closePanel() {
      this.$refs.notificationMissingData.$el.style.display = 'none';
    },
    highlightErroredInput() {
      if (!this.failedBrokerOrderRequest) return;
      if (
        [BROKER_ORDER_ERROR_REASON.HARD_BOUNCE, BROKER_ORDER_ERROR_REASON.SOFT_BOUNCE].includes(
          this.failedBrokerOrderRequest.reason,
        )
      ) {
        this.$refs.form.$refs.fields
          .find((el) => el.name === EMAIL)
          .showError(this.$t('WIZARD.INSTRUCTION.VALIDATION.EMAIL'));
      } else if (this.failedBrokerOrderRequest.reason === BROKER_ORDER_ERROR_REASON.PHONE) {
        this.$refs.form.$refs.fields
          .find((el) => el.name === TELEPHONE)
          .showError(this.$t('WIZARD.INSTRUCTION.VALIDATION.PHONE'));
      }
    },
    async finishEvaluation() {
      const { currentDeal } = this.$store.state.wizard;

      // Calculate cold rent per month if applicable
      const calculatedColdRent =
        this.currentDeal.livingSpace * this.currentDeal.baseRentPerSqm +
        this.currentDeal.commercialSpace * this.currentDeal.commercialBaseRentPerSqm;

      this.$store.commit(
        MUTATION.WIZARD.CURRENT_DEAL.SET_ACTUAL_COLD_RENT,
        calculatedColdRent || 0,
      );

      const evaluation = createEvaluationFromDeal({
        deal: currentDeal,
        getters: this.$store.getters,
      });

      // We update the deals array to save this data even if the request fails
      this.$store.commit(MUTATION.WIZARD.UPDATE_DEAL_PROPERTIES, {
        uid: currentDeal.uid,
        properties: {
          ...currentDeal,
          // We reset the values valid for the current evaluation session only
          optimalPrice: undefined,
        },
      });

      // We submit the evaluation
      this.$store.dispatch(ACTION.WIZARD.CREATE_EVALUATION, {
        dealUid: currentDeal.uid,
        evaluation,
      });
    },
    async validate() {
      return this.$refs.form.validate();
    },
    async beforeMoveToNextSlide() {
      const data = _get(this.$refs, 'form.formData', {});

      const dealParams = {
        ownerFirstName: data.firstName,
        ownerLastName: data.lastName,
        ownerSalutation: data.salutation,
        ownerPhoneNumber: data.telephone,
        ownerEmail: data.email,
        // marketingStart: data.marketingStart, Todo
      };

      try {
        await this.$store.dispatch(ACTION.WIZARD.SIGN_BROKER_ORDER, {
          dealUid: this.currentDeal.uid,
          payload: dealParams,
        });
      } catch (err) {
        // If we are offline we proceed to the next slide where we tell the user about it
        if (this.$store.state.offline) {
          return true;
        }
        this.highlightErroredInput();

        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.instruction {
  &__wrapper {
    display: grid;

    @include for('tablet') {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: $sp-l;
    }

    @include for('desktop') {
      grid-column-gap: $sp-xl;
    }

    .instruction-form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include for('tablet') {
        margin-bottom: -24px; // Error spacing from input
      }

      &__legend {
        margin-bottom: $sp-m;

        @include for('mobile-only') {
          display: none;
        }
      }
    }

    .instruction-notes {
      display: flex;
      flex-flow: column wrap;

      &__icon {
        display: block;
        margin: 0 auto;

        @include for('mobile-only') {
          display: none;
        }
      }

      &__text {
        @include font('DS-100');
        margin-top: $sp-m;
        margin-bottom: $sp-l;

        @include for('mobile-only') {
          display: none;
        }
      }

      &__button-wrapper {
        display: block;
        text-align: center;
        margin-top: auto;
      }

      &__button {
        width: 100%;
        margin-top: $sp-m;
        @include font('DS-100');

        @include for('tablet') {
          width: auto;
        }
      }
    }

    .instruction-missing_fields {
      color: #ff142b;
      padding: $sp-m 0;
      text-align: left;

      &__title {
        text-align: center;
        font-size: 14px;
        font-style: italic;
        margin-bottom: $sp-m;
      }

      &__item {
        text-align: center;
        margin-bottom: $sp-s;
      }

      &__link {
        color: getShade($secondary-color, 110);
      }
    }
  }

  .notification__missing_data {
    animation: slideDown 0.3s forwards;
    animation-delay: 0.5s;
    opacity: 0;
    padding: $sp-s $sp-m;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateY(-88px);
    width: 100%;
    z-index: 30;

    @include for('tablet') {
      transform: translateY(-40px);
      transition: width 0.3s;
      width: calc(100% - 67px);
    }

    ::v-deep .notification__content {
      display: flex;
      justify-content: space-between;
    }

    ::v-deep .notification__close_button {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      display: inherit;
      padding: 0;

      path {
        fill: white;
      }
    }
  }

  .notification__missing_data.menuIsOpen {
    @include for('tablet') {
      width: calc(100% - 258px);
    }
  }

  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-88px);

      @include for('tablet') {
        transform: translateY(-40px);
      }
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
