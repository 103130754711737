import { get as _get } from 'lodash-es';
import { UtilsService } from 'homeday-blocks';
import { isTablet, isMobile } from '@/services/breakpoints';
import store from '@/store';
import { GETTER } from '@/store/CONSTANTS';
import CONFIG from '@/config/env';
import {
  initListener as initCookiebotListener,
  shareConsentGTM,
  getCurrentConsent,
  hasStatisticsConsent,
} from '@/services/cookiebot';

export { default as EVENTS } from '@/config/GTM_EVENTS';

let isLoaded = false;

export function setDataLayerVariables() {
  window.dataLayer = window.dataLayer || [];

  let deviceType = 'desktop';
  if (isTablet()) deviceType = 'tablet';
  if (isMobile()) deviceType = 'mobile';

  const application = _get(CONFIG, 'integrations.gtm.application');

  const variables = {
    environment: {
      country: 'de',
      language: 'de',
      device_type: deviceType,
      application,
      network_status: 'online',
    },
  };

  const { uid } = store.state.user.data;

  if (uid) {
    variables.user = {
      uid,
    };
  }

  window.dataLayer.push(variables);

  // Forward cookie consent state to GTM
  const currentConsent = getCurrentConsent().CookieConsent.consent;
  ['preferences', 'statistics', 'marketing'].forEach((type) => {
    const event = `cookie_consent_${type}`;
    if (currentConsent[type]) window.dataLayer.push({ event });
  });
}

const initializeDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  // We just empty the array. Attention! don't assign an empty array to the dataLayer
  // As you'll loose the redefined `push()` method added by GTM
  window.dataLayer.length = 0;

  shareConsentGTM();
};

export function gtag() {
  // gtag expects Arguments and not just a normal array of params, so spread operator doesn't work
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

const setupConsentMode = () => {
  initializeDataLayer();

  // Default ad_storage to 'denied'.
  gtag('consent', 'default', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
    wait_for_update: 500,
  });

  gtag('set', 'ads_data_redaction', true);
};

export function load() {
  if (isLoaded) {
    return;
  }

  const id = _get(CONFIG, 'integrations.gtm.id');

  if (!id) {
    return;
  }

  UtilsService.loadScript({
    url: `https://www.googletagmanager.com/gtm.js?id=${id}`,
    head: true,
    first: true,
  });

  // Cookiebot works with GTM consent mode. You can read more information following the next links
  // https://developers.google.com/gtagjs/devguide/consent
  // https://support.cookiebot.com/hc/en-us/articles/360016047000-Cookiebot-and-Google-Consent-Mode
  setupConsentMode();
  initCookiebotListener();

  // We populate the dataLayer
  setDataLayerVariables();

  window.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });

  isLoaded = true;
}

export function getCustomStatistics(store, path) {
  return {
    language: 'de',
    path,
    environment: 'production',
    platform: window.navigator.userAgent,
    site: 'Homeday',
    user: store.getters[GETTER.USER.UID],
  };
}

export function sendEvent({ event = '', category = '', email = '', linkText = '' }) {
  if (!hasStatisticsConsent()) {
    return;
  }
  if (window.dataLayer === undefined) {
    return;
  }
  window.dataLayer.push({
    event,
    av_category: category,
    'av_user.email': email,
    link_text: linkText,
  });
}

export function sendPageView({
  event = '',
  pageTitle = '',
  category = '',
  language = '',
  path = '',
  platform = '',
  environment = '',
  site = '',
  user = '',
}) {
  if (!isLoaded || !hasStatisticsConsent()) {
    return;
  }

  window.dataLayer.push({
    event,
    av_pagetitle: pageTitle,
    av_category: category,
    av_language: language,
    av_url_path: path,
    av_platform: platform,
    av_environment: environment,
    av_site: site,
    av_user: user,
  });
}

// We update the datalayer variables on user
store.watch(
  (state) => state.user.data.uid,
  () => {
    if (!hasStatisticsConsent()) return;
    setDataLayerVariables();
  },
);
