<template>
  <div v-if="isLoading" class="loading">
    <hd-loading-spinner class="loading__spinner" />
  </div>
  <slide-error
    v-else-if="isError"
    :icon="errorIcon"
    :title="$t('WIZARD.LIST.ERROR.TITLE')"
    :text="$t('WIZARD.LIST.ERROR.TEXT')"
  />
  <slide-error
    v-else-if="isEmpty"
    :icon="errorIcon"
    :title="$t('WIZARD.LIST.EMPTY.TITLE')"
    :text="$t('WIZARD.LIST.EMPTY.TEXT')"
  />
  <deals-list v-else :deals="deals" />
</template>

<script>
// Components
import HdLoadingSpinner from '@/components/HdLoadingSpinner.vue';
import DealsList from '@/components/DealsList.vue';
import SlideError from '@/components/SlideError.vue';
// Icons
import { crossCircle as errorIcon } from 'homeday-assets/L';
// Constants
import { STATUS } from '@/config/WIZARD';
import { FILTERS } from '@/config/DEAL';
// Store
import { ACTION } from '@/store/CONSTANTS';

export default {
  name: 'DealsBaseView',
  components: {
    HdLoadingSpinner,
    DealsList,
    SlideError,
  },
  data() {
    return {
      filter: FILTERS.FUTURE,
      status: STATUS.LOADING,
      errorIcon,
    };
  },
  computed: {
    deals() {
      return [...this.$store.state.wizard.deals].filter((deal) => deal.filter === this.filter);
    },
    isLoading() {
      return this.status === STATUS.LOADING;
    },
    isError() {
      return this.status === STATUS.ERROR;
    },
    isEmpty() {
      return this.status === STATUS.SUCCESS && this.deals.length === 0;
    },
  },
  async created() {
    if (this.deals.length === 0) {
      this.status = STATUS.LOADING;
      try {
        await this.$store.dispatch(ACTION.WIZARD.FETCH_DEALS, { filter: this.filter });

        this.status = STATUS.SUCCESS;
      } catch {
        this.status = STATUS.ERROR;
      }
    } else {
      this.status = STATUS.SUCCESS;
    }
  },
};
</script>

<style lang="scss" scoped>
.loading {
  display: flex;

  &__spinner {
    margin: auto;
  }
}
</style>
