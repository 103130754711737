import localStorage from './localStorage';
import sessionStorage from './sessionStorage';

function getItem(key) {
  return sessionStorage.getItem(key) || localStorage.getItem(key);
}
function setItem(key, value) {
  localStorage.setItem(key, value);
  sessionStorage.setItem(key, value);
}
function removeItem(key) {
  localStorage.removeItem(key);
  sessionStorage.removeItem(key);
}

const browserStorage = {
  local: localStorage,
  session: sessionStorage,
  getItem,
  setItem,
  removeItem,
};
export default browserStorage;
