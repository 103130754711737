import Vue from 'vue';
import { MUTATION } from './CONSTANTS';

export default {
  [MUTATION.SET_DATA](state, data) {
    Vue.set(state, 'data', data);
  },
  [MUTATION.SET_TOKEN](state, token) {
    Vue.set(state, 'token', token);
  },
  [MUTATION.SET_ACTION_STATUS](state, status) {
    Vue.set(state, 'userActionStatus', status);
  },
};
