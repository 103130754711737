<template>
  <rent-status-base
    class="rent-status-multi-family-house"
    :title="title"
    :rent-statuses="rentStatuses"
  />
</template>

<script>
// Component
import RentStatusBase from '@/views/wizard/RentStatusBase.vue';
// Services
import { mapFeatures } from '@/services/feature';
import { mapPriceContributions } from '@/services/priceContribution';
// Icons
import { handEuro as handEuroL, crossCircle as crossCircleL } from 'homeday-assets/L';

import { handEuro as handEuroM, crossCircle as crossCircleM } from 'homeday-assets/M';
// Constants
import { RENT_STATUS } from '@/config/CONSTANTS';

export default {
  name: 'RentStatusMultiFamilyHouseSlide',
  components: { RentStatusBase },
  data() {
    return {
      title: this.$t('WIZARD.RENT_STATUS.TITLE'),
      rentStatuses: [
        {
          text: this.$t('WIZARD.MULTI_FAMILY_HOUSE.RENT_STATUS.RENTED'),
          value: RENT_STATUS.RENTED,
          icon: handEuroL,
          mobileIcon: handEuroM,
        },
        {
          text: this.$t('WIZARD.RENT_STATUS.VACANT'),
          value: RENT_STATUS.VACANT,
          icon: crossCircleL,
          mobileIcon: crossCircleM,
        },
      ],
    };
  },
  // It is necessary because we use computed to know which features are being applied
  computed: {
    ...mapFeatures(['rentStatus']),
    ...mapPriceContributions(['availability']),
  },
};
</script>

<!-- Overwrite inner styles -->
<style lang="scss">
.rent-status-multi-family-house {
  .rent-status {
    &__input {
      display: none;
    }
  }
}
</style>
