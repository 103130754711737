<template>
  <div class="page-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PageContainer',
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.page-container {
  height: 100%;
  width: 100%;
  max-width: #{$content-max-width + (2 * $sp-l)};
  margin-left: auto;
  margin-right: auto;

  padding-left: $sp-s;
  padding-right: $sp-s;

  @include for('tablet') {
    padding-left: $sp-m;
    padding-right: $sp-m;
  }

  @include for('desktop') {
    padding-left: $sp-l;
    padding-right: $sp-l;
  }
}
</style>
