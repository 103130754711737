export default {
  MAP_IMAGE: {
    q: 95,
    blend: 'https://homeday-assets.s3.eu-central-1.amazonaws.com/images/UI/marker-tip-center.png',
    'blend-mode': 'normal',
    'blend-w': 30,
    'blend-h': 90,
  },
  MAP_APPROXIMATIVE_IMAGE: {
    q: 95,
    blend: 'https://homeday-assets.s3.eu-central-1.amazonaws.com/images/UI/circle.png?v=20220920',
    'blend-mode': 'normal',
    'blend-w': 700,
    'blend-h': 700,
  },
  REALTOR: {
    trim: 'color',
    w: 350,
    'max-h': 350,
    fit: 'crop',
    crop: 'top',
  },
  BROKER_ORDER_REALTOR: {
    trim: 'color',
    w: (window.devicePixelRatio || 1) >= 2 ? 1028 : 514,
    'max-h': (window.devicePixelRatio || 1) >= 2 ? 1028 : 514,
    fit: 'crop',
    crop: 'top',
  },
};
