import { BUILDING_TYPES, SPECIFIC_PROPERTY_TYPE } from '@/config/CONSTANTS';
import { SLIDES } from '@/router/wizard/slides';
import { house, location, buildings, group, chart, globe, euroSymbol, plot } from 'homeday-assets';

export const APARTMENT_SLIDES_NAVIGATION = [
  {
    ...SLIDES.explanation,
    shouldBeSkipped: () => true,
  },
  {
    icon: house,
    title: 'GENERAL.INTRO',
    items: [SLIDES.procedure],
  },
  {
    icon: location,
    title: 'GENERAL.LOCATION',
    items: [SLIDES.location, SLIDES.livingSpace, SLIDES.calculatedPrice],
  },
  {
    icon: buildings,
    title: 'GENERAL.PROPERTY_DETAILS',
    items: [
      {
        title: 'GENERAL.BASIC_DATA',
        items: [
          SLIDES.constructionAndRenovation,
          SLIDES.roofRenovation,
          SLIDES.facadeRenovation,
          SLIDES.windowRenovation,
          SLIDES.heatingRenovation,
          SLIDES.apartmentAccessibility,
          SLIDES.ownerShare,
          SLIDES.rooms,
        ],
      },
      {
        title: 'GENERAL.INTERIOR_DESIGN',
        items: [SLIDES.bathroom, SLIDES.basement],
      },
      {
        title: 'GENERAL.EXTERIOR_DESIGN',
        items: [SLIDES.parking, SLIDES.balconyAndTerrace, SLIDES.garden],
      },
      SLIDES.additionalFeatures,
    ],
  },
  {
    icon: group,
    title: 'GENERAL.BUYER_TARGET',
    items: [SLIDES.rentStatus, SLIDES.targetGroups, SLIDES.capitalInvestor, SLIDES.bankValuation],
  },
  {
    icon: globe,
    title: 'GENERAL.MARKET',
    items: [SLIDES.marketDevelopment],
  },
  {
    icon: chart,
    title: 'GENERAL.ASSESSMENT_REPORT',
    items: [
      SLIDES.sellingStrategy,
      {
        ...SLIDES.similarProperties,
        shouldBeSkipped: () => true,
      },
      SLIDES.listingRotation,
      SLIDES.instruction,
      SLIDES.confirmation,
    ],
  },
];

export const HOUSE_SLIDES_NAVIGATION = [
  {
    ...SLIDES.explanation,
    shouldBeSkipped: () => true,
  },
  {
    icon: house,
    title: 'GENERAL.INTRO',
    items: [SLIDES.procedure],
  },
  {
    icon: location,
    title: 'GENERAL.LOCATION',
    items: [SLIDES.location, SLIDES.livingSpace, SLIDES.calculatedPrice, SLIDES.plotArea],
  },
  {
    icon: buildings,
    title: 'GENERAL.PROPERTY_DETAILS',
    items: [
      {
        title: 'GENERAL.BASIC_DATA',
        items: [
          SLIDES.buildingType,
          SLIDES.constructionAndRenovation,
          SLIDES.roofRenovation,
          SLIDES.facadeRenovation,
          SLIDES.windowRenovation,
          SLIDES.heatingRenovation,
          SLIDES.floors,
          SLIDES.rooms,
        ],
      },
      {
        title: 'GENERAL.INTERIOR_DESIGN',
        items: [SLIDES.bathroom, SLIDES.basement],
      },
      {
        title: 'GENERAL.EXTERIOR_DESIGN',
        items: [SLIDES.balconyAndTerrace, SLIDES.garden, SLIDES.parking],
      },
      SLIDES.additionalFeatures,
    ],
  },
  {
    icon: group,
    title: 'GENERAL.BUYER_TARGET',
    items: [SLIDES.rentStatus, SLIDES.targetGroups, SLIDES.capitalInvestor, SLIDES.bankValuation],
  },
  {
    icon: globe,
    title: 'GENERAL.MARKET',
    items: [SLIDES.marketDevelopment],
  },
  {
    icon: chart,
    title: 'GENERAL.ASSESSMENT_REPORT',
    items: [
      SLIDES.sellingStrategy,
      {
        ...SLIDES.similarProperties,
        shouldBeSkipped: () => true,
      },
      SLIDES.listingRotation,
      SLIDES.instruction,
      SLIDES.confirmation,
    ],
  },
];

export const MULTI_FAMILY_HOUSE_SLIDES_NAVIGATION = [
  SLIDES.procedureMultiFamilyHouse,
  {
    icon: euroSymbol,
    title: 'GENERAL.EARNINGS',
    items: [
      SLIDES.livingAndCommercialSpace,
      {
        ...SLIDES.floors,
        // We don't want to show the price tag in this case
        headerCenter: undefined,
      },
      SLIDES.rentStatusMultiFamilyHouse,
      SLIDES.earningsValue,
    ],
  },
  {
    icon: plot,
    title: 'GENERAL.CONDITION',
    items: [
      {
        ...SLIDES.roofRenovation,
        shouldBeSkipped: undefined,
      },
      {
        ...SLIDES.facadeRenovation,
        shouldBeSkipped: undefined,
      },
      {
        ...SLIDES.windowRenovation,
        shouldBeSkipped: undefined,
      },
      {
        ...SLIDES.heatingRenovation,
        shouldBeSkipped: undefined,
      },
      SLIDES.additionalFeatures,
    ],
  },
  {
    icon: chart,
    title: 'GENERAL.MARKET_COMPARISON',
    items: [
      {
        ...SLIDES.similarProperties,
        shouldBeSkipped: () => true,
      },
      SLIDES.marketDevelopment,
      SLIDES.sellingStrategy,
      SLIDES.listingRotation,
      SLIDES.instruction,
      SLIDES.confirmation,
    ],
  },
];

// TODO: We don't have PLOTs available at the moment
export const PLOT_SLIDES_NAVIGATION = [
  SLIDES.additionalFeatures,
  SLIDES.targetGroups,
  SLIDES.capitalInvestor,
  SLIDES.bankValuation,
  {
    ...SLIDES.similarProperties,
    shouldBeSkipped: () => true,
  },
  SLIDES.marketDevelopment,
  SLIDES.sellingStrategy,
  SLIDES.listingRotation,
  SLIDES.instruction,
  SLIDES.confirmation,
];

export default {
  [SPECIFIC_PROPERTY_TYPE.APARTMENT]: APARTMENT_SLIDES_NAVIGATION,
  [SPECIFIC_PROPERTY_TYPE.HOUSE]: HOUSE_SLIDES_NAVIGATION,
  [BUILDING_TYPES.MULTI_FAMILY_HOUSE]: MULTI_FAMILY_HOUSE_SLIDES_NAVIGATION,
  [SPECIFIC_PROPERTY_TYPE.PLOT]: PLOT_SLIDES_NAVIGATION,
};
