<template>
  <wizard-price-base
    v-model="model"
    :label="label"
    :editable="$attrs.editable"
    pre-value-token="Ø"
    post-value-token="pro m²"
    :max-price="maxPrice"
  />
</template>

<script>
// Components
import WizardPriceBase from '@/components/WizardPriceBase.vue';
// Constants
import { MUTATION } from '@/store/CONSTANTS';

const MAX_SQM_PRICE = 99_999;

export default {
  name: 'WizardPriceSuggestedSqmPrice',
  components: {
    WizardPriceBase,
  },
  data() {
    return {
      label: this.$t('WIZARD.PRICE_WIDGET.AVERAGE_SELLING_PRICE'),
      maxPrice: MAX_SQM_PRICE,
    };
  },
  computed: {
    model: {
      get() {
        return this.$store.state.wizard.currentDeal.suggestedSqmPrice;
      },
      set(value) {
        this.$store.commit(MUTATION.WIZARD.CURRENT_DEAL.SET_SUGGESTED_SQM_PRICE, value);
      },
    },
  },
};
</script>
