<template>
  <price-input
    ref="input"
    :name="`${name}Price`"
    :label="$t('WIZARD.CHANGE_IN_VALUE')"
    :value="value"
    :parse-to-number="true"
    :custom-rules="customRules"
    @input="handleInputEvent"
    @blur="handleBlurEvent"
  />
</template>

<script>
// Services
import { get as _get, isNil as _isNil, uniq as _uniq } from 'lodash-es';
// Components
import PriceInput from '@/components/PriceInput.vue';
// Constants
import PRICE_CONTRIBUTIONS from '@/config/PRICE_CONTRIBUTIONS';
import { MUTATION } from '@/store/CONSTANTS';

export default {
  name: 'PriceContributionInput',
  components: {
    PriceInput,
  },
  inject: ['getPreviousSlides'],
  props: {
    value: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    customRules: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      boundValue: this.value,
      isFocused: false,
      isEmpty: false,
    };
  },
  computed: {
    dependencies() {
      return PRICE_CONTRIBUTIONS.find(({ name }) => name === this.name).usedFeatures || [];
    },
    dependenciesUsedInPreviousSlides() {
      return _uniq(
        this.getPreviousSlides()
          .flatMap((slide) => Object.keys(slide.component.computed || {}))
          .filter((computedProperty) => this.dependencies.includes(computedProperty)),
      );
    },
    hasRealtorPrice() {
      return !_isNil(_get(this.$store.state, `wizard.currentDeal.priceContributions.${this.name}`));
    },
  },
  watch: {
    hasRealtorPrice() {
      this.checkForMissingDependencies();
    },
  },
  mounted() {
    this.checkForMissingDependencies();
  },
  methods: {
    checkForMissingDependencies() {
      const isMissingDependency = this.dependenciesUsedInPreviousSlides.some((dependency) =>
        _isNil(_get(this.$store.state, `wizard.currentDeal.${dependency}`)),
      );

      if (isMissingDependency && !this.hasRealtorPrice) {
        this.showHelper(this.$t('WIZARD.PREVIOUS_FEATURE_MISSING'));
      } else {
        this.showHelper('');
      }
    },
    handleInputEvent(value) {
      // We set the value of isEmpty here and we handle it on blur (check `handleBlurEvent`)
      this.isEmpty = value.length === 0;

      // We parse to a number to make the empty string considered as `0`
      // so the user can see the price without the effect of this feature
      this.$emit('input', Number(value));
    },
    handleBlurEvent() {
      if (this.isEmpty) {
        this.unsetRealtorPrice();
      }
    },
    unsetRealtorPrice() {
      this.$store.commit(MUTATION.WIZARD.CURRENT_DEAL.SET_PRICE_CONTRIBUTION, {
        name: this.name,
        value: null,
      });
    },
    showError(...args) {
      return this.$refs.input.showError(...args);
    },
    showHelper(...args) {
      return this.$refs.input.showHelper(...args);
    },
    validate(...args) {
      return this.$refs.input.validate(...args);
    },
    hideError(...args) {
      return this.$refs.input.hideError(...args);
    },
  },
};
</script>
