<template>
  <section class="listing-rotation">
    <h2 class="listing-rotation__title">
      {{ $t('WIZARD.LISTING_ROTATION.TITLE') }}
    </h2>
    <section class="listing-rotation__content">
      <div class="listing-rotation__chart-block">
        <img
          :src="permanentAdSvg"
          :alt="$t('WIZARD.LISTING_ROTATION.CHART_PERMANENTLY_ONLINE')"
          class="listing-rotation__chart-image"
        />
      </div>
      <div class="listing-rotation__chart-block">
        <img
          :src="optimalAdSvg"
          :alt="$t('WIZARD.LISTING_ROTATION.CHART_OPTIMIZED_AD_POSTURE')"
          class="listing-rotation__chart-image"
        />
      </div>
      <div class="listing-rotation__portals">
        <div
          v-for="(logo, index) in portalLogos"
          :key="index"
          :style="{
            backgroundImage: `url('${logo}')`,
          }"
          class="listing-rotation__portal"
        />
      </div>
    </section>
  </section>
</template>

<script>
import permanentAdSvg from '@/assets/listing-rotation/permanent-ad.svg';
import optimalAdSvg from '@/assets/listing-rotation/optimal-ad.svg';

// Logos
import companyLogo from '@/assets/logo/immowelt-logo-default.svg';
import immobilienscout24Logo from '@/assets/portals/logo_immobilienscout24.svg';
import ebayLogo from '@/assets/portals/logo_ebay-kleinanzeigen.svg';
import immonetLogo from '@/assets/portals/logo_immonet.svg';
import immoweltLogo from '@/assets/portals/logo_immowelt.svg';

export default {
  name: 'ListingRotationSlide',
  data() {
    return {
      permanentAdSvg,
      optimalAdSvg,
      portalLogos: [companyLogo, immobilienscout24Logo, ebayLogo, immonetLogo, immoweltLogo],
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.listing-rotation {
  &__title {
    text-align: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    background-color: getShade($quaternary-color, 40);
    padding: $sp-l;
    margin-top: $sp-l;
    border-radius: 4px;

    @include for('tablet') {
      flex-direction: row;
    }
  }

  &__chart-block {
    flex: 4;
    display: flex;
    align-items: center;
    margin-bottom: $sp-m;
    width: 100%;

    @include for('tablet') {
      margin-right: $sp-m;
      margin-bottom: 0;
    }
  }

  &__chart-image {
    width: 100%;
  }

  &__portals {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @include for('tablet') {
      flex: 0 0 12%;
      flex-direction: column;
      width: auto;
    }
  }

  &__portal {
    width: 100%;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: $sp-xs;
    margin-left: $sp-xs;

    @include for('tablet') {
      width: 100%;
      height: 50px;
      margin-right: 0;
      margin-left: 0;
    }
  }
}
</style>
