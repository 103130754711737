// @ts-check

/** @type {{ [key in import('@/config/@types').ServiceWorkerStatusType as Uppercase<key>]: key }} */
export const SERVICE_WORKER_STATUS = {
  READY: 'ready',
  REGISTERED: 'registered',
  CACHED: 'cached',
  UPDATE_FOUND: 'updateFound',
  UPDATED: 'updated',
  OFFLINE: 'offline',
  ERROR: 'error',
};

/** @type {{ [key in import('@/config/@types').StatusType as Uppercase<key>]: key }} */
export const REQUEST_STATUS = {
  SUCCESS: 'success',
  INFO: 'info',
  ERROR: 'error',
};

/** @type {{ [key in import('@/config/@types').Salutation as Uppercase<key>]: key }} */
export const SALUTATION = {
  MALE: 'male',
  FEMALE: 'female',
};

/** @type {{ [key in import('@/config/@types').PropertyType as Uppercase<key>]: key }} */
export const SPECIFIC_PROPERTY_TYPE = {
  APARTMENT: 'apartment',
  HOUSE: 'house',
  COMMERCIAL: 'commercial',
  PLOT: 'plot',
};

/** @type {{ [key in import('@/config/@types').BrokerOrderErrorReasonType as Uppercase<key>]: key }} */
export const BROKER_ORDER_ERROR_REASON = {
  UNKNOWN: 'unknown',
  SOFT_BOUNCE: 'soft_bounce',
  HARD_BOUNCE: 'hard_bounce',
  PHONE: 'phone',
  OFFLINE: 'offline',
  TOO_MANY_REQUESTS: 'too_many_requests',
};

/** @type {{ [key in import('@/config/@types').EvaluationReportReasonType as Uppercase<key>]: key }} */
export const EVALUATION_REPORT_ERROR_REASON = {
  UNKNOWN: 'unknown',
  OFFLINE: 'offline',
  TOO_MANY_REQUESTS: 'too_many_requests',
};

/** @type {{ [key in import('@/config/@types').BuildingType as Uppercase<key>]: key }} */
export const BUILDING_TYPES = {
  BUNGALOW: 'bungalow',
  CASTLE_MANOR_HOUSE: 'castle_manor_house',
  FARMHOUSE: 'farmhouse',
  MULTI_FAMILY_HOUSE: 'multi_family_house',
  SEMIDETACHED_HOUSE: 'semidetached_house',
  SINGLE_FAMILY_HOUSE: 'single_family_house',
  STEPPED_BUILDING: 'stepped_building',
  TERRACE_HOUSE: 'terrace_house',
  VILLA: 'villa',
};

/** @type {{ [key in import('@/config/@types').HeatingType as Uppercase<key>]: key }} */
export const HEATING = {
  GAS_CENTRAL_HEATING: 'gas_central_heating',
  GAS_FLOOR_HEATING: 'gas_floor_heating',
  OIL_HEATING: 'oil_heating',
  DISTRICT_HEATING: 'district_heating',
  PELLET_HEATING: 'pellet_heating',
  ELECTRIC_HEATING: 'electric_heating',
  COGENERATION_UNIT: 'cogeneration_unit',
  SOLAR_THERMAL_HEATING: 'solar_thermal_heating',
  HEAT_PUMP: 'heat_pump',
};

/** @type {{ [key in import('@/config/@types').PropertyType as Uppercase<key>]: key}} */
export const PROPERTY_TYPE = {
  APARTMENT: 'apartment',
  COMMERCIAL: 'commercial',
  HOUSE: 'house',
  PLOT: 'plot',
};

/** @type {{ [key in import('@/config/@types').RentStatusType as Uppercase<key>]: key }} */
export const RENT_STATUS = {
  RENTED: 'rented',
  VACANT: 'vacant',
  OWNER_OCCUPATION: 'owner_occupation',
};

/** @type {{ [key in import('@/config/@types').PropertyTypeFlow as Uppercase<key>]: key }} */
export const PROPERTY_TYPE_FLOW = {
  HOUSE: 'house',
  APARTMENT: 'apartment',
  COMMERCIAL: 'commercial',
  PLOT: 'plot',
  MULTI_FAMILY_HOUSE: 'multi_family_house',
};

/** @type {{ [key in import('@/config/@types').StatusType as Uppercase<key>]: key }} */
export const STATUS = {
  NEW_LEAD: 'new_lead',
  CONFIRM_FIRST_APPOINTMENT: 'confirm_first_appointment',
  PREPARE_FIRST_APPOINTMENT: 'prepare_first_appointment',
  UPCOMING_FIRST_APPOINTMENT: 'upcoming_first_appointment',
  SCHEDULE_ANOTHER_FIRST_APPOINTMENT: 'schedule_another_first_appointment',
  THINKING_TIME_OWNER: 'thinking_time_owner',
  OWNER_CONSIDERS_OTHER_REALTOR: 'owner_considers_other_realtor',
  ACHIEVE_PRICING_AGREEMENT: 'achieve_pricing_agreement',
  ALIGNMENT_OTHER_OWNERS: 'alignment_other_owners',
  OWNER_ON_VACATION: 'owner_on_vacation',
  OWNER_TRIES_TO_SELL_HIMSELF: 'owner_tries_to_sell_himself',
  OWNER_WANTS_TO_BUY_FIRST: 'owner_wants_to_buy_first',
  WAITING_FOR_TENANT_MOVE_OUT: 'waiting_for_tenant_move_out',
  OTHER_SHORT_TERM_DELAY: 'other_short_term_delay',
  OTHER_LONG_TERM_DELAY: 'other_long_term_delay',
  BROKER_ORDER_HANDSHAKE_AGREEMENT: 'broker_order_handshake_agreement',
  BROKER_ORDER_ONE_SIDED_SIGNATURE: 'broker_order_one_sided_signature',
  HANDSHAKE_AGREEMENT_INSTRUCTION_PHYSICAL: 'handshake_agreement_instruction_physical',
  HANDSHAKE_AGREEMENT_INSTRUCTION_DIGITAL: 'handshake_agreement_instruction_digital',
  AGREE_ON_MARKETING_PRICE: 'agree_on_marketing_price',
  PREPARE_MARKETING: 'prepare_marketing',
  CREATE_EXPOSE: 'create_expose',
  MARKETING_ON_HOLD: 'marketing_on_hold',
  GET_EXPOSE_APPROVAL: 'get_expose_approval',
  PUBLISH_EXPOSE: 'publish_expose',
  VIEWINGS: 'viewings',
  FEEDBACK_VIEWING_ROUND: 'feedback_viewing_round',
  START_NEW_VIEWING_ROUND: 'start_new_viewing_round',
  MARKETING_ANALYSIS: 'marketing_analysis',
  PREPARE_RESERVATION: 'prepare_reservation',
  RESERVED: 'reserved',
  SCHEDULE_NOTARY_APPOINTMENT: 'schedule_notary_appointment',
  UPCOMING_NOTARY_APPOINTMENT: 'upcoming_notary_appointment',
  CUSTOMER_TERMINATION_REQUEST: 'customer_termination_request',
  SCHEDULE_HANDOVER_APPOINTMENT: 'schedule_handover_appointment',
  UPCOMING_HANDOVER_APPOINTMENT: 'upcoming_handover_appointment',
  SOLD: 'sold',
  LOST: 'lost',
};

/** @type {{ [key in import('@/config/@types').StageType as Uppercase<key>]: key }} */
export const STAGE = {
  ACQUISITION: 'acquisition',
  FIRST_APPOINTMENT: 'first_appointment',
  CLOSE_BROKER_ORDER: 'close_broker_order',
  MARKETING: 'marketing',
  SOLD: 'sold',
  LOST: 'lost',
};

/** @type {{ [key in import('@/config/@types').ApartmentType as Uppercase<key>]: key }} */
export const APARTMENT_TYPE = {
  ROOF_STOREY: 'roof_storey',
  LOFT: 'loft',
  MAISONETTE: 'maisonette',
  PENTHOUSE: 'penthouse',
  TERRACED_FLAT: 'terraced_flat',
  GROUND_FLOOR: 'ground_floor',
  APARTMENT: 'apartment',
  RAISED_GROUND_FLOOR: 'raised_ground_floor',
  HALF_BASEMENT: 'half_basement',
};

export const CURRENT_YEAR = new Date().getFullYear();
export const MINIMUM_YEAR = 1400;
export const MAXIMUM_YEAR = CURRENT_YEAR + 10;
export const MINIMUM_SPACE = 10;
export const MAXIMUM_SPACE = 10_000_000;
export const MINIMUM_FLOORS = 1;
export const MAXIMUM_FLOORS = 100;

export const SVG_DEFAULT_COLORS = {
  '#C2DDFF': 'icon-primary-color',
  '#1C3553': 'icon-secondary-color',
};
