import {
  snakeCase as _snakeCase,
  isNil as _isNil,
  isNaN as _isNaN,
  pickBy as _pickBy,
  mapKeys as _mapKeys,
  invoke as _invoke,
  get as _get,
  camelCase as _camelCase,
} from 'lodash-es';

import { getPriceContributionPriceName } from '@/services/priceContribution';
import FEATURES from '@/config/FEATURES';
import PRICE_CONTRIBUTIONS from '@/config/PRICE_CONTRIBUTIONS';
import { bathroomNormalizer } from '@/services/normalizers';

export function createEvaluationFromDeal({ deal, getters, gettersPrefix = 'wizard/currentDeal/' }) {
  // We create an object with only the deal getters, using the local names
  const dealGettersWithGlobalNames = _pickBy(getters, (_, key) => key.startsWith(gettersPrefix));
  const dealGetters = _mapKeys(dealGettersWithGlobalNames, (_, key) =>
    key.replace(gettersPrefix, ''),
  );

  const customFeatures = deal.additionalFeatures.map(({ name, price }) => ({ name, price }));

  // Todo: Sync with heating normalizer.
  const allBathrooms = bathroomNormalizer.denormalize(deal.bathrooms);

  const priceContributions = PRICE_CONTRIBUTIONS.map((priceContribution) => {
    const value = dealGetters[getPriceContributionPriceName(priceContribution.name)];
    const calculatedValue = _invoke(priceContribution, 'getCalculatedPrice', deal, dealGetters);
    const usedFeatures = priceContribution.getUsedFeatures
      ? _invoke(priceContribution, 'getUsedFeatures', deal, dealGetters)
      : priceContribution.usedFeatures;

    return {
      name: _snakeCase(priceContribution.name),
      value,
      calculated_value: calculatedValue,
      used_features: usedFeatures.map(_snakeCase),
    };
  }).filter(({ value }) => !_isNil(value) && !_isNaN(value));

  const mainFeatures = FEATURES.map((feature) => {
    // Skip un-normalized features
    if (feature.normalizer) return {};

    let value = deal[feature.name];

    // If no cold rent is calculated, don't send it to BE
    if (feature.name === 'actualColdRent' && !deal.actualColdRent) return {};

    // BE does not support Array types yet, so we send them a comma separated string
    if (Array.isArray(value)) {
      value = value.join(',');
    }

    return {
      name: _snakeCase(feature.name),
      value,
    };
  }).filter(({ value }) => !_isNil(value) && value !== '');

  const normalizedFeatures = FEATURES.map((feature) => {
    // Skip main features
    if (!feature.normalizer) return {};

    return feature.normalizer.denormalize(deal[feature.name]);
  })
    .flat()
    .filter(({ value }) => !_isNil(value) && !_isNaN(value));

  const features = [...mainFeatures, ...normalizedFeatures, ...allBathrooms];

  return {
    deal_uid: deal.uid,
    public: deal.lastEvaluation.public || false,
    marketing_price: dealGetters.marketingPrice,
    calculated_price: dealGetters.totalPrice,
    sqm_price: deal.suggestedSqmPrice, // Shall it still be that?
    features,
    custom_features: customFeatures,
    price_contributions: priceContributions,
  };
}

export function createEnhancedEvaluation({ evaluation }) {
  const featureReducer = (object, { name, value }) => {
    if (object[_camelCase(name)]) {
      const _value = Array.isArray(object[_camelCase(name)])
        ? [...object[_camelCase(name)], value]
        : [value];
      return { ...object, [_camelCase(name)]: _value };
    }
    return { ...object, [_camelCase(name)]: value };
  };

  return {
    ...evaluation,
    createdAt: _get(evaluation, 'created_at') && new Date(_get(evaluation, 'created_at')),
    features: _get(evaluation, 'features', []).reduce(featureReducer, {}),
    custom_features: _get(evaluation, 'custom_features', []) || [],
    price_contributions: _get(evaluation, 'price_contributions', []).reduce(featureReducer, {}),
  };
}
