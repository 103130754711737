function getLocalStorage() {
  try {
    window.localStorage.setItem('availabilityTest', 'test');
    window.localStorage.removeItem('availabilityTest');
    return window.localStorage;
  } catch (e) {
    return null;
  }
}

const localStorage = getLocalStorage();

function getItem(key) {
  return localStorage !== null ? localStorage.getItem(key) : undefined;
}

function setItem(key, value) {
  if (localStorage !== null) localStorage.setItem(key, value);
}

function removeItem(key) {
  if (localStorage !== null) localStorage.removeItem(key);
}

const localStorageService = {
  getItem,
  setItem,
  removeItem,
};
export default localStorageService;
