const BREAKPOINT_TABLET = '(min-width:600px)';
const BREAKPOINT_DESKTOP = '(min-width:900px)';
const BREAKPOINT_DESKTOP_WIDE = '(min-width:1200px)';

export const isMobile = () => !window.matchMedia(BREAKPOINT_TABLET).matches;
export const isDesktop = () => window.matchMedia(BREAKPOINT_DESKTOP).matches;
export const isDesktopWide = () => window.matchMedia(BREAKPOINT_DESKTOP_WIDE).matches;
export const isTablet = () => !isMobile() && !isDesktop();

export function getDeviceType() {
  let deviceType = 'tablet';
  if (isMobile()) {
    deviceType = 'mobile';
  } else if (isDesktop()) {
    deviceType = 'desktop';
  } else if (isDesktopWide()) {
    deviceType = 'desktopWide';
  }
  return deviceType;
}
export default {
  isMobile,
  isDesktop,
  isDesktopWide,
  isTablet,
  getDeviceType,
};
