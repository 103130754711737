import { get } from 'lodash-es';

export async function cacheUrls(type, urls) {
  const serviceWorker = get(navigator, 'serviceWorker.controller');

  if (serviceWorker) {
    serviceWorker.postMessage({ type, payload: urls });
  }
}

export async function cacheImages(urls) {
  cacheUrls('CACHE_IMAGES', urls);
}
