export const getDefaultState = () => ({
  priceContributions: {},
  marketingPrice: undefined,
  // The optimal price is set and used on the sellingStrategy slide
  // we save it one generated to keep consitency during the evaluation
  optimalPrice: undefined,
  similarProperties: [],
  appliedPriceContributions: [],
  shouldShowDetailedRenovations: false,
  lendingValue: undefined,
});

export default {
  ...getDefaultState(),
};
