<template>
  <wizard-base class="procedure-mfh" :title="$t('WIZARD.PROCEDURE.TITLE')">
    <ProcedureItems :items="items" />
  </wizard-base>
</template>

<script>
// Components
import WizardBase from '@/layout/WizardBase.vue';
import ProcedureItems from '@/components/ProcedureItems.vue';
// Icons
import {
  walletEuro as earningsIcon,
  houseConstruction as conditionIcon,
  propertyMarketing as marketingIcon,
  houseSold as soldIcon,
} from 'homeday-assets/L';

export default {
  name: 'ProcedureMultiFamilyHouseSlide',
  components: {
    WizardBase,
    ProcedureItems,
  },
  data() {
    return {
      items: [
        {
          icon: earningsIcon,
          label: this.$t('GENERAL.EARNINGS'),
        },
        {
          icon: conditionIcon,
          label: this.$t('GENERAL.CONDITION'),
        },
        {
          icon: marketingIcon,
          label: this.$t('GENERAL.MARKET_COMPARISON'),
        },
        {
          icon: soldIcon,
          label: this.$t('GENERAL.ASSESSMENT_REPORT'),
        },
      ],
    };
  },
};
</script>
