<template>
  <div class="slide-error">
    <section class="slide-error__section">
      <HdIcon class="slide-error__image" :src="icon" :class-from-fill="defaultIconColors" />
      <div class="slide-error__text">
        <h3 class="slide-error__title">
          {{ title }}
        </h3>
        <p v-html="text" />
      </div>
    </section>
    <slide-submit-button-wrapper v-slot="{ disabled }" class="slide-error__button-wrapper">
      <hd-button
        v-if="buttonText"
        :disabled="disabled"
        class="slide-error__button"
        modifier="primary"
        type="submit"
      >
        {{ buttonText }}
      </hd-button>
    </slide-submit-button-wrapper>
  </div>
</template>

<script>
import { HdButton, HdIcon } from 'homeday-blocks';
import SlideSubmitButtonWrapper from '@/components/SlideSubmitButtonWrapper.vue';
import { SVG_DEFAULT_COLORS } from '@/config/CONSTANTS';

export default {
  name: 'SlideError',
  components: {
    HdButton,
    HdIcon,
    SlideSubmitButtonWrapper,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
  },
  computed: {
    defaultIconColors() {
      return SVG_DEFAULT_COLORS;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.slide-error {
  display: flex;
  flex-direction: column;

  &__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: getShade($quaternary-color, 23);
    padding: $sp-l;
    margin-bottom: $sp-l;

    @include for('tablet') {
      flex-direction: row;
      justify-content: center;
      padding-top: $sp-xl;
      padding-bottom: $sp-xl;
      margin-bottom: $sp-xl;
    }
  }

  &__image {
    width: 140px;
    height: 140px;

    @include for('tablet') {
      margin-right: $sp-m;
    }
  }

  &__text {
    @include for('mobile-only') {
      text-align: center;
    }
  }

  &__title {
    @include font('DS-400');

    margin-bottom: $sp-m;
  }

  &__button {
    @include for('tablet') {
      padding-left: $sp-l;
      padding-right: $sp-l;
    }
  }
  &__button-wrapper {
    margin: auto;
    width: auto;
  }
}
</style>
