import { get as _get, isNil as _isNil } from 'lodash-es';
import { CURRENT_YEAR, validateYear } from '@/services/validators';
import baseCalculations from '../base';
import { findInRange } from '../helper';
import { RENT_STATUSES, getApartmentTypeFloorElevatorFactors } from './CONSTANTS';

function getApartmentTypeAndFloorAndElevatorPrice({
  basePrice,
  apartmentType,
  floor,
  numberOfFloors,
  hasElevator,
  scaleFactor = 1,
}) {
  if (_isNil(apartmentType) || _isNil(hasElevator)) {
    return 0;
  }

  const DEFAULT = 1;

  const FACTORS = getApartmentTypeFloorElevatorFactors({
    floor,
    numberOfFloors,
  });

  const rangeValue = findInRange({
    ranges: _get(FACTORS, apartmentType, []),
    value: floor,
    defaultValue: {},
  });

  let featureFactor = hasElevator ? rangeValue.withElevator : rangeValue.withoutElevator;

  if (_isNil(featureFactor)) {
    featureFactor = DEFAULT;
  }

  return Math.round(basePrice * featureFactor * scaleFactor) - basePrice;
}

function getRentStatusPrice(params) {
  return baseCalculations.getRentStatusPrice({ ...params, RENT_STATUSES });
}

// TODO: Merge with houseCalculations
function getRoofRenovationPrice({ lastRoofRenovationYear, ownerShare = 1, scaleFactor = 1 }) {
  if (!validateYear(lastRoofRenovationYear)) {
    return 0;
  }

  const ROOF_LIFE_SPAN = 40;
  const AVERAGE_ROOF_AGE = 20;
  const ROOF_PRICE_PER_SQM = 160;
  const ROOF_AREA = 150;

  const roofAge = Math.min(CURRENT_YEAR - lastRoofRenovationYear, AVERAGE_ROOF_AGE);

  const numerator = ROOF_PRICE_PER_SQM * ROOF_AREA * (AVERAGE_ROOF_AGE - roofAge);
  const denominator = ROOF_LIFE_SPAN;
  const featurePrice = numerator / denominator;

  return Math.round(featurePrice * ownerShare * scaleFactor);
}

// TODO: Merge with houseCalculations
function getFacadeRenovationPrice({ lastFacadeRenovationYear, ownerShare = 1, scaleFactor = 1 }) {
  if (!validateYear(lastFacadeRenovationYear)) {
    return 0;
  }

  const FACADE_LIFE_SPAN = 50;
  const AVERAGE_FACADE_AGE = 25;
  const FACADE_PRICE_PER_SQM = 90;

  const facadeAge = Math.min(CURRENT_YEAR - lastFacadeRenovationYear, AVERAGE_FACADE_AGE);
  const facadeArea = 240;

  const numerator = FACADE_PRICE_PER_SQM * facadeArea * (AVERAGE_FACADE_AGE - facadeAge);
  const denominator = FACADE_LIFE_SPAN;
  const featurePrice = numerator / denominator;

  return Math.round(featurePrice * ownerShare * scaleFactor);
}

export default {
  ...baseCalculations,
  getApartmentTypeAndFloorAndElevatorPrice,
  getRentStatusPrice,
  getRoofRenovationPrice,
  getFacadeRenovationPrice,
};
