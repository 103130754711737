<template>
  <div class="logout">
    <hd-loading-spinner />
  </div>
</template>

<script>
// Components
import HdLoadingSpinner from '@/components/HdLoadingSpinner.vue';
// Constants
import { ACTION } from '@/store/CONSTANTS';

export default {
  name: 'LogoutView',
  components: { HdLoadingSpinner },
  async created() {
    await this.$store.dispatch(ACTION.USER.LOGOUT);

    this.$router.push('/login');
    this.$router.go(0);
  },
};
</script>

<style lang="scss" scoped>
.logout {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
