<template>
  <section class="location-qualities">
    <div
      v-for="{ quality, label, color } in allQualities"
      :key="`location-quality-${quality}`"
      :style="{
        flexBasis: `calc(100% / ${allQualities.length})`,
      }"
      :class="{
        isCurrent: quality === currentDeal.quality,
      }"
      class="location-qualities-item"
    >
      <div
        :style="{
          backgroundColor: color,
        }"
        class="location-qualities-item__color"
      />
      <p class="location-qualities-item__label">
        {{ label }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'LocationQualities',
  data() {
    return {
      allQualities: [
        {
          quality: 1,
          label: this.$t('QUALITIES.HUMBLE'),
          color: '#FFFAB9',
        },
        {
          quality: 2,
          label: this.$t('QUALITIES.NORMAL'),
          color: '#FFD786',
        },
        {
          quality: 3,
          label: this.$t('QUALITIES.GOOD'),
          color: '#FF9957',
        },
        {
          quality: 4,
          label: this.$t('QUALITIES.SOPHISTICATED'),
          color: '#FF4141',
        },
        {
          quality: 5,
          label: this.$t('QUALITIES.OUTSTANDING'),
          color: '#D4314A',
        },
      ],
    };
  },
  computed: {
    currentDeal() {
      return this.$store.state.wizard.currentDeal;
    },
    dealQualities() {
      return this.currentDeal.qualities;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.location-qualities {
  display: flex;
  align-items: flex-end;
}

.location-qualities-item {
  $item: &;
  flex-grow: 1;
  color: $quaternary-color;

  &.isCurrent {
    color: $primary-color;
  }

  &__color {
    height: 12px;
    margin-top: $sp-xs;

    #{$item}.isCurrent & {
      height: 24px;
    }
  }

  &__label {
    @include font('text-xxsmall');
    text-align: center;
    margin-top: $sp-s;

    #{$item}.isCurrent & {
      @include font('DS-80');
      font-weight: bold;
    }

    span {
      color: $monza-red;
    }

    @include for('tablet') {
      @include font('DS-80');
    }
  }
}
</style>
