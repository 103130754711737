<template>
  <wizard-price-base v-model="model" :label="computedLabel" :editable="$attrs.editable" />
</template>

<script>
// Components
import WizardPriceBase from '@/components/WizardPriceBase.vue';
// Constants
import { MUTATION, GETTER } from '@/store/CONSTANTS';

export const PRICE_LABEL = {
  DEFAULT: 'DEFAULT',
  SQM_PRICE: 'SQM_PRICE',
  STARTING_PRICE: 'STARTING_PRICE',
};

export default {
  name: 'WizardPriceMarketingPrice',
  components: {
    WizardPriceBase,
  },
  props: {
    label: {
      type: String,
      default: PRICE_LABEL.DEFAULT,
      validator(value) {
        return PRICE_LABEL[value];
      },
    },
  },
  computed: {
    computedLabel() {
      switch (this.label) {
        case PRICE_LABEL.SQM_PRICE: {
          const calculatedSqmPrice =
            this.$store.getters[GETTER.WIZARD.CURRENT_DEAL.CALCULATED_SQM_PRICE];

          return `(${this.$formatSqmPrice(calculatedSqmPrice)})`;
        }
        case PRICE_LABEL.STARTING_PRICE:
          return this.$t('WIZARD.PRICE_WIDGET.STARTING_MARKETING_PRICE');
        case PRICE_LABEL.DEFAULT:
        default:
          return this.$t('WIZARD.PRICE_WIDGET.AVERAGE_SELLING_PRICE');
      }
    },
    model: {
      get() {
        return this.$store.getters[GETTER.WIZARD.CURRENT_DEAL.MARKETING_PRICE];
      },
      set(value) {
        this.$store.commit(MUTATION.WIZARD.CURRENT_DEAL.SET_MARKETING_PRICE, value);
      },
    },
  },
};
</script>
