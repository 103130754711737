<template>
  <transition name="fade">
    <router-view />
  </transition>
</template>

<script>
export default {
  name: 'WizardView',
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-active {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
</style>
